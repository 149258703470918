import React from 'react';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';

import {
  BIDS_RAKING,
  BIDS_SENT_BY_TRADER_PATH,
  DOES_NOT_EXIST_PATH,
  PROPOSAL_INFO_PATH,
  VIEW_BID_LIST_PATH,
} from '@routers/constants';
import { useTrader } from '@routers/trader-route';

import ContentLayoutContainer from '@components/layout/content-layout-container';
import { RoundActionButtonProps, RoundTraderActionsBar } from '@components/molecules/proposal-round/actions-bar';
import RoundHeader, { getCurrentProposalFromProcess } from '@components/organisms/proposal-round-header';
import { HeaderSkeleton } from '@components/atoms/proposal-round/header-skeleton';

import useAuth from '@hooks/auth';
import { useGetProcessAndGroupByProposalIdForTrader } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id';
import { ProcessData, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface TraderProposalViewContext {
  proposal: Proposal;
  processData: ProcessData;
}

export function useTraderProposal() {
  return useOutletContext<TraderProposalViewContext>();
}

const TraderProposalRoundLayout: React.FC = () => {
  const { proposalId } = useParams();

  const { authStatus } = useAuth();
  const traderContext = useTrader();
  const traderId = traderContext.traderData.id;

  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const { processData, fetchProcessData, isProcessDataLoading } = useGetProcessAndGroupByProposalIdForTrader();

  React.useEffect(() => {
    if (proposalId && traderId) {
      fetchProcessData(proposalId, traderId);
    }
  }, [proposalId, traderId]);

  const isReadyForDisplay =
    traderContext.isTraderLoaded && !traderContext.loading && authStatus.isAuthenticated && !isProcessDataLoading;

  if (!isReadyForDisplay) return <HeaderSkeleton />;

  const proposal = cloneDeep(getCurrentProposalFromProcess(processData, proposalId));

  return (
    <div className="flex flex-col">
      <RoundHeader
        processData={processData}
        currentProposalId={proposalId}
        goBackProps={{ url: VIEW_BID_LIST_PATH, label: 'Propostas' }}
        urlPathPrefixToMatch={VIEW_BID_LIST_PATH}
        urlSuffixToNavigateTo={BIDS_SENT_BY_TRADER_PATH}
      />
      <ContentLayoutContainer>
        <Outlet context={{ proposal, processData }} />
      </ContentLayoutContainer>
    </div>
  );
};

export default TraderProposalRoundLayout;

const traderActionsButtonList: RoundActionButtonProps[] = [
  {
    urlBuilderFunction: (proposal) => `${VIEW_BID_LIST_PATH}/${proposal.id}/${PROPOSAL_INFO_PATH}`,
    checkIfShouldBeDisplayed: (proposal) => true,
    label: 'Informações',
    eventToBeTracked: 'DETAILED_PROPOSAL_PAGE_INFORMATIONS_BUTTON_CLICKED',
  },
  {
    urlBuilderFunction: (proposal) => `${VIEW_BID_LIST_PATH}/${proposal.id}/${BIDS_SENT_BY_TRADER_PATH}`,
    checkIfShouldBeDisplayed: (proposal) => true,
    label: 'Propostas',
    eventToBeTracked: 'DETAILED_PROPOSAL_PAGE_BIDS_BUTTON_CLICKED',
  },
  {
    urlBuilderFunction: (proposal) => `${VIEW_BID_LIST_PATH}/${proposal.id}/${BIDS_RAKING}`,
    checkIfShouldBeDisplayed: () => true,
    label: 'Ranking',
    eventToBeTracked: 'DETAILED_PROPOSAL_PAGE_BIDS_BUTTON_CLICKED',
  },
];

interface TraderLayoutWithRoundActionsBarProps {
  children: React.ReactNode;
  proposal: Proposal;
}
export const TraderLayoutWithRoundActionsBar: React.FC<TraderLayoutWithRoundActionsBarProps> = ({
  children,
  proposal,
}) => {
  return (
    <>
      <div className="space-y-6">
        <RoundTraderActionsBar proposal={proposal} traderActionsButtonList={traderActionsButtonList} />
        <div className="overflow-y-auto w-full h-full max-h-[calc(100vh-18rem)]">{children}</div>
      </div>
    </>
  );
};
