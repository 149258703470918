import React from 'react';

import { BidWithSavings } from '@hooks/bids/savings/types';
import { orderedBidsForSavings } from '@hooks/bids/utils';
import {
  CompletedSavingsDisplayItem,
  NotRequestedSavingsDisplayItem,
  SavingsWithErrorDisplayItem,
} from './bid-with-savings-display';

interface SavingsScopeProps {
  bids: BidWithSavings[];
  scopeType: 'inside' | 'outside';
}
const SavingsScope: React.FC<SavingsScopeProps> = ({ bids, scopeType }) => {
  const title = scopeType === 'inside' ? 'Dentro do escopo' : 'Fora do escopo';
  const bidsForDisplay = orderedBidsForSavings(bids).filter(({ savings }) =>
    scopeType === 'inside' ? savings.isInsideScope : !savings.isInsideScope,
  );

  return (
    <div className="flex flex-col space-y-2">
      {bidsForDisplay.length === 0 ? (
        <h3 className="font-bold text-heading-xsmall">{`Não há ofertas ${title.toLowerCase()}`}</h3>
      ) : (
        <>
          <h3 className="font-bold text-heading-xsmall">{title}</h3>
          {bidsForDisplay.map((bid) => {
            switch (bid.savings.status) {
              case 'COMPLETED':
                return <CompletedSavingsDisplayItem key={bid.id} {...{ bid }} />;

              case 'NOT_REQUESTED':
                return <NotRequestedSavingsDisplayItem key={bid.id} {...{ bid }} />;

              case 'BLOCKED':
                return <SavingsWithErrorDisplayItem key={bid.id} {...{ bid }} />;

              default:
                throw new Error(`Invalid status type "${bid.savings.status}"`);
            }
          })}
        </>
      )}
    </div>
  );
};

export default SavingsScope;
