import React, { ComponentProps } from 'react';
import { DeepRequired, FieldErrorsImpl, FieldName, FieldPath, RegisterOptions, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';

export interface ExtendedRegisterOptions extends RegisterOptions {
  readOnly?: boolean;
}

export interface InputOptions {
  id: string;
  optionLabel: string;
  value: string;
  optionSelected?: boolean;
}
export interface RadioFormFieldProps<T> extends ComponentProps<'input'> {
  field: FieldPath<T>;
  inputOptions: InputOptions[];
  label?: string;
  options?: ExtendedRegisterOptions;
}

export function RadioFormField<T>({
  field,
  inputOptions,
  options,
  className,
  label,
  ...props
}: RadioFormFieldProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();
  const isInputDisabled = options?.disabled === true;
  const inputColorClass = isInputDisabled ? 'text-neutral-50' : 'text-primary-60';
  const textColorClass = isInputDisabled ? 'text-neutral-50' : '';

  return (
    <div className={twMerge('w-full flex flex-col space-y-1 item-center', className)}>
      {label && (
        <label className="text-paragraph-medium">
          {label}
          {options?.required && <span className="text-danger-60">*</span>}
        </label>
      )}
      {inputOptions.map(({ id, value, optionLabel, optionSelected }, index) => (
        <div key={index} className="flex gap-2 items-center">
          <fieldset disabled={options?.readOnly}>
            <input
              id={id}
              data-cy={id}
              type="radio"
              className={inputColorClass}
              value={value}
              defaultChecked={optionSelected}
              {...register(field, options)}
            />
          </fieldset>
          <label htmlFor={id} className={`text-paragraph-medium ${textColorClass}`}>
            {optionLabel}
          </label>
        </div>
      ))}
      <ErrorMessage
        name={field as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={errors}
        render={({ message }) => <span className="text-paragraph-medium text-danger-30">{message}</span>}
      />
    </div>
  );
}
