import React from 'react';
import { useLocation } from 'react-router-dom';
import { TraderLayoutWithRoundActionsBar, useTraderProposal } from '@components/layout/trader-proposal-round-layout';
import {
  TraderInFinalRoundWithBidsSummary,
  TraderInFinalRoundWithoutBidsSummary,
  TraderInNonFinalRoundWithBidsSummary,
  TraderInNonFinalRoundWithoutBidsSummary,
} from '@components/organisms/trader-bid-summary';
import { useNotification } from '@clarke-energia/foton';
import { useTrader } from '@routers/trader-route';

const BidDisplayForTrader: React.FC = () => {
  const { proposal } = useTraderProposal();
  const { createNotification, deleteNotification } = useNotification();
  const traderContext = useTrader();
  const traderId = traderContext.traderData.id;

  const location = useLocation();
  React.useEffect(() => {
    const state = location.state as { deletedBid?: boolean; createdBid?: boolean; updatedBid?: boolean } | null;
    if (state?.deletedBid) {
      const notificationId = createNotification({
        kind: 'primary',
        label: 'Sucesso!',
        message: 'Sua proposta foi excluída com sucesso!',
      });

      return () => {
        deleteNotification(notificationId);
      };
    }

    if (state?.createdBid) {
      const notificationId = createNotification({
        kind: 'primary',
        label: 'Sucesso!',
        message: 'Sua proposta foi enviada com sucesso!',
      });

      return () => {
        deleteNotification(notificationId);
      };
    }

    if (state?.updatedBid) {
      const notificationId = createNotification({
        kind: 'primary',
        label: 'Sucesso!',
        message: 'Sua proposta foi atualizada com sucesso!',
      });

      return () => {
        deleteNotification(notificationId);
      };
    }
  }, []);

  const thisProposalHasBidsFromThisTrader = proposal.bids.filter((bid) => bid.trader.id === traderId);
  const thisProposalIsInFinalRound = proposal.round === 0;

  if (thisProposalHasBidsFromThisTrader.length > 0) {
    if (thisProposalIsInFinalRound) return <TraderInFinalRoundWithBidsSummary proposal={proposal} />;

    const bid = proposal.bids[0];
    return <TraderInNonFinalRoundWithBidsSummary bid={bid} proposal={proposal} />;
  }

  // Round without bids from this trader
  if (thisProposalIsInFinalRound) return <TraderInFinalRoundWithoutBidsSummary proposal={proposal} />;

  return <TraderInNonFinalRoundWithoutBidsSummary proposal={proposal} />;
};

const TraderBidInfoPage: React.FC = () => {
  const { proposal } = useTraderProposal();
  const traderContext = useTrader();
  const traderId = traderContext.traderData.id;
  proposal.bids = proposal.bids.filter((bid) => bid.trader.id === traderId);
  return (
    <TraderLayoutWithRoundActionsBar {...{ proposal }}>
      <BidDisplayForTrader />
    </TraderLayoutWithRoundActionsBar>
  );
};

export default TraderBidInfoPage;
