import React from 'react';

import { TraderLayoutWithRoundActionsBar, useTraderProposal } from '@components/layout/trader-proposal-round-layout';
import { useFetchDocumentsByEntity } from '@hooks/documents';
import ProposalInformationSummary from '@components/organisms/proposal-summary';

const TraderProposalInformationPage: React.FC = () => {
  const { proposal } = useTraderProposal();
  const documents = useFetchDocumentsByEntity(proposal.id);
  return (
    <TraderLayoutWithRoundActionsBar {...{ proposal }}>
      <ProposalInformationSummary {...{ proposal, documents }} />
    </TraderLayoutWithRoundActionsBar>
  );
};

export default TraderProposalInformationPage;
