import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormField,
  FormSection,
  MultiSelectFormField,
  RadioFormField,
  SelectFormField,
  FileFormField,
  NumericFormField,
  TextAreaFormField,
} from '@components/atoms/form';

import { RetailTrader } from '@hooks/proposals';
import { ProposalFormData } from '@components/molecules/create-proposal/types';
import FinancialGuaranteeFields from '@components/molecules/create-proposal/financial-guarantee-fields';
import { GroupForProposalCreation } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { useGroupDataContext } from './group-data-context';
import { useProposalForm } from '@components/molecules/create-proposal/proposal-form-context';
import { CommissionModalityTypeEnum } from '@utils/translators/proposal';
import { FormTextInput } from '@components/atoms/form/text';
import ComboBoxInput from '@components/atoms/form/combobox';

const specialCharRegex = /[%$./\\]/;

interface FirstStepProps {
  availableTraders: RetailTrader[];
  availableGroups: GroupForProposalCreation[];
}
export const FirstStep: React.FC<FirstStepProps> = ({ availableTraders, availableGroups }) => {
  const { getValues } = useFormContext<ProposalFormData>();
  const { setSelectedGroupId, groupData } = useGroupDataContext();
  const { isCreating } = useProposalForm();
  React.useEffect(() => {
    const groupId = getValues('client.id');
    if (!isCreating && groupId) setSelectedGroupId(groupId);
  }, []);

  return (
    <div className="grid grid-cols-3 gap-6 py-6">
      <div className="col-span-full">
        <FormSection title="Informações do Cliente" subtitle="Informações do cliente para o contrato" />
      </div>

      <div className="col-span-full xl:col-span-1">
        <FormTextInput<ProposalFormData>
          id="process-name"
          field="contract.process.name"
          label="Nome do processo"
          placeholder="Digite o nome do processo"
          options={{
            required: { value: true, message: 'Defina o nome do processo' },
            validate: (value) => !specialCharRegex.test(value) || 'Caracter não permitido',
          }}
        />
      </div>

      <div className="col-span-full xl:col-span-1">
        <ComboBoxInput<ProposalFormData>
          id="client-name"
          field="client.id"
          label="Cliente"
          placeholder="Selecione um cliente"
          inputOptions={availableGroups
            .map((group) => ({ optionLabel: group.name, value: group.id }))
            .sort((a, b) =>
              a.optionLabel.localeCompare(b.optionLabel, 'pt-BR', { numeric: true, sensitivity: 'base' }),
            )}
          options={{
            required: { value: true, message: 'O nome do cliente é obrigatório' },
            onChange: (e: { type: string; target: { value: any; name: string } }) => setSelectedGroupId(e.target.value),
          }}
        />
      </div>

      <div className="col-span-full xl:col-span-1">
        <MultiSelectFormField<ProposalFormData>
          field="selectedUnitIds"
          label="Unidades consumidoras"
          id="proposal-units"
          inputOptions={groupData.units.map(({ id, name }) => ({ value: id, label: name }))}
          placeholder="Selecione unidades"
          options={{ required: { value: true, message: 'Ao menos uma unidade deve ser escolhida' } }}
        />
      </div>

      <div className="col-span-full">
        <FormSection title="Sobre o Contrato" subtitle="Informações sobre o perfil e o contrato de energia" />
      </div>

      <div className="col-span-full xl:col-span-1">
        <RadioFormField<ProposalFormData>
          field="contract.contractType"
          label="Tipo de Contrato"
          inputOptions={[{ id: 'fixed-price', optionLabel: 'Preço Fixo Escalonado', value: 'FIXED_PRICE' }]}
          options={{ required: { value: true, message: 'O tipo de contrato deve ser escolhido' } }}
        />
      </div>

      <div className="col-span-full xl:col-span-1">
        <RadioFormField<ProposalFormData>
          field="contract.commissionModality"
          label="Modalidade de contratação"
          inputOptions={[
            { id: 'retail', optionLabel: CommissionModalityTypeEnum['RETAILER'], value: 'RETAILER' },
            { id: 'wholesale', optionLabel: CommissionModalityTypeEnum['WHOLESALER'], value: 'WHOLESALER' },
            { id: 'no-preference', optionLabel: CommissionModalityTypeEnum['NO_PREFERENCE'], value: 'NO_PREFERENCE' },
          ]}
          options={{ required: { value: true, message: 'O tipo de contrato deve ser escolhido' } }}
        />
      </div>

      <div className="col-span-full xl:col-span-1">
        <RadioFormField<ProposalFormData>
          field="contract.proposalModality"
          label="Qual o Tipo de Proposta"
          inputOptions={[
            { id: 'indicative', optionLabel: 'Indicativa', value: 'INDICATIVE' },
            { id: 'firm', optionLabel: 'Firme', value: 'FIRM' },
          ]}
          options={{ required: { value: true, message: 'O tipo de proposta deve ser escolhida' } }}
        />
      </div>

      <div className="col-span-full xl:col-span-1">
        <MultiSelectFormField<ProposalFormData>
          field="contract.traders"
          label="Comercializadoras"
          id="traders"
          inputOptions={availableTraders.map(({ id, name }) => ({ value: id, label: name }))}
          placeholder="Selecione todas as Comercializadoras"
          options={{ required: { value: true, message: 'Ao menos uma comercializadora deve ser escolhida' } }}
          enableSelectAll
        />
      </div>
      <div className="col-span-full xl:col-span-1">
        <NumericFormField<ProposalFormData>
          field="contract.lowerFlexibility"
          id="lower-flexibility"
          label="Flexibilidade inferior (%)"
          formatProps={{
            placeholder: 'Flexibilidade(%)',
            prefix: '-',
            suffix: '%',
            fixedDecimalScale: true,
            decimalScale: 0,
            allowNegative: false,
          }}
          options={{
            required: { value: true, message: 'Defina o valor de limite de flexibilidade superior' },
          }}
        />
      </div>
      <div className="col-span-full xl:col-span-1">
        <NumericFormField<ProposalFormData>
          field="contract.upperFlexibility"
          id="upper-flexibility"
          label="Flexibilidade superior (%)"
          formatProps={{
            placeholder: 'Flexibilidade(%)',
            prefix: '+',
            suffix: '%',
            fixedDecimalScale: true,
            decimalScale: 0,
            allowNegative: false,
          }}
          options={{
            required: { value: true, message: 'Defina o valor de limite de flexibilidade superior' },
          }}
        />
      </div>
      <div className="col-span-full xl:col-span-1">
        <FormField<ProposalFormData>
          field="contract.proposalDeadline.date"
          id="proposal-deadline-date"
          label="Data de entrega da proposta"
          type="date"
          options={{
            required: { value: true, message: 'Defina a data de prazo para entrega' },
          }}
        />
      </div>
      <div className="col-span-full xl:col-span-1">
        <SelectFormField<ProposalFormData>
          field="contract.proposalDeadline.time"
          id="proposal-deadline-time"
          label="Hora de entrega da proposta"
          placeholder="Selecione um prazo"
          inputOptions={[...Array(16).keys()].map((_, index) => {
            const formattedHour = `${(index + 8).toLocaleString('pt-BR', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:00`;
            return { optionLabel: formattedHour, value: formattedHour };
          })}
          options={{
            required: { value: true, message: 'Defina a hora para prazo para entrega' },
          }}
        />
      </div>

      <div className="col-span-full">
        <div className="grid grid-cols-1 gap-6 xl:grid-cols-3">
          <FinancialGuaranteeFields />
        </div>
      </div>

      <div className="col-span-full">
        <FileFormField<ProposalFormData>
          name="contract.files"
          id="contract-files"
          label={{ text: 'Arquivos' }}
          sublabel={{ text: 'Anexe documentos gerais do cliente' }}
          fileSizeLimitInBytes={30 * 1020 * 1024}
          fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
          multiple
        />
      </div>

      <div className="col-span-full">
        <TextAreaFormField<ProposalFormData> field="contract.note" id="contract-note" label="Observações" />
      </div>
    </div>
  );
};
