import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  DOES_NOT_EXIST_PATH,
  VIEW_BID_LIST_PATH,
  VIEW_PROPOSAL_LIST_PATH,
  VIEW_SAVINGS_RESULTS_PATH,
} from '@routers/constants';
import { ListSkeleton } from '@components/molecules/process-list-panel/list-panel-skeleton';
import ContentLayoutContainer from '@components/layout/content-layout-container';
import useAuth from '@hooks/auth';

const HomePage: React.FC = () => {
  const {
    user,
    authStatus: {
      isAuthenticated,
      loginStatus: { isLoginInProgress },
    },
  } = useAuth();

  if (isLoginInProgress || !isAuthenticated)
    return (
      <ContentLayoutContainer>
        <div className="h-screen">
          <ListSkeleton isAdmin={false} />
        </div>
      </ContentLayoutContainer>
    );

  if (user.roles?.includes('admin')) return <Navigate to={VIEW_PROPOSAL_LIST_PATH} replace />;
  if (user.roles?.includes('supplier')) return <Navigate to={VIEW_BID_LIST_PATH} replace />;
  if (user.roles?.includes('customer')) return <Navigate to={VIEW_SAVINGS_RESULTS_PATH} replace />;

  return <Navigate to={DOES_NOT_EXIST_PATH} />;
};

export default HomePage;
