import React from 'react';

import { TextAreaFormField } from '@components/atoms/form';
import { OpenFinalRoundFormData } from '@components/molecules/open-final-round/types';
import { useTraderSelectionContext } from '@components/molecules/open-final-round/trader-selection-context';

const SecondStep: React.FC = () => {
  const { selectedTradersFieldArray } = useTraderSelectionContext();

  return (
    <>
      <p className="text-paragraph-medium">
        Informe que ela foi a comercializadora escolhida para fornecer energia para o cliente! Escreva os motivos que a
        fizeram vencedora.
      </p>
      {selectedTradersFieldArray.fields.map((field, index) => {
        return (
          <TextAreaFormField<OpenFinalRoundFormData>
            key={field.id}
            id={`selected-trader-${field.trader.id}-feedback`}
            field={`feedbacksForTradersThatAreWinners.${index}.feedback` as const}
            label={`Feedback ${field.trader.name}`}
            options={{
              required: { value: true, message: `É necessário definir o feedback para ${field.trader.name}.` },
            }}
          />
        );
      })}
    </>
  );
};

export default SecondStep;
