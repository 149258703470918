import React from 'react';

import { TraderLayoutWithRoundActionsBar, useTraderProposal } from '@components/layout/trader-proposal-round-layout';
import { useTrader } from '@routers/trader-route';

import { useGetProcessRanking } from '@hooks/process/queries/get-process-tader-ranking';
import RankingDisplay from '@components/organisms/process-ranking';

const TraderRankingPage: React.FC = () => {
  const {
    proposal,
    processData: { proposals },
  } = useTraderProposal();
  const trader = useTrader();
  const totalOfProposals = proposals.length;

  const [targetProposalId, setTargetProposalId] = React.useState<string>(proposal.id);

  const { proposalRound, ranking, loading } = useGetProcessRanking(targetProposalId, trader.traderData);

  React.useEffect(() => {
    const lastRoundProposal = proposals[totalOfProposals - 2];

    if (loading) return;

    if (ranking.length === 0 && lastRoundProposal) {
      setTargetProposalId(lastRoundProposal.id);
    }
  }, [ranking, loading]);

  return (
    <TraderLayoutWithRoundActionsBar {...{ proposal }}>
      <RankingDisplay
        ranking={ranking}
        isLoading={loading}
        title={`Ranking de preços da ${proposalRound}ª rodada`}
        targetTraderId={trader.traderData.id}
      />
    </TraderLayoutWithRoundActionsBar>
  );
};

export default TraderRankingPage;
