import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const HeaderSkeleton: React.FC = () => {
  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 pt-6 pb-8 pl-6 border-b border-black md:pl-12 shrink">
        <div className="col-span-12 lg:col-span-10">
          <div className="flex flex-col gap-x-14 space-y-2 sm:flex-row sm:justify-between">
            <div className="flex flex-col items-stretch space-y-2 sm:flex-row sm:items-center sm:space-x-20 sm:grow">
              <div className="flex flex-col space-y-2 max-w-md sm:justify-center grow">
                <Skeleton width="100%" height="100%" containerClassName="w-16" className="text-paragraph-medium" />
                <Skeleton width="100%" height="100%" className="text-heading-2xlarge" />
              </div>
              <div className="w-24 h-5">
                <Skeleton width="100%" height="100%" className="text-paragraph-medium" />
              </div>
            </div>
            <div className="flex justify-self-start space-x-4 sm:justify-self-auto sm:self-end">
              <Skeleton width="100%" height="100%" containerClassName="w-28 h-8" />
              <Skeleton width="100%" height="100%" containerClassName="w-28 h-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
