import React from 'react';

const CompilationNotAllowedDisplay: React.FC = () => {
  return (
    <div className="flex flex-col py-8 space-y-2 max-w-xl">
      <h1 className="text-heading-2xlarge">Esta proposta não possui ofertas de fornecedoras para compilar!</h1>
    </div>
  );
};

export default CompilationNotAllowedDisplay;
