import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FormSection, NumericFormField } from '@components/atoms/form';
import { BidFormData } from './types';
import { formatDate } from '@utils/text';

interface ValuesFieldsProps {
  supply: { start: Date; end: Date };
}
const ValuesFields: React.FC<ValuesFieldsProps> = ({ supply }) => {
  const { control, watch } = useFormContext<BidFormData>();
  const { fields } = useFieldArray<BidFormData, 'productAndPrices.prices.values'>({
    name: 'productAndPrices.prices.values',
    control,
  });

  const watchContractType = watch('productAndPrices.contractType');

  const isFixedPrice = watchContractType === 'FIXED_PRICE';
  const placeholder = isFixedPrice ? 'R$' : '%';
  const prefix = isFixedPrice ? 'R$ ' : undefined;
  const suffix = isFixedPrice ? undefined : '%';
  const decimalScale = isFixedPrice ? 2 : 0;

  return (
    <>
      <div className="grid grid-cols-1 gap-x-5 py-6 md:grid-cols-3">
        <FormSection
          title="Preço da energia"
          subtitle="Atente-se ao início e fim do suprimento e insira o valor da energia ano a ano."
        />
        <div className="grid grid-cols-1 col-span-2 gap-5 lg:grid-cols-2">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col border-t border-black">
              <p className="text-paragraph-medium">Início do suprimento</p>
              <p className="text-paragraph-medium" data-cy="supply-start-date">
                {formatDate(supply.start, 'DD/MM/YYYY')}
              </p>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <div className="flex flex-col border-t border-black">
              <p className="text-paragraph-medium">Fim do suprimento</p>
              <p className="text-paragraph-medium" data-cy="supply-end-date">
                {formatDate(supply.end, 'DD/MM/YYYY')}
              </p>
            </div>
          </div>

          {fields.map((field, index) => {
            const year = watch(`productAndPrices.prices.values.${index}.year`);
            const errorMessage = `O valor do ano ${year} precisa ser definido`;

            return (
              <div key={field.id} className="col-span-1">
                <NumericFormField<BidFormData>
                  id={`value-${year}`}
                  className="flex flex-col space-y-1"
                  field={`productAndPrices.prices.values.${index}.amount` as const}
                  label={`${year}`}
                  formatProps={{
                    placeholder,
                    prefix,
                    suffix,
                    fixedDecimalScale: true,
                    decimalScale,
                  }}
                  options={{
                    required: { value: true, message: errorMessage },
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ValuesFields;
