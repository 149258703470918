import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '@hooks/auth';

import { HOME_PATH } from './constants';

interface AdminRouteProps {
  children: JSX.Element;
}
const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const {
    user,
    authStatus: { isAuthenticated },
  } = useAuth();

  if (isAuthenticated && !user.roles?.includes('admin')) return <Navigate to={HOME_PATH} replace />;

  return children;
};

export default AdminRoute;
