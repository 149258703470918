import React from 'react';
import { useFormContext } from 'react-hook-form';

import { formatDate } from '@utils/text';
import { Unpacked } from '@utils/types';

import { FileFormField, SelectFormField } from '@components/atoms/form';
import { SendSignedProposalFormData } from '@components/molecules/send-signed-proposal/types';
import { useTraderSelectionContext } from '@components/molecules/send-signed-proposal/trader-selection-context';
import {
  getChosenFeedbackTemplate,
  getRefusedFeedbackTemplate,
} from '@components/organisms/open-final-round/form-helpers';

import { ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const buildTradersForSelectionOptions = (proposal: Proposal) =>
  proposal.traders
    .filter((trader) => trader.bid !== undefined)
    .map((trader) => {
      const formattedLabel = `${trader.name} • ${formatDate(
        (trader.bid as Unpacked<Proposal['bids']>).createdAt,
      ).toUpperCase()}`;
      return { id: trader.id, value: trader.id, optionLabel: formattedLabel };
    });

const FirstStep: React.FC<{ proposal: Proposal; dataForFeedback: ProposalForFeedback }> = ({
  proposal,
  dataForFeedback,
}) => {
  const inputOptions = buildTradersForSelectionOptions(proposal);

  const { watch, setValue } = useFormContext<SendSignedProposalFormData>();
  const watchSelectedTrader = watch('selectedTrader');

  const { refusedTradersFieldArray } = useTraderSelectionContext();

  React.useEffect(() => {
    if (watchSelectedTrader) {
      refusedTradersFieldArray.replace(
        proposal.traders
          .filter((trader) => trader.bid !== undefined && trader.id !== watchSelectedTrader)
          .map((trader) => ({
            trader: { id: trader.id, name: trader.name },
            feedback: getRefusedFeedbackTemplate(trader.id, dataForFeedback),
          })),
      );

      const selectedTrader = proposal.traders.find((t) => t.id === watchSelectedTrader);
      if (selectedTrader !== undefined) {
        setValue('selectedTraderFeedback', getChosenFeedbackTemplate(dataForFeedback));
      }
    }
  }, [watchSelectedTrader]);

  return (
    <>
      <div className="flex flex-col space-y-2">
        <p className="text-paragraph-medium">
          Parabéns! O cliente assinou uma proposta firme! Envie para a comercializadora o documento assinado para
          finalizar o processo.
        </p>

        <SelectFormField<SendSignedProposalFormData>
          field="selectedTrader"
          id="selected-trader"
          label="Selecione a proposta firme assinada"
          inputOptions={inputOptions}
          options={{ required: { value: true, message: 'A comercializadora ganhadora deve ser escolhida' } }}
        />

        <FileFormField<SendSignedProposalFormData>
          name="files"
          id="signed-proposal-files"
          label={{ text: 'Anexe a proposta firme assinada', className: 'text-paragraph-medium' }}
          options={{ required: { value: true, message: 'É preciso selecionar ao menos um arquivo' } }}
          fileSizeLimitInBytes={30 * 1020 * 1024}
          fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
        />
      </div>
    </>
  );
};

export default FirstStep;
