import React from 'react';

import { HeroIcon } from '@clarke-energia/foton';
import style from './style.module.css';

interface InfoRanking {
  title: string;
  text: string;
}

const EmptyRankingDisplay: React.FC<InfoRanking> = ({ text, title }) => {
  return (
    <div className={style.emptyRankingContainer}>
      <div className="p-3 w-7 h-7 rounded-md bg-danger-20">
        <HeroIcon icon="FaceFrownIcon" />
      </div>
      <h1 className={style.title}>{title}</h1>
      <p className={style.emptyText}>{text}</p>
    </div>
  );
};

export default EmptyRankingDisplay;
