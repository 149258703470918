import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ProposalCompilationSkeleton: React.FC = () => {
  return (
    <div>
      <div className="flex flex-col py-8 space-y-4 max-w-xl">
        <Skeleton className="text-heading-2xlarge" />
        <div className="flex flex-col space-y-8">
          <Skeleton className="text-paragraph-medium" count={3} />
          <div className="mr-auto w-52 h-8">
            <Skeleton width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalCompilationSkeleton;
