import React from 'react';

import HeroIcon from '@components/atoms/hero-icon';

interface FormHeaderProps {
  headers: string[];
  activeHeaderNum: number;
}
export const FormHeader: React.FC<FormHeaderProps> = ({ headers, activeHeaderNum }) => {
  return (
    <div className="flex justify-between">
      {headers.map((header, index) => {
        const stepNum = index + 1;
        const isStepCompleted = activeHeaderNum > stepNum;

        const textColorClass = activeHeaderNum == stepNum ? 'text-primary-60' : 'text-black';
        const borderColorClass = activeHeaderNum == stepNum ? 'border-primary-60' : 'border-black';
        const stepColorClass = activeHeaderNum == stepNum ? 'text-primary-60' : 'text-neutral-50';
        return (
          <div
            className={`flex flex-col items-center sm:flex-row pb-4 border-b ${borderColorClass} space-x-3 space-y-2 sm:space-y-1 w-full`}
            key={index}
          >
            {isStepCompleted ? (
              <p
                className={`py-1 px-1.5 text-paragraph-small rounded-full border ${borderColorClass} ${textColorClass} bg-primary-60`}
              >
                <HeroIcon icon="CheckIcon" extraClassNames="w-6 h-6" />
              </p>
            ) : (
              <p
                className={`py-1 px-1.5 text-paragraph-small rounded-pill border ${borderColorClass} ${textColorClass}`}
              >
                {`0${stepNum}`}
              </p>
            )}
            <div className="flex flex-col w-14 md:w-full">
              <p className={`text-paragraph-small ${stepColorClass}`}>{`Passo ${stepNum}`}</p>
              <p className={`text-paragraph-small lg:text-paragraph-medium ${textColorClass}`}>{header}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
