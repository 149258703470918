import React from 'react';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { DeepRequired, FieldErrorsImpl, FieldName, Path, RegisterOptions, useFormContext } from 'react-hook-form';

export interface TextAreaFormFieldProps<T> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions;
}

export function TextAreaFormField<T>({ field, id, label, placeholder, options }: TextAreaFormFieldProps<T>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();
  return (
    <div className="flex flex-col space-y-1">
      <label htmlFor={id} className="text-paragraph-medium">
        {label}
        {options?.required && <span className="text-danger-60">*</span>}
      </label>
      <textarea
        id={id}
        data-cy={id}
        className="h-32 rounded-md border border-black resize-y text-paragraph-medium"
        placeholder={placeholder}
        {...register(field, options)}
      ></textarea>
      <ErrorMessage
        name={field as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={errors}
        render={({ message }) => <span className="text-paragraph-medium text-danger-30">{message}</span>}
      />
    </div>
  );
}
