import React from 'react';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import {
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  FieldName,
  FieldPath,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import Select, {
  createFilter,
  GroupBase,
  InputProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  components,
  PlaceholderProps,
  OptionProps,
} from 'react-select';

import HeroIcon from '@components/atoms/hero-icon';

type OptionDefinition = { label: string; value: string; isFixed?: boolean };

function Input<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group>,
) {
  return (
    <components.Input
      {...props}
      data-cy={props.id}
      inputClassName="outline-none border-none shadow-none focus:ring-transparent"
    />
  );
}

function MultiValueContainer<
  Option extends OptionDefinition,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueGenericProps<Option, IsMulti, Group>) {
  const isFixed = props.data.isFixed;
  const bgColorClass = isFixed ? 'bg-primary-70' : 'bg-primary-60';

  return (
    <components.MultiValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
        className: `flex items-center text-center text-white rounded-full px-2 py-0.5 ${bgColorClass}`,
      }}
    />
  );
}

function MultiValueLabel<
  Option extends OptionDefinition,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueGenericProps<Option, IsMulti, Group>) {
  const isFixed = props.data.isFixed;

  const inputId = props.selectProps.inputId;
  const optionValue = props.data.value;

  const testId = `${inputId}-selected-option-${optionValue}`;
  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{
        ...props.innerProps,
        className: `text-white ${isFixed ? '' : 'pr-1'}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-cy': testId,
      }}
    />
  );
}

function Placeholder<
  Option extends OptionDefinition,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: PlaceholderProps<Option, IsMulti, Group>) {
  return <components.Placeholder {...props} innerProps={{ ...props.innerProps, className: 'text-paragraph-medium' }} />;
}

function MultiValueRemove<
  Option extends OptionDefinition,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueRemoveProps<Option, IsMulti, Group>) {
  const isFixed = props.data.isFixed;

  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{ ...props.innerProps, className: isFixed ? 'hidden text-primary-70' : 'text-white' }}
    >
      <HeroIcon icon="XCircleIcon" extraClassNames="w-5 h-5" />
    </components.MultiValueRemove>
  );
}

function Option<
  Option extends OptionDefinition,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: OptionProps<Option, IsMulti, Group>) {
  const inputId = props.selectProps.inputId;
  const testId = `${inputId}-option-${props.data.value}`;

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-cy': testId,
      }}
    />
  );
}

export interface MultiSelectFormFieldProps<T> {
  field: FieldPath<T>;
  label: string;
  id: string;
  inputOptions: OptionDefinition[];
  placeholder?: string;
  options?: RegisterOptions;
  enableSelectAll?: boolean;
}
export function MultiSelectFormField<T>({
  field,
  label,
  id,
  inputOptions,
  placeholder,
  options,
  enableSelectAll,
}: MultiSelectFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  const optionsForSelect = enableSelectAll
    ? [{ label: 'Selecionar todos', value: 'ALL' }, ...inputOptions]
    : inputOptions;
  return (
    <div className="flex flex-col space-y-1">
      <label className="text-paragraph-medium">
        {label}
        {options?.required && <span className="text-danger-60">*</span>}
      </label>
      <Controller
        control={control}
        name={field}
        rules={options}
        render={({ field: { ref, name, onChange, onBlur, value } }) => {
          const optionsValues = inputOptions.map((option) => option.value);
          const translatedValue: typeof inputOptions = [];

          (value as string[]).forEach((v) => {
            const indexOfOption = optionsValues.indexOf(`${v}`);
            if (indexOfOption > -1) translatedValue.push(inputOptions[indexOfOption]);
          });

          return (
            <Select
              options={optionsForSelect}
              isMulti={true}
              isSearchable
              inputId={id}
              id={`${id}-multi-select-container`}
              ref={ref}
              value={translatedValue}
              onBlur={onBlur}
              className="rounded-md border border-black"
              components={{
                Input,
                MultiValueContainer,
                MultiValueLabel,
                MultiValueRemove,
                Placeholder,
                Option,
              }}
              placeholder={placeholder}
              name={name}
              onChange={(newValue, actionMeta) => {
                switch (actionMeta.action) {
                  case 'remove-value':
                  case 'pop-value': {
                    // cancel the individual option removal if fixed
                    if (actionMeta.removedValue.isFixed) return;

                    break;
                  }
                  case 'clear': {
                    // remove all options except the ones set as fixed
                    newValue = inputOptions.filter((o) => o.isFixed);
                    break;
                  }

                  default:
                    break;
                }

                const isAllSelected = newValue.find((option) => option.value === 'ALL');
                const result =
                  isAllSelected !== undefined ? optionsForSelect.filter((option) => option.value !== 'ALL') : newValue;
                onChange(result.map((selected) => selected.value));
              }}
              filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: 'any',
                stringify: (option) => `${option.label}`,
                trim: true,
              })}
            />
          );
        }}
      />
      <ErrorMessage
        name={field as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={errors}
        render={({ message }) => <span className="text-paragraph-medium text-danger-30">{message}</span>}
      />
    </div>
  );
}
