import { Button } from '@clarke-energia/foton';
import React from 'react';

interface ReturnButtonProps {
  callback?: () => void;
  isButtonDisabled: boolean;
}
const ReturnButton: React.FC<ReturnButtonProps> = ({ callback, isButtonDisabled = false }) => {
  return <Button kind="secondary" type="button" label="Voltar" disabled={isButtonDisabled} onClick={callback} />;
};

interface GoForwardButtonProps {
  label: string;
  callback?: () => void;
  isButtonDisabled: boolean;
  loading?: boolean;
}
const GoForwardButton: React.FC<GoForwardButtonProps> = ({
  label,
  callback,
  isButtonDisabled = false,
  loading = false,
}) => {
  return (
    <Button
      kind="primary"
      type="button"
      label={label}
      onClick={callback}
      customAttrs={{ 'data-cy': 'next-step-button' }}
      disabled={isButtonDisabled}
      loading={loading}
    />
  );
};

interface NavigationControlsProps {
  currentStep: number;
  controlParams: {
    maxStep: number;
    cancelFormCallback: () => void;
    changeStepCallback: (nextStepValue: number) => void;
    endStepCallback: () => Promise<void>;
    textLabels: {
      endStep: string;
      changeStep: string;
    };
  };
}
export const NavigationControls: React.FC<NavigationControlsProps> = ({ currentStep, controlParams }) => {
  const { maxStep, cancelFormCallback, changeStepCallback, endStepCallback, textLabels } = controlParams;

  const [isEndStepCallbackInProgress, setIsEndStepCallbackInProgress] = React.useState<boolean>(false);

  const submitCallback = async () => {
    setIsEndStepCallbackInProgress(true);
    await endStepCallback();
    setIsEndStepCallbackInProgress(false);
  };

  if (currentStep === 1)
    return (
      <div className="flex flex-col gap-4 sm:flex-row">
        <ReturnButton callback={cancelFormCallback} isButtonDisabled={false} />
        <GoForwardButton
          label={textLabels.changeStep}
          callback={() => changeStepCallback(currentStep + 1)}
          isButtonDisabled={false}
        />
      </div>
    );
  else if (currentStep === maxStep)
    return (
      <div className="flex flex-col gap-4 sm:flex-row">
        <ReturnButton
          callback={() => changeStepCallback(currentStep - 1)}
          isButtonDisabled={isEndStepCallbackInProgress}
        />
        <GoForwardButton
          label={textLabels.endStep}
          callback={submitCallback}
          isButtonDisabled={isEndStepCallbackInProgress}
          loading={isEndStepCallbackInProgress}
        />
      </div>
    );

  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      <ReturnButton callback={() => changeStepCallback(currentStep - 1)} isButtonDisabled={false} />
      <GoForwardButton
        label={textLabels.changeStep}
        callback={() => changeStepCallback(currentStep + 1)}
        isButtonDisabled={false}
      />
    </div>
  );
};
