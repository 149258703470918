const configuration = {
  GATEWAY_API_HOST: import.meta.env.VITE_GATEWAY_API_HOST,
  CUSTOMER_API_HOST: import.meta.env.VITE_CUSTOMER_API_HOST,
  SAVINGS_ROUTE: import.meta.env.VITE_SAVINGS_ROUTE,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  IS_IN_PRODUCTION: import.meta.env.PROD,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
};

// canary check
if (!configuration.GATEWAY_API_HOST) {
  throw `Invalid GATEWAY_APY_HOST! value found: ${configuration.GATEWAY_API_HOST}`;
}

export default configuration;
