import React from 'react';

import { LayoutWithRoundActionsBar, useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import { useFetchDocumentsByEntity } from '@hooks/documents';
import ProposalInformationSummary from '@components/organisms/proposal-summary';

const AdminProposalInformationPage: React.FC = () => {
  const { proposal } = useAdminProposal();
  const documents = useFetchDocumentsByEntity(proposal.id);
  return (
    <LayoutWithRoundActionsBar proposal={proposal}>
      <ProposalInformationSummary {...{ proposal, documents }} />
    </LayoutWithRoundActionsBar>
  );
};

export default AdminProposalInformationPage;
