import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import cloneDeep from 'lodash.clonedeep';
import { ButtonIcon } from '@clarke-energia/foton';

import { Bid } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import BidDisplay from './bid-display';
import { BidsForCalculation, ProposalCompilationFormData } from './types';
import AccordionItem from '../accordion';

const addBidToScope = (bid: Bid, scope: BidsForCalculation) => ({ ...scope, ...{ [bid.id]: bid } });
const removeBidFromScope = (bid: Bid, scope: BidsForCalculation) => {
  if (Object.keys(scope).includes(bid.id)) {
    const { [bid.id]: _, ...remainingBids } = scope;
    return { ...remainingBids };
  }
  return { ...scope };
};

interface BidScopeSelectorItemProps {
  scope: BidsForCalculation;
  scopeType: 'inside' | 'outside';
  onClickCallback: (bid: Bid) => void;
}
const BidScopeSelectorItem: React.FC<BidScopeSelectorItemProps> = ({ scope, scopeType, onClickCallback }) => {
  const values = Object.values(scope);

  const {
    register,
    formState: { errors },
  } = useFormContext<ProposalCompilationFormData>();
  return (
    <div
      ref={
        scopeType === 'inside'
          ? register('bidCategorization.bidsInsideScope', {
              validate: (v) => Object.keys(v).length > 0 || 'Ao menos uma proposta dentro do escopo é necessária.',
            }).ref
          : register('bidCategorization.bidsOutsideScope').ref
      }
      data-cy={`${scopeType}-scope-selector-panel`}
      className="space-y-4"
    >
      {values.length === 0 ? (
        <>
          <p className="py-4 text-center rounded-md border-2 border-dashed text-paragraph-medium text-neutral-50 border-neutral-50">
            <b>Nenhuma proposta aqui. </b>
            {`Utilize as setas para trazer as propostas que estão ${
              scopeType === 'inside' ? 'dentro' : 'fora'
            } do escopo solicitado.`}
          </p>
          <ErrorMessage
            name={scopeType === 'inside' ? 'bidsInsideScope' : 'bidsOutsideScope'}
            errors={errors}
            render={({ message }) => <span className="text-paragraph-medium text-danger-30">{message}</span>}
          />
        </>
      ) : (
        values
          // natural sort of bids by trader name
          .sort((a, b) => a.trader.name.localeCompare(b.trader.name, 'pt-BR', { numeric: true, sensitivity: 'base' }))
          .map((bid) => {
            return (
              <div key={`bid-${bid.id}`} className="flex space-x-4">
                <div className="grow">
                  <AccordionItem label={bid.trader.name} initiallyOpen={false}>
                    <div className="p-4 space-y-2">
                      <BidDisplay bid={bid} />
                    </div>
                  </AccordionItem>
                </div>
                <ButtonIcon
                  customAttrs={{ 'data-cy': `select-button-bid-${bid.id}` }}
                  kind="ghost"
                  type="button"
                  icon={scopeType === 'inside' ? 'ArrowDownIcon' : 'ArrowUpIcon'}
                  onClick={() => onClickCallback(bid)}
                />
              </div>
            );
          })
      )}
    </div>
  );
};

const BidForCalculationSelector: React.FC = () => {
  const { setValue, getValues } = useFormContext<ProposalCompilationFormData>();
  const [bidsInsideScope, setBidsInsideScope] = React.useState<BidsForCalculation>(
    cloneDeep(getValues('bidCategorization.bidsInsideScope')),
  );
  const [bidsOutsideScope, setBidsOutsideScope] = React.useState<BidsForCalculation>(
    cloneDeep(getValues('bidCategorization.bidsOutsideScope')),
  );

  React.useEffect(() => {
    setValue('bidCategorization.bidsInsideScope', cloneDeep(bidsInsideScope));
  }, [bidsInsideScope]);

  React.useEffect(() => {
    setValue('bidCategorization.bidsOutsideScope', cloneDeep(bidsOutsideScope));
  }, [bidsOutsideScope]);

  const onClickRemoveBidEvent = (bid: Bid) => {
    setBidsInsideScope((prev) => {
      return { ...removeBidFromScope(bid, prev) };
    });
    setBidsOutsideScope((prev) => {
      return { ...addBidToScope(bid, prev) };
    });
  };

  const onClickAddBidEvent = (bid: Bid) => {
    setBidsOutsideScope((prev) => {
      return { ...removeBidFromScope(bid, prev) };
    });
    setBidsInsideScope((prev) => {
      return { ...addBidToScope(bid, prev) };
    });
  };

  return (
    <>
      <div className="space-y-2 flex-flex-col">
        <h2 className="font-bold text-heading-xsmall">Dentro do escopo</h2>
        <BidScopeSelectorItem scope={bidsInsideScope} scopeType="inside" onClickCallback={onClickRemoveBidEvent} />
        <h2 className="font-bold text-heading-xsmall">Fora do escopo</h2>
        <BidScopeSelectorItem scope={bidsOutsideScope} scopeType="outside" onClickCallback={onClickAddBidEvent} />
      </div>
    </>
  );
};

export default BidForCalculationSelector;
