import React from 'react';
import { useMatch, useParams, Link } from 'react-router-dom';

import { ProcessData } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface ProposalRoundsNavigationProps {
  processData: ProcessData;
  urlPathPrefixToMatch: string;
  urlSuffixToNavigateTo: string;
}

export const ProposalRoundsNavigation: React.FC<ProposalRoundsNavigationProps> = ({
  processData,
  urlPathPrefixToMatch,
  urlSuffixToNavigateTo,
}) => {
  const { proposalId } = useParams();
  const matchInfo = useMatch(`${urlPathPrefixToMatch}/:id/info`);
  return (
    <>
      {processData.proposals.map(({ id, round }) => {
        const label = round !== 0 ? `${round}ª Rodada` : 'Final';
        const colorClass = proposalId === id ? 'text-white bg-black' : 'text-black bg-white hover:bg-neutral-20';
        const destinationPath = matchInfo ? 'info' : urlSuffixToNavigateTo;
        return (
          <Link
            key={id}
            role="button"
            to={`${urlPathPrefixToMatch}/${id}/${destinationPath}`}
            className={`font-medium rounded-3xl py-1.5 px-6 border border-black ${colorClass}`}
          >
            {label}
          </Link>
        );
      })}
    </>
  );
};
