import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useFormContext } from 'react-hook-form';

import { FormSection } from '@components/atoms/form';
import { RetailTrader } from '@hooks/proposals';
import FieldValueDisplaySection, {
  FieldForDisplay,
} from '@components/molecules/create-proposal/third-step-field-value-for-display-section';
import ConsumptionHistoryTable from '@components/molecules/create-proposal/unit-history-display-table';
import AccordionItem from '@components/molecules/accordion';
import { useGroupDataContext } from './group-data-context';
import { Unpacked } from '@utils/types';
import { GroupDataForProposalCreation } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { formatCNPJ, formatCPF, formatCurrency, formatDateFromString, formatNumber } from '@utils/text';
import { EnergyTypeEnum, SubmarketEnum, TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';
import { ProposalFormData } from '@components/molecules/create-proposal/types';

import EntityInformationContents, { EntityValueForDisplayProps } from './entity-display';

const clientInfo: FieldForDisplay[] = [
  { label: 'Cliente', field: 'client.name' },
  { label: 'Documento Contratante', field: 'client.document' },
  { label: 'Razão Social', field: 'client.companyName' },
  { label: 'Número de Unidades', field: 'units' },
];

const contractCommercialConditionsInfo: FieldForDisplay[] = [
  { label: 'Tipo de Contrato', field: 'contract.contractType' },
  { label: 'Comercializadoras', field: 'contract.traders', truncate: true },
  { label: 'Flexibilidade inferior', field: 'contract.lowerFlexibility' },
  { label: 'Flexibilidade superior', field: 'contract.upperFlexibility' },
  { label: 'Tipo de Proposta', field: 'contract.proposalModality' },
  { label: 'Prazo de Envio de Proposta', field: 'contract.proposalDeadline', truncate: true },
  { label: 'Tipo de Garantia', field: 'contract.guaranteeType' },
  { label: 'Meses de Faturamento', field: 'contract.guaranteeMonths' },
  { label: 'Início de suprimento', field: 'contract.supplyStartDate' },
  { label: 'Fim de suprimento', field: 'contract.supplyEndDate' },
];

interface ExtendedUnit extends Unpacked<GroupDataForProposalCreation['units']> {
  state: string;
  supplyStartDate: string;
  supplyEndDate: string;
}
const unitDefinitions: {
  field: EntityValueForDisplayProps<ExtendedUnit>['field'];
  label: string | ((entity: ExtendedUnit) => string);
  truncate?: boolean;
}[] = [
  { field: 'name', label: 'Nome da unidade' },
  { field: 'legalName', label: 'Razão Social' },
  { field: 'unitType', label: 'Classificação', truncate: true },
  { field: 'energyType', label: 'Tipo de energia' },
  { field: 'submarket', label: 'Submercado' },
  { field: 'state', label: 'Estado' },
  { field: 'edc', label: 'Distribuidora', truncate: true },
  { field: 'tariffSubgroup', label: 'Subgrupo' },
  { field: 'tariffModality', label: 'Modalidade tarifária' },
  { field: 'contractedDemandOffPeak', label: 'Demanda fora ponta / única' },
  { field: 'contractedDemandPeak', label: 'Demanda ponta', truncate: true },
  { field: 'averageInvoiceAmount', label: 'Valor estimado mensal da fatura' },
  { field: 'migrationDate', label: 'Data de migração' },
  { field: 'supplyStartDate', label: 'Início de suprimento' },
  { field: 'supplyEndDate', label: 'Fim de suprimento', truncate: true },
  { field: 'powerGenerator', label: 'Gerador' },
  { field: 'generatorCost', label: 'Custo médio mensal do gerador' },
];

const translateUnitValues = (
  field: EntityValueForDisplayProps<ExtendedUnit>['field'],
  entity: ExtendedUnit,
): string => {
  switch (field) {
    case 'name':
      return entity.name;
    case 'legalName':
      return entity.legalName;
    case 'docNumber': {
      const docType = entity.docType;
      const docNumber = entity.docNumber.replace(/\D/g, '');
      return docType === 1 ? formatCPF(docNumber) : formatCNPJ(docNumber);
    }
    case 'unitType':
      return UnitTypeEnum[entity.unitType];
    case 'state':
      return entity.edc.state;
    case 'edc':
      return entity.edc.name.split(' ')[0];
    case 'tariffSubgroup':
      return entity.tariffSubgroup;
    case 'tariffModality':
      return TariffModeEnum[entity.tariffModality];
    case 'contractedDemandOffPeak':
      return `${formatNumber(entity.contractedDemandOffPeak)} kW`;
    case 'contractedDemandPeak':
      if (entity.contractedDemandPeak === null || isNaN(entity.contractedDemandPeak)) return '-';
      return `${formatNumber(entity.contractedDemandPeak)} kW`;
    case 'averageInvoiceAmount':
      return formatCurrency(entity.averageInvoiceAmount);
    case 'migrationDate':
      return formatDateFromString(entity.migrationDate, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
    case 'supplyStartDate': {
      return formatDateFromString(entity.supplyStartDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
    }
    case 'supplyEndDate': {
      return formatDateFromString(entity.supplyEndDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
    }
    case 'powerGenerator':
      return entity.powerGenerator ? 'Sim' : 'Não';
    case 'generatorCost': {
      if (entity.generatorCost === null || isNaN(entity.generatorCost)) return '-';
      return formatCurrency(entity.generatorCost);
    }
    case 'energyType': {
      if (entity.energyType === null) return '-';
      return EnergyTypeEnum[entity.energyType];
    }
    case 'submarket':
      return entity.submarket === null ? '-' : SubmarketEnum[entity.submarket];
    default:
      return '-';
  }
};

interface ThirdStepProps {
  availableTraders: RetailTrader[];
}
export const ThirdStep: React.FC<ThirdStepProps> = ({ availableTraders }) => {
  const { groupData } = useGroupDataContext();
  const { getValues } = useFormContext<ProposalFormData>();
  const unitsFormData = getValues('units');

  return (
    <div className="mt-14">
      <FormSection
        title="Confirmação da Solicitação"
        subtitle="Cheque as informações inseridas antes de solicitar a Proposta"
      />

      <div className="mt-5 space-y-3">
        <AccordionItem label="Sobre o Cliente">
          <div className="p-2">
            <div className="grid grid-cols-1 gap-y-1 gap-x-10 sm:grid-cols-4">
              <FieldValueDisplaySection fields={clientInfo} traders={availableTraders} />
            </div>
          </div>
        </AccordionItem>

        <AccordionItem label="Sobre o Contrato" testId="contract-info-accordion">
          <div className="p-4 space-y-6">
            <div>
              <p className="font-bold text-paragraph-small">Condições comerciais</p>
              <div className="grid grid-cols-1 gap-y-1 gap-x-10 py-2 border-black sm:grid-cols-4 border-y">
                <FieldValueDisplaySection fields={contractCommercialConditionsInfo} traders={availableTraders} />
              </div>
            </div>
          </div>
        </AccordionItem>

        {unitsFormData.map((unitFromForm, index) => {
          const unit = groupData.units.find((unit) => unit.id === unitFromForm.unitId);
          if (unit === undefined) {
            throw new Error(`Unit ${unitFromForm.unitId} not found in group data.`);
          }

          const docNumberLabelBuilder = (unit: Unpacked<GroupDataForProposalCreation['units']>): string => {
            const docType = unit.docType;
            return docType === 1 ? 'CPF' : 'CNPJ';
          };

          const completeUnit = cloneDeep(unit) as ExtendedUnit;
          completeUnit.state = unit.edc.state;
          completeUnit.supplyStartDate = unitFromForm?.supplyStartDate || '';
          completeUnit.supplyEndDate = unitFromForm?.supplyEndDate || '';

          const completeUnitDefinition = cloneDeep(unitDefinitions);
          completeUnitDefinition.splice(2, 0, { field: 'docNumber', label: docNumberLabelBuilder });

          return (
            <AccordionItem
              key={index}
              testId={`unit-${unit.id}`}
              label={`Unidade ${index + 1} - ${unit.name}`}
              initiallyOpen={false}
            >
              <div className="flex flex-col p-2 space-y-4">
                <EntityInformationContents<ExtendedUnit>
                  entity={completeUnit}
                  valuesDefinitions={completeUnitDefinition}
                  displayTranslatorFunction={translateUnitValues}
                />
                <p className="pb-2 font-semibold border-b border-black text-heading-xsmall">
                  Histórico / Projeção de Consumo
                </p>
                <ConsumptionHistoryTable unit={{ history: unit.history, hasGenerator: unit.powerGenerator }} />
              </div>
            </AccordionItem>
          );
        })}
      </div>
    </div>
  );
};
