import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_ALL_TRADERS_QUERY } from './queries';
import { RetailTrader, TradersListGraphQLResponse } from './types';
export * from './types';

export function useAllTraders(): [RetailTrader[], boolean] {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [allTraders, setAllTraders] = React.useState<RetailTrader[]>([]);
  useQuery<TradersListGraphQLResponse>(GET_ALL_TRADERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const traders = data.traders.data.map(({ id, name }) => ({ id, name }));
      setAllTraders(traders);
      setIsLoading(false);
    },
    onError: () => {
      setAllTraders([]);
      setIsLoading(false);
    },
  });

  return [allTraders, isLoading];
}
