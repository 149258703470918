import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldPath, FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { useNotification } from '@clarke-energia/foton';

import { BIDS_LIST_FOR_PROPOSAL_PATH, FINISH_ROUND_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import { formatDateIntoLocalISOString } from '@utils/translators/date';

import { useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import LoadingPage from '@components/layout/loading-page';
import { OpenFinalRoundFormData } from '@components/molecules/open-final-round/types';
import FormStepHeader from '@components/molecules/rounds-form-step-header';
import FirstStep from '@components/organisms/open-final-round/first-step';
import SecondStep from '@components/organisms/open-final-round/second-step';
import ThirdStep from '@components/organisms/open-final-round/third-step';
import { NavigationControls } from '@components/molecules/form-steps-navigation-control';
import TraderSelectionProvider from '@components/molecules/open-final-round/trader-selection-context';
import { ConfirmSubmissionDialog } from '@components/molecules/confirm-form-submission-dialog';
import { parseProposalFormDataIntoBaseGraphQLInput } from '@components/organisms/create-proposal/form-helpers';
import { getRefusedFeedbackTemplate } from '@components/organisms/open-final-round/form-helpers';

import { BidWithFeedback, getSelectedBidsWithFeedbacks, useCreateFeedback } from '@hooks/bids/create-feedback';
import { RetailTrader, useAllTraders } from '@hooks/proposals';
import { convertUnitSupplyInfo } from '@hooks/proposals/single-proposal/creation/proposal';
import { useUpdateBidStatus } from '@hooks/bids/update';
import { ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';
import { useGetBidDataForFeedback } from '@hooks/bids/get-data-for-feedback';
import { useOpenFinalRound } from '@hooks/process/mutations/open-final-round';
import { useGiveUpContractProcessRound } from '@hooks/process/mutations/give-up-contract-process';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { useGetProcessAndGroupByProposalId } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id';

const renderStepElement = (currentStep: number, dataForFeedback: ProposalForFeedback, proposal: Proposal) => {
  switch (currentStep) {
    case 1:
      return <FirstStep {...{ proposal, dataForFeedback }} />;

    case 2:
      return <SecondStep />;

    case 3:
      return <ThirdStep />;

    default:
      throw 'Invalid step number';
  }
};

const getFieldsToBeValidated = (
  step: number,
  formValuesGetterFunction: UseFormGetValues<OpenFinalRoundFormData>,
): FieldPath<OpenFinalRoundFormData>[] | undefined => {
  if (step === 1) return ['tradersThatAreWinners'];

  const tradersThatAreWinners = formValuesGetterFunction('tradersThatAreWinners');
  const noWinnersChosen = tradersThatAreWinners.includes('NO_WINNER');

  if (step === 2) return noWinnersChosen ? undefined : ['feedbacksForTradersThatAreWinners'];

  const refusedFeedbacks = formValuesGetterFunction('feedbacksForTradersThatAreRefused');
  if (refusedFeedbacks.length !== 0) return ['feedbacksForTradersThatAreRefused'];
};

interface OpenFinalRoundFormProps {
  proposal: Proposal;
  dataForFeedback: ProposalForFeedback;
  onSubmitCallback: (data: OpenFinalRoundFormData) => void;
}

const dialogButtonConfirmTextWhenWinnersPresent = 'Abrir rodada final';
const dialogTitleTextWhenWinnersPresent =
  'Comercializadoras selecionadas e feedbacks escritos. Tudo certo para abrir a Rodada Final?';

const dialogButtonConfirmTextWhenNoWinnersPresent = 'Concluir negociação';
const dialogTitleTextWhenNoWinnersPresent =
  'Nenhuma comercializadora foi selecionada. Deseja realmente concluir a negociação?';

const OpenFinalRoundForm: React.FC<OpenFinalRoundFormProps> = ({ proposal, onSubmitCallback, dataForFeedback }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [isConfirmSubmitDialogOpen, setIsConfirmSubmitDialogOpen] = React.useState<boolean>(false);
  const [dialogTitleMessage, setDialogTitleMessage] = React.useState<string>(dialogTitleTextWhenWinnersPresent);
  const [dialogConfirmButtonText, setDialogConfirmButtonText] = React.useState<string>(
    dialogButtonConfirmTextWhenWinnersPresent,
  );

  const defaultRefusedTraders = proposal.traders
    .filter((trader) => trader.bid !== undefined)
    .map((trader) => ({
      trader: { id: trader.id, name: trader.name },
      feedback: getRefusedFeedbackTemplate(trader.id, dataForFeedback),
    }));

  const parsedDeadline = formatDateIntoLocalISOString(proposal.deadline).split(' ');

  const methods = useForm<OpenFinalRoundFormData>({
    defaultValues: {
      traders: [],
      tradersThatAreWinners: [],
      feedbacksForTradersThatAreWinners: [],
      feedbacksForTradersThatAreRefused: defaultRefusedTraders,
      guaranteeType: proposal.guaranteeType,
      guaranteeMonths: proposal.guaranteeMonths as OpenFinalRoundFormData['guaranteeMonths'],
      otherKindGuarantee: proposal.otherGuarantee,
      lowerFlexibility: proposal.lowerFlexibility,
      upperFlexibility: proposal.upperFlexibility,
      contractType: proposal.contractType,
      proposalModality: proposal.proposalType,
      proposalDeadline: { date: parsedDeadline[0], time: parsedDeadline[1].slice(0, 5) },
      commissionModality: proposal.commissionModality,
    },
  });

  const onCancelFormCallback = () => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${FINISH_ROUND_PATH}`);

  const onStepChangeCallback = (nextStepValue: number) => {
    // REFACTOR: Make this mess of a function more readable
    const tradersThatAreWinners = methods.getValues('tradersThatAreWinners');
    const noWinnersChosen = tradersThatAreWinners.includes('NO_WINNER');
    if (nextStepValue === 2 && noWinnersChosen) {
      // no winners option chosen, when advancing
      if (nextStepValue > currentStep) {
        const fields = getFieldsToBeValidated(currentStep, methods.getValues);
        methods.trigger(fields).then((fieldsAreValid) => {
          // skip straight into step 3
          if (fieldsAreValid) setCurrentStep(nextStepValue + 1);
        });
      } else {
        // go back to step 1 from 3
        setCurrentStep(nextStepValue - 1);
      }
      return;
    }

    if (nextStepValue > currentStep) {
      const fields = getFieldsToBeValidated(currentStep, methods.getValues);
      methods.trigger(fields).then((fieldsAreValid) => {
        if (fieldsAreValid) setCurrentStep(nextStepValue);
      });
    } else {
      setCurrentStep(nextStepValue);
    }
  };

  const onLastStepCallback = async () => {
    const fields = getFieldsToBeValidated(3, methods.getValues);
    methods.trigger(fields).then((fieldsAreValid) => {
      if (fieldsAreValid) {
        const tradersThatAreWinners = methods.getValues('tradersThatAreWinners');
        const noWinnersChosen = tradersThatAreWinners.includes('NO_WINNER');

        if (noWinnersChosen) {
          setDialogTitleMessage(dialogTitleTextWhenNoWinnersPresent);
          setDialogConfirmButtonText(dialogButtonConfirmTextWhenNoWinnersPresent);
        } else {
          setDialogTitleMessage(dialogTitleTextWhenWinnersPresent);
          setDialogConfirmButtonText(dialogButtonConfirmTextWhenWinnersPresent);
        }

        setIsConfirmSubmitDialogOpen(true);
      }
    });
  };

  return (
    <>
      <div className="overflow-y-auto w-full h-full max-h-[calc(100vh-14rem)]">
        <FormProvider {...methods}>
          <form>
            <TraderSelectionProvider>
              <div className="p-4 space-y-4">
                <FormStepHeader currentStep={currentStep} operationType="openFinalRound" />
                <div className="space-y-8">
                  {renderStepElement(currentStep, dataForFeedback, proposal)}

                  <NavigationControls
                    currentStep={currentStep}
                    controlParams={{
                      maxStep: 3,
                      textLabels: {
                        changeStep: 'Próximo passo',
                        endStep: 'Abrir rodada final',
                      },
                      cancelFormCallback: onCancelFormCallback,
                      changeStepCallback: onStepChangeCallback,
                      endStepCallback: onLastStepCallback,
                    }}
                  />
                </div>
              </div>
            </TraderSelectionProvider>

            {currentStep === 3 && (
              <ConfirmSubmissionDialog
                displayOptions={{
                  titleText: dialogTitleMessage,
                  buttonSubmitText: dialogConfirmButtonText,
                }}
                isOpen={isConfirmSubmitDialogOpen}
                confirmDialogCallback={async () => {
                  await methods.handleSubmit(onSubmitCallback)();
                  setIsConfirmSubmitDialogOpen(false);
                }}
                closeDialogCallback={() => setIsConfirmSubmitDialogOpen(false)}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </>
  );
};

const getSelectedTradersForFinalRound = (selectedBids: BidWithFeedback[], allTraders: RetailTrader[]) => {
  const filteredTraders: RetailTrader[] = [];

  selectedBids.forEach(({ bid }) => {
    const foundTrader = allTraders.find((trader) => trader.id === bid.trader.id);
    if (foundTrader !== undefined) filteredTraders.push({ ...foundTrader });
  });
  return filteredTraders;
};

const OpenFinalRoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { proposal } = useAdminProposal();
  const { createNotification } = useNotification();
  const [allTraders] = useAllTraders();
  const { fetchData, proposalData, isLoading } = useGetBidDataForFeedback();
  const { processData, fetchProcessData, isProcessDataLoading } = useGetProcessAndGroupByProposalId();

  const isPageLoading = isLoading || isProcessDataLoading;

  const updateBidStatus = useUpdateBidStatus();
  const createFeedback = useCreateFeedback();
  const openFinalRound = useOpenFinalRound();
  const giveUpContractProcess = useGiveUpContractProcessRound();

  React.useEffect(() => {
    if (proposal.id) {
      fetchData(proposal.id);
      fetchProcessData(proposal.id);
    }
  }, [proposal.id]);

  const onSubmit = async (data: OpenFinalRoundFormData) => {
    const { feedbacksForTradersThatAreWinners, feedbacksForTradersThatAreRefused, noTradersChosen } = data;
    const selectedBids = getSelectedBidsWithFeedbacks(feedbacksForTradersThatAreWinners, proposal, 'FINAL_WINNER');
    const refusedBids = getSelectedBidsWithFeedbacks(feedbacksForTradersThatAreRefused, proposal, 'FINAL_REFUSED');

    const selectedTraders = getSelectedTradersForFinalRound(selectedBids, allTraders);
    const selectedTradersIds = selectedTraders.map((trader) => trader.id);

    // Create final proposal round
    const contractData = { ...data, traders: selectedTradersIds, files: [] };
    const payload = parseProposalFormDataIntoBaseGraphQLInput({
      contract: contractData,
      client: {
        id: proposal.group.id,
        name: proposal.group.name,
        docType: proposal.group.docType,
        document: proposal.group.docNumber,
        companyName: proposal.group.legalName,
      },
      units: convertUnitSupplyInfo(proposal),
      selectedUnitIds: proposal.units.map((unit) => unit.id),
    });

    let createdProposalId: string;
    try {
      if (noTradersChosen) {
        const giveUpResult = await giveUpContractProcess(proposal.id);
        createdProposalId = giveUpResult.id;
      } else {
        const finalRoundResult = await openFinalRound(processData.id, payload);
        createdProposalId = finalRoundResult.id;
      }
    } catch (error) {
      createNotification({
        kind: 'error',
        label: 'Erro!',
        message: `Não foi possível abrir a rodada final. Por favor, avise ao suporte técnico, informando o seguinte erro: ${error}`,
      });
      return;
    }

    // Update bids and create feedbacks
    await Promise.all(
      selectedBids.map(async ({ bidId, bid, feedback }) => {
        await updateBidStatus(bid.id, bid.status);
        await createFeedback({
          bidId,
          nextProposalId: createdProposalId,
          feedback: feedback.feedback,
        });
      }),
    );

    await Promise.all(
      refusedBids.map(async ({ bidId, bid, feedback }) => {
        await updateBidStatus(bid.id, bid.status);
        await createFeedback({
          bidId,
          nextProposalId: createdProposalId,
          feedback: feedback.feedback,
        });
      }),
    );

    navigate(`${VIEW_PROPOSAL_LIST_PATH}/${createdProposalId}/${BIDS_LIST_FOR_PROPOSAL_PATH}`, { replace: true });
  };
  return isPageLoading ? (
    <LoadingPage />
  ) : (
    <OpenFinalRoundForm proposal={proposal} dataForFeedback={proposalData} onSubmitCallback={onSubmit} />
  );
};

export default OpenFinalRoundPage;
