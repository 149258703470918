import React from 'react';

const EmptyFinalBidMessageDisplay: React.FC = () => (
  <div className="p-5 sm:p-10">
    <h1 className="pb-5 text-heading-2xlarge text-medium">Sem proposta :(</h1>
    <p className="pb-5 w-4/5 text-paragraph-medium">
      A fornecedora ainda não enviou a proposta final para assinatura. Deixe o cliente preparado para assinar o mais
      rápido possível!
    </p>
  </div>
);

export default EmptyFinalBidMessageDisplay;
