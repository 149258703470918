import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import errorTracker from '@services/error-tracking';

import PrivateRoute from './private-route';
import AdminRoute from './admin-route';
import TraderRoute, { TraderProvider } from './trader-route';

import {
  BIDS_RAKING,
  BIDS_LIST_FOR_PROPOSAL_PATH,
  BIDS_SENT_BY_TRADER_PATH,
  COMPILATION_SUFFIX_PATH,
  CREATE_BID_PATH_SUFFIX,
  CREATE_FINAL_BID_PATH_SUFFIX,
  CREATE_PROPOSAL_PATH,
  EDIT_BID_PATH_SUFFIX_PATH,
  EDIT_PROPOSAL_SUFFIX_PATH,
  FINISH_ROUND_PATH,
  HOME_PATH,
  OPEN_FINAL_ROUND_PATH,
  OPEN_NEW_ROUND_PATH,
  PREVIEW_CUSTOMER_COMPILATION_SUFFIX_PATH,
  PROPOSAL_INFO_FOR_TRADER_PATH,
  PROPOSAL_INFO_PATH,
  SEND_SIGNED_PROPOSAL_PATH,
  START_COMPILATION_SUFFIX_PATH,
  VIEW_BID_LIST_PATH,
  VIEW_PROPOSAL_LIST_PATH,
  VIEW_SAVINGS_RESULTS_PATH,
} from './constants';

import HomePage from '@pages/home';
import Layout from '@components/layout/main-layout';
import CreateProposalPage from '@pages/admin/create-proposal';
import AdminProposalRoundLayout from '@components/layout/admin-proposal-round-layout';
import AdminProposalInformationPage from '@pages/admin/round/proposal-info';
import RoundBidsListPage from '@pages/admin/round/proposal-bids-list';
import TraderProposalRoundLayout from '@components/layout/trader-proposal-round-layout';
import TraderProposalInformationPage from '@pages/trader/round/proposal-info';
import TraderBidInfoPage from '@pages/trader/round/bid-info';
import TraderRankingPage from '@pages/trader/round/ranking';
import NotFound404 from '@pages/404';
import CreateBidPage from '@pages/trader/create-bid';
import AdminFinishRoundPage from '@pages/admin/round/finish-round';
import OpenNewRoundPage from '@pages/admin/round/open-new-round';
import SendSignedProposalPage from '@pages/admin/round/send-signed-proposal';
import OpenFinalRoundPage from '@pages/admin/round/open-final-round';
import CreateFinalBidPage from '@pages/trader/create-final-bid';
import EditProposalPage from '@pages/admin/round/edit-proposal';
import ProposalCompilationPage from '@pages/admin/round/proposal-compilation-display';
import StartCompilationPage from '@pages/admin/round/start-compilation';
import CommercialGroupSavingsResultsPage from '@pages/commercial-group/savings-result';
import EditBidPage from '@pages/trader/edit-bid';
import PublicRoute from './public-route';
import AdminProcessListPanelPage from '@pages/admin/process-list-panel';
import TraderProcessListPanelPage from '@pages/trader/process-list-panel';

const MarketplaceRouter: React.FC = () => {
  const ErrorTrackedRoutes = errorTracker.routingIntegration(Routes);

  return (
    <ErrorTrackedRoutes>
      {/* ----------- Private routes ----------- */}

      {/* ----------- Commercial group routes ----------- */}
      <Route
        path={`${VIEW_SAVINGS_RESULTS_PATH}/:proposalId`}
        element={
          <PublicRoute>
            <CommercialGroupSavingsResultsPage />
          </PublicRoute>
        }
      >
        <Route path=":unitId" element={<CommercialGroupSavingsResultsPage />} />
      </Route>

      <Route
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path={HOME_PATH} element={<HomePage />} />

        {/* ----------- Admin routes ----------- */}
        <Route
          path={VIEW_PROPOSAL_LIST_PATH}
          element={
            <AdminRoute>
              <Outlet />
            </AdminRoute>
          }
        >
          <Route index element={<AdminProcessListPanelPage />} />
          <Route path=":proposalId" element={<AdminProposalRoundLayout />}>
            <Route path={BIDS_LIST_FOR_PROPOSAL_PATH} element={<RoundBidsListPage />} />
            <Route path={PROPOSAL_INFO_PATH} element={<AdminProposalInformationPage />} />
            <Route path={COMPILATION_SUFFIX_PATH} element={<ProposalCompilationPage />} />
            <Route path={FINISH_ROUND_PATH} element={<AdminFinishRoundPage />} />
            <Route path={OPEN_NEW_ROUND_PATH} element={<OpenNewRoundPage />} />
            <Route path={SEND_SIGNED_PROPOSAL_PATH} element={<SendSignedProposalPage />} />
            <Route path={OPEN_FINAL_ROUND_PATH} element={<OpenFinalRoundPage />} />
          </Route>

          <Route path={`:proposalId/${EDIT_PROPOSAL_SUFFIX_PATH}`} element={<EditProposalPage />} />
          <Route path={`:proposalId/${START_COMPILATION_SUFFIX_PATH}`} element={<StartCompilationPage />} />
          <Route
            path={`:proposalId/${PREVIEW_CUSTOMER_COMPILATION_SUFFIX_PATH}`}
            element={<CommercialGroupSavingsResultsPage />}
          >
            <Route path=":unitId" element={<CommercialGroupSavingsResultsPage />} />
          </Route>
        </Route>

        <Route
          path={CREATE_PROPOSAL_PATH}
          element={
            <AdminRoute>
              <CreateProposalPage />
            </AdminRoute>
          }
        />

        {/* ----------- Trader routes ----------- */}
        <Route
          path={VIEW_BID_LIST_PATH}
          element={
            <TraderRoute>
              <TraderProvider>
                <Outlet />
              </TraderProvider>
            </TraderRoute>
          }
        >
          <Route index element={<TraderProcessListPanelPage />} />
          <Route path=":proposalId" element={<TraderProposalRoundLayout />}>
            <Route path={BIDS_RAKING} element={<TraderRankingPage />} />
            <Route path={BIDS_SENT_BY_TRADER_PATH} element={<TraderBidInfoPage />} />
            <Route path={PROPOSAL_INFO_FOR_TRADER_PATH} element={<TraderProposalInformationPage />} />
            <Route path={CREATE_FINAL_BID_PATH_SUFFIX} element={<CreateFinalBidPage />} />
          </Route>
          <Route path={`:proposalId/${CREATE_BID_PATH_SUFFIX}`} element={<CreateBidPage />} />
          <Route path={`:proposalId/${EDIT_BID_PATH_SUFFIX_PATH}/:bidId`} element={<EditBidPage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound404 />} />
    </ErrorTrackedRoutes>
  );
};

export default MarketplaceRouter;
