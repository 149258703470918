import { useMutation } from '@apollo/client';

import { Bid, BidStatus, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import {
  CreateFeedbackForSignedProposalGraphQLInput,
  CreateFeedbackForSignedProposalGraphQLResponse,
  CreateFeedbackGraphQLInput,
  CreateFeedbackGraphQLResponse,
  CreateRefusedFeedbackForSignedProposalGraphQLInput,
  CreateRefusedFeedbackForSignedProposalGraphQLResponse,
  CREATE_FEEDBACK_MUTATION,
  CREATE_FEEDBACK_SIGNED_PROCESS_MUTATION,
  CREATE_REFUSED_FEEDBACK_SIGNED_PROCESS_MUTATION,
} from './mutations';

interface CreateFeedbackParams {
  bidId: string;
  nextProposalId: string;
  feedback: string;
}
export function useCreateFeedback() {
  const [mutationFunction] = useMutation<CreateFeedbackGraphQLResponse, CreateFeedbackGraphQLInput>(
    CREATE_FEEDBACK_MUTATION,
  );

  const createFeedback = async ({ bidId, nextProposalId, feedback }: CreateFeedbackParams) => {
    const { data, errors } = await mutationFunction({
      variables: { createFeedbackInput: { bidId, nextProposalId, text: feedback } },
    });

    if (errors !== undefined || !data) throw new Error(`Falha ao criar feedback da oferta ${bidId}.`);

    const result = data.createFeedback;
    return { id: result.id };
  };

  return createFeedback;
}

export interface TraderFeedback {
  trader: { id: string; name: string };
  feedback: string;
}
export interface BidWithFeedback {
  bidId: string;
  bid: Bid;
  feedback: TraderFeedback;
}
export const getSelectedBidsWithFeedbacks = (
  data: TraderFeedback[],
  proposal: Proposal,
  newStatus: keyof typeof BidStatus,
) => {
  const selectedBids: BidWithFeedback[] = [];
  data.forEach((feedback) => {
    const traderId = feedback.trader.id;
    const selectedBid = proposal.bids.find((bid) => bid.trader.id === traderId);
    if (selectedBid === undefined) throw new Error(`Oferta com id de comercializadora "${traderId} não encontrada.`);

    selectedBid.status = newStatus;
    selectedBids.push({ bidId: selectedBid.id, bid: { ...selectedBid }, feedback });
  });
  return selectedBids;
};

export function useCreateFeedbackForSignedProposal() {
  const [mutationFunction] = useMutation<
    CreateFeedbackForSignedProposalGraphQLResponse,
    CreateFeedbackForSignedProposalGraphQLInput
  >(CREATE_FEEDBACK_SIGNED_PROCESS_MUTATION);

  const createFeedback = async (bidId: string, feedback: string) => {
    const { data, errors } = await mutationFunction({
      variables: { createFeedbackInput: { bidId, text: feedback } },
    });

    if (
      errors !== undefined ||
      !data ||
      data.createFeedbackForSignedProposal.error !== null ||
      data.createFeedbackForSignedProposal.feedback === null
    )
      throw new Error(`Falha ao criar feedback da oferta ${bidId}.`);

    const result = data.createFeedbackForSignedProposal.feedback;
    return { id: result.id };
  };

  return createFeedback;
}

export function useCreateRefusedFeedbackForSignedProposal() {
  const [mutationFunction] = useMutation<
    CreateRefusedFeedbackForSignedProposalGraphQLResponse,
    CreateRefusedFeedbackForSignedProposalGraphQLInput
  >(CREATE_REFUSED_FEEDBACK_SIGNED_PROCESS_MUTATION);

  const createFeedback = async (bidId: string, feedback: string) => {
    const { data, errors } = await mutationFunction({
      variables: { createFeedbackInput: { bidId, text: feedback } },
    });

    if (
      errors !== undefined ||
      !data ||
      data.createRefusedFeedbackForSignedProposal.error !== null ||
      data.createRefusedFeedbackForSignedProposal.feedback === null
    )
      throw new Error(`Falha ao criar feedback da oferta ${bidId}.`);

    const result = data.createRefusedFeedbackForSignedProposal.feedback;
    return { id: result.id };
  };

  return createFeedback;
}
