import React from 'react';
import { useFormContext, UseFormGetValues } from 'react-hook-form';

import { parseStringToDate } from '@utils/text';
import { ClarkeCommissionTypeEnum } from '@utils/translators/proposal';

import AccordionItem from '@components/molecules/accordion';
import { BidFormData } from '@components/molecules/create-bid/types';
import {
  BidCommercialConditionsContentsDisplay,
  BidEconomyProposalContentsDisplay,
  BidPaymentContentsDisplay,
} from '@components/molecules/proposal-round/trader-bid-contents-display';

import { Bid, DocumentFile } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const parseEconomyFormValuesIntoBidEconomy = (prices: BidFormData['productAndPrices']['prices']): Bid['economy'] => {
  return prices.values;
};

const parseFormValuesIntoBidValuesForDisplay = (getValuesFunction: UseFormGetValues<BidFormData>) => {
  const contractType = getValuesFunction('productAndPrices.contractType');
  const lowerFlexibility = getValuesFunction('commercialConditions.lowerFlexibility');
  const upperFlexibility = getValuesFunction('commercialConditions.upperFlexibility');
  const prices = getValuesFunction('productAndPrices.prices');
  const economyParsedValues = parseEconomyFormValuesIntoBidEconomy(prices);
  const proposalType = getValuesFunction('commercialConditions.proposalType');
  const retailService = getValuesFunction('commercialConditions.traderType') === 'RETAIL';
  const coverCceeCost = getValuesFunction('commercialConditions.coverCceeCost');
  const coverCceeTaxes = getValuesFunction('commercialConditions.coverCceeTaxes');
  const coverMeterSystem = getValuesFunction('commercialConditions.coverMeterSystem') === 'YES';
  const meterSystemAmount = getValuesFunction('commercialConditions.meterSystemAmount');
  const deadlineFormValue = getValuesFunction('commercialConditions.bidDeadline');
  const deadline = parseStringToDate(`${deadlineFormValue.date} ${deadlineFormValue.time}:00`);
  const guaranteeType = getValuesFunction('commercialConditions.guaranteeType');
  const payDay = getValuesFunction('commercialConditions.payDay');
  const clarkeCommissiontType = getValuesFunction('commercialConditions.clarkeCommissionType');

  const clarkeCommissionNote = clarkeCommissiontType === '' ? '' : ClarkeCommissionTypeEnum[clarkeCommissiontType];
  const note = getValuesFunction('commercialConditions.note');
  const documents: DocumentFile[] = getValuesFunction('commercialConditions.files').map((file) => ({
    id: '',
    entity: 'bid',
    entityId: '',
    label: file.name,
    uri: URL.createObjectURL(file),
    docType: 'OTHERS',
  }));

  return {
    contractType,
    lowerFlexibility,
    upperFlexibility,
    periods: [prices.duration],
    economyParsedValues,
    proposalType,
    retailService,
    coverCceeCost,
    coverCceeTaxes,
    coverMeterSystem,
    meterSystemAmount,
    deadline,
    guaranteeType,
    payDay,
    clarkeCommissionNote,
    note,
    documents,
  };
};

const FourthStep: React.FC = () => {
  const { getValues } = useFormContext<BidFormData>();
  const {
    contractType,
    lowerFlexibility,
    upperFlexibility,
    periods,
    economyParsedValues,
    proposalType,
    retailService,
    coverCceeCost,
    coverCceeTaxes,
    coverMeterSystem,
    meterSystemAmount,
    deadline,
    guaranteeType,
    payDay,
    clarkeCommissionNote,
    note,
    documents,
  } = parseFormValuesIntoBidValuesForDisplay(getValues);

  return (
    <>
      <AccordionItem label="Proposta de Economia" initiallyOpen>
        <BidEconomyProposalContentsDisplay
          bid={{
            contractType,
            periods,
            economy: economyParsedValues,
          }}
        />
      </AccordionItem>
      <AccordionItem label="Condições Comerciais" initiallyOpen>
        <BidCommercialConditionsContentsDisplay
          bid={{
            deadline,
            proposalType,
            lowerFlexibility,
            upperFlexibility,
            retailService,
            coverCceeCost,
            coverCceeTaxes,
            coverMeterSystem,
            meterSystemAmount,
          }}
        />
      </AccordionItem>
      <AccordionItem label="Pagamento e Financeiro" initiallyOpen>
        <BidPaymentContentsDisplay bid={{ guaranteeType, payDay, clarkeCommissionNote, note, documents }} />
      </AccordionItem>
    </>
  );
};

export default FourthStep;
