import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { QueryBidDataGraphQLResponse, QUERY_BID_DATA } from './query';
import { parseStringToDate } from '@utils/text';
import {
  ClarkeCommissionTypeEnum,
  ContractTypeEnum,
  CoverCeeeCostEnum,
  CoverCeeeTaxesEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
} from '@utils/translators/proposal';

export interface ParsedBid {
  id: string;
  deadline: Date;
  contractType: keyof typeof ContractTypeEnum;
  periods: number[];
  economy: {
    amount: number;
    year: number;
  }[];
  units: { id: string; supply: { start: Date | null; end: Date | null } }[];
  trader: { id: string };
  lowerFlexibility: number;
  upperFlexibility: number;
  proposalType: keyof typeof ProposalTypeEnum;
  traderType: 'RETAIL' | 'WHOLESALE';
  commissionModality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
  coverCceeCost: keyof typeof CoverCeeeCostEnum;
  coverCceeTaxes: keyof typeof CoverCeeeTaxesEnum;
  coverMeterSystem: boolean;
  meterSystemAmount: number;
  guaranteeType: keyof typeof GuaranteeTypeEnum;
  guaranteeMonths: number;
  otherKindGuarantee: string;
  payDay: number;
  clarkeCommissionNote: keyof typeof ClarkeCommissionTypeEnum;
  note: string;
}

const EMPTY_BID: ParsedBid = {
  id: '',
  deadline: new Date(),
  contractType: 'FIXED_PRICE',
  periods: [],
  economy: [],
  units: [],
  trader: { id: '' },
  lowerFlexibility: NaN,
  upperFlexibility: NaN,
  proposalType: 'INDICATIVE',
  traderType: 'RETAIL',
  coverCceeCost: 'NONE',
  coverCceeTaxes: 'NONE',
  coverMeterSystem: false,
  meterSystemAmount: NaN,
  guaranteeType: 'NO_GUARANTEE',
  guaranteeMonths: NaN,
  otherKindGuarantee: '',
  payDay: NaN,
  clarkeCommissionNote: 'NO_PREFERENCE',
  commissionModality: 'NO_PREFERENCE',
  note: '',
};

export function useGetBidData(): [ParsedBid, (bidId: string) => Promise<void>, boolean] {
  const [isBidLoaded, setIsBidLoaded] = React.useState<boolean>(false);
  const [bid, setBid] = React.useState<ParsedBid>({ ...EMPTY_BID });
  const [queryFunction] = useLazyQuery<QueryBidDataGraphQLResponse, { id: string }>(QUERY_BID_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const rawBid = data.bid;

      const entry = Object.entries(ClarkeCommissionTypeEnum).find(
        ([_, value]) => value === rawBid.clarkeCommissionNote,
      );
      const clarkeCommissionNote =
        entry === undefined ? 'NO_PREFERENCE' : (entry[0] as keyof typeof ClarkeCommissionTypeEnum);

      setBid({
        id: rawBid.id,
        deadline: parseStringToDate(rawBid.deadline),
        contractType: rawBid.contractType,
        periods: rawBid.periods,
        economy: rawBid.economy.map(({ year, amount }) => ({ year, amount })),
        units: rawBid.proposal.supply.map((entry) => ({
          id: entry.unit.id,
          supply: {
            start: entry.startDate ? parseStringToDate(entry.startDate) : null,
            end: entry.endDate ? parseStringToDate(entry.endDate) : null,
          },
        })),
        trader: { id: rawBid.trader.id },
        lowerFlexibility: rawBid.lowerFlexibility,
        upperFlexibility: rawBid.lowerFlexibility,
        proposalType: rawBid.proposal.proposalType,
        traderType: rawBid.retailService ? 'RETAIL' : 'WHOLESALE',
        commissionModality: rawBid.proposal.commissionModality,
        coverCceeCost: rawBid.coverCceeCost,
        coverCceeTaxes: rawBid.coverCceeTaxes ? 'FULL' : 'NONE',
        coverMeterSystem: rawBid.coverMeterSystem,
        meterSystemAmount: rawBid.meterSystemAmount ?? NaN,
        guaranteeType: (rawBid.guaranteeType ?? 'NO_GUARANTEE') as ParsedBid['guaranteeType'],
        guaranteeMonths: rawBid.guaranteeMonths ?? NaN,
        otherKindGuarantee: rawBid.otherGuaranteeType ?? '',
        payDay: rawBid.payDay,
        clarkeCommissionNote,
        note: rawBid.note,
      });

      setIsBidLoaded(true);
    },
    onError: () => {
      setBid({ ...EMPTY_BID });
      setIsBidLoaded(false);
    },
  });

  const fetchBidData = async (id: string) => {
    const { data, error } = await queryFunction({ variables: { id } });

    if (!data || error) throw new Error('Servidor não se comportou como esperava.');
  };

  return [bid, fetchBidData, isBidLoaded];
}
