import { gql, useMutation } from '@apollo/client';

interface SignProposalGraphQLResponse {
  signProposal: { error: string | null; bid: { id: string; proposal: { id: string } } | null };
}

const SIGN_PROPOSAL_STR = gql`
  mutation SignProposal($proposalId: ID!, $winnerBidId: ID!) {
    signProposal(proposalId: $proposalId, winnerBidId: $winnerBidId) {
      error
      bid {
        id
        proposal {
          id
        }
      }
    }
  }
`;

export function useSignProposal() {
  const [mutationFunction] = useMutation<SignProposalGraphQLResponse, { proposalId: string; winnerBidId: string }>(
    SIGN_PROPOSAL_STR,
  );

  const signProposal = async (
    proposalId: string,
    winnerBidId: string,
  ): Promise<{ createdProposalId: string; createdBidId: string }> => {
    const { data, errors } = await mutationFunction({ variables: { proposalId, winnerBidId } });

    if (errors !== undefined || !data || data.signProposal.error !== null || data.signProposal.bid === null)
      throw new Error('Falha ao assinar proposta.');

    const result = data.signProposal.bid;
    return { createdProposalId: result.proposal.id, createdBidId: result.id };
  };

  return signProposal;
}
