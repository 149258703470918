import { gql } from '@apollo/client';

export const GET_EDCS_LIST_QUERY = gql`
  query GetEdcs {
    edcs {
      data {
        id
        name
        legalName
        label
        state
        tariffAdjustmentDate
        removesIcms
        fixedPriceGuaranteedDiscountRelation {
          percentage
        }
        createdAt
        updatedAt
      }
    }
  }
`;
