import React from 'react';

import { NavLink } from 'react-router-dom';

const RoundActionButton: React.FC<{ url: string; label: string; onClick?: () => void }> = ({ url, label, onClick }) => {
  return (
    <NavLink
      role="button"
      to={url}
      className={({ isActive }) =>
        `font-medium rounded-full text-paragraph-large py-1.5 px-6 border border-black ${
          isActive ? 'text-white bg-black' : 'text-black bg-white hover:bg-neutral-20'
        }`
      }
      onClick={onClick}
    >
      {label}
    </NavLink>
  );
};

export default RoundActionButton;
