import React from 'react';

import { FormSection, RadioFormField } from '@components/atoms/form';
import { BidFormData } from '@components/molecules/create-bid/types';
import ValuesFields from '@components/molecules/create-bid/values-fields';

const FirstStep: React.FC<{ supply: { start: Date; end: Date } }> = ({ supply }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-x-5 py-6 md:grid-cols-3">
        <FormSection
          title="Produto e Preços"
          subtitle="A partir das premissas do cliente, informe o produto, duração e preços."
        />
        <div className="col-span-1">
          <RadioFormField<BidFormData>
            field="productAndPrices.contractType"
            label="Tipo de Contrato"
            inputOptions={[{ id: 'fixed-price', optionLabel: 'Preço Fixo Escalonado', value: 'FIXED_PRICE' }]}
            options={{ required: { value: true, message: 'O tipo de contrato deve ser escolhido' } }}
          />
        </div>
      </div>
      <ValuesFields {...{ supply }} />
    </>
  );
};

export default FirstStep;
