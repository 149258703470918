import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

export default function initializeService(dsnToken?: string) {
  Sentry.init({
    dsn: dsnToken,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
export function configureRoutesIntegration(routesElement: typeof Routes) {
  return Sentry.withSentryReactRouterV6Routing(routesElement);
}

export function captureMessage(message: string) {
  Sentry.captureMessage(message);
}

export function captureException(err: any, context?: { name: string; values: Record<string, any> }) {
  if (context) Sentry.setContext(context.name, context.values);
  Sentry.captureException(err);
}
