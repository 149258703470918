import React from 'react';

const StatusBadgeBulletDot: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} width="6" height="6" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="3" />
    </svg>
  );
};

export type ProposalRoundStatus = 'analysis' | 'requested' | 'finished' | 'expired';

const getStatusBadgeStylesAndLabel = (
  status: ProposalRoundStatus,
): { fillClass: string; textClass: string; backgroundClass: string; label: string } => {
  switch (status) {
    case 'analysis':
      return {
        fillClass: 'fill-neutral-90',
        textClass: 'text-neutral-90',
        backgroundClass: 'bg-neutral-10',
        label: 'Em Análise',
      };
    case 'requested':
      return {
        fillClass: 'fill-warning-70',
        textClass: 'text-warning-70',
        backgroundClass: 'bg-warning-10',
        label: 'Solicitado',
      };
    case 'finished':
      return {
        fillClass: 'fill-primary-90',
        textClass: 'text-primary-90',
        backgroundClass: 'bg-primary-10',
        label: 'Finalizado',
      };
    case 'expired':
      return {
        fillClass: 'fill-danger-60',
        textClass: 'text-danger-60',
        backgroundClass: 'bg-danger-10',
        label: 'Expirado',
      };

    default:
      throw `Invalid status: ${status}`;
  }
};

export const RoundStatusBadge: React.FC<{ status: ProposalRoundStatus }> = ({ status }) => {
  const styles = getStatusBadgeStylesAndLabel(status);
  return (
    <div className={`flex items-center py-0.5 px-2 mr-auto space-x-2 rounded ${styles.backgroundClass}`}>
      <StatusBadgeBulletDot className={styles.fillClass} />
      <p className={`text-paragraph-medium whitespace-nowrap ${styles.textClass}`}>{styles.label}</p>
    </div>
  );
};
