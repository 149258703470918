import configuration from '@config';
import { Routes } from 'react-router-dom';
import initializeService, { captureException, captureMessage, configureRoutesIntegration } from './sentry';

class ErrorTracker {
  private initialized = false;
  private unitializedError = new Error('"initializeService" was not called.');

  initializeService() {
    if (this.initialized) throw new Error('Initialization already called!.');

    initializeService(configuration.SENTRY_DSN);
    this.initialized = true;
  }

  captureException(err: Error, context?: { name: string; values: Record<string, any> }) {
    if (!this.initialized) throw this.unitializedError;
    captureException(err, context);
  }

  captureMessage(message: string) {
    if (!this.initialized) throw this.unitializedError;
    captureMessage(message);
  }

  routingIntegration(routesElement: typeof Routes) {
    if (!this.initialized) throw this.unitializedError;
    return configureRoutesIntegration(routesElement);
  }
}

const errorTracker = new ErrorTracker();

export default errorTracker;
