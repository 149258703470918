import { gql } from '@apollo/client';

const UNIT_FIELDS_IN_PROPOSAL_SUPPLY_FRAGMENT = gql`
  fragment UnitFields on Unit {
    id
    edc {
      id
      name
      state
    }
    name
    legalName
    docType
    docNumber
    unitType
    tariffSubgroup
    tariffModality
    totalConsumptionVolume
    contractedDemandOffPeak
    contractedDemandPeak
    averageInvoiceAmount
    powerGenerator
    generatorCost
    energyType
    submarket
    history {
      id
      consumptionOffPeak
      consumptionPeak
      generator
      yearMonth
    }
  }
`;

const BID_FIELDS_IN_PROPOSAL_FRAGMENT = gql`
  fragment BidFields on Bid {
    id
    createdAt
    deadline
    status
    trader {
      id
      name
    }
    documents {
      id
      entity
      entityId
      label
      uri
      docType
    }
    contractType
    periods
    economy {
      year
      amount
    }
    proposal {
      proposalType
    }
    lowerFlexibility
    upperFlexibility
    retailService
    coverCceeCost
    coverCceeTaxes
    guaranteeType
    guaranteeMonths
    otherGuaranteeType
    coverMeterSystem
    meterSystemAmount
    payDay
    clarkeCommissionNote
    feedback {
      createdAt
      text
    }
    note
  }
`;

const PROPOSAL_FIELDS_FRAGMENT = gql`
  ${UNIT_FIELDS_IN_PROPOSAL_SUPPLY_FRAGMENT}
  ${BID_FIELDS_IN_PROPOSAL_FRAGMENT}
  fragment ProposalFields on Proposal {
    id
    createdAt
    deadline
    status
    round
    bids {
      ...BidFields
    }
    traders {
      id
      name
    }
    supply {
      startDate
      endDate
      unit {
        ...UnitFields
      }
    }
    productType
    contractType
    periods
    lowerFlexibility
    upperFlexibility
    totalConsumptionVolume
    proposalType
    guaranteeType
    guaranteeMonths
    otherGuarantee
    note
    commissionModality
    gmvAmount
    carbonDioxideTonAmount
    takeRateAmount
    commissionType
    commissionAnalysis {
      id
      suggestedCommission
      contractingModel
      commissionType
    }
  }
`;

export const GET_PROCESS_AND_GROUP_BY_PROPOSAL_ID = gql`
  ${PROPOSAL_FIELDS_FRAGMENT}
  query GetProcessAndGroupByProposalId($proposalId: ID!) {
    getProcessByProposalId(proposalId: $proposalId) {
      error
      process {
        id
        name
        group {
          id
          name
          legalName
          docType
          docNumber
        }
        proposals {
          ...ProposalFields
        }
      }
    }
  }
`;

export const GET_PROCESS_AND_GROUP_BY_PROPOSAL_ID_AND_TRADER_ID = gql`
  ${PROPOSAL_FIELDS_FRAGMENT}
  query GetProcessByProposalAndTraderId($proposalId: ID!, $traderId: ID!) {
    getProcessByProposalIdForTrader(proposalId: $proposalId, traderId: $traderId) {
      error
      process {
        id
        name
        group {
          id
          name
          legalName
          docType
          docNumber
        }
        proposals {
          ...ProposalFields
        }
      }
    }
  }
`;
