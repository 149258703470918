import React from 'react';
import { XIcon } from '@heroicons/react/outline';

interface CloseButtonProps {
  closeSideBar: () => void;
}
const CloseButton: React.FC<CloseButtonProps> = ({ closeSideBar }) => {
  return (
    <div className="absolute top-0 right-0 pt-2 -mr-12">
      <button
        type="submit"
        className="flex justify-center items-center ml-1 w-10 h-10 rounded-full ring-2 ring-inset ring-white outline-none"
        onClick={() => closeSideBar()}
      >
        <span className="sr-only">Fechar</span>
        <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
      </button>
    </div>
  );
};

export default CloseButton;
