import React from 'react';

import HeroIcon from '@components/atoms/hero-icon';
import { DocumentFile } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const UploadedFileDisplay: React.FC<{ file: DocumentFile }> = ({ file }) => {
  return (
    <div className="py-3 px-4 rounded-md border border-black border-solid cursor-pointer">
      <div className="grid grid-cols-4">
        <div className="flex col-span-3 gap-2 items-center pt-1">
          <HeroIcon icon="PaperClipIcon" extraClassNames="w-4 h-4" />
          <p className="col-span-1 truncate">{file.label}</p>
        </div>
        <a href={file.uri} target="blank" className="block col-span-1 place-self-end font-semibold text-primary-60">
          baixar
        </a>
      </div>
    </div>
  );
};

export default UploadedFileDisplay;
