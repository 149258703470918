import { gql, useMutation } from '@apollo/client';

interface GiveUpContractProcessGraphQLResponse {
  giveUpContractProcess: { error: string | null; proposal: { id: string } | null };
}

const GIVE_UP_CONTRACT_PROCESS_STR = gql`
  mutation GiveUpContractProcess($proposalId: ID!) {
    giveUpContractProcess(proposalId: $proposalId) {
      error
      proposal {
        id
        status
      }
    }
  }
`;

export function useGiveUpContractProcessRound() {
  const [mutationFunction] = useMutation<GiveUpContractProcessGraphQLResponse, { proposalId: string }>(
    GIVE_UP_CONTRACT_PROCESS_STR,
  );

  const giveUpContractProcess = async (proposalId: string): Promise<{ id: string }> => {
    const { data, errors } = await mutationFunction({ variables: { proposalId } });

    if (
      errors !== undefined ||
      !data ||
      data.giveUpContractProcess.error !== null ||
      data.giveUpContractProcess.proposal === null
    )
      throw new Error('Falha ao abrir a rodada final.');

    const result = data.giveUpContractProcess.proposal;
    return { id: result.id };
  };

  return giveUpContractProcess;
}
