import { gql } from '@apollo/client';

export const QUERY_PROPOSAL_DATA_FOR_BID_CREATION = gql`
  query GetProposalForBidCreationByIdAndTrader($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      createdAt
      deadline
      status
      round
      contractType
      periods
      traders {
        id
        name
      }
      lowerFlexibility
      upperFlexibility
      proposalType
      commissionModality
      guaranteeType
      guaranteeMonths
      otherGuarantee
      supply {
        endDate
        startDate
        unit {
          id
        }
      }
      commissionAnalysis {
        id
        suggestedCommission
      }
    }
  }
`;
