import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { NumericFormField } from '@components/atoms/form';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';

import { formatNumber } from '@utils/text';

import { Unit } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface NonChangingConsumptionDisplaProps {
  unitPrefix: string;
  history: Unit['history'];
}
export const NonChangingConsumptionDisplay: React.FC<NonChangingConsumptionDisplaProps> = ({ unitPrefix, history }) => {
  const offPeakConsumptionSum =
    (Object.keys(history) as (keyof Unit['history'])[])
      .map((month) => history[month].offPeak)
      .reduce((total, value) => total + value, 0) / 12;

  const peakConsumptionSum =
    (Object.keys(history) as (keyof Unit['history'])[])
      .map((month) => history[month].peak)
      .reduce((total, value) => total + value, 0) / 12;

  return (
    <div className="grid grid-cols-2 gap-8 grow">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col border-t border-black">
          <p className="text-paragraph-medium">Consumo Fora Ponta</p>
          <p className="text-paragraph-medium" data-cy={`${unitPrefix}-consumption-changes-off-peak`}>{`${formatNumber(
            offPeakConsumptionSum,
          )} kWh`}</p>
        </div>
      </div>

      {!isNaN(peakConsumptionSum) && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col border-t border-black">
            <p className="text-paragraph-medium">Consumo Ponta</p>
            <p className="text-paragraph-medium" data-cy={`${unitPrefix}-consumption-changes-peak`}>{`${formatNumber(
              peakConsumptionSum,
            )} kWh`}</p>
          </div>
        </div>
      )}
    </div>
  );
};

interface ChangingConsumptionDisplayProps {
  unitIndex: number;
  unitId: string;
}
export const ChangingConsumptionDisplay: React.FC<ChangingConsumptionDisplayProps> = ({ unitIndex, unitId }) => {
  const unitPrefix = `unit-${unitId}`;
  const { control } = useFormContext<ProposalCompilationFormData>();

  const offPeakFieldName = `demandAndConsumption.${unitIndex}.unitInfo.changingConsumptionValues.offPeak` as const;
  const { fields: offPeakFields } = useFieldArray<ProposalCompilationFormData, typeof offPeakFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingConsumptionValues.offPeak`,
  });

  const peakFieldName = `demandAndConsumption.${unitIndex}.unitInfo.changingConsumptionValues.peak` as const;
  const { fields: peakFields } = useFieldArray<ProposalCompilationFormData, typeof peakFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingConsumptionValues.peak`,
  });

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-1">
        <p className="text-heading-xsmall">Consumo Fora Ponta</p>
        <div className="grid grid-cols-4 gap-6">
          {offPeakFields.map((field, index) => {
            return (
              <NumericFormField<ProposalCompilationFormData>
                key={field.id}
                id={`${unitPrefix}-off-peak-consumption-${field.year}`}
                className="flex flex-col space-y-1"
                field={
                  `demandAndConsumption.${unitIndex}.unitInfo.changingConsumptionValues.offPeak.${index}.value` as const
                }
                label={`${field.year}`}
                formatProps={{
                  placeholder: 'kWh',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                options={{
                  required: { value: true, message: 'Insira o valor de consumo em kWh' },
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="flex flex-col space-y-1">
        <p className="text-heading-xsmall">Consumo Ponta</p>
        <div className="grid grid-cols-4 gap-6">
          {peakFields.map((field, index) => {
            return (
              <NumericFormField<ProposalCompilationFormData>
                key={field.id}
                id={`${unitPrefix}-peak-consumption-${field.year}`}
                className="flex flex-col space-y-1"
                field={
                  `demandAndConsumption.${unitIndex}.unitInfo.changingConsumptionValues.peak.${index}.value` as const
                }
                label={`${field.year}`}
                formatProps={{
                  placeholder: 'kWh',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                options={{
                  required: { value: true, message: 'Insira o valor de consumo em kWh' },
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
