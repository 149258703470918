import React from 'react';

import { capitalize, formatNumber } from '@utils/text';
import { MonthTranslationMapper } from '@utils/translators/proposal';
import { Unit, UnitConsumptionHistory } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { getPaddedUnitHistoryTotal } from './unit-history-helper-methods';

interface ConsumptionHistoryTableRowProps {
  month: keyof UnitConsumptionHistory;
  hasGenerator: boolean;
  peak: number;
  offPeak: number;
  generator: number;
}
const ConsumptionHistoryTableRow: React.FC<ConsumptionHistoryTableRowProps> = ({
  month,
  hasGenerator,
  peak,
  offPeak,
  generator,
}) => {
  const total = peak + offPeak + generator;
  return (
    <tr>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">
        {capitalize(MonthTranslationMapper[month])}
      </td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">
        {hasGenerator ? `${formatNumber(generator / 1000)}` : '-'}
      </td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        peak / 1000,
      )}`}</td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        offPeak / 1000,
      )}`}</td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        total / 1000,
      )}`}</td>
    </tr>
  );
};

const ConsumptionHistoryTable: React.FC<{ unit: Pick<Unit, 'history' | 'hasGenerator'> }> = ({ unit }) => {
  const totalEnergyInKiloWattsHour = getPaddedUnitHistoryTotal(unit.history);
  return (
    <table className="table-auto">
      <thead>
        <tr>
          <th className="py-2 w-1/5 border-b border-black"></th>
          <th className="py-2 w-1/5 font-normal text-center border-b border-black text-paragraph-small text-neutral-50">
            Gerador (MWh)
          </th>
          <th className="py-2 w-1/5 font-normal text-center border-b border-black text-paragraph-small text-neutral-50">
            Energia Ponta (MWh)
          </th>
          <th className="py-2 w-1/5 font-normal text-center border-b border-black text-paragraph-small text-neutral-50">
            Energia Fora Ponta (MWh)
          </th>
          <th className="py-2 w-1/5 font-normal text-center border-b border-black text-paragraph-small text-neutral-50">
            Total (MWh)
          </th>
        </tr>
      </thead>
      <tbody>
        {(Object.keys(unit.history) as (keyof UnitConsumptionHistory)[]).map((month, index) => (
          <ConsumptionHistoryTableRow
            key={index}
            month={month}
            {...unit.history[month]}
            hasGenerator={unit.hasGenerator}
          />
        ))}
        <tr>
          <td className="py-2 w-1/5 border-b border-black"></td>
          <td className="py-2 w-1/5 border-b border-black"></td>
          <td className="py-2 w-1/5 border-b border-black"></td>
          <td className="py-2 w-1/5 border-b border-black">
            <div className="flex flex-col">
              <p className="text-right text-paragraph-small text-neutral-50">Consumo Total (MWh)</p>
              <p className="text-right text-paragraph-medium">{`${formatNumber(totalEnergyInKiloWattsHour / 1000, {
                style: 'decimal',
                useGrouping: true,
                maximumFractionDigits: 0,
              })}`}</p>
            </div>
          </td>
          <td className="py-2 w-1/5 border-b border-black">
            <p className="text-right text-paragraph-small text-neutral-50">Consumo Total (MWm)</p>
            <p className="text-right text-paragraph-medium">{`${formatNumber(
              totalEnergyInKiloWattsHour / 1000 / 24 / 365,
              {
                style: 'decimal',
                useGrouping: true,
                maximumFractionDigits: 3,
              },
            )}`}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ConsumptionHistoryTable;
