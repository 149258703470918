import React from 'react';

import { TextInput } from '@clarke-energia/foton';
import { Controller, Path, RegisterOptions, useFormContext } from 'react-hook-form';

export interface FormTextInputProps<T> {
  field: Path<T>;
  id: string;
  label: string;
  placeholder?: string;
  options?: RegisterOptions;
}

export function FormTextInput<T>({ field, id, label, placeholder, options }: FormTextInputProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value, ref } }) => (
        <TextInput
          id={id}
          customAttrs={{ 'data-cy': id }}
          name={name}
          label={label}
          // @ts-expect-error :: because value is supposed to be read-only
          value={value}
          ref={ref}
          placeholder={placeholder || 'Digite aqui'}
          required={(options?.required as boolean) || false}
          error={errors[field]?.message as string | undefined}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    />
  );
}
