import React from 'react';

import { formatCurrency, formatNumber } from '@utils/text';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { AverageEconomyPerYear } from './types';

interface AveragePriceDisplayProps {
  proposal: Proposal;
  traderType: 'retail' | 'wholesale' | 'both';
  averageEntries: AverageEconomyPerYear[];
}

const getTraderTypeDisplay = (
  traderType: AveragePriceDisplayProps['traderType'],
  contractType: Proposal['contractType'],
): string => {
  const isFixedPrice = contractType === 'FIXED_PRICE';
  const contractTypeDisplay = isFixedPrice ? 'Preço' : 'Desconto';

  switch (traderType) {
    case 'retail':
      return `${contractTypeDisplay} de comercializadoras Varejistas`;

    case 'wholesale':
      return `${contractTypeDisplay} de comercializadoras Atacadistas`;

    default:
      return `${contractTypeDisplay} Geral`;
  }
};

const AveragePriceDisplay: React.FC<AveragePriceDisplayProps> = ({ proposal, traderType, averageEntries }) => {
  const isFixedPrice = proposal.contractType === 'FIXED_PRICE';

  const traderTypeDisplay = getTraderTypeDisplay(traderType, proposal.contractType);

  const totalAverage = averageEntries
    .map(({ amount }) => amount)
    .filter((amount) => !isNaN(amount))
    .reduce((average, value, _, { length }) => average + value / length, 0);

  const formattedTotalAverage = isFixedPrice ? formatCurrency(totalAverage) : `${formatNumber(totalAverage)}%`;

  return (
    <div className="space-y-4">
      <h4 className="font-bold text-paragraph-small">{traderTypeDisplay}</h4>
      <div className="flex overflow-x-auto justify-between p-2 space-x-3 border-black sm:space-x-10 border-y">
        {averageEntries.map(({ year, amount }, pearYearIndex) => {
          const formattedAmount = isNaN(amount) ? '-' : isFixedPrice ? formatCurrency(amount) : `${amount}%`;
          return (
            <div key={pearYearIndex} className="flex flex-col">
              <p className="text-paragraph-small text-neutral-50">{year}</p>
              <p className="text-paragraph-medium">{formattedAmount}</p>
            </div>
          );
        })}
        <div className="flex flex-col">
          <p className="text-paragraph-small text-neutral-50">Média total</p>
          <p className="text-paragraph-medium">{totalAverage === 0 ? '-' : formattedTotalAverage}</p>
        </div>
      </div>
    </div>
  );
};
export default AveragePriceDisplay;
