import React from 'react';
import { Link } from 'react-router-dom';

import HeroIcon from '@components/atoms/hero-icon';
import { ProposalRoundsNavigation } from '@components/atoms/proposal-round/rounds-navigation';
import { ProposalRoundStatus, RoundStatusBadge } from '@components/atoms/proposal-round/round-status-badge';
import {
  EMPTY_PROCESS,
  ProcessData,
  Proposal,
} from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const getProposalStatus = (proposal: Proposal): ProposalRoundStatus => {
  if (proposal.status === 'FINISHED') return 'finished';
  if (proposal.deadline.getTime() <= Date.now()) return 'expired';
  return 'requested';
};

export const getCurrentProposalFromProcess = (process: ProcessData, currentProposalId: string): Proposal => {
  const proposal = process.proposals.find((p) => p.id === currentProposalId);
  if (proposal !== undefined) {
    return proposal;
  }
  return EMPTY_PROCESS.proposals[0];
};

interface RoundHeaderProps {
  processData: ProcessData;
  currentProposalId: string;
  goBackProps: { url: string; label: string };
  urlPathPrefixToMatch: string;
  urlSuffixToNavigateTo: string;
}
const RoundHeader: React.FC<RoundHeaderProps> = ({
  processData,
  currentProposalId,
  goBackProps,
  urlPathPrefixToMatch,
  urlSuffixToNavigateTo,
}) => {
  const proposal = getCurrentProposalFromProcess(processData, currentProposalId);
  const status = getProposalStatus(proposal);
  return (
    <div className="col-span-12 border-b border-black">
      <div className="grid grid-cols-12 px-8 pt-6 pb-8">
        <div className="col-span-12">
          <div className="flex flex-col gap-x-14 space-y-3 xl:flex-row xl:justify-between">
            <div className="flex flex-col items-stretch space-y-2 sm:flex-row sm:items-center sm:space-x-20 sm:grow">
              <div className="flex flex-col space-y-2 max-w-md sm:justify-center grow">
                <Link to={goBackProps.url} className="flex items-center space-x-2">
                  <HeroIcon icon="ArrowLeftIcon" extraClassNames="w-4 h-4 text-neutral-50" />
                  <span className="font-medium text-paragraph-medium text-neutral-50">{goBackProps.label}</span>
                </Link>

                <p className="text-heading-2xlarge">{processData.name}</p>
              </div>
              <RoundStatusBadge status={status} />
            </div>
            <div className="flex justify-self-start space-x-4 xl:justify-self-auto xl:self-end">
              <ProposalRoundsNavigation
                processData={processData}
                urlPathPrefixToMatch={urlPathPrefixToMatch}
                urlSuffixToNavigateTo={urlSuffixToNavigateTo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundHeader;
