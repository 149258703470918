import React from 'react';
import { Dialog } from '@headlessui/react';

import { Button } from '@clarke-energia/foton';

interface ConfirmSubmissionDialogProps {
  displayOptions: {
    titleText: string;
    buttonSubmitText: string;
  };
  isOpen: boolean;
  closeDialogCallback: () => void;
  confirmDialogCallback: () => Promise<void>;
}
export const ConfirmSubmissionDialog: React.FC<ConfirmSubmissionDialogProps> = ({
  displayOptions,
  isOpen,
  closeDialogCallback,
  confirmDialogCallback,
}) => {
  const [isConfirmActionInProgress, setIsConfirmActionInProgress] = React.useState<boolean>(false);
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        return;
      }}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="flex fixed inset-0 justify-center items-center p-4">
        <Dialog.Panel
          data-cy="confirm-submit-dialog"
          className="flex flex-col justify-center p-4 space-y-4 bg-white border border-black max-w-[80%] sm:max-w-[20rem]"
        >
          <h1 className="text-center">{displayOptions.titleText}</h1>
          <div className="flex flex-col space-y-4">
            <Button
              kind="secondary"
              type="button"
              label="Cancelar"
              onClick={closeDialogCallback}
              disabled={isConfirmActionInProgress}
            />
            <Button
              kind="primary"
              type="button"
              label={displayOptions.buttonSubmitText}
              customAttrs={{ 'data-cy': 'confirm-submit-button' }}
              loading={isConfirmActionInProgress}
              disabled={isConfirmActionInProgress}
              onClick={async () => {
                setIsConfirmActionInProgress(true);
                await confirmDialogCallback();
                setIsConfirmActionInProgress(false);
              }}
            />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
