import React from 'react';
import { Navigate, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import {
  BIDS_LIST_FOR_PROPOSAL_PATH,
  COMPILATION_SUFFIX_PATH,
  DOES_NOT_EXIST_PATH,
  PROPOSAL_INFO_PATH,
  VIEW_PROPOSAL_LIST_PATH,
} from '@routers/constants';
import ContentLayoutContainer from '@components/layout/content-layout-container';
import RoundHeader, { getCurrentProposalFromProcess } from '@components/organisms/proposal-round-header';
import { HeaderSkeleton } from '@components/atoms/proposal-round/header-skeleton';
import { RoundActionButtonProps, RoundAdminActionsBar } from '@components/molecules/proposal-round/actions-bar';
import SendSignedFinalProposalFormDialog from '@components/organisms/send-signed-final-proposal';
import { SendSignedFinalProposalFormData } from '@components/molecules/send-signed-final-proposal/types';
import SendSignedFinalProposalContextProvider from '@components/molecules/send-signed-final-proposal/context';
import { useCreateBidDocument } from '@hooks/bids/create-bid';
import { Unpacked } from '@utils/types';
import { DocumentTypeForBid } from '@hooks/bids/create-bid/mutations';
import useAuth from '@hooks/auth';
import { useUpdateProposalStatus } from '@hooks/proposals/single-proposal/creation/proposal';
import { useUpdateBidStatus } from '@hooks/bids/update';
import { useGetProcessAndGroupByProposalId } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id';
import { Proposal, ProposalStatus } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface AdminProposalViewContext {
  proposal: Proposal;
}

export function useAdminProposal() {
  return useOutletContext<AdminProposalViewContext>();
}

const AdminProposalRoundLayout: React.FC = () => {
  const { proposalId } = useParams();
  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const { processData, fetchProcessData, isProcessDataLoading } = useGetProcessAndGroupByProposalId();

  React.useEffect(() => {
    if (proposalId) {
      fetchProcessData(proposalId);
    }
  }, [proposalId]);

  if (isProcessDataLoading) return <HeaderSkeleton />;

  const proposal = getCurrentProposalFromProcess(processData, proposalId);

  return (
    <>
      <div className="flex flex-col">
        <RoundHeader
          processData={processData}
          currentProposalId={proposalId}
          goBackProps={{ url: VIEW_PROPOSAL_LIST_PATH, label: 'Clientes' }}
          urlPathPrefixToMatch={VIEW_PROPOSAL_LIST_PATH}
          urlSuffixToNavigateTo={BIDS_LIST_FOR_PROPOSAL_PATH}
        />
        <ContentLayoutContainer>
          <Outlet context={{ proposal }} />
        </ContentLayoutContainer>
      </div>
    </>
  );
};

export default AdminProposalRoundLayout;

const getMostRecentBidFromTrader = (bids: Proposal['bids'], traderId: string): Unpacked<Proposal['bids']> => {
  const bidsFromThisTrader = bids.filter((bid) => bid.trader.id === traderId);
  const bidsSortedByMostRecentCreation = bidsFromThisTrader.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  );
  if (bidsSortedByMostRecentCreation.length === 0)
    throw new Error(`Não existem ofertas com comercializadora ${traderId}.`);

  return bidsSortedByMostRecentCreation[0];
};

const adminActionsButtonList: RoundActionButtonProps[] = [
  {
    urlBuilderFunction: (proposal) => `${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${PROPOSAL_INFO_PATH}`,
    checkIfShouldBeDisplayed: (proposal) => true,
    label: 'Informações',
    eventToBeTracked: 'DETAILED_PROPOSAL_PAGE_INFORMATIONS_BUTTON_CLICKED',
  },
  {
    urlBuilderFunction: (proposal) => `${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${BIDS_LIST_FOR_PROPOSAL_PATH}`,
    checkIfShouldBeDisplayed: (proposal) => true,
    label: 'Propostas',
    eventToBeTracked: 'DETAILED_PROPOSAL_PAGE_BIDS_BUTTON_CLICKED',
  },
  {
    urlBuilderFunction: (proposal) => `${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${COMPILATION_SUFFIX_PATH}`,
    checkIfShouldBeDisplayed: (proposal) => proposal.round !== 0 && proposal.bids.length > 0,
    label: 'Compilado',
    eventToBeTracked: 'DETAILED_PROPOSAL_PAGE_COMPILATION_BUTTON_CLICKED',
  },
];

interface LayoutWithRoundActionsBarProps {
  children: React.ReactNode;
  proposal: Proposal;
}
export const LayoutWithRoundActionsBar: React.FC<LayoutWithRoundActionsBarProps> = ({ children, proposal }) => {
  const navigate = useNavigate();

  const {
    authStatus: { accessToken },
  } = useAuth();

  const updateBidStatus = useUpdateBidStatus();
  const createBidDocument = useCreateBidDocument();
  const updateProposalStatus = useUpdateProposalStatus();

  const onSubmit = async (data: SendSignedFinalProposalFormData) => {
    const selectedBid = getMostRecentBidFromTrader(proposal.bids, data.selectedTrader);

    await updateBidStatus(selectedBid.id, 'SIGNED');
    await Promise.all(
      data.files.map((file) =>
        createBidDocument(file, selectedBid.id, accessToken, DocumentTypeForBid.SIGNED_PROPOSAL),
      ),
    );

    await updateProposalStatus(proposal.id, ProposalStatus.FINISHED);
    navigate(0);
  };

  return (
    <>
      <SendSignedFinalProposalContextProvider>
        {proposal.round === 0 && (
          <SendSignedFinalProposalFormDialog proposal={proposal} confirmDialogCallback={onSubmit} />
        )}
        <div className="space-y-6">
          <RoundAdminActionsBar proposal={proposal} adminActionsButtonList={adminActionsButtonList} />
          <div className="overflow-y-auto w-full h-full max-h-[calc(100vh-18rem)]">{children}</div>
        </div>
      </SendSignedFinalProposalContextProvider>
    </>
  );
};
