import React from 'react';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import {
  ProposalValueForDisplay,
  ProposalValueForDisplayProps,
} from '@components/atoms/proposal-round/information-summary/proposal-value-for-display';

interface ProposalInformationContentsProps<T> {
  proposal: Proposal;
  valuesDefinitions: Pick<ProposalValueForDisplayProps<T>, 'label' | 'field'>[];
  displayTranslatorFunction: ProposalValueForDisplayProps<T>['displayTranslatorFunction'];
  sectionTitle?: string;
  truncate?: boolean;
}

export function ProposalInformationContents<T>(props: ProposalInformationContentsProps<T>) {
  const { valuesDefinitions, sectionTitle, ...rest } = props;
  return (
    <div className="p-4 space-y-4">
      <p className="font-bold text-paragraph-small">{sectionTitle}</p>
      <div className="grid grid-cols-2 gap-y-1 gap-x-10 border-black sm:grid-cols-4 border-y">
        {valuesDefinitions.map((definition, index) => (
          <ProposalValueForDisplay<T> key={index} {...rest} {...definition} />
        ))}
      </div>
    </div>
  );
}
