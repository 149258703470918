import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Ranking } from '@hooks/process/queries/get-process-tader-ranking/types';
import EmptyRankingDisplay from '@components/atoms/process-trader-ranking/empty-ranking-content';
import RankingContent from '@components/molecules/process-ranking';
import { checkPositionOfTraderAtRanking, oderByRanking } from './helper';
import { Tabs } from '@clarke-energia/foton';

interface ProcessRankingProps {
  ranking: Ranking[];
  title: string;
  isLoading: boolean;
  targetTraderId: string;
}

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

const RankingDisplay = ({ ranking, isLoading, title, targetTraderId }: ProcessRankingProps): JSX.Element => {
  const rankingOutsideScope = ranking.filter(({ isInsideScope }) => !isInsideScope);
  const rankingInsideScope = ranking
    .filter(({ isInsideScope }) => isInsideScope)
    .map(({ position, ...item }, idx) => ({ position: idx + 1, ...item }));

  const topRanked = rankingInsideScope.length >= 3 ? rankingInsideScope.slice(0, 3) : ranking;
  const traderIsOutsideScope = rankingOutsideScope.filter(({ trader }) => trader.id === targetTraderId);

  const traderRanking = checkPositionOfTraderAtRanking(topRanked, rankingInsideScope, targetTraderId);
  const newRanking = traderRanking ? [traderRanking, ...topRanked] : topRanked;

  const handleRenderContent = () => {
    if (isLoading) {
      return <RowSkeleton />;
    }

    if (!isLoading && ranking.length === 0) {
      return <EmptyRankingDisplay title="Sem ranking" text="Não há nenhum ranking disponível para visualização!" />;
    }

    return (
      <>
        <p className="mb-5 text-heading-2xlarge">{title}</p>
        <Tabs
          tabs={[
            { label: 'Dentro do escopo', children: <RankingContent ranking={oderByRanking(newRanking)} /> },
            ...(traderIsOutsideScope.length > 0
              ? [
                  {
                    label: 'Fora Escopo',
                    children: <RankingContent ranking={traderIsOutsideScope} />,
                  },
                ]
              : []),
          ]}
        />
      </>
    );
  };

  return handleRenderContent();
};

export default RankingDisplay;
