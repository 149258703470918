import React from 'react';
import { Link } from 'react-router-dom';
import { SearchBar, ColumnOptions } from '@clarke-energia/foton';

import { BIDS_LIST_FOR_PROPOSAL_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { ContractTypeEnum } from '@utils/translators/proposal';
import { formatDate } from '@utils/text';
import { UserType } from '@utils/constants/common';

import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-labels';

import { ListHeader } from '@components/atoms/proposal-list-panel/header';
import ContentLayoutContainer from '@components/layout/content-layout-container';
import { ProcessesTable } from '@components/molecules/process-list-panel/table';
import { RoundLabelTag } from '@components/molecules/process-list-panel/round-status-tag';

import useAuth from '@hooks/auth';
import { ProcessSummaryData, useGetProcessSummaryListByStage } from '@hooks/process/queries/get-process-list-for-admin';

type renderFunction = ColumnOptions<ProcessSummaryData>['renderAsElement'];
const renderProcessName: renderFunction = (entry) => (
  <Link
    to={`${VIEW_PROPOSAL_LIST_PATH}/${entry.mostRecentProposalId}/${BIDS_LIST_FOR_PROPOSAL_PATH}`}
    className="text-blue-50 underline"
  >
    {entry.name}
  </Link>
);
const renderGroupName: renderFunction = (entry) => (
  <Link
    to={`${VIEW_PROPOSAL_LIST_PATH}/${entry.mostRecentProposalId}/${BIDS_LIST_FOR_PROPOSAL_PATH}`}
    className="text-blue-50 underline"
  >
    {entry.groupName}
  </Link>
);
const renderContractType: renderFunction = (entry) => ContractTypeEnum[entry.contractType].toString();
const renderTotalVolume: renderFunction = (entry) =>
  `${(entry.totalConsumptionVolume / 1000).toFixed(3)}`.replace('.', ',');
const renderDeadline: renderFunction = (entry) => `${formatDate(entry.deadline)}`;
const renderProgress: renderFunction = (entry) => `${entry.numBids}/${entry.numTraders}`;
const renderStatus: renderFunction = ({ mostRecentProposalProcessStatus, round }) => (
  <RoundLabelTag status={mostRecentProposalProcessStatus} round={round} />
);

const adminTableColumns: ColumnOptions<ProcessSummaryData>[] = [
  { accessor: 'name', header: 'Processo', renderAsElement: renderProcessName },
  { accessor: 'groupName', header: 'Cliente', renderAsElement: renderGroupName },
  { accessor: 'contractType', header: 'Produto', renderAsElement: renderContractType },
  { accessor: 'totalConsumptionVolume', header: 'Volume MWm', renderAsElement: renderTotalVolume },
  { accessor: 'deadline', header: 'Prazo da proposta', renderAsElement: renderDeadline },
  { accessor: 'numBids', header: 'Progresso', renderAsElement: renderProgress },
  { accessor: 'mostRecentProposalProcessStatus', header: 'Etapa', renderAsElement: renderStatus },
];

export const filterEntriesByGroupOrProcessName = (entries: ProcessSummaryData[], term: string) =>
  entries.filter(
    (entry) =>
      entry.groupName?.toLowerCase().includes(term.toLowerCase()) ||
      entry.name?.toLowerCase().includes(term.toLowerCase()),
  );

const AdminProcessListPanelPage: React.FC = () => {
  const {
    authStatus: { isAuthenticated },
  } = useAuth();

  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');

  const {
    processData: requestedProcesses,
    isRequestLoading: isRequestedProcessesLoading,
    fetchProcessListData: fetchRequestedProcessesData,
  } = useGetProcessSummaryListByStage();

  const {
    processData: underAnalysisProcesses,
    isRequestLoading: isUnderAnalysisProcessesLoading,
    fetchProcessListData: fetchUnderAnalysisProcessesData,
  } = useGetProcessSummaryListByStage();

  const {
    processData: finishedProcesses,
    isRequestLoading: isFinishedProcessesLoading,
    fetchProcessListData: fetchFinishedProcessesData,
  } = useGetProcessSummaryListByStage();

  React.useEffect(() => {
    eventTracker.trackEvent(eventLabels.PROPOSALS_LIST_PAGE_VIEWED, {
      userType: UserType.admin,
    });
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchRequestedProcessesData('REQUESTED');
      fetchUnderAnalysisProcessesData('UNDER_ANALYSIS');
      fetchFinishedProcessesData('FINISHED');
    }
  }, [isAuthenticated]);

  const data = {
    requested: filterEntriesByGroupOrProcessName(requestedProcesses, typedSearchTerm),
    underAnalysis: filterEntriesByGroupOrProcessName(underAnalysisProcesses, typedSearchTerm),
    finished: filterEntriesByGroupOrProcessName(finishedProcesses, typedSearchTerm),
  };
  const isFetching = {
    requested: isRequestedProcessesLoading,
    underAnalysis: isUnderAnalysisProcessesLoading,
    finished: isFinishedProcessesLoading,
  };
  return (
    <ContentLayoutContainer className="overflow-y-auto h-screen">
      <ListHeader />
      <div className="mb-8 w-full md:w-1/2 lg:w-1/4">
        <SearchBar
          label=""
          customAttrs={{ 'data-cy': 'search-proposals-bar' }}
          placeholder="Pesquisar"
          onTypedTermChangedCallback={(term) => setTypedSearchTerm(term)}
          onBlur={() =>
            eventTracker.trackEvent(eventLabels.PROPOSALS_LIST_SEARCH_PROPOSAL, {
              userType: UserType.admin,
            })
          }
          className="w-[20rem]"
        />
      </div>
      <ProcessesTable data={data} isFetching={isFetching} columnDefinitions={adminTableColumns} />
    </ContentLayoutContainer>
  );
};

export default AdminProcessListPanelPage;
