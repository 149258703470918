import React from 'react';
import { Dialog } from '@headlessui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@clarke-energia/foton';

import { Unpacked } from '@utils/types';
import { formatDate } from '@utils/text';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import HeroIcon from '@components/atoms/hero-icon';
import { FileFormField, SelectFormField } from '@components/atoms/form';
import { SendSignedFinalProposalFormData } from '@components/molecules/send-signed-final-proposal/types';
import { useSendSignedFinalProposalContext } from '@components/molecules/send-signed-final-proposal/context';

const buildTradersForSelectionOptions = (proposal: Proposal) =>
  proposal.traders
    .filter((trader) => trader.bid !== undefined)
    .map((trader) => {
      const formattedLabel = `${trader.name} • ${formatDate(
        (trader.bid as Unpacked<Proposal['bids']>).createdAt,
      ).toUpperCase()}`;
      return { id: trader.id, value: trader.id, optionLabel: formattedLabel };
    });

interface SendSignedFinalProposalFormDialogProps {
  proposal: Proposal;
  confirmDialogCallback: (data: SendSignedFinalProposalFormData) => Promise<void>;
}
export const SendSignedFinalProposalFormDialog: React.FC<SendSignedFinalProposalFormDialogProps> = ({
  proposal,
  confirmDialogCallback,
}) => {
  const { isModalFormOpen, closeModalDialogCallback } = useSendSignedFinalProposalContext();

  const tradersForSelectionOptions = buildTradersForSelectionOptions(proposal);

  const methods = useForm<SendSignedFinalProposalFormData>({
    defaultValues: {
      selectedTrader: '',
      files: [],
    },
  });
  return (
    <Dialog
      open={isModalFormOpen}
      onClose={() => {
        return;
      }}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="flex fixed inset-0 justify-center items-center p-4">
        <Dialog.Panel
          className="p-4 bg-white border border-black max-w-[80%] sm:max-w-[24rem]"
          data-cy="send-signed-final-proposal-modal"
        >
          <FormProvider {...methods}>
            <form className="flex flex-col justify-center space-y-4">
              <div className="flex justify-between">
                <p className="text-neutral-50">{proposal.group.name}</p>
                <button type="button" onClick={closeModalDialogCallback}>
                  <HeroIcon icon="XIcon" extraClassNames="w-5 h-5" />
                </button>
              </div>

              <h1 className="font-bold text-heading-2xlarge">Enviar proposta final assinada</h1>
              <h2 className="text-paragraph-medium">
                Parabéns! O cliente assinou a proposta final! Envie para a comercializadora o documento.
              </h2>

              <SelectFormField<SendSignedFinalProposalFormData>
                field="selectedTrader"
                label="Selecione a proposta final assinada"
                id="selected-trader"
                inputOptions={tradersForSelectionOptions}
                options={{ required: { value: true, message: 'É preciso escolher a comercializadora ganhadora.' } }}
              />

              <FileFormField<SendSignedFinalProposalFormData>
                name="files"
                id="signed-proposal-files"
                label={{ text: 'Anexe a proposta final assinada', className: 'text-paragraph-medium' }}
                options={{ required: { value: true, message: 'É preciso selecionar ao menos um arquivo' } }}
                fileSizeLimitInBytes={30 * 1020 * 1024}
                fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
              />

              <div className="flex flex-col pt-4 space-y-4 space-x-0 sm:flex-row sm:space-y-0 sm:space-x-4">
                <Button kind="secondary" type="button" label="Cancelar" onClick={closeModalDialogCallback} />
                <Button
                  kind="primary"
                  type="button"
                  customAttrs={{ 'data-cy': 'submit-signed-final-proposal-button' }}
                  label="Enviar proposta assinada"
                  onClick={async () => {
                    await methods.handleSubmit(confirmDialogCallback)();
                  }}
                />
              </div>
            </form>
          </FormProvider>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default SendSignedFinalProposalFormDialog;
