import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import { parseStringToDate } from '@utils/text';
import { ProcessStageType, ProcessSummaryData, RawDataEntry } from './get-process-list-for-admin';

const GET_PROCESS_LIST_FOR_TRADER_STR = gql`
  query GetProcessListSummaryForTrader($stage: ProcessStageType!, $traderId: ID!) {
    getProcessListSummaryForTrader(stage: $stage, traderId: $traderId) {
      error
      data {
        id
        name
        numBids
        numTraders
        deadline
        contractType
        totalConsumptionVolume
        group {
          name
        }
        mostRecentProposalId
        round
        mostRecentProposalProcessStatus
      }
    }
  }
`;

interface GetProcessListSummaryGraphQLResponse {
  getProcessListSummaryForTrader: {
    error: string | null;
    data: RawDataEntry[] | null;
  };
}

const parseListData = (rawList: RawDataEntry[]): ProcessSummaryData[] => {
  return rawList.map((entry) => {
    const {
      id,
      name,
      numBids,
      numTraders,
      deadline,
      contractType,
      totalConsumptionVolume,
      group,
      mostRecentProposalId,
      round,
      mostRecentProposalProcessStatus,
    } = entry;
    return {
      id,
      name,
      numBids,
      numTraders,
      deadline: parseStringToDate(deadline),
      contractType,
      totalConsumptionVolume,
      groupName: group.name,
      mostRecentProposalId,
      round,
      mostRecentProposalProcessStatus,
    };
  });
};

export function useGetProcessSummaryListByStageForTrader() {
  const [isRequestLoading, setIsRequestLoading] = React.useState<boolean>(true);
  const [processData, setProcessData] = React.useState<ProcessSummaryData[]>([]);
  const [queryFunction] = useLazyQuery<
    GetProcessListSummaryGraphQLResponse,
    { stage: ProcessStageType; traderId: string }
  >(GET_PROCESS_LIST_FOR_TRADER_STR);

  const fetchProcessListData = async (stage: ProcessStageType, traderId: string): Promise<void> => {
    await queryFunction({
      variables: { stage, traderId },
      onCompleted: (data) => {
        if (data.getProcessListSummaryForTrader.error !== null || data.getProcessListSummaryForTrader.data === null) {
          setProcessData([]);
          setIsRequestLoading(false);
          throw new Error('Server failed to respond with valid process list data.');
        }

        const rawList = data.getProcessListSummaryForTrader.data;
        const parsedList = parseListData(rawList);
        setProcessData(parsedList);
        setIsRequestLoading(false);
      },
      onError: () => {
        setProcessData([]);
        setIsRequestLoading(false);
        throw new Error('Server failed to respond with valid process list data.');
      },
    });
  };

  return { processData, fetchProcessListData, isRequestLoading };
}
