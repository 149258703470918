import { gql } from '@apollo/client';

export const GET_ALL_TRADERS_QUERY = gql`
  query GetAllTradersData {
    traders {
      data {
        id
        name
      }
    }
  }
`;
