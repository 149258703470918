import cloneDeep from 'lodash.clonedeep';

import { BidForFeedback, ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';
import { formatCurrency } from '@utils/text';
import { ContractTypeEnum } from '@utils/translators/proposal';

interface RefusedStringParams {
  totalBids: BidForFeedback[];
  bidRanking: number;
  traderBid: BidForFeedback;
  bestBidPrice: string;
}

const getRefusedInsideScopeString = ({
  totalBids,
  bidRanking,
  traderBid,
  bestBidPrice,
}: RefusedStringParams): string => {
  const numBids = totalBids.length;
  const modality = traderBid.retailService ? 'Varejista' : 'Atacadista';

  return `Oi pessoal, tudo bem? Infelizmente não foi dessa vez :( O cliente optou por seguir a contratação com outra fornecedora de energia. A sua proposta ficou dentro do escopo pedido, fator que é importante para o posicionamento e avaliação do cliente. Tivemos ${numBids} fornecedoras participantes nessa rodada e você ficou em ${bidRanking}º lugar.

A melhor curva de preços da rodada na modalidade, ${modality}, sem levar em consideração a comissão da Clarke, foi de:
${bestBidPrice}

Agradecemos a participação! Fiquem por perto que em breve abriremos novos processos de contratação e contamos com a sua proposta! Abraços, Clarke.`;
};

const getRefusedOutsideScopeString = ({
  totalBids,
  bidRanking,
  traderBid,
  bestBidPrice,
}: RefusedStringParams): string => {
  const numBids = totalBids.length;
  const modality = traderBid.retailService ? 'Varejista' : 'Atacadista';

  return `Oi pessoal, tudo bem? Infelizmente não foi dessa vez :( O cliente optou por seguir a contratação com outra fornecedora de energia. Tivemos ${numBids} fornecedoras participantes nessa rodada e você ficou em ${bidRanking}º lugar.

É muito importante que a proposta seja o mais dentro do escopo requerido possível, isso faz diferença na sua avaliação e na decisão do cliente. Para essa definição, levamos em conta os seguintes fatores: Flexibilidade inferior e superior, garantia financeira e a modalidade de contratação. Consideramos que a sua proposta tenha ficado fora do escopo pedido e isso influenciou o seu resultado.

A melhor curva de preços da rodada na modalidade, ${modality}, sem levar em consideração a comissão da Clarke, foi de:
${bestBidPrice}

Agradecemos a participação! Fiquem por perto que em breve abriremos novos processos de contratação e contamos com a sua proposta! Abraços, Clarke.`;
};

const sortAllBidsByTotalSavings = (
  allBids: BidForFeedback[],
  contractType: keyof typeof ContractTypeEnum,
): BidForFeedback[] => {
  return cloneDeep(allBids).sort((a, b) => {
    const bTotalSavings =
      (contractType === 'FIXED_PRICE'
        ? b.savings.fixedPrice?.groupReport.totalCurrencyAmount
        : b.savings.guaranteedSaving?.groupReport.totalCurrencyAmount) || NaN;
    const aTotalSavings =
      (contractType === 'FIXED_PRICE'
        ? a.savings.fixedPrice?.groupReport.totalCurrencyAmount
        : a.savings.guaranteedSaving?.groupReport.totalCurrencyAmount) || NaN;
    return bTotalSavings - aTotalSavings;
  });
};

const getRefusedTraderIndexPosition = (traderId: string, allBids: BidForFeedback[]): number => {
  const overallIndex = allBids.findIndex((bid) => bid.trader.id === traderId);
  return overallIndex;
};

const getBestBidPrice = (traderBid: BidForFeedback, allBids: BidForFeedback[]): string => {
  let bestBid: BidForFeedback;
  if (traderBid.retailService) {
    const retailBids = allBids.filter((bid) => bid.retailService);
    bestBid = retailBids[0];
  } else {
    const wholesaleBids = allBids.filter((bid) => !bid.retailService);
    bestBid = wholesaleBids[0];
  }
  return bestBid.economy.map(({ year, amount }) => `${year}: ${formatCurrency(amount)}`).join(' | ');
};

export const getRefusedFeedbackTemplate = (traderId: string, proposal: ProposalForFeedback): string => {
  const sortedBids = sortAllBidsByTotalSavings(proposal.bids, proposal.contractType);
  const thisTraderIndex = sortedBids.findIndex((bid) => bid.trader.id === traderId);
  const traderBid = sortedBids[thisTraderIndex];
  const traderIndexPosition = getRefusedTraderIndexPosition(traderId, sortedBids);
  const bestBidPrice = getBestBidPrice(traderBid, sortedBids);

  if (traderBid.savings.isInsideScope) {
    return getRefusedInsideScopeString({
      totalBids: sortedBids,
      bidRanking: traderIndexPosition + 1,
      traderBid,
      bestBidPrice,
    });
  }
  return getRefusedOutsideScopeString({
    totalBids: sortedBids,
    bidRanking: traderIndexPosition + 1,
    traderBid,
    bestBidPrice,
  });
};

export const getChosenFeedbackTemplate = (proposal: ProposalForFeedback): string => {
  const numBids = proposal.bids.length;
  return `Oi pessoal, tudo bem? Parabéns, temos boas notícias! O cliente escolheu vocês para serem os fornecedores de energia para os próximos anos. :) Tivemos ${numBids} fornecedoras participantes e vocês ofereceram o melhor produto e os melhores preços, encaixando com as necessidades do cliente. 

Agradecemos a participação e contamos com vocês para os próximos processos de contração. Abraços, Clarke!`;
};
