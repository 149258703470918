import React from 'react';

import { useQuery } from '@apollo/client';
import { TraderInfo } from '@routers/trader-route';
import { Ranking, ProcessSavingsBasePriceResponseGraphQl } from './types';
import { GET_PROCESS_SAVINGS_BASE_PRICE_BY_PROPOSAL_ID_AND_TRADER_ID } from './query';
import { formatRanking, findTargetProposal } from './parser';

export const useGetProcessRanking = (proposalId: string, trader: TraderInfo) => {
  const [ranking, setRanking] = React.useState<Ranking[]>([]);
  const [proposalRound, setProposalRound] = React.useState<number>(1);

  const { loading } = useQuery<ProcessSavingsBasePriceResponseGraphQl>(
    GET_PROCESS_SAVINGS_BASE_PRICE_BY_PROPOSAL_ID_AND_TRADER_ID,
    {
      fetchPolicy: 'network-only',
      variables: { proposalId, traderId: trader.id },
      onCompleted: (data) => {
        if (data.getProcessByProposalIdForTrader.process) {
          const proposal = findTargetProposal(data?.getProcessByProposalIdForTrader?.process.proposals, proposalId);
          const rankingFormated = proposal ? formatRanking(proposal?.bids, trader) : [];
          setRanking(rankingFormated);

          if (proposal) setProposalRound(proposal?.round);
        }
      },
      onError: () => {
        setRanking([]);
      },
    },
  );

  return { proposalRound, ranking, loading };
};
