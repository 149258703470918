import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import {
  CommissionModalityTypeEnum,
  CommissionTypeEnum,
  ContractTypeEnum,
  EnergyTypeEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
  SubmarketEnum,
  TariffModeEnum,
  UnitTypeEnum,
} from '@utils/translators/proposal';
import { formatCNPJ, formatCPF, formatCurrency, formatDate, formatNumber } from '@utils/text';
import { Unpacked } from '@utils/types';
import { ProposalValueForDisplayProps } from '@components/atoms/proposal-round/information-summary/proposal-value-for-display';
import { ProposalInformationContents } from '@components/molecules/proposal-round/proposal-information-summary-contents';
import { UnitHistoryTable } from '@components/atoms/proposal-round/information-summary/unit-history-table';
import UploadedFileDisplay from '@components/atoms/proposal-round/uploaded-file-display';
import AccordionItem from '@components/molecules/accordion';
import { DocumentFile, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { DocType } from '@hooks/bids/savings/types';

type GroupWithUnits = Proposal['group'] & { units: Proposal['units'] };
const clientDefinitions = (
  docLabel: DocType,
): {
  field: ProposalValueForDisplayProps<GroupWithUnits>['field'];
  label: string;
}[] => [
  { field: 'name', label: 'Cliente' },
  { field: 'docType', label: docLabel },
  { field: 'legalName', label: 'Razão Social' },
  { field: 'units', label: 'Número de unidades' },
];

const contractDefinitions: {
  field: ProposalValueForDisplayProps<Proposal>['field'];
  label: string;
  truncate?: boolean;
}[] = [
  { field: 'contractType', label: 'Tipo de contrato' },
  { field: 'commissionModality', label: 'Modalidade de contratação' },
  { field: 'periods', label: 'Durações do contrato' },
  { field: 'proposalType', label: 'Tipo de proposta', truncate: true },
  { field: 'lowerFlexibility', label: 'Flexibilidade inferior' },
  { field: 'upperFlexibility', label: 'Flexibilidade superior' },
  { field: 'deadline', label: 'Prazo de envio da proposta' },
  { field: 'guaranteeType', label: 'Tipo de garantia', truncate: true },
  { field: 'guaranteeMonths', label: 'Meses de faturamento' },
  { field: 'note', label: 'Observações' },
  { field: 'supplyStartDate', label: 'Início do suprimento' },
  { field: 'supplyEndDate', label: 'Fim do suprimento' },

  /* FIXME: Implement documents query by binding proposal to doc in clarke-customer first (like bid) */
];

type G = Proposal['unitsResume'];
interface ExtendedUnitResume extends G {
  edcs: string[];
}
const unitsResumeDefinitions: {
  field: ProposalValueForDisplayProps<ExtendedUnitResume>['field'];
  label: string;
}[] = [
  { field: 'edcs', label: 'Distribuidoras' },
  { field: 'totalVolume', label: 'Volume Total (MWm)' },
  { field: 'totalAmount', label: 'Valor Total das Faturas' },
  { field: 'totalContractedDemandOffPeak', label: 'Demanda Contratada Fora Ponta Total' },
  { field: 'totalContractedDemandPeak', label: 'Demanda Contratada Ponta Total' },
];

interface ExtendedUnit extends Unpacked<Proposal['units']> {
  state: string;
  supplyStartDate: Date | null;
  supplyEndDate: Date | null;
}
const unitDefinitions: {
  field: ProposalValueForDisplayProps<ExtendedUnit>['field'];
  label: string | ((proposal: Proposal) => string);
}[] = [
  { field: 'name', label: 'Nome da unidade' },
  { field: 'legalName', label: 'Razão Social' },
  { field: 'unitType', label: 'Classificação' },
  { field: 'state', label: 'Estado' },
  { field: 'edc', label: 'Distribuidora' },
  { field: 'subgroup', label: 'Subgrupo' },
  { field: 'tariffMode', label: 'Modalidade tarifária' },
  { field: 'contractedDemandOffPeak', label: 'Demanda fora ponta / única' },
  { field: 'contractedDemandPeak', label: 'Demanda ponta' },
  { field: 'averageInvoiceAmount', label: 'Valor estimado mensal da fatura' },
  { field: 'supplyStartDate', label: 'Início de suprimento' },
  { field: 'supplyEndDate', label: 'Fim de suprimento' },
  { field: 'hasGenerator', label: 'Gerador' },
  { field: 'generatorCost', label: 'Custo médio mensal do gerador' },
  { field: 'energyType', label: 'Tipo de energia' },
  { field: 'submarket', label: 'Submercado' },
];

const translateClientValues = (
  field: ProposalValueForDisplayProps<GroupWithUnits>['field'],
  proposal: Proposal,
): string => {
  switch (field) {
    case 'name':
      return proposal.group.name;
    case 'docType':
      return proposal.group.docType === 'CNPJ'
        ? formatCNPJ(proposal.group.docNumber.replace(/\D/g, ''))
        : formatCPF(proposal.group.docNumber);
    case 'legalName':
      return proposal.group.legalName;
    case 'units':
      return `${proposal.units.length}`;
    default:
      return '-';
  }
};

function getSmallerDateOfStartDate(units: Proposal['units']) {
  if (!Array.isArray(units) || units.length === 0) return null;

  return units.reduce((lowestDate: Date | null, unit) => {
    const startDate = unit.supply.startDate;
    return startDate !== null && (lowestDate === null || startDate < lowestDate) ? startDate : lowestDate;
  }, null);
}

function getHighestDateOfEndDate(units: Proposal['units']) {
  if (!Array.isArray(units) || units.length === 0) return null;

  return units.reduce((highestDate: Date | null, unit) => {
    const endDate = unit.supply.endDate;
    return endDate !== null && (highestDate === null || endDate > highestDate) ? endDate : highestDate;
  }, null);
}

const translateContractValues = (
  field: ProposalValueForDisplayProps<Proposal>['field'],
  proposal: Proposal,
): string => {
  switch (field) {
    case 'contractType':
      return ContractTypeEnum[proposal.contractType];
    case 'commissionModality':
      return CommissionModalityTypeEnum[proposal.commissionModality];
    case 'periods':
      return proposal.periods.map((year) => `${year} anos`).join(', ');
    case 'lowerFlexibility':
      return `-${proposal.lowerFlexibility}%`;
    case 'upperFlexibility':
      return `+${proposal.upperFlexibility}%`;
    case 'proposalType':
      return ProposalTypeEnum[proposal.proposalType];
    case 'deadline':
      return formatDate(proposal.deadline, 'DD/MM/YYYY, HH:mm');
    case 'guaranteeType':
      return GuaranteeTypeEnum[proposal.guaranteeType];
    case 'guaranteeMonths':
      return isNaN(proposal.guaranteeMonths)
        ? 'Não'
        : `${proposal.guaranteeMonths} ${proposal.guaranteeMonths === 1 ? 'mês' : 'meses'}`;
    case 'note':
      return proposal.note ? proposal.note : '-';
    case 'supplyStartDate': {
      const lowestDate = getSmallerDateOfStartDate(proposal.units);
      if (lowestDate === null) return '-';
      return formatDate(lowestDate, 'DD/MM/YYYY');
    }
    case 'supplyEndDate': {
      const highestDate = getHighestDateOfEndDate(proposal.units);
      if (highestDate === null) return '-';
      return formatDate(highestDate, 'DD/MM/YYYY');
    }
    default:
      return '-';
  }
};

const formatCommissionValue = (value: number, type: keyof typeof CommissionTypeEnum): string => {
  if (type === 'PERCENTAGE') return `${value}%`;
  if (type === 'REALS_PER_MONTH') return `${formatCurrency(value)}/mês`;
  return `${formatCurrency(value)}/MWh`;
};

const formatEdcName = (name: string, uf: string) => {
  const hasSpace = /\s/.test(name);
  if (hasSpace) {
    return `${name.split(' ')[0]} (${uf})`;
  }
  return `${name} (${uf})`;
};

const translateUnitsResumeValues = (
  field: ProposalValueForDisplayProps<ExtendedUnitResume>['field'],
  proposal: Proposal,
): string => {
  switch (field) {
    case 'totalVolume':
      return formatNumber(proposal.unitsResume.totalVolume / 1000, {
        style: 'decimal',
        useGrouping: true,
        maximumFractionDigits: 3,
      });
    case 'totalAmount':
      return formatCurrency(proposal.unitsResume.totalAmount);
    case 'totalContractedDemandOffPeak':
      return `${formatNumber(proposal.unitsResume.totalContractedDemandOffPeak)} kW`;
    case 'edcs': {
      return proposal.units.map(({ edc }) => formatEdcName(edc.name, edc.state)).join(', ');
    }
    default:
      return '-';
  }
};

const translateUnitValues = (
  field: ProposalValueForDisplayProps<ExtendedUnit>['field'],
  unitIndex: number,
  proposal: Proposal,
): string => {
  const unit = proposal.units[unitIndex];
  switch (field) {
    case 'name':
      return unit.name;
    case 'legalName':
      return unit.legalName;
    case 'docNumber': {
      const docType = unit.docType;
      const docNumber = unit.docNumber;
      return docType === 1 ? formatCPF(docNumber) : formatCNPJ(docNumber);
    }
    case 'unitType':
      return UnitTypeEnum[unit.unitType];
    case 'state':
      return unit.edc.state;
    case 'edc':
      return unit.edc.name.split(' ')[0];
    case 'subgroup':
      return unit.subgroup;
    case 'tariffMode':
      return TariffModeEnum[unit.tariffMode];
    case 'contractedDemandOffPeak':
      return `${formatNumber(unit.contractedDemandOffPeak)} kW`;
    case 'contractedDemandPeak':
      return isNaN(unit.contractedDemandPeak) ? '-' : `${formatNumber(unit.contractedDemandPeak)} kW`;
    case 'averageInvoiceAmount':
      return formatCurrency(unit.averageInvoiceAmount);
    case 'supplyStartDate': {
      if (unit.supply.startDate === null) return '-';
      return formatDate(unit.supply.startDate, 'DD/MM/YYYY');
    }
    case 'supplyEndDate': {
      if (unit.supply.endDate === null) return '-';
      return formatDate(unit.supply.endDate, 'DD/MM/YYYY');
    }
    case 'hasGenerator':
      return unit.hasGenerator ? 'Sim' : 'Não';
    case 'generatorCost':
      return isNaN(unit.generatorCost) ? '-' : formatCurrency(unit.generatorCost);
    case 'energyType':
      return EnergyTypeEnum[unit.energyType];
    case 'submarket':
      return unit.submarket === null ? '-' : SubmarketEnum[unit.submarket];
    default:
      return '-';
  }
};

interface ProposalInformationSummaryProps {
  proposal: Proposal;
  documents: DocumentFile[];
}
const ProposalInformationSummary: React.FC<ProposalInformationSummaryProps> = ({ proposal, documents }) => {
  return (
    <div className="space-y-4">
      <AccordionItem label="Sobre o Cliente" initiallyOpen={false}>
        <ProposalInformationContents<GroupWithUnits>
          proposal={proposal}
          valuesDefinitions={clientDefinitions(proposal.group.docType)}
          displayTranslatorFunction={translateClientValues}
        />
      </AccordionItem>
      <AccordionItem label="Sobre o Contrato" initiallyOpen={false}>
        <ProposalInformationContents<Proposal>
          proposal={proposal}
          valuesDefinitions={contractDefinitions}
          displayTranslatorFunction={translateContractValues}
          sectionTitle="Condições comerciais"
        />

        {documents.length > 0 && (
          <div className="flex flex-col space-y-1">
            <p className="text-paragraph-small text-neutral-50">Documentos</p>
            <div className="flex flex-col space-y-2">
              {documents.map((document, index) => (
                <UploadedFileDisplay key={`file-${index}`} file={document} />
              ))}
            </div>
          </div>
        )}
      </AccordionItem>

      <AccordionItem label="Resumo das Unidades" initiallyOpen={false}>
        <ProposalInformationContents<ExtendedUnitResume>
          proposal={proposal}
          valuesDefinitions={unitsResumeDefinitions}
          displayTranslatorFunction={translateUnitsResumeValues}
        />
      </AccordionItem>
      {proposal.units.map((unit, index) => {
        const docNumberLabelBuilder = (proposal: Proposal): string => {
          const docType = proposal.units[index].docType;
          return docType === 1 ? 'CPF' : 'CNPJ';
        };

        const completeUnitDefinition = cloneDeep(unitDefinitions);
        completeUnitDefinition.splice(2, 0, { field: 'docNumber', label: docNumberLabelBuilder });

        return (
          <AccordionItem key={index} label={`Unidade ${index + 1} - ${unit.name}`} initiallyOpen={false}>
            <ProposalInformationContents<ExtendedUnit>
              proposal={proposal}
              valuesDefinitions={completeUnitDefinition}
              displayTranslatorFunction={(field, proposal) => translateUnitValues(field, index, proposal)}
            />
            <UnitHistoryTable history={unit.history} />
          </AccordionItem>
        );
      })}
    </div>
  );
};

export default ProposalInformationSummary;
