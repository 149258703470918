import React from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioFormField, SelectFormField } from '@components/atoms/form';
import { ProposalFormData } from './types';
import { FormTextInput } from '@components/atoms/form/text';

const FinancialGuaranteeFields: React.FC = () => {
  const { watch, unregister } = useFormContext<ProposalFormData>();
  const watchHasFinancialGuarantee = watch('contract.hasGuaranteeSugestion');
  const watchGuaranteeType = watch('contract.guaranteeType');

  const hasGuarantee = watchHasFinancialGuarantee && watchHasFinancialGuarantee === 'yes';
  const hasToSelectBillingMonths =
    hasGuarantee &&
    watchGuaranteeType &&
    ['SURETY_BOND', 'ADVANCE_PAYMENT', 'BANK_GUARANTEE', 'GUARANTEE_LETTER'].includes(watchGuaranteeType);
  const hasToFillOtherKindOfGuarantee = hasGuarantee && watchGuaranteeType && watchGuaranteeType === 'OTHER';
  return (
    <>
      <RadioFormField<ProposalFormData>
        field="contract.hasGuaranteeSugestion"
        label="Sugestão de Garantia"
        inputOptions={[
          { id: 'has-guarantee', optionLabel: 'Sim', value: 'yes' },
          { id: 'no-guarantee', optionLabel: 'Não', value: 'no' },
        ]}
        options={{
          required: { value: true, message: 'O tipo de valor de comissão deve ser escolhido' },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === 'no') {
              unregister('contract.guaranteeType');
              unregister('contract.guaranteeMonths');
              unregister('contract.otherKindGuarantee');
            }
          },
        }}
      />
      {hasGuarantee && (
        <SelectFormField<ProposalFormData>
          field="contract.guaranteeType"
          id="guarantee-type"
          label="Qual o tipo de Garantia?"
          inputOptions={[
            { value: 'SURETY_BOND', optionLabel: 'Seguro garantia' },
            { value: 'ADVANCE_PAYMENT', optionLabel: 'Pagamento antecipado' },
            { value: 'BANK_GUARANTEE', optionLabel: 'Carta fiança bancária' },
            { value: 'GUARANTEE_LETTER', optionLabel: 'Nota promissória' },
            { value: 'REGISTRATION_AGAINST_PAYMENT', optionLabel: 'Registro contra pagamento' },
            { value: 'OTHER', optionLabel: 'Outro' },
          ]}
          options={{
            required: { value: true, message: 'O tipo de garantia financeira deve ser escolhido' },

            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value === 'OTHER') {
                unregister('contract.guaranteeMonths');
              }
              if (e.target.value === 'REGISTRATION_AGAINST_PAYMENT') {
                unregister('contract.guaranteeMonths');
                unregister('contract.otherKindGuarantee');
              }
            },
          }}
        />
      )}
      {hasToSelectBillingMonths && (
        <SelectFormField<ProposalFormData>
          field="contract.guaranteeMonths"
          id="guarantee-months"
          label="Meses de faturamento"
          placeholder="Selecione a quantidade de meses"
          inputOptions={[
            { value: '1', optionLabel: '1 mês' },
            { value: '2', optionLabel: '2 meses' },
            { value: '3', optionLabel: '3 meses' },
            { value: '4', optionLabel: '4 meses' },
            { value: '5', optionLabel: '5 meses' },
            { value: '6', optionLabel: '6 meses' },
            { value: '7', optionLabel: '7 meses' },
            { value: '8', optionLabel: '8 meses' },
            { value: '9', optionLabel: '9 meses' },
            { value: '10', optionLabel: '10 meses' },
            { value: '11', optionLabel: '11 meses' },
            { value: '12', optionLabel: '12 meses' },
          ]}
          options={{ required: { value: true, message: 'A quantidade de meses deve ser escolhida' } }}
          transformOutput={(value) => parseInt(value)}
        />
      )}
      {hasToFillOtherKindOfGuarantee && (
        <FormTextInput<ProposalFormData>
          id="other-kind-guarantee"
          field="contract.otherKindGuarantee"
          label="Informe outra garantia"
          placeholder="Digite o outro tipo de garantia"
          options={{ required: { value: true, message: 'Defina outra sugestão de garantia' } }}
        />
      )}
    </>
  );
};

export default FinancialGuaranteeFields;
