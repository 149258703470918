import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ListSkeleton: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => (
  <>
    <div
      className={`w-full grid ${isAdmin ? 'grid-cols-2 items-center justify-between gap-10' : 'grid-cols-1'}  mb-10`}
    >
      <Skeleton width="100%" height="100%" />
      {isAdmin && <Skeleton width="100%" height="30%" />}
    </div>
    <div className="grid mb-5 w-full md:w-1/3">
      <Skeleton width="100%" height="100%" />
    </div>
    <div className="grid grid-cols-1 grid-rows-3 gap-y-10 h-2/3">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton key={`proposal-skeleton-${index}`} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  </>
);
