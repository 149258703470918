import React from 'react';

import { Bid, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

type FinalBidState = 'signed' | 'refused' | 'expired' | 'waitingForSignature';

const badgeColorScheme = {
  success: { text: 'text-primary-90', background: 'bg-primary-60' },
  waiting: { text: 'text-warning-70', background: 'bg-warning-10' },
  alert: { text: 'text-danger-60', background: 'bg-danger-10' },
};

const getFinalBidBadgeState = (
  proposalStatus: Proposal['status'],
  bid: Bid,
): [FinalBidState, keyof typeof badgeColorScheme] => {
  if (bid.status === 'SIGNED') return ['signed', 'success'];

  const now = Date.now();
  if (proposalStatus === 'FINISHED' || bid.deadline.getTime() <= now) return ['expired', 'waiting'];

  if (bid.status === 'FINAL_REFUSED') return ['refused', 'alert'];

  return ['waitingForSignature', 'success'];
};

const staticLabels: Record<Exclude<FinalBidState, 'bidDeadlineAboutToExpire'>, string> = {
  expired: 'Expirada',
  waitingForSignature: 'Aguardando assinatura',
  refused: 'Recusada',
  signed: 'Assinada',
};

const getFinalBidBadgeLabel = (state: FinalBidState): string => {
  return staticLabels[state];
};

const FinalBidForTraderStateBadge: React.FC<{ proposal: Proposal; bid: Bid }> = ({ proposal, bid }) => {
  const [bidState, colorScheme] = getFinalBidBadgeState(proposal.status, bid);
  const label = getFinalBidBadgeLabel(bidState);
  const colorClass = badgeColorScheme[colorScheme];
  return (
    <div className={`py-1 px-2 rounded-full ${colorClass.background}`}>
      <p className={`text-paragraph-medium whitespace-nowrap  ${colorClass.text}`}>{label}</p>
    </div>
  );
};

export default FinalBidForTraderStateBadge;
