import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ProposalFormData } from '@components/molecules/create-proposal/types';
import { useProposalForm } from '@components/molecules/create-proposal/proposal-form-context';

import { GroupDataForProposalCreation, UnitForGroup } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { formatDateIntoLocalISOString } from '@utils/translators/date';
import { Unpacked } from '@utils/types';
import { EnergyTypeEnum } from '@utils/translators/proposal';

interface GroupDataContext {
  groupData: GroupDataForProposalCreation;
  isFetchingGroupData: boolean;
  setSelectedGroupId: (groupId: string) => void;
}
const groupDataContext = React.createContext<GroupDataContext>({} as GroupDataContext);

export const useGroupDataContext = () => React.useContext(groupDataContext);

const EMPTY_GROUP: GroupDataForProposalCreation = {
  id: '',
  name: '',
  legalName: '',
  docType: 'CNPJ',
  docNumber: '',
  units: [],
};

/**
 * If the form is in edit mode, we must use the already defined supply dates.
 * Otherwise, start with blank values.
 * */
const getSupplyDates = (
  unit: Unpacked<GroupDataForProposalCreation['units']>,
  isCreating: boolean,
  proposal?: Proposal,
) => {
  const thisUnit = proposal?.units.find((unitWithSupply) => unitWithSupply.id === unit.id);

  const possibleStartDate = isCreating || thisUnit === undefined ? null : thisUnit.supply.startDate;
  const supplyStartDate =
    possibleStartDate === null ? '' : formatDateIntoLocalISOString(possibleStartDate).split(' ')[0];

  const possibleEndDate = isCreating || thisUnit === undefined ? null : thisUnit.supply.endDate;
  const supplyEndDate = possibleEndDate === null ? '' : formatDateIntoLocalISOString(possibleEndDate).split(' ')[0];
  return { supplyStartDate, supplyEndDate };
};

const parseUnitFromGroup = (unit: UnitForGroup, isCreating: boolean, proposal?: Proposal) => {
  const { supplyStartDate, supplyEndDate } = getSupplyDates(unit, isCreating, proposal);

  return {
    unitId: unit.id,
    energyType: (isCreating || unit.energyType === null ? '' : unit.energyType) as '' | keyof typeof EnergyTypeEnum,
    supplyStartDate,
    supplyEndDate,
    files: [],
    name: unit.name,
    companyName: unit.legalName,
    unitType: unit.unitType,
    subgroup: unit.tariffSubgroup,
    tariffMode: unit.tariffModality,
    monthlyInvoice: unit.averageInvoiceAmount,
    edc: parseInt(unit.edc.id),
    totalConsumptionVolume: unit.totalConsumptionVolume,
  };
};

interface GroupDataProviderProps {
  children: React.ReactNode;
  groupDataFetcher: {
    fetchGroupData: (groupId: string) => Promise<GroupDataForProposalCreation>;
    isFetchingGroupData: boolean;
  };
}
const GroupDataProvider: React.FC<GroupDataProviderProps> = ({
  children,
  groupDataFetcher: { fetchGroupData, isFetchingGroupData },
}) => {
  const { isCreating, proposal } = useProposalForm();
  const { control, setValue, watch } = useFormContext<ProposalFormData>();
  const { replace } = useFieldArray<ProposalFormData, 'units'>({ name: 'units', control });

  const [selectedGroupId, setSelectedGroupId] = React.useState<string>('');
  const [groupData, setGroupData] = React.useState<GroupDataForProposalCreation>({ ...EMPTY_GROUP });

  React.useEffect(() => {
    if (selectedGroupId) {
      fetchGroupData(selectedGroupId).then((data) => {
        setValue('client.name', data.name);
        setValue('client.docType', data.docType);
        setValue('client.document', data.docNumber.replace(/\D/g, ''));
        setValue('client.companyName', data.legalName);
        setGroupData(data);

        if (isCreating) {
          // wipes unit input array when a new group is loaded
          setValue('selectedUnitIds', []);
        } else {
          setValue('selectedUnitIds', proposal?.units.map((u) => u.id) || []);
        }
      });
    }
  }, [selectedGroupId]);

  const watchedSelectedUnitIds = watch('selectedUnitIds');
  React.useEffect(() => {
    replace(
      groupData.units
        .filter((unit) => watchedSelectedUnitIds.includes(unit.id))
        .map((unit) => parseUnitFromGroup(unit, isCreating, proposal)),
    );
  }, [watchedSelectedUnitIds]);

  const selectGroupIdCallback = (groupId: string) => setSelectedGroupId(groupId);

  return (
    <groupDataContext.Provider value={{ groupData, isFetchingGroupData, setSelectedGroupId: selectGroupIdCallback }}>
      {children}
    </groupDataContext.Provider>
  );
};

export default GroupDataProvider;
