import React from 'react';

import { formatCurrency, formatDate } from '@utils/text';
import { CoverCeeeTaxesEnum, GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';

import { Bid } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const BidEconomySection: React.FC<{ bid: Bid }> = ({ bid }) => {
  const isFixedPrice = bid.contractType === 'FIXED_PRICE';
  return (
    <div className="flex overflow-x-auto space-x-3 sm:space-x-10">
      {bid.economy.map(({ year, amount }, pearYearIndex) => (
        <div key={pearYearIndex} className="flex flex-col">
          <p className="text-paragraph-small text-neutral-50">{year}</p>
          <p className="text-paragraph-medium">{isFixedPrice ? formatCurrency(amount) : `${amount}%`}</p>
        </div>
      ))}
    </div>
  );
};

const BidDisplay: React.FC<{ bid: Bid }> = ({ bid }) => {
  return (
    <div className="space-y-4">
      <div>
        <h4 className="font-bold text-paragraph-small">Características da proposta</h4>
        <div className="grid grid-cols-4 gap-y-2 py-2 border-black border-y">
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Flexibilidade</p>
            <p className="text-paragraph-medium">{`-${bid.lowerFlexibility}% / +${bid.upperFlexibility}%`}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Garantia</p>
            <p className="text-paragraph-medium">{`${GuaranteeTypeEnum[bid.guaranteeType]}`}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Modalidade de contratação</p>
            <p className="text-paragraph-medium">{bid.retailService ? 'Varejista' : 'Atacadista'}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Tipo de proposta</p>
            <p className="text-paragraph-medium">{`${ProposalTypeEnum[bid.proposalType]}`}</p>
          </div>
          <div className="col-span-full border-b border-black"></div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Validade da proposta</p>
            <p className="text-paragraph-medium">
              {isNaN(bid.deadline.getTime()) ? '-' : formatDate(bid.deadline, 'DD/MM/YYYY HH:mm')}
            </p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Duração de contrato</p>
            <p className="text-paragraph-medium">{bid.periods.map((year) => `${year} anos`).join(', ')}</p>
          </div>

          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-paragraph-small text-neutral-50">Cobertura dos encargos CCEE</p>
            <p className="text-paragraph-medium">{CoverCeeeTaxesEnum[bid.coverCceeTaxes]}</p>
          </div>
        </div>
      </div>
      <div>
        <h4 className="font-bold text-paragraph-small">Preços ano a ano</h4>
        <div className="py-2 border-black border-y">
          <BidEconomySection bid={bid} />
        </div>
      </div>
    </div>
  );
};
export default BidDisplay;
