import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import useAuth from '@hooks/auth';
import { BIDS_SENT_BY_TRADER_PATH, DOES_NOT_EXIST_PATH, VIEW_BID_LIST_PATH } from '@routers/constants';
import { useGetBidData } from '@hooks/bids/get-data';
import { useNotification } from '@clarke-energia/foton';
import BidForm from '@components/organisms/create-bid/form';
import { BidFormData } from '@components/molecules/create-bid/types';
import { getContractDates, translateBidIntoFormData } from '@components/organisms/create-bid/helpers';
import { parseBidFormDataIntoBidForUpdate, useCreateBidDocument, useUpdateBid } from '@hooks/bids/create-bid';
import { DocumentTypeForBid } from '@hooks/bids/create-bid/mutations';
import { ServerValidationError } from '@utils/errors';

const EditBidPage: React.FC = () => {
  const [hasErrorOnFetchData, setHasErrorOnFetchData] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { proposalId, bidId } = useParams();
  const { createNotification, deleteNotification } = useNotification();
  const {
    authStatus: { isAuthenticated, accessToken },
  } = useAuth();
  const [bid, fetchBidData, isBidLoaded] = useGetBidData();

  const updateBid = useUpdateBid();
  const createBidDocument = useCreateBidDocument();

  if (!proposalId || !bidId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const isPageReady = isAuthenticated && isBidLoaded;

  React.useEffect(() => {
    if (isAuthenticated && bidId) {
      fetchBidData(bidId)
        .then(() => setHasErrorOnFetchData(false))
        .catch(() => setHasErrorOnFetchData(true));
    }
  }, [isAuthenticated, bidId]);

  React.useEffect(() => {
    if (hasErrorOnFetchData) {
      const notificationId = createNotification({
        kind: 'error',
        label: 'Erro!',
        message:
          'Houve um problema ao processar essa operação. Tente novamente mais tarde ou nos comunique pelos nossos canais de atendimento.',
      });

      return () => deleteNotification(notificationId);
    }
  }, [hasErrorOnFetchData]);

  const cancelForm = () => navigate(`${VIEW_BID_LIST_PATH}/${proposalId}/${BIDS_SENT_BY_TRADER_PATH}`);

  const submitForm = async (data: BidFormData) => {
    const parsedBid = parseBidFormDataIntoBidForUpdate(bid.id, data, bid.trader, 'ACTIVE');

    try {
      const updatedBid = await updateBid(parsedBid, proposalId);
      await Promise.all(
        data.commercialConditions.files.map((file) =>
          createBidDocument(file, updatedBid.id, accessToken, DocumentTypeForBid.PROPOSAL),
        ),
      );

      navigate(`${VIEW_BID_LIST_PATH}/${proposalId}/${BIDS_SENT_BY_TRADER_PATH}`, { state: { updatedBid: true } });
    } catch (error) {
      const message =
        error instanceof ServerValidationError
          ? error.message
          : 'Houve um problema ao criar sua proposta. Tente novamente mais tarde ou nos comunique pelos nossos canais de atendimento.';

      createNotification({
        kind: 'error',
        label: 'Erro!',
        message,
      });
    }
  };

  const initialValues = translateBidIntoFormData(bid);
  const contractDuration = getContractDates(bid.units);
  const commissionModality = bid.commissionModality;
  return isPageReady ? (
    <BidForm {...{ contractDuration, commissionModality, initialValues, callbacks: { submitForm, cancelForm } }} />
  ) : (
    <>
      <h1>Loading... Please wait</h1>
    </>
  );
};

export default EditBidPage;
