import React from 'react';

import EmptyFinalBidMessageDisplay from '@components/atoms/proposal-round/empty-final-bid-message';
import UploadedFileDisplay from '@components/atoms/proposal-round/uploaded-file-display';

import { Bid } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { DocumentTypeEnum } from '@utils/translators/proposal';

interface FinalBidContentsDisplayProps {
  bid: Bid | null;
  gmv?: string | null;
  carbonDioxide?: string | null;
  takeRate?: string | null;
}
const FinalBidContentsDisplay: React.FC<FinalBidContentsDisplayProps> = ({ bid, gmv, takeRate, carbonDioxide }) => {
  if (!bid) return <EmptyFinalBidMessageDisplay />;

  return (
    <div className="grid grid-cols-1 gap-6 mt-4 mb-10 lg:grid-cols-9">
      <div className={`col-span-6 ${bid.documents.length > 1 ? 'grid grid-cols-1 lg:grid-cols-2 gap-6' : ''}`}>
        {bid.documents.map((file, index) => (
          <div key={index}>
            <p className="text-paragraph-small text-neutral-50">{DocumentTypeEnum[file.docType]}</p>
            <UploadedFileDisplay file={file} />
          </div>
        ))}
      </div>
      <div className="col-span-2">
        <p className="text-paragraph-small text-neutral-50">Validade da proposta</p>
        <p className="mt-0.5 text-paragraph-medium">
          {new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }).format(bid.deadline)}
        </p>
      </div>
      {gmv && carbonDioxide && takeRate ? (
        <>
          <div className="col-span-2">
            <p className="text-paragraph-small text-neutral-50">GMV</p>
            <p className="mt-0.5 text-paragraph-medium">{gmv}</p>
          </div>
          <div className="col-span-2">
            <p className="text-paragraph-small text-neutral-50">tCO2</p>
            <p className="mt-0.5 text-paragraph-medium">{carbonDioxide}</p>
          </div>
          <div className="col-span-2">
            <p className="text-paragraph-small text-neutral-50">Take Rate</p>
            <p className="mt-0.5 text-paragraph-medium">{takeRate}</p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default FinalBidContentsDisplay;
