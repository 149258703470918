import React from 'react';

import HeroIcon from '@components/atoms/hero-icon';
import { Bid } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { parseStringAsHTML } from '@utils/text';

const BidFeedbackMessageDisplay: React.FC<{ feedback: Bid['feedback'] }> = ({ feedback }) => {
  return (
    feedback && (
      <div className="py-6 px-8 w-full font-medium rounded-lg border border-black">
        <div className="flex flex-row gap-6 items-start">
          <div className="self-center">
            <HeroIcon icon="PresentationChartLineIcon" extraClassNames="w-12 h-12" />
          </div>
          <div className="flex-col grow">
            <div className="font-bold">Feedback</div>
            <p>{feedback?.text ? parseStringAsHTML(feedback.text) : 'Houve um erro ao mostrar essa mensagem :('}</p>
          </div>
        </div>
      </div>
    )
  );
};

export default BidFeedbackMessageDisplay;
