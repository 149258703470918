import React from 'react';

export interface EntityValueForDisplayProps<T> {
  entity: T;
  field: keyof T;
  label: string | ((entity: T) => string);
  displayTranslatorFunction: (field: EntityValueForDisplayProps<T>['field'], entity: T) => string;
  truncate?: boolean;
}
function EntityValueForDisplay<T>({
  field,
  label,
  truncate,
  entity,
  displayTranslatorFunction,
}: EntityValueForDisplayProps<T>) {
  return (
    <>
      <div className="flex flex-col mb-2">
        <p className="text-paragraph-small text-neutral-50">{typeof label === 'string' ? label : label(entity)}</p>
        <p className="text-paragraph-medium">{`${displayTranslatorFunction(field, entity)}`}</p>
      </div>
      {truncate && <hr className="col-span-1 border-t border-black sm:col-span-4" />}
    </>
  );
}

interface EntityInformationContentsProps<T> {
  entity: T;
  valuesDefinitions: Pick<EntityValueForDisplayProps<T>, 'label' | 'field' | 'truncate'>[];
  displayTranslatorFunction: EntityValueForDisplayProps<T>['displayTranslatorFunction'];
}

function EntityInformationContents<T>(props: EntityInformationContentsProps<T>) {
  const { valuesDefinitions, ...rest } = props;
  return (
    <div className="grid grid-cols-2 gap-y-1 gap-x-10 py-4 px-4 sm:grid-cols-4">
      {valuesDefinitions.map((definition, index) => (
        <EntityValueForDisplay<T> key={index} {...rest} {...definition} />
      ))}
    </div>
  );
}

export default EntityInformationContents;
