import { DeepPartial } from 'react-hook-form';

import { Subgroup } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { DocType } from '@hooks/bids/savings/types';
import { CommissionModalityTypeEnum, UnitTypeEnum } from '@utils/translators/proposal';
import { StringLiteralOrEmpty } from '@utils/types';

export const UNIT_DOC_TYPE_CNPJ = '2';
export const UNIT_DOC_TYPE_CPF = '1';

type EnergyType = 'CONVENTIONAL' | 'INCENTIVIZED_0' | 'INCENTIVIZED_50' | 'INCENTIVIZED_80' | 'INCENTIVIZED_100';

export interface UnitFormData {
  unitId: string;
  energyType: StringLiteralOrEmpty<EnergyType>;
  files: File[];
  supplyStartDate: string;
  supplyEndDate: string;
  totalConsumptionVolume: number;
  name: string;
  companyName: string;
  unitType: StringLiteralOrEmpty<keyof typeof UnitTypeEnum>;
  subgroup: StringLiteralOrEmpty<Subgroup>;
  tariffMode: StringLiteralOrEmpty<'BLUE' | 'GREEN'>;
  monthlyInvoice: number;
  edc: number;
}

export const EMPTY_UNIT: UnitFormData = {
  unitId: '',
  energyType: '',
  files: [],
  supplyStartDate: '',
  supplyEndDate: '',
  totalConsumptionVolume: 0,
  name: '',
  companyName: '',
  unitType: '',
  subgroup: '',
  tariffMode: '',
  monthlyInvoice: NaN,
  edc: NaN,
};

export const EMPTY_PROPOSAL: DeepPartial<ProposalFormData> = {
  client: { id: '', name: '', docType: 'CNPJ', document: '', companyName: '' },
  contract: {
    process: { name: '' },
    contractType: '',
    traders: [],
    lowerFlexibility: NaN,
    upperFlexibility: NaN,
    proposalModality: '',
    proposalDeadline: { date: '', time: '' },
    guaranteeType: '',
    commissionModality: '',
    files: [],
    note: '',
  },
  units: [{ ...EMPTY_UNIT }],
  selectedUnitIds: [],
};

type ContractType = 'GUARANTEED_SAVING' | 'FIXED_PRICE';
type ProposalModality = 'INDICATIVE' | 'FIRM';
type CommissionType = 'PERCENTAGE' | 'REALS_PER_MONTH' | 'REALS_PER_MEGAWATT';
type GuaranteeType =
  | 'NO_GUARANTEE'
  | 'SURETY_BOND'
  | 'ADVANCE_PAYMENT'
  | 'BANK_GUARANTEE'
  | 'GUARANTEE_LETTER'
  | 'REGISTRATION_AGAINST_PAYMENT'
  | 'OTHER';

export interface ProposalFormData {
  client: { id: string; name: string; docType: DocType; document: string; companyName: string };
  contract: {
    process: { name: string };
    contractType: StringLiteralOrEmpty<ContractType>;
    lowerFlexibility: number;
    upperFlexibility: number;
    proposalModality: StringLiteralOrEmpty<ProposalModality>;
    traders: string[];
    commissionModality: StringLiteralOrEmpty<keyof typeof CommissionModalityTypeEnum>;
    proposalDeadline: { date: string; time: string };
    hasGuaranteeSugestion: 'yes' | 'no';
    commissionType?: CommissionType;
    guaranteeType: StringLiteralOrEmpty<GuaranteeType>;
    guaranteeMonths: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | '';
    otherKindGuarantee: string;
    files: File[];
    note: string;
    supplyStartDate: string | null;
    supplyEndDate: string | null;
  };
  selectedUnitIds: string[];
  units: UnitFormData[];
}
