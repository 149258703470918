import React from 'react';
import { DeepPartial, FieldPath, FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { useNotification } from '@clarke-energia/foton';

import { TariffModeEnum } from '@utils/translators/proposal';
import { Unpacked } from '@utils/types';

import ContentLayoutContainer from '@components/layout/content-layout-container';
import { NavigationControls } from '@components/molecules/form-steps-navigation-control';
import { FormHeader } from '@components/atoms/form/form-header';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import FirstStep from './first-step';
import SecondStep from './second-step';
import ThirdStep, { AfterSavingsSubmissionDialog } from './third-step';

const renderStepElement = (currentNumber: number, proposal: Proposal) => {
  switch (currentNumber) {
    case 1:
      return <FirstStep proposal={proposal} />;
    case 2:
      return <SecondStep proposal={proposal} />;
    case 3:
      return <ThirdStep proposal={proposal} />;

    default:
      throw 'Invalid step number';
  }
};

const getFieldsIfEqualDemandThatChanges = (
  unitIndex: number,
  tariffMode: keyof typeof TariffModeEnum,
  entry: Unpacked<ProposalCompilationFormData['demandAndConsumption']>,
): FieldPath<ProposalCompilationFormData>[] => {
  const result: FieldPath<ProposalCompilationFormData>[] = [];

  entry.unitInfo.changingEqualDemandValues.offPeak.forEach((_, valueIndex) => {
    result.push(
      `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.offPeak.${valueIndex}.value` as const,
    );
  });

  if (tariffMode === 'BLUE') {
    entry.unitInfo.changingEqualDemandValues.peak.forEach((_, valueIndex) => {
      result.push(
        `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.peak.${valueIndex}.value` as const,
      );
    });
  }
  return result;
};

const getFieldsIfNonEqualDemandThatChanges = (
  unitIndex: number,
  tariffMode: keyof typeof TariffModeEnum,
  entry: Unpacked<ProposalCompilationFormData['demandAndConsumption']>,
): FieldPath<ProposalCompilationFormData>[] => {
  const result: FieldPath<ProposalCompilationFormData>[] = [];

  const cNEDV = entry.unitInfo.changingNonEqualDemandValues;

  cNEDV.acr.offPeak.forEach((_, valueIndex) => {
    result.push(
      `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.offPeak.${valueIndex}.value` as const,
    );
  });

  cNEDV.acl.offPeak.forEach((_, valueIndex) => {
    result.push(
      `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acl.offPeak.${valueIndex}.value` as const,
    );
  });

  if (tariffMode === 'BLUE') {
    cNEDV.acr.peak.forEach((_, valueIndex) => {
      const fieldName =
        `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.peak.${valueIndex}.value` as const;
      result.push(fieldName);
    });

    cNEDV.acl.peak.forEach((_, valueIndex) => {
      result.push(
        `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acl.peak.${valueIndex}.value` as const,
      );
    });
  }

  return result;
};

const getDynamicFieldsForSecondStepValidation = (
  formValuesGetterFunction: UseFormGetValues<ProposalCompilationFormData>,
): FieldPath<ProposalCompilationFormData>[] => {
  const demandAndConsumption = formValuesGetterFunction('demandAndConsumption');
  const result: FieldPath<ProposalCompilationFormData>[] = [];
  demandAndConsumption.forEach((entry, index) => {
    result.push(`demandAndConsumption.${index}.equalDemand` as const);
    result.push(`demandAndConsumption.${index}.demandChangesThroughYears` as const);
    result.push(`demandAndConsumption.${index}.consumptionChangesThroughYears` as const);

    const tariffMode = entry.unitInfo.tariffMode;

    const equalDemandValue = entry.equalDemand;
    const isEqualDemandDefined = equalDemandValue !== '';
    const isEqualDemand = isEqualDemandDefined && equalDemandValue === 'YES';

    const demandChangesValue = entry.demandChangesThroughYears;
    const isDemandChangesDefined = demandChangesValue !== '';
    const demandChanges = isDemandChangesDefined && demandChangesValue === 'YES';

    if (isEqualDemandDefined) {
      if (isEqualDemand && demandChanges) {
        result.push(...getFieldsIfEqualDemandThatChanges(index, tariffMode, entry));
      }

      if (!isEqualDemand && !demandChanges) {
        result.push(`demandAndConsumption.${index}.unitInfo.nonChangingNonEqualDemandValues.acr.offPeak` as const);
        result.push(`demandAndConsumption.${index}.unitInfo.nonChangingNonEqualDemandValues.acl.offPeak` as const);

        if (tariffMode === 'BLUE') {
          result.push(`demandAndConsumption.${index}.unitInfo.nonChangingNonEqualDemandValues.acr.peak` as const);
          result.push(`demandAndConsumption.${index}.unitInfo.nonChangingNonEqualDemandValues.acl.peak` as const);
        }
      }

      if (!isEqualDemand && demandChanges) {
        result.push(...getFieldsIfNonEqualDemandThatChanges(index, tariffMode, entry));
      }
    }
  });
  return result;
};

const getFieldsToBeValidated = (
  step: number,
  formValuesGetterFunction: UseFormGetValues<ProposalCompilationFormData>,
): FieldPath<ProposalCompilationFormData>[] => {
  switch (step) {
    case 1:
      return ['bidCategorization.bidsInsideScope', 'bidCategorization.bidsOutsideScope'];
    case 2:
      return getDynamicFieldsForSecondStepValidation(formValuesGetterFunction);
    case 3:
      return ['taxesAndCommissions.retailCommission', 'taxesAndCommissions.wholesaleCommission'];

    default:
      throw new Error('Invalid step number.');
  }
};

const headers = ['Categorizar propostas', 'Demanda e Consumo', 'Comissão e Encargos'];
interface CompilationFormProps {
  proposal: Proposal;
  initialValues: DeepPartial<ProposalCompilationFormData>;
  onFormSubmitCallback: (data: ProposalCompilationFormData) => Promise<void>;
  cancelFormCallback: () => void;
}
const CompilationForm: React.FC<CompilationFormProps> = ({
  proposal,
  initialValues,
  onFormSubmitCallback,
  cancelFormCallback,
}) => {
  const { createNotification } = useNotification();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const methods = useForm<ProposalCompilationFormData>({
    defaultValues: initialValues,
  });

  React.useEffect(() => {
    // NOTE: This useEffect is to keep form state in sync with proposal data.
    // see: https://stackoverflow.com/a/64307087
    methods.reset(initialValues);
  }, [methods.reset]);

  const onStepChangeCallback = (nextStepValue: number) => {
    if (nextStepValue > currentStep) {
      const fields = getFieldsToBeValidated(currentStep, methods.getValues);
      methods.trigger(fields).then((fieldsAreValid) => {
        if (fieldsAreValid) {
          setCurrentStep(nextStepValue);
        } else {
          createNotification({
            kind: 'error',
            label: 'Input(s) inválido(s)!',
            message: 'Alguns dos inputs do formulário estão faltando ou errados. Verifique-os para dar sequência.',
          });
        }
      });
    } else {
      setCurrentStep(nextStepValue);
    }
  };
  const onSubmitCallback = async () => {
    await methods.handleSubmit(async (data) => {
      try {
        await onFormSubmitCallback(data);
        setIsDialogOpen(true);
      } catch (error) {
        setIsDialogOpen(false);
        createNotification({
          kind: 'error',
          label: 'Problemas técnicos com o servidor',
          message: `Se você está vendo essa mensagem, 
            é porque houve algo errado com a compilação de propostas. 
            Por favor, avise o suporte técnico, informando a seguinte mensagem: ${error}`,
        });
      }
    })();
  };

  return (
    <ContentLayoutContainer className="overflow-y-auto h-screen">
      <FormProvider {...methods}>
        <form>
          <FormHeader headers={headers} activeHeaderNum={currentStep} />

          <div className="space-y-10">
            {renderStepElement(currentStep, proposal)}

            <NavigationControls
              currentStep={currentStep}
              controlParams={{
                maxStep: 3,
                textLabels: {
                  changeStep: 'Próximo passo',
                  endStep: 'Gerar compilado',
                },
                cancelFormCallback,
                changeStepCallback: onStepChangeCallback,
                endStepCallback: onSubmitCallback,
              }}
            />
          </div>
        </form>
      </FormProvider>

      {isDialogOpen && <AfterSavingsSubmissionDialog proposal={proposal} />}
    </ContentLayoutContainer>
  );
};

export default CompilationForm;
