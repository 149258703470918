import React from 'react';
import { DeepPartial } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { COMPILATION_SUFFIX_PATH, DOES_NOT_EXIST_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { getCurrentProposalFromProcess } from '@components/organisms/proposal-round-header';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';
import CompilationForm from '@components/organisms/start-compilation/form';
import {
  parseBidsIntoFormScopeData,
  parseFormDataIntoPayloadData,
  parseUnitIntoDemandAndConsumptionData,
} from '@components/organisms/start-compilation/form-helpers';
import LoadingPage from '@components/layout/loading-page';

import useAuth from '@hooks/auth';
import { useGetProcessAndGroupByProposalId } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id';
import { requestSavingsCalculation } from '@services/rest/request-savings-calculation';

const StartCompilationPage: React.FC = () => {
  const navigate = useNavigate();
  const { proposalId } = useParams();
  const {
    authStatus: { accessToken },
  } = useAuth();

  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const { processData, fetchProcessData, isProcessDataLoading } = useGetProcessAndGroupByProposalId();

  React.useEffect(() => {
    if (proposalId) {
      fetchProcessData(proposalId);
    }
  }, [proposalId]);

  const proposal = getCurrentProposalFromProcess(processData, proposalId);

  const cancelFormCallback = () => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${COMPILATION_SUFFIX_PATH}`);

  const initialValues: DeepPartial<ProposalCompilationFormData> = {
    bidCategorization: parseBidsIntoFormScopeData(proposal.bids),
    demandAndConsumption: proposal.units.map((unit) => parseUnitIntoDemandAndConsumptionData(unit)),
    taxesAndCommissions: {
      retailCommission: 0,
      wholesaleCommission: 0,
      standardCommission: 0,
      isStandardForAllYears: 'YES',
      commissionPerYear: [],
      essAndEer: NaN,
      modulation: NaN,
      managementFee: NaN,
    },
    customerEngagementLevel: 'VERY_HIGH',
  };

  const onSubmit = async ({
    taxesAndCommissions: {
      retailCommission,
      wholesaleCommission,
      standardCommission,
      commissionPerYear,
      isStandardForAllYears,
      commissionModality,
      ...taxes
    },
    ...data
  }: ProposalCompilationFormData) => {
    const commissionData = {
      retailCommission,
      wholesaleCommission,
      standardCommission,
      commissionPerYear,
      isStandardForAllYears,
      commissionModality,
    };

    if (commissionData.isStandardForAllYears === 'YES') {
      if (commissionData.commissionModality === 'RETAILER') {
        commissionData.retailCommission = commissionData.standardCommission;
        commissionData.wholesaleCommission = null;
        commissionData.commissionPerYear = [];
      } else {
        commissionData.retailCommission = null;
        commissionData.wholesaleCommission = standardCommission;
        commissionData.commissionPerYear = [];
      }
    } else {
      commissionData.retailCommission = null;
      commissionData.wholesaleCommission = null;
      commissionData.commissionPerYear = commissionData.commissionPerYear.map((el) => ({
        year: el.year,
        commissionValue: el.commissionValue,
        commissionModality: el.commissionModality,
      }));
    }

    await Promise.all(
      Object.values(data.bidCategorization.bidsInsideScope).map(async (bid) => {
        const payloadWithCommissions = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: true },
          group: proposal.group,
          units: proposal.units,
          isBasePriceType: false,
          formData: {
            taxesAndCommissions: {
              retailCommission: commissionData.retailCommission,
              wholesaleCommission: commissionData.wholesaleCommission,
              commissionPerYear: commissionData.commissionPerYear,
              standardCommission: commissionData.standardCommission,
              ...taxes,
            },
            ...data,
          },
        });

        const payloadBasePrice = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: true },
          group: proposal.group,
          units: proposal.units,
          isBasePriceType: true,
          formData: {
            taxesAndCommissions: {
              retailCommission: null,
              wholesaleCommission: null,
              standardCommission: null,
              commissionPerYear: [],
              ...taxes,
            },
            ...data,
          },
        });
        await requestSavingsCalculation(payloadWithCommissions, accessToken);
        await requestSavingsCalculation(payloadBasePrice, accessToken);
      }),
    );
    await Promise.all(
      Object.values(data.bidCategorization.bidsOutsideScope).map(async (bid) => {
        const payloadWithCommissions = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: false },
          group: proposal.group,
          units: proposal.units,
          isBasePriceType: false,
          formData: {
            taxesAndCommissions: {
              retailCommission: commissionData.retailCommission,
              wholesaleCommission: commissionData.wholesaleCommission,
              commissionPerYear: commissionData.commissionPerYear,
              standardCommission: commissionData.standardCommission,
              ...taxes,
            },
            ...data,
          },
        });

        const payloadBasePrice = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: false },
          group: proposal.group,
          units: proposal.units,
          isBasePriceType: true,
          formData: {
            taxesAndCommissions: {
              retailCommission: null,
              wholesaleCommission: null,
              standardCommission: null,
              commissionPerYear: [],
              ...taxes,
            },
            ...data,
          },
        });
        await requestSavingsCalculation(payloadWithCommissions, accessToken);
        await requestSavingsCalculation(payloadBasePrice, accessToken);
      }),
    );
  };

  return isProcessDataLoading ? (
    <LoadingPage />
  ) : (
    <>
      <CompilationForm {...{ proposal, initialValues, onFormSubmitCallback: onSubmit, cancelFormCallback }} />
    </>
  );
};
export default StartCompilationPage;
