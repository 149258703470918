import React from 'react';

/** Wrap any page that must follow the main layout (sidebars on the left side,
 *  main content on the right) with this component.
 * */
const ContentLayoutContainer: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={className}>
      <div className="flex flex-col py-6 px-8">
        <div>{children}</div>
      </div>
    </div>
  );
};
export default ContentLayoutContainer;
