import React from 'react';
import { FieldPath, useFormContext, UseFormGetValues } from 'react-hook-form';

import { formatCNPJ, formatCPF, formatDate, formatDateFromString } from '@utils/text';
import { ContractTypeEnum, GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';
import { RetailTrader } from '@hooks/proposals';
import { ProposalFormData, UnitFormData } from '@components/molecules/create-proposal/types';

function getSmallerDateOfStartDate(units: UnitFormData[]): Date | null {
  if (!Array.isArray(units) || units.length === 0) return null;

  return units.reduce((lowestDate: Date | null, unit): Date | null => {
    const startDateString: string | null = unit.supplyStartDate;
    const startDate: Date | null = startDateString ? new Date(startDateString + 'T00:00:00') : null;

    const isLowestDateValid = lowestDate && startDate && startDate >= lowestDate;

    if (!startDate || isLowestDateValid) {
      return lowestDate;
    }

    return startDate;
  }, null);
}

function getHighestDateOfEndDate(units: UnitFormData[]): Date | null {
  if (!Array.isArray(units) || units.length === 0) return null;

  return units.reduce((highestDate: Date | null, unit): Date | null => {
    const endDateString: string | null = unit.supplyEndDate;
    const endDate: Date | null = endDateString ? new Date(endDateString + 'T00:00:00') : null;

    const isHighestDateValid = highestDate && endDate && endDate >= highestDate;

    if (!endDate || isHighestDateValid) {
      return highestDate;
    }

    return endDate;
  }, null);
}

const translateFieldValueToDisplayString = (
  field: FieldPath<ProposalFormData>,
  getValuesFunction: UseFormGetValues<ProposalFormData>,
  traders: RetailTrader[],
): string => {
  const units = getValuesFunction('units');

  switch (field) {
    case 'units':
      return `${getValuesFunction(field).length}`;
    case 'client.document': {
      const value = getValuesFunction(field);
      const docType = getValuesFunction('client.docType');
      if (value === '') return '-';
      return docType === 'CNPJ' ? formatCNPJ(value) : formatCPF(value);
    }
    case 'contract.contractType': {
      const value = getValuesFunction(field);
      if (value === '') return '-';
      return ContractTypeEnum[value];
    }
    case 'contract.proposalModality': {
      const value = getValuesFunction(field);
      if (value === '') return '-';
      return ProposalTypeEnum[value];
    }
    case 'contract.traders': {
      const tradersForDisplay: string[] = [];
      const selectedTradersIds = getValuesFunction(field);
      selectedTradersIds.forEach((traderId) => {
        const trader = traders.find((trader) => trader.id == traderId);
        if (trader) tradersForDisplay.push(trader.name);
      });
      return `${tradersForDisplay.join(', ') || '-'}`;
    }
    case 'contract.lowerFlexibility': {
      const value = getValuesFunction(field);
      return value ? `-${value}%` : '-';
    }
    case 'contract.upperFlexibility': {
      const value = getValuesFunction(field);
      return value ? `+${value}%` : '-';
    }
    case 'contract.proposalDeadline': {
      const value = getValuesFunction(field);
      const date = formatDateFromString(`${value.date} ${value.time}`, 'YYYY-MM-DD HH:mm');
      return value.date && value.time ? `${date}` : '-';
    }
    case 'contract.guaranteeType': {
      const value = getValuesFunction(field);
      if (value === '') return '-';
      return GuaranteeTypeEnum[value];
    }
    case 'contract.guaranteeMonths': {
      const value = getValuesFunction(field);
      return value ? `${value} ${`${value}` === '1' ? 'mês' : 'meses'}` : '-';
    }
    case 'contract.supplyStartDate': {
      const lowestDate = getSmallerDateOfStartDate(units);
      if (lowestDate === null) return '-';
      return formatDate(lowestDate, 'DD/MM/YYYY');
    }
    case 'contract.supplyEndDate': {
      const highestDate = getHighestDateOfEndDate(units);
      if (highestDate === null) return '-';
      return formatDate(highestDate, 'DD/MM/YYYY');
    }
    default:
      return `${getValuesFunction(field) || '-'}`;
  }
};

export interface FieldForDisplay {
  label: string | ((field: FieldPath<ProposalFormData>) => string);
  field: FieldPath<ProposalFormData>;
  truncate?: boolean;
}

const FieldValueDisplaySection: React.FC<{ fields: FieldForDisplay[]; traders: RetailTrader[] }> = ({
  fields,
  traders,
}) => {
  const { getValues } = useFormContext<ProposalFormData>();

  const clientInfo: FieldForDisplay[] = fields.map((field) => {
    if (field.label === 'Documento Contratante') {
      return {
        ...field,
        label: getValues('client.docType'),
      };
    }
    return field;
  });
  return (
    <>
      {clientInfo.map(({ label, field, truncate }) => (
        <React.Fragment key={`${label}`}>
          <div className={`flex flex-col mb-2`}>
            <p className="text-paragraph-small text-neutral-50">{typeof label === 'string' ? label : label(field)}</p>
            <p className="text-paragraph-medium">{`${translateFieldValueToDisplayString(
              field,
              getValues,
              traders,
            )}`}</p>
          </div>
          {truncate && <hr className="col-span-1 border-t border-black sm:col-span-4" />}
        </React.Fragment>
      ))}
    </>
  );
};

export default FieldValueDisplaySection;
