import { gql, useMutation } from '@apollo/client';
import { NewRoundGraphQLInput } from './open-new-round';

interface OpenFinalRoundGraphQLResponse {
  openFinalRound: { error: string | null; proposal: { id: string } | null };
}

const OPEN_FINAL_ROUND_STR = gql`
  mutation OpenFinalRound($processId: ID!, $proposalInput: NewRoundInput!) {
    openFinalRound(processId: $processId, proposalInput: $proposalInput) {
      error
      proposal {
        id
      }
    }
  }
`;

export function useOpenFinalRound() {
  const [mutationFunction] = useMutation<
    OpenFinalRoundGraphQLResponse,
    { processId: string; proposalInput: NewRoundGraphQLInput }
  >(OPEN_FINAL_ROUND_STR);

  const openFinalRound = async (processId: string, proposalInput: NewRoundGraphQLInput): Promise<{ id: string }> => {
    const { data, errors } = await mutationFunction({ variables: { processId, proposalInput } });

    if (errors !== undefined || !data || data.openFinalRound.error !== null || data.openFinalRound.proposal === null)
      throw new Error('Falha ao abrir a rodada final.');

    const result = data.openFinalRound.proposal;
    return { id: result.id };
  };

  return openFinalRound;
}
