import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@clarke-energia/foton';

import { useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import {
  OPEN_FINAL_ROUND_PATH,
  OPEN_NEW_ROUND_PATH,
  SEND_SIGNED_PROPOSAL_PATH,
  VIEW_PROPOSAL_LIST_PATH,
} from '@routers/constants';

const OpenNewRoundButton: React.FC = () => {
  const { proposal } = useAdminProposal();
  const navigate = useNavigate();

  const proposalHasBids = proposal.bids.length !== 0;
  const proposalIsInFinalRound = proposal.round === 0;

  const isDisabled = !proposalHasBids || proposalIsInFinalRound;
  return (
    <Button
      kind="primary"
      type="button"
      label="Abrir Nova Rodada"
      customAttrs={{ 'data-cy': 'open-new-round-button' }}
      disabled={isDisabled}
      onClick={() => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${OPEN_NEW_ROUND_PATH}`)}
    />
  );
};

const SendSignedProposalButton: React.FC = () => {
  const { proposal } = useAdminProposal();
  const navigate = useNavigate();

  const proposalHasBids = proposal.bids.length !== 0;
  const proposalIsInFinalRound = proposal.round === 0;

  const isDisabled = !proposalHasBids || proposalIsInFinalRound;

  return (
    <Button
      kind="secondary"
      type="button"
      label="Proposta Assinada"
      customAttrs={{ 'data-cy': 'send-signed-proposal-button' }}
      disabled={isDisabled}
      onClick={() => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${SEND_SIGNED_PROPOSAL_PATH}`)}
    />
  );
};

const OpenFinalRoundButton: React.FC = () => {
  const { proposal } = useAdminProposal();
  const navigate = useNavigate();

  return (
    <Button
      kind="secondary"
      type="button"
      label="Concluir Negociação"
      customAttrs={{ 'data-cy': 'open-final-round-button' }}
      onClick={() => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${OPEN_FINAL_ROUND_PATH}`)}
    />
  );
};

const AdminFinishRoundPage: React.FC = () => {
  return (
    <>
      <div className="space-y-10">
        <div className="space-y-4">
          <div className="space-y-2">
            <h2 className="uppercase text-paragraph-small text-neutral-50">Finalização</h2>
            <h1 className="font-bold text-heading-2xlarge">Finalizar etapa</h1>
          </div>
          <p className="text-paragraph-medium">
            Se quiser encerrar ou ir para rodada final, escolha Concluir negociação. Caso queira pedir uma nova leva de
            propostas, escolha Abrir nova rodada.
          </p>
        </div>

        <div className="flex space-x-3">
          <OpenNewRoundButton />
          <SendSignedProposalButton />
          <OpenFinalRoundButton />
        </div>
      </div>
    </>
  );
};

export default AdminFinishRoundPage;
