import React from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  GetProcessAndGroupByProposalIdForTraderGraphQLResponse,
  GetProcessAndGroupByProposalIdGraphQLResponse,
} from './graphql-types';
import {
  GET_PROCESS_AND_GROUP_BY_PROPOSAL_ID,
  GET_PROCESS_AND_GROUP_BY_PROPOSAL_ID_AND_TRADER_ID,
} from './query-strings';
import { EMPTY_PROCESS, ProcessData } from './types';
import { parseGroup, parseProposals } from './helpers';

export function useGetProcessAndGroupByProposalId() {
  const [isProcessDataLoading, setIsProcessDataLoading] = React.useState<boolean>(true);
  const [processData, setProcessData] = React.useState<ProcessData>({ ...EMPTY_PROCESS });
  const [queryFunction] = useLazyQuery<GetProcessAndGroupByProposalIdGraphQLResponse, { proposalId: string }>(
    GET_PROCESS_AND_GROUP_BY_PROPOSAL_ID,
  );

  const fetchProcessData = async (proposalId: string): Promise<void> => {
    await queryFunction({
      variables: { proposalId },
      onCompleted: (data) => {
        if (data.getProcessByProposalId.error !== null || data.getProcessByProposalId.process === null) {
          setProcessData({ ...EMPTY_PROCESS });
          throw new Error('Server failed to respond with valid process data.');
        }

        const { name, id, group, proposals } = data.getProcessByProposalId.process;

        const parsedGroup = parseGroup(group);
        const parsedProposals = parseProposals(proposals, parsedGroup);
        const result = { name, id, group: parsedGroup, proposals: parsedProposals };

        setProcessData(result);
        setIsProcessDataLoading(false);
      },
      onError: () => {
        setProcessData({ ...EMPTY_PROCESS });
      },
    });
  };

  return { processData, fetchProcessData, isProcessDataLoading };
}

export function useGetProcessAndGroupByProposalIdForTrader() {
  const [isProcessDataLoading, setIsProcessDataLoading] = React.useState<boolean>(true);
  const [processData, setProcessData] = React.useState<ProcessData>({ ...EMPTY_PROCESS });
  const [queryFunction] = useLazyQuery<
    GetProcessAndGroupByProposalIdForTraderGraphQLResponse,
    { proposalId: string; traderId: string }
  >(GET_PROCESS_AND_GROUP_BY_PROPOSAL_ID_AND_TRADER_ID);

  const fetchProcessData = async (proposalId: string, traderId: string): Promise<void> => {
    await queryFunction({
      variables: { proposalId, traderId },
      onCompleted: (data) => {
        if (
          data.getProcessByProposalIdForTrader.error !== null ||
          data.getProcessByProposalIdForTrader.process === null
        ) {
          setProcessData({ ...EMPTY_PROCESS });
          throw new Error('Server failed to respond with valid process data.');
        }

        const { name, id, group, proposals } = data.getProcessByProposalIdForTrader.process;

        const parsedGroup = parseGroup(group);
        const parsedProposals = parseProposals(proposals, parsedGroup);
        const result = { name, id, group: parsedGroup, proposals: parsedProposals };

        setProcessData(result);
        setIsProcessDataLoading(false);
      },
      onError: () => {
        setProcessData({ ...EMPTY_PROCESS });
      },
    });
  };

  return { processData, fetchProcessData, isProcessDataLoading };
}
