import { BidWithSavings } from '@hooks/bids/savings/types';
import { formatCurrency, formatDate, formatNumber } from '@utils/text';
import { GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';
import React from 'react';

const BidEconomySection: React.FC<{ bid: BidWithSavings }> = ({ bid }) => {
  const isFixedPrice = bid.contractType === 'FIXED_PRICE';
  const inputPrices = isFixedPrice ? bid.savings.fixedPrice.inputPrices : bid.savings.guaranteedSaving.inputPrices;
  return (
    <>
      {inputPrices.map(({ pricePerYear }, index) => (
        <React.Fragment key={index}>
          <div className="flex overflow-x-auto space-x-3 sm:space-x-10">
            {pricePerYear.map(({ year, value }, pearYearIndex) => (
              <div key={pearYearIndex} className="flex flex-col">
                <p className="text-gray-500 text-paragraph-small">{year}</p>
                <p className="text-paragraph-medium">{isFixedPrice ? formatCurrency(value) : `${value}%`}</p>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

const BidWithSavingsContents: React.FC<{ bid: BidWithSavings }> = ({ bid }) => {
  const savingValues = bid.contractType === 'FIXED_PRICE' ? bid.savings.fixedPrice : bid.savings.guaranteedSaving;
  const currencyValueForDisplay = formatCurrency(savingValues.groupReport.totalCurrencyAmount);
  const percentageValueForDisplay = formatNumber(savingValues.groupReport.totalPercentageAmount * 100);
  const economyValueForDisplay = `${currencyValueForDisplay} (${percentageValueForDisplay}%)`;
  const npvValueForDisplay = formatCurrency(savingValues.groupReport.npv);

  return (
    <div className="space-y-4">
      <div>
        <h4 className="font-bold text-paragraph-small">Características da proposta</h4>
        <div className="grid grid-cols-4 gap-y-2 py-2 border-black border-y">
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Flexibilidade</p>
            <p className="text-paragraph-medium">{`-${bid.lowerFlexibility}% / +${bid.upperFlexibility}%`}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Garantia</p>
            <p className="text-paragraph-medium">{`${GuaranteeTypeEnum[bid.guaranteeType]}`}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Tipo de fornecedora</p>
            <p className="text-paragraph-medium">{bid.retailService ? 'Varejista' : 'Atacadista'}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Tipo de proposta</p>
            <p className="text-paragraph-medium">{`${ProposalTypeEnum[bid.proposalType]}`}</p>
          </div>
          <div className="col-span-full border-b border-black"></div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Validade da proposta</p>
            <p className="text-paragraph-medium">
              {isNaN(bid.deadline.getTime()) ? '-' : formatDate(bid.deadline, 'DD/MM/YYYY HH:mm')}
            </p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Duração de contrato</p>
            <p className="text-paragraph-medium">{bid.periods.map((year) => `${year} anos`).join(', ')}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">Economia</p>
            <p className="text-paragraph-medium">{economyValueForDisplay}</p>
          </div>
          <div className="flex flex-col col-span-full space-y-1 md:col-span-1">
            <p className="text-gray-500 text-paragraph-small">VPL da economia</p>
            <p className="text-paragraph-medium">{npvValueForDisplay}</p>
          </div>
        </div>
      </div>
      <div>
        <h4 className="font-bold text-paragraph-small">Preços ano a ano</h4>
        <div className="py-2 border-black border-y">
          <BidEconomySection bid={bid} />
        </div>
      </div>
    </div>
  );
};

export default BidWithSavingsContents;
