import numeral from 'numeral';
import { parseStringToDate } from '@utils/text';
import {
  CommissionModalityTypeEnum,
  ContractTypeEnum,
  CoverCeeeCostEnum,
  DocumentTypeEnum,
  EnergyTypeEnum,
  GuaranteeTypeEnum,
  ProductTypeEnum,
  ProposalTypeEnum,
  SubmarketEnum,
  TariffModeEnum,
  UnitTypeEnum,
} from '@utils/translators/proposal';

import {
  BidGraphQL,
  DocumentGraphQL,
  FeedbackGraphQL,
  GroupGraphQL,
  ProposalGraphQL,
  SupplyGraphQL,
  UnitHistoryGraphQL,
} from './graphql-types';
import {
  Bid,
  BidStatus,
  CommercialGroup,
  ConsumptionHistoryEntry,
  DocumentFile,
  Feedback,
  Proposal,
  ProposalStatus,
  ProposalTraderWithBid,
  Subgroup,
  Trader,
  Unit,
  UnitsResume,
} from './types';

const bindBidsToProposalTraders = (traders: Trader[], bids: Bid[]): ProposalTraderWithBid[] => {
  const result = traders.map((trader) => {
    const bidForTrader = bids.find((bid) => bid.trader.id === trader.id);
    if (bidForTrader) return { id: trader.id, name: trader.name, bid: bidForTrader };
    return { id: trader.id, name: trader.name };
  });
  return result;
};

const adjustBidStatusIfSignedProposalFound = (status: Bid['status'], documents: DocumentFile[]): Bid['status'] => {
  const hasSignedProposalFile = documents.some((document) => document.docType === 'SIGNED_PROPOSAL');
  return hasSignedProposalFile ? 'SIGNED' : status;
};

const parseDocuments = (documents: DocumentGraphQL[]): DocumentFile[] =>
  documents.map((document) => {
    const { docType, ...rest } = document;
    return { ...rest, docType: docType as keyof typeof DocumentTypeEnum };
  });

const parseBidFeedback = ({ text, createdAt }: FeedbackGraphQL): Feedback | null => {
  if (text === null || createdAt === null) return null;

  return { text, createdAt: parseStringToDate(createdAt) };
};

const parseBids = (bids: BidGraphQL[]): Bid[] =>
  bids.map(
    ({
      id,
      createdAt,
      deadline,
      status,
      trader,
      documents,
      contractType,
      periods,
      economy,
      proposal,
      lowerFlexibility,
      upperFlexibility,
      retailService,
      coverCceeCost,
      coverCceeTaxes,
      guaranteeType,
      guaranteeMonths,
      otherGuaranteeType,
      coverMeterSystem,
      meterSystemAmount,
      payDay,
      clarkeCommissionNote,
      commissionModality,
      feedback,
      note,
    }) => {
      const parsedStatus = BidStatus[status] as keyof typeof BidStatus;
      const parsedDocuments = documents ? parseDocuments(documents) : [];
      return {
        id,
        createdAt: parseStringToDate(createdAt),
        deadline: parseStringToDate(deadline),
        status: adjustBidStatusIfSignedProposalFound(parsedStatus, parsedDocuments),
        trader: { id: trader.id, name: trader.name },
        documents: parsedDocuments,
        contractType: contractType as keyof typeof ContractTypeEnum,
        periods: [...periods],
        economy: economy.map((entry) => ({ year: entry.year, amount: entry.amount })),
        proposalType: proposal.proposalType as keyof typeof ProposalTypeEnum,
        lowerFlexibility,
        upperFlexibility,
        retailService,
        coverCceeCost: coverCceeCost as keyof typeof CoverCeeeCostEnum,
        coverCceeTaxes: coverCceeTaxes ? 'FULL' : 'NONE',
        guaranteeType: guaranteeType === null ? 'NO_GUARANTEE' : (guaranteeType as keyof typeof GuaranteeTypeEnum),
        guaranteeMonths: guaranteeMonths ? guaranteeMonths : NaN,
        otherGuaranteeType,
        coverMeterSystem,
        meterSystemAmount: meterSystemAmount === null ? NaN : meterSystemAmount,
        payDay,
        clarkeCommissionNote,
        commissionModality,
        feedback: feedback === null ? null : parseBidFeedback(feedback),
        note,
      };
    },
  );

export const parseProposals = (rawProposals: ProposalGraphQL[], parsedGroup: CommercialGroup): Proposal[] => {
  return rawProposals
    .map((rawProposal) => {
      const {
        id,
        status,
        round,
        createdAt,
        deadline,
        bids,
        traders,
        supply,
        productType,
        contractType,
        periods,
        lowerFlexibility,
        upperFlexibility,
        proposalType,
        guaranteeType,
        guaranteeMonths,
        otherGuarantee,
        note,
        commissionModality,
        commissionType,
        gmvAmount,
        carbonDioxideTonAmount,
        takeRateAmount,
        commissionAnalysis,
      } = rawProposal;
      const parsedBids = parseBids(bids);

      return {
        id,
        status: ProposalStatus[status] as keyof typeof ProposalStatus,
        round,
        createdAt: parseStringToDate(createdAt),
        deadline: parseStringToDate(deadline),
        bids: parsedBids,
        traders: bindBidsToProposalTraders(traders, parsedBids),
        group: { ...parsedGroup },
        productType: productType !== null ? (productType as keyof typeof ProductTypeEnum) : 'ACL',
        contractType: contractType as keyof typeof ContractTypeEnum,
        periods: [...periods],
        lowerFlexibility,
        upperFlexibility,
        proposalType: proposalType as keyof typeof ProposalTypeEnum,
        guaranteeType: guaranteeType === null ? 'NO_GUARANTEE' : (guaranteeType as keyof typeof GuaranteeTypeEnum),
        guaranteeMonths: guaranteeMonths ? guaranteeMonths : NaN,
        otherGuarantee: otherGuarantee ?? '',
        note,
        commissionModality: commissionModality as keyof typeof CommissionModalityTypeEnum,
        commissionType,
        units: parseUnits(supply),
        unitsResume: parseUnitsResume(rawProposal),
        gmv: gmvAmount ? numeral(gmvAmount).format('$ 0,0.00') : null,
        carbonDioxide: carbonDioxideTonAmount ? numeral(carbonDioxideTonAmount).format('0,0.00') : null,
        takeRate: takeRateAmount ? numeral(takeRateAmount).format('0,0.00') : null,
        commissionAnalysis,
      };
    })
    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
};

const monthNumberToNameMap: Record<number, keyof Unit['history']> = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
};
export const parseUnitConsumptionHistory = (rawHistory: UnitHistoryGraphQL[]): Unit['history'] => {
  const result: Map<keyof Unit['history'], ConsumptionHistoryEntry> = new Map();
  const sortedRawHistory = rawHistory
    .slice()
    .sort((a, b) => parseStringToDate(a.yearMonth).getMonth() - parseStringToDate(b.yearMonth).getMonth());

  sortedRawHistory.forEach(({ id, consumptionPeak, consumptionOffPeak, generator, yearMonth }) => {
    const month = monthNumberToNameMap[parseStringToDate(yearMonth).getMonth()];
    result.set(month, { id, peak: consumptionPeak, offPeak: consumptionOffPeak, generator });
  });
  return Object.fromEntries(result) as unknown as Unit['history'];
};

const parseUnits = (rawSupply: SupplyGraphQL[]): Unit[] => {
  return rawSupply.map(({ startDate, endDate, unit }) => {
    const {
      id,
      edc,
      name,
      legalName,
      docType,
      docNumber,
      unitType,
      tariffSubgroup,
      tariffModality,
      contractedDemandPeak,
      contractedDemandOffPeak,
      averageInvoiceAmount,
      powerGenerator,
      generatorCost,
      energyType,
      submarket,
      history,
      totalConsumptionVolume,
      managementPrice,
    } = unit;

    const parsedStartDate = startDate === null ? null : parseStringToDate(startDate);
    const parsedEndDate = endDate === null ? null : parseStringToDate(endDate);

    return {
      id,
      edc: { id: parseInt(edc.id), name: edc.name, state: edc.state },
      name,
      legalName,
      docType,
      docNumber: docNumber.replace(/\D/g, ''),
      unitType: unitType as keyof typeof UnitTypeEnum,
      subgroup: tariffSubgroup as Subgroup,
      tariffMode: tariffModality as keyof typeof TariffModeEnum,
      contractedDemandPeak: contractedDemandPeak === null ? NaN : contractedDemandPeak,
      contractedDemandOffPeak,
      averageInvoiceAmount,
      hasGenerator: powerGenerator,
      generatorCost: generatorCost === null ? NaN : generatorCost,
      energyType: energyType as keyof typeof EnergyTypeEnum,
      submarket: submarket === null ? null : (submarket as keyof typeof SubmarketEnum),
      history: parseUnitConsumptionHistory(history),
      totalConsumptionVolume,
      supply: { startDate: parsedStartDate, endDate: parsedEndDate },
      managementPrice: managementPrice,
    };
  });
};

const parseUnitsResume = (proposal: ProposalGraphQL): UnitsResume => {
  const totalVolume = proposal.totalConsumptionVolume;
  let totalAmount = 0;
  let totalContractedDemandPeak = 0;
  let totalContractedDemandOffPeak = 0;

  proposal.supply.forEach(({ unit }) => {
    const unitPeakDemand = unit.contractedDemandPeak === null ? 0 : unit.contractedDemandPeak;
    const unitOffPeakDemand = unit.contractedDemandOffPeak;

    totalAmount = totalAmount + unit.averageInvoiceAmount;
    totalContractedDemandPeak = totalContractedDemandPeak + unitPeakDemand;
    totalContractedDemandOffPeak = totalContractedDemandOffPeak + unitOffPeakDemand;
  });

  return {
    totalVolume,
    totalAmount,
    totalContractedDemandOffPeak,
    totalContractedDemandPeak,
  };
};

export const parseGroup = ({ id, name, legalName, docNumber, docType }: GroupGraphQL): CommercialGroup => {
  const parsedGroup = {
    id,
    name,
    legalName,
    docType,
    docNumber: docNumber.replace(/\D/g, ''),
  };
  return parsedGroup;
};
