import React from 'react';
import { useFieldArray, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { OpenNewRoundFormData } from './types';

interface TraderSelectionContext {
  selectedTradersFieldArray: UseFieldArrayReturn<OpenNewRoundFormData, 'feedbacksForTradersThatAreWinners', 'id'>;
  refusedTradersFieldArray: UseFieldArrayReturn<OpenNewRoundFormData, 'feedbacksForTradersThatAreRefused', 'id'>;
}

const traderSelectionContext = React.createContext<TraderSelectionContext>({} as TraderSelectionContext);

export function useTraderSelectionContext() {
  return React.useContext(traderSelectionContext);
}

/*
 * Why is this context needed?
 *
 * The traders chosen on the first step define how many feeback fields must appear for both
 * traders chosen and refused for next round. In other words, the checkboxes on the first
 * step must dynamically render the text areas for steps 2 and 3.
 *
 */
const TraderSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { control } = useFormContext<OpenNewRoundFormData>();
  const selectedTradersFieldArray = useFieldArray<OpenNewRoundFormData, 'feedbacksForTradersThatAreWinners'>({
    name: 'feedbacksForTradersThatAreWinners',
    control,
  });

  const refusedTradersFieldArray = useFieldArray<OpenNewRoundFormData, 'feedbacksForTradersThatAreRefused'>({
    name: 'feedbacksForTradersThatAreRefused',
    control,
  });

  return (
    <traderSelectionContext.Provider value={{ selectedTradersFieldArray, refusedTradersFieldArray }}>
      {children}
    </traderSelectionContext.Provider>
  );
};

export default TraderSelectionProvider;
