import React from 'react';

import Skeleton from 'react-loading-skeleton';

const SidebarSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col justify-around px-4 space-y-4 grow">
      <div className="grow">
        <Skeleton count={3} height={20} />
      </div>
      <div className="flex items-center space-x-2">
        <Skeleton circle containerClassName="w-9 h-9" height="100%" />
        <div className="flex flex-col w-full">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default SidebarSkeleton;
