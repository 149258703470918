import React from 'react';
import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import {
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  FieldName,
  Path,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

export interface NumericFormFieldProps<T> {
  field: Path<T>;
  id: string;
  formatProps: Pick<
    NumberFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'format'
  >;
  label?: string;
  options?: Omit<RegisterOptions, 'disabled'>;
  className?: string;
  disabled?: boolean;
}

const buildTrueControllerOptions = (options?: Omit<RegisterOptions, 'disabled'>) => {
  let trueOptions = cloneDeep(options); // because we need to modify a react prop

  if (trueOptions) {
    trueOptions.valueAsNumber = true;

    if (options?.required) {
      const message =
        typeof options.required === 'string'
          ? options.required
          : typeof options.required === 'boolean'
          ? ''
          : options.required.message;
      trueOptions.validate = { checkIsNotNaN: (v) => !isNaN(v) || message };

      delete trueOptions.required;
    }
  } else {
    trueOptions = { valueAsNumber: true };
  }
  return trueOptions;
};

export function NumericFormField<T>({
  field,
  id,
  label,
  formatProps: {
    placeholder,
    prefix,
    suffix,
    allowNegative = false,
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = true,
    format,
  },
  options,
  className,
  disabled,
}: NumericFormFieldProps<T>) {
  const { control, formState } = useFormContext<T>();

  const disabledClass = disabled ? 'text-neutral-40' : 'text-black';

  const required = options?.required !== undefined;
  const trueOptions = buildTrueControllerOptions(options);

  const error = get(formState.errors, field);
  return (
    <div className="flex relative flex-col">
      {label && (
        <label htmlFor={id} className={`mb-2 text-paragraph-medium ${disabledClass}`}>
          {label}
          {required && <span className="text-danger-60">*</span>}
        </label>
      )}
      <Controller
        control={control}
        name={field}
        rules={trueOptions}
        render={({ field: { value, onChange, onBlur, ref } }) => {
          const errorClass = disabled
            ? 'outline-none border ring-0'
            : error
            ? 'outline-none border border-danger-60 ring-danger-60'
            : '';
          const focusClass = error
            ? 'focus:border focus:outline-none focus:border-danger-60 focus:ring-danger-60'
            : 'focus:border focus:outline-none focus:border-primary-60 focus:ring-primary-60';

          const borderClass = disabled ? 'border-neutral-40' : 'border border-black';

          const dynamicClasses = [borderClass, errorClass, focusClass, className].join(' ');

          return (
            <NumberFormat
              id={id}
              data-cy={id}
              className={`rounded-small text-paragraph-medium ${dynamicClasses}`}
              {...{
                thousandSeparator,
                decimalSeparator,
                decimalScale,
                fixedDecimalScale,
                allowNegative,
                isNumericString: true,
                prefix,
                suffix,
                placeholder,
                format,
              }}
              value={
                // @ts-expect-error :: does not match with react-hook-form value
                isNaN(value) || value === null || value === undefined ? '' : value.toString()
              }
              onBlur={onBlur}
              getInputRef={ref}
              onValueChange={(v) => {
                const value = v.floatValue === undefined ? NaN : v.floatValue;
                if (!allowNegative) onChange(Math.abs(value));
                else onChange(value);
              }}
            />
          );
        }}
      />
      <ErrorMessage
        name={field as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>}
        errors={formState.errors}
        render={({ message }) => <span className="text-paragraph-small text-danger-60">{message}</span>}
      />
    </div>
  );
}
