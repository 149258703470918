import React from 'react';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { CommissionModalityTypeEnum, GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';

const ProposalDisplay: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  return (
    <>
      <h3 className="font-bold text-heading-xsmall">Organize as propostas</h3>
      <div className="space-y-4">
        <p className="w-2/3 text-paragraph-medium text-neutral-50">
          Solicitamos propostas com um escopo bem definido que você pode ver abaixo. Organize movendo as propostas que
          se enquadram dentro e fora do escopo.
        </p>

        <h4 className="font-bold text-paragraph-small">{`Escopo solicitado para ${proposal.group.name}`}</h4>
        <div className="grid grid-cols-1 gap-y-2 py-2 border-black lg:grid-cols-4 border-y">
          <div className="flex flex-col space-y-1">
            <p className="text-paragraph-small text-neutral-50">Flexibilidade</p>
            <p className="text-paragraph-medium">{`-${proposal.lowerFlexibility}% / +${proposal.upperFlexibility}%`}</p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-paragraph-small text-neutral-50">Garantia</p>
            <p className="text-paragraph-medium">{`${GuaranteeTypeEnum[proposal.guaranteeType]}`}</p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-paragraph-small text-neutral-50">Tipo de proposta</p>
            <p className="text-paragraph-medium">{`${ProposalTypeEnum[proposal.proposalType]}`}</p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-paragraph-small text-neutral-50">Modalidade de contratação</p>
            <p className="text-paragraph-medium">{`${CommissionModalityTypeEnum[proposal.commissionModality]}`}</p>
          </div>
          <div className="col-span-full border-t border-black"></div>
          <div className="flex flex-col space-y-1">
            <p className="text-paragraph-small text-neutral-50">Duração de contrato</p>
            <p className="text-paragraph-medium">{proposal.periods.map((year) => `${year} anos`).join(', ')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalDisplay;
