import { Ranking } from '@hooks/process/queries/get-process-tader-ranking/types';

export const checkPositionOfTraderAtRanking = (
  topRanked: Ranking[],
  generalRanking: Ranking[],
  traderId: string,
): Ranking | undefined => {
  const traderIsAlreadyTopRanked = topRanked.find(({ trader }) => trader.id === traderId);
  const traderPoisition = generalRanking.find(({ trader }) => trader.id === traderId);

  if (traderIsAlreadyTopRanked) return undefined;

  if (traderPoisition) return traderPoisition;

  return undefined;
};

export const oderByRanking = (ranking: Ranking[]) =>
  ranking.sort((rankingA, rankingB) => rankingA.position - rankingB.position);
