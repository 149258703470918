import { useMutation } from '@apollo/client';
import { Proposal, ProposalStatus } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { createCustomerDocument } from '@services/rest/create-documents';
import { formatDateIntoLocalISOString } from '@utils/translators/date';
import {
  CreateProposalGraphQLInput,
  UpsertProposalGraphQLResponse,
  CREATE_PROPOSAL,
  EditProposalGraphQLInput,
  UpdateProposalStatusGraphQLInput,
  UpdateProposalStatusGraphQLResponse,
  UPDATE_PROPOSAL_STATUS,
  EDIT_PROPOSAL,
} from './mutation';

export const convertUnitSupplyInfo = (proposal: Proposal) => {
  return proposal.units.map((unit) => ({
    unitId: unit.id,
    energyType: unit.energyType,
    supplyStartDate:
      unit.supply.startDate === null ? '' : formatDateIntoLocalISOString(unit.supply.startDate).split(' ')[0],
    supplyEndDate: unit.supply.endDate === null ? '' : formatDateIntoLocalISOString(unit.supply.endDate).split(' ')[0],
    files: [],
    name: unit.name,
    companyName: unit.legalName,
    unitType: unit.unitType,
    subgroup: unit.subgroup,
    tariffMode: unit.tariffMode,
    monthlyInvoice: unit.averageInvoiceAmount,
    edc: unit.edc.id,
    totalConsumptionVolume: unit.totalConsumptionVolume,
  }));
};

export function useCreateProposal() {
  const [mutationFunction] = useMutation<UpsertProposalGraphQLResponse, CreateProposalGraphQLInput>(CREATE_PROPOSAL);

  const createProposal = async (input: CreateProposalGraphQLInput['createProposalInput']) => {
    const { data, errors } = await mutationFunction({ variables: { createProposalInput: input } });

    if (errors !== undefined || !data) throw new Error('Falha ao criar a proposta.');

    const result = data.createProposal;
    return { id: result.id };
  };

  return createProposal;
}

export function useCreateProposalDocument() {
  return (file: File, proposalId: string, authToken: string) => {
    const formData = new FormData();
    formData.append('label', `${file.name}`);
    formData.append('file', file);
    formData.append('entity_id', proposalId);
    formData.append('entity', 'proposal');

    createCustomerDocument(formData, authToken);
  };
}

export function useUpdateProposalStatus() {
  const [mutationFunction] = useMutation<UpdateProposalStatusGraphQLResponse, UpdateProposalStatusGraphQLInput>(
    UPDATE_PROPOSAL_STATUS,
  );

  const updateProposalStatus = async (proposalId: string, status: ProposalStatus) => {
    const { data, errors } = await mutationFunction({ variables: { proposalId, updateProposalInput: { status } } });

    if (errors !== undefined || !data) throw new Error(`Falha ao atualizar a proposta ${proposalId}.`);

    const result = data.updateProposal;
    return { id: result.id };
  };

  return updateProposalStatus;
}

export function useEditProposal() {
  const [mutationFunction] = useMutation<UpsertProposalGraphQLResponse, EditProposalGraphQLInput>(EDIT_PROPOSAL);

  const editProposal = async (proposalId: string, input: CreateProposalGraphQLInput['createProposalInput']) => {
    const { data, errors } = await mutationFunction({
      variables: { editProposalInput: { ...input, id: proposalId } },
    });

    if (errors !== undefined || !data) throw new Error(`Falha ao editar a proposta ${proposalId}`);

    const result = data.createProposal;
    return { id: result.id };
  };

  return editProposal;
}
