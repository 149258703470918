import configuration from '@config';

export async function createCustomerDocument(formData: FormData, authToken: string) {
  const url = `${configuration.CUSTOMER_API_HOST}/api/v1/documents/`;
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  const json = await response.json();
}
