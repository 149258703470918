import { ProposalFormData } from '@components/molecules/create-proposal/types';
import { CreateProposalGraphQLInput } from '@hooks/proposals/single-proposal/creation/mutation';
import { formatDateFromString, parseStringToDate } from '@utils/text';
import { ContractTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';

const getContractDuration = (units: ProposalFormData['units']): number => {
  const startYear = [
    ...new Set(
      units
        .map(
          (unit) =>
            parseStringToDate(
              formatDateFromString(unit.supplyStartDate, 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss'),
            ).getFullYear() ?? NaN,
        )
        .filter((year) => !isNaN(year)),
    ),
  ].sort((a, b) => a - b)[0];

  const endYear = [
    ...new Set(
      units
        .map(
          (unit) =>
            parseStringToDate(
              formatDateFromString(unit.supplyEndDate, 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss'),
            ).getFullYear() ?? NaN,
        )
        .filter((year) => !isNaN(year)),
    ),
  ].sort((a, b) => b - a)[0];

  return endYear - startYear + 1;
};

interface AdvanceCommissionAmounts {
  retailAdvanceCommissionAmounts: { period: number; amount: number }[];
  wholesaleAdvanceCommissionAmounts: { period: number; amount: number }[];
}

interface CommissionAmounts {
  retailCommissionAmounts: { period: number; amount: number }[];
  wholesaleCommissionAmounts: { period: number; amount: number }[];
}

// const getCommissionData = ({
//   contract,
//   units,
// }: Pick<ProposalFormData, 'contract' | 'units'>):
//   CreateProposalGraphQLInput['createProposalInput']
// > => {
//   const { commissionModality } = contract;

//   return {
//     commissionModality: commissionModality === '' ? 'NO_PREFERENCE' : commissionModality,
//   };
// };

const parseProposalUnitsInfo = (
  units: ProposalFormData['units'],
): CreateProposalGraphQLInput['createProposalInput']['units'] => {
  return units.map((unit) => {
    const startDate = unit.supplyStartDate === '' ? null : unit.supplyStartDate;
    const endDate = unit.supplyEndDate === '' ? null : unit.supplyEndDate;
    return { id: unit.unitId, startDate, endDate, totalConsumptionVolume: unit.totalConsumptionVolume };
  });
};

type BaseProposalGraphQLInput = Omit<CreateProposalGraphQLInput['createProposalInput'], 'round'>;

export const parseProposalFormDataIntoBaseGraphQLInput = (formData: ProposalFormData): BaseProposalGraphQLInput => {
  const { contract, units, client } = formData;

  const hasNoGuarantee = contract.guaranteeType === '' || contract.guaranteeType === 'NO_GUARANTEE';
  const guaranteeType = hasNoGuarantee ? null : contract.guaranteeType;
  const guaranteeMonths = hasNoGuarantee ? null : contract.guaranteeMonths === '' ? null : contract.guaranteeMonths;

  const otherGuaranteeIsSelected = contract.guaranteeType === 'OTHER';
  const otherGuarantee = otherGuaranteeIsSelected ? contract.otherKindGuarantee : null;

  const selectedTraders = contract.traders;

  const yearsDuration = getContractDuration(units);
  // const commissionData = getCommissionData({ contract, units });

  return {
    traders: selectedTraders,
    units: parseProposalUnitsInfo(units),
    groupId: client.id,
    proposalType: contract.proposalModality as keyof typeof ProposalTypeEnum,
    contractType: contract.contractType as keyof typeof ContractTypeEnum,
    periods: [yearsDuration],
    deadline: `${contract.proposalDeadline.date} ${contract.proposalDeadline.time}:00`,
    lowerFlexibility: contract.lowerFlexibility,
    upperFlexibility: contract.upperFlexibility,
    guaranteeType,
    guaranteeMonths,
    otherGuarantee,
    commissionModality: contract.commissionModality === '' ? 'NO_PREFERENCE' : contract.commissionModality,
    note: contract.note,
  };
};

interface Params {
  round: number;
  formData: ProposalFormData;
}
export const parseProposalFormDataIntoGraphQLInput = ({
  round,
  formData,
}: Params): CreateProposalGraphQLInput['createProposalInput'] => {
  return {
    round,
    ...parseProposalFormDataIntoBaseGraphQLInput(formData),
  };
};
