import React from 'react';
import { DeepPartial, FieldPath, FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ContentLayoutContainer from '@components/layout/content-layout-container';
import { FirstStep } from '@components/organisms/create-proposal/first-step';
import { SecondStep } from '@components/organisms/create-proposal/second-step';
import { ThirdStep } from '@components/organisms/create-proposal/third-step';
import { ProposalFormData } from '@components/molecules/create-proposal/types';
import { FormHeader } from '@components/atoms/form/form-header';
import { NavigationControls } from '@components/molecules/form-steps-navigation-control';
import { ConfirmSubmissionDialog } from '@components/molecules/confirm-form-submission-dialog';
import { HOME_PATH } from '@routers/constants';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-labels';
import {
  GroupDataForProposalCreation,
  GroupForProposalCreation,
} from '@hooks/proposals/single-proposal/creation/commercial-group';
import ProposalFormProvider from '@components/molecules/create-proposal/proposal-form-context';

import { RetailTrader } from '@hooks/proposals';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import GroupDataProvider from './group-data-context';

const headers = ['Dados do Cliente e Contrato', 'Dados das Unidades', 'Confirmação do Orçamento'];
const renderStepElement = (currentStep: number, allTraders: RetailTrader[], allGroups: GroupForProposalCreation[]) => {
  switch (currentStep) {
    case 1:
      return <FirstStep availableTraders={allTraders} availableGroups={allGroups} />;

    case 2:
      return <SecondStep />;

    case 3:
      return <ThirdStep availableTraders={allTraders} />;

    default:
      throw 'Invalid step number';
  }
};

const getDynamicFieldsForFirstStepValidation = (
  formValuesGetterFunction: UseFormGetValues<ProposalFormData>,
): FieldPath<ProposalFormData>[] => {
  const result: FieldPath<ProposalFormData>[] = [];

  // financial guarantee fields
  const hasGuaranteeSugestion = formValuesGetterFunction('contract.hasGuaranteeSugestion');
  if (hasGuaranteeSugestion === 'yes') {
    result.push('contract.guaranteeType');
    const guaranteeType = formValuesGetterFunction('contract.guaranteeType');

    if (['SURETY_BOND', 'ADVANCE_PAYMENT', 'BANK_GUARANTEE', 'GUARANTEE_LETTER'].includes(guaranteeType))
      result.push('contract.guaranteeMonths');

    if (guaranteeType === 'OTHER') result.push('contract.otherKindGuarantee');
  }

  return result;
};

const getDynamicFieldsForSecondStepValidation = (
  formValuesGetterFunction: UseFormGetValues<ProposalFormData>,
): FieldPath<ProposalFormData>[] => {
  const units = formValuesGetterFunction('units');
  const result: FieldPath<ProposalFormData>[] = [];
  units.forEach((_unit, index) => {
    result.push(`units.${index}.unitId` as const);
    result.push(`units.${index}.energyType` as const);
    result.push(`units.${index}.supplyStartDate` as const);
    result.push(`units.${index}.supplyEndDate` as const);
  });
  return result;
};

const firstStepStaticFieldsForValidation: FieldPath<ProposalFormData>[] = [
  'client.name',
  'client.docType',
  'client.document',
  'client.companyName',
  'contract.contractType',
  'contract.lowerFlexibility',
  'contract.upperFlexibility',
  'contract.proposalModality',
  'contract.traders',
  'contract.proposalDeadline.date',
  'contract.proposalDeadline.time',
  'contract.hasGuaranteeSugestion',
  'contract.files',
];
const getFieldsToBeValidated = (
  step: number,
  formValuesGetterFunction: UseFormGetValues<ProposalFormData>,
): FieldPath<ProposalFormData>[] => {
  if (step === 1)
    return [...firstStepStaticFieldsForValidation, ...getDynamicFieldsForFirstStepValidation(formValuesGetterFunction)];
  return [...getDynamicFieldsForSecondStepValidation(formValuesGetterFunction)];
};

interface CreateProposalFormProps {
  isCreating: boolean;
  allTraders: RetailTrader[];
  allGroups: GroupForProposalCreation[];
  onFormSubmitCallback: (data: ProposalFormData) => Promise<void>;
  initialValues: DeepPartial<ProposalFormData>;
  proposal: Proposal | null;
  groupDataFetcher: {
    fetchGroupData: (groupId: string) => Promise<GroupDataForProposalCreation>;
    isFetchingGroupData: boolean;
  };
}
const CreateProposalForm: React.FC<CreateProposalFormProps> = ({
  isCreating,
  allTraders,
  allGroups,
  proposal,
  onFormSubmitCallback,
  initialValues,
  groupDataFetcher,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [isConfirmSubmitDialogOpen, setIsConfirmSubmitDialogOpen] = React.useState<boolean>(false);

  const methods = useForm<ProposalFormData>({ defaultValues: initialValues });

  const onCancelFormCallback = () => navigate(`${HOME_PATH}`);

  const onStepChangeCallback = (nextStepValue: number) => {
    if (nextStepValue > currentStep) {
      const fields = getFieldsToBeValidated(currentStep, methods.getValues);
      methods.trigger(fields).then((fieldsAreValid) => {
        if (fieldsAreValid) setCurrentStep(nextStepValue);
      });
    } else {
      setCurrentStep(nextStepValue);
    }
  };

  const onSubmitCallback = async () => setIsConfirmSubmitDialogOpen(true);

  return (
    <ContentLayoutContainer className="overflow-y-auto h-screen">
      <FormProvider {...methods}>
        <form>
          <FormHeader headers={headers} activeHeaderNum={currentStep} />

          <div className="space-y-8">
            <ProposalFormProvider isCreating={isCreating} proposal={proposal ?? undefined}>
              <GroupDataProvider {...{ groupDataFetcher: groupDataFetcher }}>
                {renderStepElement(currentStep, allTraders, allGroups)}
              </GroupDataProvider>
            </ProposalFormProvider>

            <NavigationControls
              currentStep={currentStep}
              controlParams={{
                maxStep: 3,
                textLabels: {
                  changeStep: 'Próximo passo',
                  endStep: 'Enviar proposta',
                },
                cancelFormCallback: onCancelFormCallback,
                changeStepCallback: onStepChangeCallback,
                endStepCallback: onSubmitCallback,
              }}
            />
          </div>
          {currentStep === 3 && (
            <ConfirmSubmissionDialog
              displayOptions={{
                titleText: 'Enviar solicitação?',
                buttonSubmitText: 'Enviar',
              }}
              isOpen={isConfirmSubmitDialogOpen}
              confirmDialogCallback={async () => {
                await methods.handleSubmit(onFormSubmitCallback)();
                setIsConfirmSubmitDialogOpen(false);
                eventTracker.trackEvent(eventLabels.ADMIN_SEND_NEW_PROPOSAL_BUTTON_CLICKED);
              }}
              closeDialogCallback={() => setIsConfirmSubmitDialogOpen(false)}
            />
          )}
        </form>
      </FormProvider>
    </ContentLayoutContainer>
  );
};

export default CreateProposalForm;
