import React from 'react';
import ClarkeLogo from '@assets/clarke-logo.svg';

const CompanyLogo: React.FC = () => {
  return (
    <div className="items-start px-4">
      <img className="w-auto h-8" src={ClarkeLogo} alt="Clarke logo" />
    </div>
  );
};

export default CompanyLogo;
