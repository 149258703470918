import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FormSection, SelectFormField } from '@components/atoms/form';
import AccordionItem from '@components/molecules/accordion';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { ChangingConsumptionDisplay, NonChangingConsumptionDisplay } from './consumption';
import EqualDemandDisplay from './equal-demand';
import NonEqualDemandDisplay from './non-equal-demand';

interface SecondStepProps {
  proposal: Proposal;
}
const SecondStep: React.FC<SecondStepProps> = ({ proposal }) => {
  const { control, watch } = useFormContext<ProposalCompilationFormData>();
  const { fields } = useFieldArray<ProposalCompilationFormData, 'demandAndConsumption'>({
    name: 'demandAndConsumption',
    control,
  });
  return (
    <div className="py-5">
      <div className="flex flex-col py-5 space-y-1">
        <p className="font-bold text-heading-xsmall">Informações das Unidades</p>
        <p className="text-paragraph-medium text-neutral-50">
          Insira as informações sobre a demanda e o consumo de cada unidade.
        </p>
      </div>

      <div className="flex flex-col space-y-8">
        {fields.map((field, index) => {
          const equalDemandWatch = watch(`demandAndConsumption.${index}.equalDemand`);
          const changeConsumptionWatch = watch(`demandAndConsumption.${index}.consumptionChangesThroughYears`);

          const unit = proposal.units.find((unit) => unit.id === field.unitInfo.id);
          const unitPrefix = `unit-${field.unitInfo.id}`;

          return (
            <AccordionItem
              key={unitPrefix}
              label={`Unidade ${index + 1} - ${unit?.name}`}
              testId={unitPrefix}
              initiallyOpen={true}
            >
              <div className="grid grid-cols-3 gap-x-5 gap-y-8 p-5">
                <div className="col-span-1">
                  <FormSection
                    title="Demanda contratada"
                    subtitle="Insira a demanda ano a ano do cliente no ACR e ACL."
                  />
                </div>

                <div className="flex flex-col col-span-2">
                  <div className="grid grid-cols-2 gap-x-5">
                    {/* Demand Section */}
                    <SelectFormField<ProposalCompilationFormData>
                      field={`demandAndConsumption.${index}.equalDemand` as const}
                      id={`${unitPrefix}-equal-demand`}
                      label="Demanda ACR é igual a ACL?"
                      inputOptions={[
                        { optionLabel: 'Sim', value: 'YES' },
                        { optionLabel: 'Não', value: 'NO' },
                      ]}
                      options={{
                        required: { value: true, message: 'O tipo de diferenciação de demanda deve ser escolhido' },
                      }}
                    />
                    <SelectFormField<ProposalCompilationFormData>
                      field={`demandAndConsumption.${index}.demandChangesThroughYears` as const}
                      id={`${unitPrefix}-demand-changes`}
                      label="Demanda altera no decorrer dos anos?"
                      inputOptions={[
                        { optionLabel: 'Sim', value: 'YES' },
                        { optionLabel: 'Não', value: 'NO' },
                      ]}
                      options={{ required: { value: true, message: 'Uma opção deve ser escolhida' } }}
                    />
                  </div>

                  {equalDemandWatch && (
                    <div className="pt-6">
                      {equalDemandWatch === 'YES' ? (
                        <EqualDemandDisplay
                          {...{
                            unitIndex: index,
                            unitId: field.unitInfo.id,
                            unitTariffMode: field.unitInfo.tariffMode,
                            unitContractedOffPeakDemand: field.unitInfo.contractedOffPeakDemand,
                            unitContractedPeakDemand: field.unitInfo.contractedPeakDemand,
                          }}
                        />
                      ) : (
                        <NonEqualDemandDisplay
                          {...{
                            unitIndex: index,
                            unitId: field.unitInfo.id,
                            unitTariffMode: field.unitInfo.tariffMode,
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>

                {/* Consumption Section */}
                <div className="col-span-1">
                  <FormSection
                    title="Consumo"
                    subtitle="Insira o consumo fora ponta e ponta do cliente conforme os anos."
                  />
                </div>

                <div className="flex flex-col col-span-2">
                  <div className="grid grid-cols-2 gap-x-5">
                    <SelectFormField<ProposalCompilationFormData>
                      field={`demandAndConsumption.${index}.consumptionChangesThroughYears` as const}
                      id={`${unitPrefix}-consumption-changes`}
                      label="Consumo altera no decorrer dos anos?"
                      inputOptions={[
                        { optionLabel: 'Sim', value: 'YES' },
                        { optionLabel: 'Não', value: 'NO' },
                      ]}
                      options={{
                        required: { value: true, message: 'O tipo de diferenciação de consumo deve ser escolhido' },
                      }}
                    />
                  </div>

                  {changeConsumptionWatch && (
                    <div className="pt-6">
                      {changeConsumptionWatch === 'NO' ? (
                        <NonChangingConsumptionDisplay {...{ unitPrefix, history: field.unitInfo.history }} />
                      ) : (
                        <ChangingConsumptionDisplay {...{ unitIndex: index, unitId: field.unitInfo.id }} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </AccordionItem>
          );
        })}
      </div>
    </div>
  );
};

export default SecondStep;
