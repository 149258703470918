import { useMutation } from '@apollo/client';

import { BidStatus } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { UpdateBidStatusGraphQLInput, UpdateBidStatusGraphQLResponse, UPDATE_BID_STATUS_MUTATION } from './mutations';

export function useUpdateBidStatus() {
  const [mutationFunction] = useMutation<UpdateBidStatusGraphQLResponse, UpdateBidStatusGraphQLInput>(
    UPDATE_BID_STATUS_MUTATION,
  );

  const updateStatus = async (id: string, status: keyof typeof BidStatus) => {
    const { data, errors } = await mutationFunction({ variables: { bidId: id, status } });

    if (errors !== undefined || !data) throw new Error('Houve um erro e o servidor não respondeu como esperado.');

    if (data.updateBidStatus.error) throw new Error(data.updateBidStatus.error);
  };

  return updateStatus;
}
