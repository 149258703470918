import React from 'react';

import { useGetBidSavingsForProposal } from '@hooks/bids/savings';
import CompilationNotAllowedDisplay from '@components/molecules/compilation-display/compilation-not-allowed';
import { LayoutWithRoundActionsBar, useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import CompilationAllowedDisplay from '@components/molecules/compilation-display/compilation-allowed';
import CompilationHasResultsDisplay from '@components/molecules/compilation-display/compilation-has-results-display';
import ProposalCompilationSkeleton from '@components/molecules/compilation-display/compilation-skeleton';

const ProposalCompilationPage: React.FC = () => {
  const { proposal } = useAdminProposal();
  const [bids, isLoading] = useGetBidSavingsForProposal(proposal.id);
  const isCompilationAllowed = proposal.bids.length > 0;
  const proposalHasCompilationResults = bids.some(({ savings }) => savings.status !== 'NOT_REQUESTED');

  return (
    <>
      <LayoutWithRoundActionsBar proposal={proposal}>
        {isLoading ? (
          <ProposalCompilationSkeleton />
        ) : proposalHasCompilationResults ? (
          <CompilationHasResultsDisplay {...{ bids, proposal }} />
        ) : isCompilationAllowed ? (
          <CompilationAllowedDisplay {...{ proposal }} />
        ) : (
          <CompilationNotAllowedDisplay />
        )}
      </LayoutWithRoundActionsBar>
    </>
  );
};

export default ProposalCompilationPage;
