import React from 'react';

interface SendSignedFinalProposalContext {
  isModalFormOpen: boolean;
  openModalDialogCallback: () => void;
  closeModalDialogCallback: () => void;
}
const sendSignedFinalProposalContext = React.createContext<SendSignedFinalProposalContext>(
  {} as SendSignedFinalProposalContext,
);

const SendSignedFinalProposalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSendSignedFinalProposalDialogOpen, setIsSendSignedFinalProposalDialogOpen] = React.useState<boolean>(false);
  return (
    <sendSignedFinalProposalContext.Provider
      value={{
        isModalFormOpen: isSendSignedFinalProposalDialogOpen,
        openModalDialogCallback: () => setIsSendSignedFinalProposalDialogOpen(true),
        closeModalDialogCallback: () => setIsSendSignedFinalProposalDialogOpen(false),
      }}
    >
      {children}
    </sendSignedFinalProposalContext.Provider>
  );
};

export default SendSignedFinalProposalContextProvider;

export function useSendSignedFinalProposalContext() {
  return React.useContext(sendSignedFinalProposalContext);
}
