import { gql, useMutation } from '@apollo/client';

const DELETE_BID_MUTATION = gql`
  mutation DeleteBid($deleteBidId: ID!) {
    deleteBid(id: $deleteBidId) {
      error
    }
  }
`;

interface DeleteBidGraphQLResponse {
  deleteBid: {
    error: string | null;
    __typename?: string;
  };
}

export function useDeleteBid(): (id: string) => Promise<boolean> {
  const [mutationFunction] = useMutation<DeleteBidGraphQLResponse, { deleteBidId: string }>(DELETE_BID_MUTATION);
  const deleteBid = async (id: string) => {
    try {
      const { data, errors } = await mutationFunction({ variables: { deleteBidId: id } });

      if (!data || errors) return false;
      if (data.deleteBid.error) return false;
    } catch (error) {
      return false;
    }

    return true;
  };

  return deleteBid;
}
