/**
 * Use this class when there is an uncaught server error.
 * */
export class ServerFaultError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
  }
}

/**
 * Use this class when there is an server side (domain) validation error occurs.
 * */
export class ServerValidationError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
  }
}
