import { gql } from '@apollo/client';
import { DocType } from '@hooks/bids/savings/types';
import { Subgroup } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { EnergyTypeEnum, SubmarketEnum, TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';

export const GET_COMMERCIAL_GROUP_DATA_FOR_PROPOSAL_CREATION = gql`
  query GetCommercialGroupDataForProposalCreation($groupId: ID!) {
    group(id: $groupId) {
      id
      name
      legalName
      docType
      docNumber
      units {
        id
        name
        legalName
        docType
        docNumber
        energyType
        unitType
        submarket
        contractedDemandPeak
        contractedDemandOffPeak
        totalConsumptionVolume
        edc {
          id
          state
          name
        }
        tariffSubgroup
        tariffModality
        averageInvoiceAmount
        migrationDate
        powerGenerator
        generatorCost
        history {
          id
          consumptionOffPeak
          consumptionPeak
          generator
          yearMonth
        }
      }
    }
  }
`;

export interface GetGroupDataGraphQLResponse {
  group: {
    id: string;
    name: string;
    legalName: string;
    docType: DocType;
    docNumber: string;
    units: {
      id: string;
      name: string;
      legalName: string;
      docType: 1 | 2;
      docNumber: string;
      energyType: keyof typeof EnergyTypeEnum | null;
      unitType: keyof typeof UnitTypeEnum;
      submarket: keyof typeof SubmarketEnum;
      contractedDemandPeak: number | null;
      contractedDemandOffPeak: number;
      totalConsumptionVolume: number;
      edc: { id: string; state: string; name: string; __typename?: string };
      tariffSubgroup: Subgroup;
      tariffModality: keyof typeof TariffModeEnum;
      averageInvoiceAmount: number;
      migrationDate: string;
      powerGenerator: boolean;
      generatorCost: number | null;
      history: {
        id: string;
        consumptionOffPeak: number;
        consumptionPeak: number;
        generator: number;
        yearMonth: string;
        __typename?: string;
      }[];
      __typename?: string;
    }[];
    __typename?: string;
  };
}

export const GET_COMMERCIAL_GROUP_LIST_DATA_FOR_PROPOSAL_CREATION = gql`
  query GetCommercialGroupListDataForProposalCreation {
    groups {
      data {
        id
        name
        docType
        docNumber
      }
    }
  }
`;

export interface GetGroupListDataGraphQLResponse {
  groups: {
    data: {
      id: string;
      name: string;
      docType: DocType;
      docNumber: string;
    }[];
  };
}
