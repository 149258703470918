import React from 'react';
import { Transition } from '@headlessui/react';

import { formatDate } from '@utils/text';

import {
  BidCommercialConditionsContentsDisplay,
  BidEconomyProposalContentsDisplay,
  BidPaymentContentsDisplay,
  StatusMessageForNonFinalBidDisplay,
  TraderCanCreateFinalBidDisplay,
  TraderCanCreateNonFinalBidDisplay,
  TraderCannotCreateBidDisplay,
  TraderCreatedFinalBidDisplayMessage,
  TraderCreatedNonFinalBidDisplayMessage,
} from '@components/molecules/proposal-round/trader-bid-contents-display';
import BidFeedbackMessageDisplay from '@components/atoms/proposal-round/bid-feedback-message';
import HeroIcon from '@components/atoms/hero-icon';
import FinalBidForTraderStateBadge from '@components/atoms/proposal-round/trader-final-bid-state-badge';
import FinalBidContentsDisplay from '@components/molecules/proposal-round/final-bid-contents-display';
import AccordionItem from '@components/molecules/accordion';

import { Bid, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

// Non final round
export const TraderInNonFinalRoundWithoutBidsSummary: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  const thisProposalIsExpired = proposal.deadline.getTime() < Date.now();
  const thisProposalIsFinished = proposal.status === 'FINISHED';

  if (thisProposalIsFinished || thisProposalIsExpired) return <TraderCannotCreateBidDisplay />;

  return <TraderCanCreateNonFinalBidDisplay proposal={proposal} />;
};

export const TraderInNonFinalRoundWithBidsSummary: React.FC<{ bid: Bid; proposal: Proposal }> = ({ bid, proposal }) => {
  const isRoundFinished = proposal.status === 'FINISHED';
  return (
    <div className="flex flex-col space-y-3">
      {isRoundFinished && (
        <>
          <StatusMessageForNonFinalBidDisplay bid={bid} />
          <BidFeedbackMessageDisplay feedback={bid.feedback} />
        </>
      )}
      <TraderCreatedNonFinalBidDisplayMessage proposal={proposal} />
      <AccordionItem label="Proposta de Economia" initiallyOpen={false}>
        <BidEconomyProposalContentsDisplay bid={bid} />
      </AccordionItem>
      <AccordionItem label="Condições Comerciais" initiallyOpen={false}>
        <BidCommercialConditionsContentsDisplay bid={bid} />
      </AccordionItem>
      <AccordionItem label="Pagamento e Financeiro" initiallyOpen={false}>
        <BidPaymentContentsDisplay bid={bid} />
      </AccordionItem>
    </div>
  );
};

// Final round
export const TraderInFinalRoundWithBidsSummary: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  return (
    <div className="flex flex-col space-y-4">
      <TraderCreatedFinalBidDisplayMessage proposal={proposal} />
      {proposal.bids.map((bid, index) => (
        <>
          <FinalBidSummaryForTraderAccordionItem
            initiallyOpen={false}
            key={`final-bid-${index}`}
            proposal={proposal}
            bidIndex={index + 1} // FIXME: (22/08) Check this order is from most recent to oldest
            bid={bid}
          />
        </>
      ))}
    </div>
  );
};

interface FinalBidSummaryForTraderAccordionItemProps {
  proposal: Proposal;
  bid: Bid;
  bidIndex: number;
  initiallyOpen?: boolean;
  testId?: string;
}
export const FinalBidSummaryForTraderAccordionItem: React.FC<FinalBidSummaryForTraderAccordionItemProps> = ({
  proposal,
  bid,
  bidIndex,
  initiallyOpen = true,
  testId,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initiallyOpen);
  const iconBaseClass = 'cursor-pointer w-6 h-6 transition-transform ease-linear duration-300';
  const rotateClass = isOpen ? '-rotate-180' : '-rotate-0';
  const iconClass = `${iconBaseClass} ${rotateClass}`;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center py-2 pr-3 pl-6 rounded-lg border border-black bg-neutral-10">
        <div className="flex space-x-3">
          <p className="font-medium text-paragraph-medium">{`Proposta final ${bidIndex}`}</p>
        </div>
        <div className="flex items-center space-x-3">
          <p className="px-2 text-paragraph-small text-neutral-50">{formatDate(bid.createdAt).toUpperCase()}</p>
          <FinalBidForTraderStateBadge proposal={proposal} bid={bid} />
          <button type="button" data-cy={testId} onClick={() => setIsOpen(!isOpen)}>
            <HeroIcon icon="ChevronDownIcon" extraClassNames={iconClass} />
          </button>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition origin-top ease-out duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition origin-top ease-out duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <FinalBidContentsDisplay bid={bid} />
      </Transition>
    </div>
  );
};

export const TraderInFinalRoundWithoutBidsSummary: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  // FIXME: differentiate between first send and re-send
  return <TraderCanCreateFinalBidDisplay proposal={proposal} />;
};
