import React from 'react';
import { Combobox } from '@headlessui/react';
import { FieldPath, RegisterOptions, Controller, useFormContext } from 'react-hook-form';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

interface ComboBoxInputProps<T> {
  field: FieldPath<T>;
  label: string;
  inputOptions: { optionLabel: string; value: string }[];
  placeholder?: string;
  id: string;
  options?: RegisterOptions;
}

export default function ComboBoxInput<T>({
  field,
  id,
  label,
  placeholder,
  inputOptions,
  options,
}: ComboBoxInputProps<T>) {
  const { control } = useFormContext<T>();

  const [query, setQuery] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState<{ optionLabel: string; value: string } | null>(null);

  const filteredOptions =
    query === ''
      ? inputOptions
      : inputOptions.filter((option) => {
          return option.optionLabel.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, ref, onBlur, value, onChange } }) => {
        const selectedValue = inputOptions.find((option) => option.value === value);
        return (
          <Combobox
            as="div"
            value={value}
            //@ts-expect-error :: Could not conciliate react-hook-form and combobox typing
            onChange={(v: { optionLabel: string; value: string }) => {
              onChange(v.value);
              setSelectedOption(v);
            }}
          >
            <Combobox.Label className="mb-2 text-black text-paragraph-medium">
              {label}
              {options?.required && <span className="text-danger-60">*</span>}
            </Combobox.Label>
            <div className="flex relative flex-col">
              <Combobox.Input
                id={id}
                className="border border-black focus:border focus:outline-none rounded-small text-paragraph-medium focus:border-primary-60 focus:ring-primary-60"
                name={name}
                ref={ref}
                data-cy={id}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(option) => {
                  return selectedValue?.optionLabel || '';
                }}
                value={selectedValue?.value || ''}
                onBlur={onBlur}
                placeholder={placeholder}
              />
              <Combobox.Button
                data-cy={`combobox-input-button-${id}`}
                className="flex absolute inset-y-0 right-0 items-center px-4 rounded-r-md focus:outline-none"
              >
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </Combobox.Button>

              {filteredOptions.length > 0 && (
                <Combobox.Options
                  data-cy={`combobox-options-list-${id}`}
                  className="overflow-auto absolute top-8 z-10 py-1 mt-1 w-full max-h-60 text-base bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg sm:text-sm focus:outline-none"
                >
                  {filteredOptions.map((option) => (
                    <Combobox.Option
                      key={option.value}
                      value={option}
                      data-cy={`combobox-option-${id}-${option.value}`}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-primary-60 text-white' : 'text-gray-900',
                        )
                      }
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              selectedOption?.value === option.value && 'font-semibold',
                            )}
                          >
                            {option.optionLabel}
                          </span>

                          {selectedOption?.value === option.value && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-primary-60',
                              )}
                            >
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        );
      }}
    />
  );
}
