import React from 'react';

import { TextAreaFormField } from '@components/atoms/form';
import { useTraderSelectionContext } from '@components/molecules/send-signed-proposal/trader-selection-context';
import { SendSignedProposalFormData } from '@components/molecules/send-signed-proposal/types';

const ThirdStep: React.FC = () => {
  const { refusedTradersFieldArray } = useTraderSelectionContext();

  return (
    <>
      {refusedTradersFieldArray.fields.length > 0 ? (
        <>
          <p className="text-paragraph-medium">
            Infelizmente elas não foram escolhidas, mas merecem saber os porquês. Elabore um feedback caprichado com os
            motivos que elas não foram selecionadas
          </p>
          {refusedTradersFieldArray.fields.map((field, index) => {
            return (
              <TextAreaFormField<SendSignedProposalFormData>
                key={field.id}
                id={`refused-trader-${field.trader.id}-feedback`}
                field={`feedbacksForTradersThatAreRefused.${index}.feedback` as const}
                label={`Feedback ${field.trader.name}`}
                options={{
                  required: { value: true, message: `É necessário definir o feedback para ${field.trader.name}.` },
                }}
              />
            );
          })}
        </>
      ) : (
        <p className="text-paragraph-medium">
          Nenhuma comercializadora foi recusada, portanto não há necessidade de redigir feedbacks.
        </p>
      )}
    </>
  );
};

export default ThirdStep;
