import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import {
  GetGroupDataGraphQLResponse,
  GetGroupListDataGraphQLResponse,
  GET_COMMERCIAL_GROUP_DATA_FOR_PROPOSAL_CREATION,
  GET_COMMERCIAL_GROUP_LIST_DATA_FOR_PROPOSAL_CREATION,
} from './query';
import { EnergyTypeEnum, SubmarketEnum, TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';
import {
  Subgroup,
  UnitConsumptionHistory,
} from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { DocType } from '@hooks/bids/savings/types';
import { parseUnitConsumptionHistory } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/helpers';

export interface UnitForGroup {
  id: string;
  name: string;
  legalName: string;
  docType: 1 | 2;
  docNumber: string;
  energyType: keyof typeof EnergyTypeEnum | null;
  unitType: keyof typeof UnitTypeEnum;
  submarket: keyof typeof SubmarketEnum;
  contractedDemandPeak: number | null;
  contractedDemandOffPeak: number;
  totalConsumptionVolume: number;
  edc: { id: string; state: string; name: string };
  tariffSubgroup: Subgroup;
  tariffModality: keyof typeof TariffModeEnum;
  averageInvoiceAmount: number;
  migrationDate: string;
  powerGenerator: boolean;
  generatorCost: number | null;
  history: UnitConsumptionHistory;
}

export interface GroupDataForProposalCreation {
  id: string;
  name: string;
  legalName: string;
  docType: DocType;
  docNumber: string;
  units: UnitForGroup[];
}

export function useGetGroupDataForProposalCreation(): [
  (groupId: string) => Promise<GroupDataForProposalCreation>,
  boolean,
] {
  const [queryFunction, { loading }] = useLazyQuery<GetGroupDataGraphQLResponse, { groupId: string }>(
    GET_COMMERCIAL_GROUP_DATA_FOR_PROPOSAL_CREATION,
  );

  const getGroupData = async (groupId: string): Promise<GroupDataForProposalCreation> => {
    // NOTE: Why this promise wrapping? For some reason this query doesn't work.
    // See: https://github.com/apollographql/apollo-client/issues/9354#issuecomment-1234952933
    return new Promise((resolve, reject) => {
      return queryFunction({
        fetchPolicy: 'network-only',
        variables: { groupId },
        onCompleted: (data) => {
          if (!data) return reject(new Error(`Não foi possível consultar dados do grupo ${groupId}`));

          const { id, name, legalName, docType, docNumber, units } = data.group;
          return resolve({
            id,
            name,
            legalName,
            docNumber,
            docType,
            units: units.map((unit) => {
              const { __typename, edc, history, ...parsedUnit } = unit;
              const { __typename: _, ...parsedEdc } = edc;
              return { ...parsedUnit, edc: parsedEdc, history: parseUnitConsumptionHistory(history) };
            }),
          });
        },
        onError: reject,
      });
    });
  };

  return [getGroupData, loading];
}

export interface GroupForProposalCreation {
  id: string;
  name: string;
  docType: DocType;
  docNumber: string;
}
export function useGetCommercialGroupList(): [GroupForProposalCreation[], boolean] {
  const [allGroups, setAllGroups] = React.useState<GroupForProposalCreation[]>([]);
  const { loading } = useQuery<GetGroupListDataGraphQLResponse>(GET_COMMERCIAL_GROUP_LIST_DATA_FOR_PROPOSAL_CREATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllGroups(data.groups.data.map(({ id, name, docType, docNumber }) => ({ id, name, docType, docNumber })));
    },
    onError: () => {
      setAllGroups([]);
    },
  });

  return [allGroups, loading];
}
