import React from 'react';
import Skeleton from 'react-loading-skeleton';

import useAuth from '@hooks/auth';

const AccountLogo: React.FC = () => {
  const {
    authStatus: { isAuthenticated },
    user: { pictureURL },
  } = useAuth();

  if (!isAuthenticated) return <Skeleton circle className="w-9 h-9" />;

  return <img className="w-8 h-8 rounded-full" referrerPolicy="no-referrer" src={pictureURL} alt="account avatar" />;
};

const SidebarFooter: React.FC = () => {
  const { logout, user } = useAuth();

  return (
    <div className="border-t border-neutral-20">
      <div className="flex items-center px-4 mt-4">
        <AccountLogo />
        <div className="ml-3">
          <p
            className="font-medium text-paragraph-medium text-neutral-70 group-hover:text-neutral-90"
            data-cy="account-badge-name"
          >{`${user.name}`}</p>
          <p
            className="font-medium cursor-pointer text-paragraph-small text-neutral-50 group-hover:text-neutral-70"
            onClick={() => logout()}
            data-cy="account-logout-link"
          >
            Sair
          </p>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
