import React from 'react';

import { CREATE_PROPOSAL_PATH } from '@routers/constants';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-labels';
import { Button } from '@clarke-energia/foton';
import useAuth from '@hooks/auth';

const NewProposalButton: React.FC = () => {
  return (
    <Button
      kind="primary"
      type="button"
      label="+ Nova Solicitação"
      customAttrs={{ 'data-cy': 'new-proposal-button' }}
      onClick={() => {
        location.href = CREATE_PROPOSAL_PATH;
        eventTracker.trackEvent(eventLabels.ADMIN_START_NEW_PROPOSAL_BUTTON_CLICKED);
      }}
    />
  );
};

export const ListHeader: React.FC = () => {
  const {
    user: { roles },
  } = useAuth();
  const isAdmin = roles?.includes('admin');

  return (
    <div className="flex justify-start items-center mb-10 space-x-5 md:justify-between">
      <h1 data-cy="proposal-list-header" className="font-medium leading-10 text-heading-2xlarge">{`Painel de ${
        isAdmin ? 'Clientes' : 'Propostas'
      }`}</h1>
      {isAdmin && <NewProposalButton />}
    </div>
  );
};
