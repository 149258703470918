import React from 'react';

interface HeaderDefinition {
  stepNumber: number;
  header: string;
}
const openNewRoundStepsHeaders: HeaderDefinition[] = [
  { stepNumber: 1, header: 'Comercializadora escolhidas' },
  {
    stepNumber: 2,
    header: 'Informar as escolhidas',
  },
  {
    stepNumber: 3,
    header: 'Feedback das recusadas',
  },
  {
    stepNumber: 4,
    header: 'Editar informações',
  },
];

const openFinalRoundStepsHeaders: HeaderDefinition[] = [
  { stepNumber: 1, header: 'Comercializadora vencedora' },
  { stepNumber: 2, header: 'Comunique a vencedora' },
  { stepNumber: 3, header: 'Feedback da recusada' },
];

const sendSignedProposalStepsHeaders: HeaderDefinition[] = [
  { stepNumber: 1, header: 'Comercializadora vencedora' },
  { stepNumber: 2, header: 'Comunique a vencedora' },
  { stepNumber: 3, header: 'Feedback da recusada' },
];

type OperationType = 'openNewRound' | 'openFinalRound' | 'sendSignedProposal';

const getHeadersAndLabelForOperation = (operation: OperationType): [HeaderDefinition[], string] => {
  switch (operation) {
    case 'openNewRound':
      return [openNewRoundStepsHeaders, 'ABRIR NOVA RODADA'];

    case 'openFinalRound':
      return [openFinalRoundStepsHeaders, 'CONCLUIR NEGOCIAÇÃO'];

    case 'sendSignedProposal':
      return [sendSignedProposalStepsHeaders, 'PROPOSTA ASSINADA'];

    default:
      throw 'Invalid operation.';
  }
};

interface FormStepHeaderProps {
  currentStep: number;
  operationType: OperationType;
}
const FormStepHeader: React.FC<FormStepHeaderProps> = ({ currentStep, operationType }) => {
  const [headers, label] = getHeadersAndLabelForOperation(operationType);
  const headerDefinition = headers[currentStep - 1];
  return (
    <>
      <div className="space-y-2">
        <h2 className="uppercase text-paragraph-small text-neutral-50">{`PASSO ${currentStep}/${headers.length} • ${label}`}</h2>
        <h1 className="font-bold text-heading-2xlarge">{headerDefinition.header}</h1>
      </div>
    </>
  );
};

export default FormStepHeader;
