import React from 'react';
import { TagProps } from '@clarke-energia/foton';
import { Ranking } from '@hooks/process/queries/get-process-tader-ranking/types';

import Trophy from '@assets/trophy.svg';
import SecondMedal from '@assets/second-medal-icon.svg';
import ThirdMedal from '@assets/third-medal-icon.svg';

import Accordion from '@components/atoms/process-trader-ranking/accordion';
import PricesContent from '@components/atoms/process-trader-ranking/accordion/accordion-content';
import DetailInfo from '@components/atoms/process-trader-ranking/accordion/bid-info';

interface ProcessRankingProps {
  ranking: Ranking[];
}

type RankinGetter = {
  [key: string]: {
    icon?: string;
    tag?: TagProps;
    initalOpen: boolean;
  };
};

const RankingContent = ({ ranking }: ProcessRankingProps): JSX.Element => {
  const rankingGetter: RankinGetter = {
    'key-0': { initalOpen: true, icon: Trophy, tag: { label: 'Melhor preço', color: 'green', kind: 'default' } },
    'key-1': { initalOpen: false, icon: SecondMedal, tag: undefined },
    'key-2': { initalOpen: false, icon: ThirdMedal, tag: undefined },
    'key-3': { initalOpen: false, icon: undefined, tag: undefined },
  };
  const lastItem = ranking.length - 1;

  return (
    <div className="mt-5">
      {ranking.map(({ trader, prices, isInsideScope, position, ...rest }, idx) => {
        const selectorProps = rankingGetter[`key-${idx}` as keyof typeof rankingGetter];
        const traderName = trader.name.includes('Colocado') ? `${position}${trader.name}` : trader.name;

        return (
          <div key={`item-${idx}-${trader.name}`} className="mb-4">
            <Accordion
              key={`item-${idx}-${trader.name}`}
              label={traderName}
              iconPath={isInsideScope ? selectorProps.icon : undefined}
              tag={isInsideScope ? selectorProps.tag : undefined}
              initiallyOpen={selectorProps.initalOpen}
              position={isInsideScope && lastItem === idx ? `${position}º lugar` : undefined}
            >
              <>
                <DetailInfo info={{ ...rest }} />
                <PricesContent prices={prices} />
              </>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default RankingContent;
