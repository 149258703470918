import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useNotification } from '@clarke-energia/foton';

import { formatDateIntoLocalISOString } from '@utils/translators/date';

import { BIDS_LIST_FOR_PROPOSAL_PATH, DOES_NOT_EXIST_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import { getCurrentProposalFromProcess } from '@components/organisms/proposal-round-header';
import CreateProposalForm from '@components/organisms/create-proposal';
import { ProposalFormData, UnitFormData } from '@components/molecules/create-proposal/types';
import { parseProposalFormDataIntoBaseGraphQLInput } from '@components/organisms/create-proposal/form-helpers';
import LoadingPage from '@components/layout/loading-page';

import { useEditProposal } from '@hooks/process/mutations/edit-proposal';
import { useGetProcessAndGroupByProposalId } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id';
import { useCreateUnitDocument, useEditUnit } from '@hooks/proposals/single-proposal/creation/unit';
import { useCreateProposalDocument } from '@hooks/proposals/single-proposal/creation/proposal';
import useAuth from '@hooks/auth';
import { useGetCommercialGroupList } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { useGetGroupDataForProposalCreation } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { Proposal, Unit } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { useAllTraders } from '@hooks/proposals';

const parseUnitIntoFormData = (unit: Unit): UnitFormData => {
  return {
    unitId: unit.id,
    energyType: unit.energyType,
    supplyStartDate:
      unit.supply.startDate === null ? '' : formatDateIntoLocalISOString(unit.supply.startDate).split(' ')[0],
    supplyEndDate: unit.supply.endDate === null ? '' : formatDateIntoLocalISOString(unit.supply.endDate).split(' ')[0],
    files: [],
    name: unit.name,
    companyName: unit.legalName,
    unitType: unit.unitType,
    subgroup: unit.subgroup,
    tariffMode: unit.tariffMode,
    monthlyInvoice: unit.averageInvoiceAmount,
    edc: unit.edc.id,
    totalConsumptionVolume: unit.totalConsumptionVolume,
  };
};

const parseCommissionData = (proposal: Proposal) => {
  const { commissionModality } = proposal;

  return {
    commissionModality,
  };
};

const parseProposalIntoFormData = (proposal: Proposal, processName: string): ProposalFormData => {
  const parsedDate = formatDateIntoLocalISOString(proposal.deadline).split(' ');
  const parsedCommissions = parseCommissionData(proposal);

  return {
    client: {
      id: proposal.group.id,
      name: proposal.group.name,
      docType: proposal.group.docType,
      document: proposal.group.docNumber,
      companyName: proposal.group.legalName,
    },
    contract: {
      process: { name: processName },
      contractType: proposal.contractType,
      lowerFlexibility: proposal.lowerFlexibility,
      upperFlexibility: proposal.upperFlexibility,
      proposalModality: proposal.proposalType,
      proposalDeadline: { date: parsedDate[0], time: parsedDate[1].slice(0, 5) },
      guaranteeType: proposal.guaranteeType,
      ...parsedCommissions,
      traders: proposal.traders.map((trader) => trader.id),
      hasGuaranteeSugestion: proposal.guaranteeType !== 'NO_GUARANTEE' ? 'yes' : 'no',
      guaranteeMonths: (proposal.guaranteeMonths || '') as ProposalFormData['contract']['guaranteeMonths'],
      otherKindGuarantee: proposal.otherGuarantee,
      note: proposal.note,
      supplyStartDate: proposal.supplyStartDate ?? null,
      supplyEndDate: proposal.supplyEndDate ?? null,
      files: [],
    },
    units: proposal.units.map((unit) => parseUnitIntoFormData(unit)),
    selectedUnitIds: proposal.units.map((unit) => unit.id),
  };
};

const EditProposalPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    authStatus: { accessToken },
  } = useAuth();
  const { createNotification } = useNotification();
  const { proposalId } = useParams();
  const [allTraders, isTraderLoading] = useAllTraders();
  const [allGroups, isGroupListLoading] = useGetCommercialGroupList();
  const [fetchGroupData, isFetchingGroupData] = useGetGroupDataForProposalCreation();

  const updateUnit = useEditUnit();
  const createUnitDocument = useCreateUnitDocument();
  const editProposal = useEditProposal();
  const createProposalDocument = useCreateProposalDocument();

  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;
  const { processData, fetchProcessData, isProcessDataLoading } = useGetProcessAndGroupByProposalId();

  React.useEffect(() => {
    if (proposalId) {
      fetchProcessData(proposalId);
    }
  }, [proposalId]);

  const proposal = getCurrentProposalFromProcess(processData, proposalId);
  const isLoading = isTraderLoading || isGroupListLoading || isProcessDataLoading;

  const onSubmit = async (data: ProposalFormData) => {
    const { client, units, contract } = data;

    await Promise.all(
      units.map(async (unit) => {
        await updateUnit(unit, client.id);
        await Promise.all(unit.files.map((file) => createUnitDocument(file, unit.unitId, accessToken)));
      }),
    );

    const payload = { id: proposal.id, ...parseProposalFormDataIntoBaseGraphQLInput(data) };

    try {
      const editedProposal = await editProposal(payload);
      await Promise.all(contract.files.map((file) => createProposalDocument(file, proposal.id, accessToken)));

      navigate(`${VIEW_PROPOSAL_LIST_PATH}/${editedProposal.id}/${BIDS_LIST_FOR_PROPOSAL_PATH}`);
    } catch (error) {
      createNotification({
        kind: 'error',
        label: 'Erro!',
        message: `Não foi possível editar a proposta. Por favor, avise ao suporte técnico, informando o seguinte erro: ${error}`,
      });
      return;
    }
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <CreateProposalForm
      isCreating={false}
      proposal={proposal}
      allTraders={allTraders}
      allGroups={allGroups}
      onFormSubmitCallback={onSubmit}
      initialValues={parseProposalIntoFormData(proposal, processData.name)}
      groupDataFetcher={{ fetchGroupData, isFetchingGroupData }}
    />
  );
};

export default EditProposalPage;
