import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextAreaFormField } from '@components/atoms/form';
import { SendSignedProposalFormData } from '@components/molecules/send-signed-proposal/types';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const SecondStep: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  const { getValues } = useFormContext<SendSignedProposalFormData>();
  const trader = proposal.traders.find((trader) => trader.id === getValues('selectedTrader'));

  return (
    <>
      <p className="text-paragraph-medium">
        Informe que ela foi a comercializadora escolhida para fornecer energia para o cliente! Escreva os motivos que a
        fizeram vencedora.
      </p>
      <TextAreaFormField<SendSignedProposalFormData>
        id={`selected-trader-feedback`}
        field="selectedTraderFeedback"
        label={`Feedback ${trader?.name}`}
        options={{
          required: { value: true, message: `É necessário definir o feedback para ${trader?.name}.` },
        }}
      />
    </>
  );
};

export default SecondStep;
