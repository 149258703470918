import React from 'react';

import { formatDate } from '@utils/text';

import FinalBidForAdminStateBadge from '@components/atoms/proposal-round/admin-final-bid-state-badge';
import NonFinalBidStateBadge from '@components/atoms/proposal-round/non-final-bid-state-badge';

import {
  Proposal,
  ProposalTraderWithBid,
} from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface BidStatusDescriptorProps {
  proposal: Proposal;
  trader: ProposalTraderWithBid;
}
export const BidStatusDescriptor: React.FC<BidStatusDescriptorProps> = ({ trader, proposal }) => {
  const isFinalRound = proposal.round === 0;
  return (
    <>
      {trader.bid && <p className="px-2 text-xs text-gray-500">{formatDate(trader.bid.createdAt).toUpperCase()}</p>}
      {isFinalRound ? (
        <FinalBidForAdminStateBadge proposal={proposal} bid={trader.bid ?? null} />
      ) : (
        <NonFinalBidStateBadge proposal={proposal} bid={trader.bid ?? null} />
      )}
    </>
  );
};
