import React from 'react';
import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { HeroIcon, Tag, TagProps } from '@clarke-energia/foton';
import style from './style.module.css';

export interface AccordionProps {
  children?: React.ReactNode;
  label: string;
  tag?: TagProps;
  initiallyOpen?: boolean;
  customAttrs?: Record<string, string>;
  iconPath?: string;
  position?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  tag,
  label,
  initiallyOpen = false,
  customAttrs,
  iconPath,
  position,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initiallyOpen);
  const rotateClass = isOpen ? style.rotate : style.notRotate;
  const colorClass = isOpen ? style.accordionOpen : style.accordionClose;

  return (
    <div className="flex flex-col" {...customAttrs}>
      <button onClick={() => setIsOpen(!isOpen)} className={`${style.mainAccordion} ${colorClass}`}>
        <div className={style.accordionDescriptionContainer}>
          {iconPath ? (
            <div className="mr-4">
              <img src={iconPath} />
            </div>
          ) : (
            <div className={twMerge(position === undefined ? '' : style.position)}>{position}</div>
          )}
          <p className={style.descriptionText}>{label}</p>
          <div className={style.iconTagsContainer}>
            {tag ? <Tag {...tag} /> : null}
            <HeroIcon icon="ChevronDownIcon" extraClassNames={`${style.iconBaseClass} ${rotateClass}`} />
          </div>
        </div>
      </button>
      <Transition
        show={isOpen}
        enter={style.transitionEnter}
        enterFrom={style.opacityZero}
        enterTo={style.opacityFull}
        leave={style.transitionEnter}
        leaveFrom={style.opacityFull}
        leaveTo={style.opacityZero}
      >
        <div className={style.accordionContent}>{children}</div>
      </Transition>
    </div>
  );
};

export default Accordion;
