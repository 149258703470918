import React from 'react';

import { Unit } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { capitalize, formatNumber } from '@utils/text';
import { MonthTranslationMapper } from '@utils/translators/proposal';

interface ConsumptionHistoryTableRowProps {
  month: keyof Unit['history'];
  peak: number;
  offPeak: number;
  generator: number;
}
const ConsumptionHistoryTableRow: React.FC<ConsumptionHistoryTableRowProps> = ({ month, peak, offPeak, generator }) => {
  const total = peak + offPeak + generator;
  return (
    <tr>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">
        {capitalize(MonthTranslationMapper[month])}
      </td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        generator / 1000,
      )}`}</td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        peak / 1000,
      )}`}</td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        offPeak / 1000,
      )}`}</td>
      <td className="py-2 w-1/5 text-center border-b border-black text-paragraph-medium">{`${formatNumber(
        total / 1000,
      )}`}</td>
    </tr>
  );
};

const sumYearTotalForConsumption = (history: Unit['history']) => {
  return Object.values(history).reduce(
    (acc, entry) => ({
      peak: (acc.peak || 0) + (entry.peak || 0),
      offPeak: (acc.offPeak || 0) + (entry.offPeak || 0),
      generator: (acc.generator || 0) + (entry.generator || 0),
    }),
    { peak: 0, offPeak: 0, generator: 0 },
  );
};

export const UnitHistoryTable: React.FC<{ history: Unit['history'] }> = ({ history }) => {
  const { peak, offPeak, generator } = sumYearTotalForConsumption(history);
  const totalEnergyInKiloWattsHour = peak + offPeak + generator;
  return (
    <div className="flex flex-col space-y-4">
      <p className="text-paragraph-medium">Histórico / Projeção de Consumo</p>
      <table className="table-auto">
        <thead>
          <tr>
            <th className="py-2 w-1/5 font-normal text-center border-black text-paragraph-small text-neutral-50 border-y">
              Mês
            </th>
            <th className="py-2 w-1/5 font-normal text-center border-black text-paragraph-small text-neutral-50 border-y">
              Gerador (MWh)
            </th>
            <th className="py-2 w-1/5 font-normal text-center border-black text-paragraph-small text-neutral-50 border-y">
              Energia Ponta (MWh)
            </th>
            <th className="py-2 w-1/5 font-normal text-center border-black text-paragraph-small text-neutral-50 border-y">
              Energia Fora Ponta (MWh)
            </th>
            <th className="py-2 w-1/5 font-normal text-center border-black text-paragraph-small text-neutral-50 border-y">
              Total (MWh)
            </th>
          </tr>
        </thead>
        <tbody>
          {(Object.keys(history) as (keyof Unit['history'])[]).map((month, index) => (
            <ConsumptionHistoryTableRow key={index} month={month} {...history[month]} />
          ))}
          <tr>
            <td className="py-2 w-1/5 border-b border-black"></td>
            <td className="py-2 w-1/5 border-b border-black"></td>
            <td className="py-2 w-1/5 border-b border-black"></td>
            <td className="py-2 w-1/5 border-b border-black">
              <div className="flex flex-col">
                <p className="text-right text-paragraph-small text-neutral-50">Consumo Total (MWh)</p>
                <p className="text-right text-paragraph-medium">{`${formatNumber(totalEnergyInKiloWattsHour / 1000, {
                  style: 'decimal',
                  useGrouping: true,
                  maximumFractionDigits: 0,
                })}`}</p>
              </div>
            </td>
            <td className="py-2 w-1/5 border-b border-black">
              <p className="text-right text-paragraph-small text-neutral-50">Consumo Total (MWm)</p>
              <p className="text-right text-paragraph-medium">{`${formatNumber(
                totalEnergyInKiloWattsHour / 1000 / 24 / 365,
                {
                  style: 'decimal',
                  useGrouping: true,
                  maximumFractionDigits: 3,
                },
              )}`}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
