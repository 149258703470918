import React from 'react';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface ProposalFormContext {
  isCreating: boolean;
  proposal?: Proposal;
}
const proposalFormContext = React.createContext<ProposalFormContext>({} as ProposalFormContext);

export const useProposalForm = () => React.useContext(proposalFormContext);

interface ProposalFormProviderProps {
  children: React.ReactNode;
  isCreating: boolean;
  proposal?: Proposal;
}
const ProposalFormProvider: React.FC<ProposalFormProviderProps> = ({ children, isCreating, proposal }) => {
  return <proposalFormContext.Provider value={{ isCreating, proposal }}>{children}</proposalFormContext.Provider>;
};

export default ProposalFormProvider;
