import configuration from '@config';
import errorTracker from '@services/error-tracking';
import axios from 'axios';

export interface ConsumptionRecords {
  '1': number;
  '2': number;
  '3': number;
  '4': number;
  '5': number;
  '6': number;
  '7': number;
  '8': number;
  '9': number;
  '10': number;
  '11': number;
  '12': number;
}

export interface ManualValueInputs {
  [targetYears: string]: { [year: string]: number };
}

export interface ValuePerYearMapper {
  [year: string]: number;
}

export interface UnitCalculationInputs {
  tariff: number;
  nickname: string;
  migration_date: string;
  power_generator_cost: number;
  energy_distribution_company: number;
  acl_contracted_demand_peak: number | null;
  acr_contracted_demand_peak: number | null;
  acl_contracted_demand_off_peak: number;
  acr_contracted_demand_off_peak: number;
  manual_acl_contracted_demand_off_peak?: ValuePerYearMapper;
  manual_acr_contracted_demand_off_peak?: ValuePerYearMapper;
  manual_acl_contracted_demand_peak?: ValuePerYearMapper;
  manual_acr_contracted_demand_peak?: ValuePerYearMapper;
  consumption_peak: ConsumptionRecords;
  consumption_off_peak: ConsumptionRecords;
  manual_consumption_off_peak?: ValuePerYearMapper;
  manual_consumption_peak?: ValuePerYearMapper;
  management_price?: number;
}

export interface CalculationInputs {
  acl_type: 'FIXED_PRICE' | 'GUARANTEED_DISCOUNT';
  target_years: number[];
  product_type: 'ACL_RETAILER' | 'ACL_WHOLESALER';
  calculation_type: 'month_to_month';
  commercial_group: string;
  custom_taxes?: { ess_eer: number; modulation: number };
  manual_acl_price?: ManualValueInputs;
  manual_discount_inputs?: ManualValueInputs;
  units: UnitCalculationInputs[];
  manual_acl_prices_has_charges_coverage: boolean;
}

export interface RequestSavingsCalculationPayload {
  calculation_inputs: CalculationInputs;
  bid_id: string;
  is_inside_scope: boolean;
  retail_commission: number | null;
  wholesale_commission: number | null;
  commission_per_year: {
    year: number;
    commission_value: number;
    commission_modality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
  }[];
  is_base_price_type: boolean;
}

export async function requestSavingsCalculation(payload: RequestSavingsCalculationPayload, authToken: string) {
  const url = configuration.SAVINGS_ROUTE;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  };

  try {
    const response = await axios.post(url, payload, { headers });

    if (!response.status) throw new Error('Queue service did not accept the payload.');
  } catch (error) {
    errorTracker.captureException(error as Error, { name: 'Request Savings Calculation Failure', values: { payload } });
  }
}
