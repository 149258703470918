import React from 'react';

import { Bid, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { formatDate } from '@utils/text';

type NonFinalBidState = 'chosen' | 'refused' | 'late' | 'received' | 'proposalDeadlineAboutToExpire';

const badgeColorScheme = {
  success: { text: 'text-primary-90', background: 'bg-primary-10' },
  waiting: { text: 'text-warning-80', background: 'bg-warning-10' },
  alert: { text: 'text-danger-60', background: 'bg-danger-10' },
};

const getNonFinalBidBadgeState = (
  proposalStatus: Proposal['status'],
  proposalDeadline: Date,
  bid: Bid | null,
): [NonFinalBidState, keyof typeof badgeColorScheme] => {
  const now = Date.now();

  if (bid === null) {
    // no bid made, either late or in progress
    if (proposalStatus === 'FINISHED' || proposalDeadline.getTime() <= now) return ['late', 'waiting'];

    return ['proposalDeadlineAboutToExpire', 'alert'];
  }

  if (proposalStatus === 'ACTIVE') return ['received', 'success'];

  /* NOTE: even though this function checks for non-final bids only,
   * there might be cases (e.g. round 2) where a non-final round might
   * have a final status. This is only a "just-in-case".
   * */
  if (['STAGE_REFUSED', 'FINAL_REFUSED'].includes(bid.status)) return ['refused', 'alert'];

  return ['chosen', 'success'];
};

const staticLabels: Record<Exclude<NonFinalBidState, 'proposalDeadlineAboutToExpire'>, string> = {
  late: 'Atrasada',
  received: 'Recebida',
  refused: 'Recusada',
  chosen: 'Escolhida',
};

const getNonFinalBidBadgeLabel = (status: NonFinalBidState, proposalDeadline: Date): string => {
  if (status !== 'proposalDeadlineAboutToExpire') return staticLabels[status];

  return formatDate(proposalDeadline).toUpperCase();
};

const NonFinalBidStateBadge: React.FC<{ proposal: Proposal; bid: Bid | null }> = ({ proposal, bid }) => {
  const [bidStatus, colorScheme] = getNonFinalBidBadgeState(proposal.status, proposal.deadline, bid ? bid : null);
  const label = getNonFinalBidBadgeLabel(bidStatus, proposal.deadline);
  const colorClass = badgeColorScheme[colorScheme];
  return (
    <div className={`py-1 px-4 rounded-full ${colorClass.background}`}>
      <p className={`text-paragraph-medium whitespace-nowrap  ${colorClass.text}`}>{label}</p>
    </div>
  );
};

export default NonFinalBidStateBadge;
