import React from 'react';
import { useNavigate } from 'react-router-dom';

import MarketplaceRouter from '@routers/marketplace-router';
import { NotificationProvider } from '@clarke-energia/foton';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import configuration from '@config';
import ApolloWrapper from './ApolloWrapper';

function App() {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  // @ts-expect-error :: Cypress is not defined in window
  const isTesting = Boolean(window.Cypress);
  const cacheLocation = isTesting ? 'localstorage' : 'memory';
  const scope = isTesting ? undefined : 'read:current_user';

  return (
    <Auth0Provider
      {...{
        domain: configuration.AUTH0_DOMAIN,
        clientId: configuration.AUTH0_CLIENT_ID,
        audience: `https://${configuration.AUTH0_DOMAIN}/api/v2/`,
        useRefreshTokens: true,
        cacheLocation,
        redirectUri: window.location.origin,
        scope,
        onRedirectCallback,
      }}
    >
      <NotificationProvider>
        <ApolloWrapper>
          <MarketplaceRouter />
        </ApolloWrapper>
      </NotificationProvider>
    </Auth0Provider>
  );
}

export default App;
