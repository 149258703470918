import React from 'react';
import { Popover } from '@headlessui/react';

import HeroIcon from '@components/atoms/hero-icon';
import RoundActionButton from '@components/atoms/proposal-round/round-action-button';
import {
  BIDS_SENT_BY_TRADER_PATH,
  EDIT_BID_PATH_SUFFIX_PATH,
  EDIT_PROPOSAL_SUFFIX_PATH,
  FINISH_ROUND_PATH,
  VIEW_BID_LIST_PATH,
  VIEW_PROPOSAL_LIST_PATH,
} from '@routers/constants';
import { useSendSignedFinalProposalContext } from '@components/molecules/send-signed-final-proposal/context';
import eventTracker from '@services/event-tracking';
import { eventLabels } from '@services/event-tracking/events-labels';
import useAuth from '@hooks/auth';
import { UserType } from '@utils/constants/common';
import { Button, useNotification } from '@clarke-energia/foton';
import { useDeleteBid } from '@hooks/bids/delete-bid';
import { useDeleteDocument } from '@hooks/documents';
import { useNavigate } from 'react-router-dom';
import { ConfirmSubmissionDialog } from '@components/molecules/confirm-form-submission-dialog';
import { useTrader } from '@routers/trader-route';
import { Bid, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

export interface RoundActionButtonProps {
  urlBuilderFunction: (proposal: Proposal) => string;
  checkIfShouldBeDisplayed: (proposal: Proposal) => boolean;
  label: string;
  eventToBeTracked: keyof typeof eventLabels;
}

interface RoundBaseActionsProps {
  proposal: Proposal;
  buttonDefinitionList: RoundActionButtonProps[];
}

const RoundBaseActions: React.FC<RoundBaseActionsProps> = ({ proposal, buttonDefinitionList }) => {
  const {
    user: { roles },
  } = useAuth();
  const isAdmin = roles?.includes('admin');

  return (
    <>
      {buttonDefinitionList
        .filter((button) => button.checkIfShouldBeDisplayed(proposal))
        .map(({ label, urlBuilderFunction, eventToBeTracked }) => (
          <RoundActionButton
            key={label}
            url={urlBuilderFunction(proposal)}
            label={label}
            onClick={() =>
              eventTracker.trackEvent(eventLabels[eventToBeTracked], {
                'userType:': isAdmin ? UserType.admin : UserType.trader,
              })
            }
          />
        ))}
    </>
  );
};

interface PopoverContainerProps {
  children: React.ReactNode;
}
const PopoverContainer: React.FC<PopoverContainerProps> = ({ children }) => {
  return (
    <Popover className="relative">
      <Popover.Button className="py-1.5 px-4 rounded-full border border-black">
        <HeroIcon icon="DotsHorizontalIcon" extraClassNames="w-4 h-4" />
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-10">
        <div className="flex flex-col p-8 bg-white border border-black">
          <div className="flex flex-col space-y-2">{children}</div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

const AdminNonFinalRoundActions: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  return (
    <>
      <Button
        kind="secondary"
        type="button"
        label="Editar"
        customAttrs={{ 'data-cy': 'edit-proposal-button' }}
        icon="PencilIcon"
        onClick={() => {
          location.href = `${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${EDIT_PROPOSAL_SUFFIX_PATH}`;
          eventTracker.trackEvent(eventLabels.DETAILED_PROPOSAL_PAGE_EDIT_PROPOSAL_BUTTON_CLICKED, {
            userType: 'admin',
          });
        }}
      />
      <Button
        kind="primary"
        type="button"
        label="Finalizar Etapa"
        customAttrs={{ 'data-cy': 'go-to-finish-round-operations-link' }}
        icon="LockClosedIcon"
        onClick={() => {
          location.href = `${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${FINISH_ROUND_PATH}`;
          eventTracker.trackEvent(eventLabels.DETAILED_PROPOSAL_PAGE_CLOSE_ROUND_BUTTON_CLICKED, { userType: 'admin' });
        }}
      />
    </>
  );
};

const AdminFinalRoundActions: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  const { openModalDialogCallback } = useSendSignedFinalProposalContext();
  const now = Date.now();
  const proposalHasBids = proposal.bids.length > 0;
  const proposalHasExpiredBids = proposal.bids.some((bid) => bid.deadline.getTime() <= now);
  const proposalIsActive = proposal.status === 'ACTIVE';

  const isResendEnabled = proposalHasBids && proposalHasExpiredBids && proposalIsActive;
  const isSendSignedFinalProposalEnabled = proposalIsActive;

  return (
    <>
      <Button
        kind="secondary"
        type="button"
        label="Reenvio de proposta"
        icon="PencilIcon"
        disabled={!isResendEnabled}
      />
      <Button
        kind="primary"
        type="button"
        label="Proposta assinada"
        icon="ClipboardDocumentCheckIcon"
        customAttrs={{ 'data-cy': 'send-signed-final-proposal-button' }}
        disabled={!isSendSignedFinalProposalEnabled}
        onClick={() => openModalDialogCallback()}
      />
    </>
  );
};

interface AdminRoundActionsProps {
  proposal: Proposal;
}
const AdminRoundActions: React.FC<AdminRoundActionsProps> = ({ proposal }) => {
  const isProposalInFinalRound = proposal.round === 0;

  return (
    <>
      {isProposalInFinalRound ? (
        <AdminFinalRoundActions proposal={proposal} />
      ) : (
        <AdminNonFinalRoundActions proposal={proposal} />
      )}
    </>
  );
};

interface RoundAdminActionsBarProps {
  proposal: Proposal;
  adminActionsButtonList: RoundActionButtonProps[];
}

export const RoundAdminActionsBar: React.FC<RoundAdminActionsBarProps> = ({ proposal, adminActionsButtonList }) => {
  const isProposalActive = proposal.status === 'ACTIVE';

  return (
    <div className="flex justify-between items-center space-x-1">
      <div className="flex overflow-x-auto space-x-2">
        <RoundBaseActions {...{ proposal, buttonDefinitionList: adminActionsButtonList }} />
      </div>

      {isProposalActive && (
        <>
          <div className="hidden items-center space-x-2 md:flex">
            <AdminRoundActions proposal={proposal} />
          </div>

          <div className="block md:hidden">
            <PopoverContainer>
              <AdminRoundActions proposal={proposal} />
            </PopoverContainer>
          </div>
        </>
      )}
    </div>
  );
};

interface EditBidButtonProps {
  proposal: Pick<Proposal, 'id' | 'status' | 'deadline'>;
  bid: Pick<Bid, 'id'>;
}

const EditBidButton: React.FC<EditBidButtonProps> = ({ bid, proposal }) => {
  const navigate = useNavigate();
  const isBidPastDeadline = proposal.deadline.getTime() <= Date.now();
  const isProposalActive = proposal.status === 'ACTIVE';
  const isBidAllowedToBeEdited = isProposalActive && !isBidPastDeadline;

  return (
    <>
      {isBidAllowedToBeEdited && (
        <Button
          kind="secondary"
          type="button"
          label="Editar"
          customAttrs={{ 'data-cy': 'edit-bid-button' }}
          icon="PencilIcon"
          disabled={!isBidAllowedToBeEdited}
          onClick={() => navigate(`${VIEW_BID_LIST_PATH}/${proposal.id}/${EDIT_BID_PATH_SUFFIX_PATH}/${bid.id}`)}
        />
      )}
    </>
  );
};

// TODO: Refactor between admin and trader files
interface DeleteBidButtonProps {
  proposal: Pick<Proposal, 'id' | 'status' | 'deadline'>;
  bid: Bid;
}

const DeleteBidButton: React.FC<DeleteBidButtonProps> = ({ bid, proposal }) => {
  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const deleteBid = useDeleteBid();
  const deleteDocument = useDeleteDocument();
  const [isConfirmSubmitDialogOpen, setIsConfirmSubmitDialogOpen] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const isBidPastDeadline = proposal.deadline.getTime() <= Date.now();
  const isProposalActive = proposal.status === 'ACTIVE';
  const isBidAllowedToBeDeleted = isProposalActive && !isBidPastDeadline && !isDeleting;

  return (
    <>
      <Button
        kind="secondary"
        type="button"
        label="Excluir"
        customAttrs={{ 'data-cy': 'delete-bid-button' }}
        icon="TrashIcon"
        disabled={!isBidAllowedToBeDeleted}
        loading={isDeleting}
        onClick={async () => setIsConfirmSubmitDialogOpen(true)}
      />

      <ConfirmSubmissionDialog
        displayOptions={{
          titleText: 'Deseja mesmo excluir a proposta?',
          buttonSubmitText: 'Excluir',
        }}
        isOpen={isConfirmSubmitDialogOpen}
        confirmDialogCallback={async () => {
          setIsDeleting(true);
          if (bid.documents.length > 0) await Promise.all(bid.documents.map((document) => deleteDocument(document.id)));
          const result = await deleteBid(bid.id);

          if (result) {
            navigate(`${VIEW_BID_LIST_PATH}/${proposal.id}/${BIDS_SENT_BY_TRADER_PATH}`, {
              state: { deletedBid: true },
            });
          } else {
            createNotification({
              kind: 'error',
              label: 'Erro!',
              message:
                'Houve um erro ao tentar excluir essa proposta. Por favor, nos comunique via nossos canais de atendimento!',
            });
          }
          setIsConfirmSubmitDialogOpen(false);
        }}
        closeDialogCallback={() => setIsConfirmSubmitDialogOpen(false)}
      />
    </>
  );
};

interface TraderRoundActionsProps {
  proposal: Proposal;
}
const TraderRoundActions: React.FC<TraderRoundActionsProps> = ({ proposal }) => {
  const { authStatus } = useAuth();
  const traderContext = useTrader();

  const trader = traderContext.traderData.id;

  const isComponentAllowedToDisplay =
    traderContext.isTraderLoaded && !traderContext.loading && authStatus.isAuthenticated;

  const bid = proposal.bids.find((bid) => bid.trader.id === trader);
  const traderHasBid = bid !== undefined;

  return isComponentAllowedToDisplay ? (
    <>
      {traderHasBid && (
        <div className="flex space-x-2">
          <EditBidButton {...{ bid, proposal }} />
          <DeleteBidButton {...{ bid, proposal }} />
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

interface RoundTraderActionsBarProps {
  proposal: Proposal;
  traderActionsButtonList: RoundActionButtonProps[];
}

export const RoundTraderActionsBar: React.FC<RoundTraderActionsBarProps> = ({ proposal, traderActionsButtonList }) => {
  const isProposalActive = proposal.status === 'ACTIVE';
  return (
    <div className="flex justify-between">
      <div className="flex space-x-2">
        <RoundBaseActions {...{ proposal, buttonDefinitionList: traderActionsButtonList }} />
      </div>

      {isProposalActive && <TraderRoundActions proposal={proposal} />}
    </div>
  );
};
