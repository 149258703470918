import React from 'react';
import { DeepPartial, FieldPath, FormProvider, useForm, UseFormGetValues } from 'react-hook-form';

import { FormHeader } from '@components/atoms/form/form-header';
import ContentLayoutContainer from '@components/layout/content-layout-container';
import { NavigationControls } from '@components/molecules/form-steps-navigation-control';
import { BidFormData } from '@components/molecules/create-bid/types';
import FirstStep from '@components/organisms/create-bid/first-step';
import SecondStep from '@components/organisms/create-bid/second-step';
import ThirdStep from '@components/organisms/create-bid/third-step';
import FourthStep from '@components/organisms/create-bid/fourth-step';

const renderStepElement = (
  currentStep: number,
  contractDuration: { start: Date; end: Date },
  commissionModality: string,
) => {
  switch (currentStep) {
    case 1: {
      return <FirstStep {...{ supply: contractDuration }} />;
    }
    case 2:
      return <SecondStep commissionModality={commissionModality} />;

    case 3:
      return <ThirdStep />;

    case 4:
      return <FourthStep />;

    default:
      // TODO: place an error boundary wrapping this case
      throw new Error('Invalid step number');
  }
};

const getFieldsToBeValidated = (
  step: number,
  formValuesGetterFunction: UseFormGetValues<BidFormData>,
): FieldPath<BidFormData>[] => {
  const result: FieldPath<BidFormData>[] = [];

  switch (step) {
    case 1: {
      const values = formValuesGetterFunction('productAndPrices.prices.values');
      values.forEach((_, index) => {
        result.push(`productAndPrices.prices.values.${index}.amount` as const);
      });
      return ['productAndPrices.contractType', ...result];
    }
    case 2: {
      const coverMeterSystem = formValuesGetterFunction('commercialConditions.coverMeterSystem');
      if (coverMeterSystem === 'YES') {
        result.push('commercialConditions.meterSystemAmount');
      }

      return [
        'commercialConditions.lowerFlexibility',
        'commercialConditions.upperFlexibility',
        'commercialConditions.traderType',
        'commercialConditions.coverCceeCost',
        'commercialConditions.coverCceeTaxes',
        'commercialConditions.coverMeterSystem',
        'commercialConditions.bidDeadline.date',
        'commercialConditions.bidDeadline.time',
        ...result,
      ];
    }
    case 3: {
      const guaranteeType = formValuesGetterFunction('commercialConditions.guaranteeType');
      const hasToSelectBillingMonths = [
        'SURETY_BOND',
        'ADVANCE_PAYMENT',
        'BANK_GUARANTEE',
        'GUARANTEE_LETTER',
      ].includes(guaranteeType);
      if (hasToSelectBillingMonths) result.push('commercialConditions.guaranteeMonths');

      const hasToFillOtherKindOfGuarantee = guaranteeType === 'OTHER';
      if (hasToFillOtherKindOfGuarantee) result.push('commercialConditions.otherKindGuarantee');

      return [
        'commercialConditions.guaranteeType',
        'commercialConditions.payDay',
        'commercialConditions.clarkeCommissionType',
        ...result,
      ];
    }
    default:
      throw 'Invalid step number';
  }
};

const headers = ['Produto e preços', 'Condições comerciais', 'Pagamento e financeiro', 'Revisão da proposta'];
interface BidFormProps {
  contractDuration: { start: Date; end: Date };
  commissionModality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
  initialValues: DeepPartial<BidFormData>;
  callbacks: {
    submitForm: (data: BidFormData) => Promise<void>;
    cancelForm: () => void;
  };
}
const BidForm: React.FC<BidFormProps> = ({ contractDuration, commissionModality, initialValues, callbacks }) => {
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const methods = useForm<BidFormData>({ defaultValues: initialValues });

  const onStepChangeCallback = (nextStepValue: number) => {
    if (nextStepValue > currentStep) {
      const fields = getFieldsToBeValidated(currentStep, methods.getValues);
      methods.trigger(fields).then((fieldsAreValid) => {
        if (fieldsAreValid) setCurrentStep(nextStepValue);
      });
    } else {
      setCurrentStep(nextStepValue);
    }
  };

  const onSubmitCallback = async () => {
    await methods.handleSubmit(callbacks.submitForm)();
  };

  return (
    <ContentLayoutContainer className="overflow-y-auto h-screen">
      <FormProvider {...methods}>
        <form>
          <FormHeader headers={headers} activeHeaderNum={currentStep} />
          <div className="space-y-8">
            {renderStepElement(currentStep, contractDuration, commissionModality)}
            <NavigationControls
              currentStep={currentStep}
              controlParams={{
                maxStep: 4,
                textLabels: {
                  changeStep: 'Próximo passo',
                  endStep: 'Enviar proposta',
                },
                cancelFormCallback: callbacks.cancelForm,
                changeStepCallback: onStepChangeCallback,
                endStepCallback: onSubmitCallback,
              }}
            />
          </div>
        </form>
      </FormProvider>
    </ContentLayoutContainer>
  );
};

export default BidForm;
