import { gql, useMutation } from '@apollo/client';
import { CreateProposalGraphQLInput } from '@hooks/proposals/single-proposal/creation/mutation';

const START_NEW_PROCESS = gql`
  mutation StartNewProcess($processInput: StartNewProcessInput!, $proposalInput: CreateProposalInput!) {
    startNewProcess(processInput: $processInput, proposalInput: $proposalInput) {
      error
      process {
        id
        proposals {
          id
        }
      }
    }
  }
`;

interface StartNewProcessGraphQLResponse {
  startNewProcess: { error: string | null; process: { id: string; proposals: { id: string }[] } | null };
}

interface StartNewProcessGraphQLInput {
  processInput: {
    name: string;
    groupId: string;
  };
  proposalInput: CreateProposalGraphQLInput['createProposalInput'];
}

export function useStartNewProcess() {
  const [mutationFunction] = useMutation<StartNewProcessGraphQLResponse, StartNewProcessGraphQLInput>(
    START_NEW_PROCESS,
  );

  const startNewProcess = async ({
    processInput,
    proposalInput,
  }: StartNewProcessGraphQLInput): Promise<{ id: string; proposalId: string }> => {
    const { data, errors } = await mutationFunction({ variables: { processInput, proposalInput } });

    if (errors !== undefined || !data || data.startNewProcess.error !== null || data.startNewProcess.process === null)
      throw new Error('Falha ao começar processo.');

    const result = data.startNewProcess.process;
    return { id: result.id, proposalId: result.proposals[0].id };
  };

  return startNewProcess;
}
