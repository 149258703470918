import React from 'react';
import { MenuIcon } from '@heroicons/react/outline';

interface OpenButtonProps {
  openSidebar: () => void;
}
const OpenButton: React.FC<OpenButtonProps> = ({ openSidebar }) => {
  return (
    <button
      type="submit"
      className="inline-flex justify-center items-center -mt-0.5 -ml-0.5 w-12 h-12 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-500 focus:outline-none text-neutral-50 hover:text-neutral-90"
      onClick={openSidebar}
    >
      <span className="sr-only">Abrir menu lateral</span>
      <MenuIcon className="w-6 h-6" aria-hidden="true" />
    </button>
  );
};

export default OpenButton;
