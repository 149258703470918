import { gql } from '@apollo/client';

import { Subgroup } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import {
  CommissionModalityTypeEnum,
  ContractTypeEnum,
  EnergyTypeEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
  TariffModeEnum,
  UnitTypeEnum,
} from '@utils/translators/proposal';

export const UPSERT_UNIT_MUTATION = gql`
  mutation UpsertUnit($upsertUnitInput: CreateUnitInput!) {
    createUnit(input: $upsertUnitInput) {
      id
    }
  }
`;

export interface EditUnitGraphQLInput {
  id: string;
  groupId: string;
  name: string;
  legalName: string;
  unitType: keyof typeof UnitTypeEnum;
  tariffSubgroup: Subgroup;
  tariffModality: keyof typeof TariffModeEnum;
  averageInvoiceAmount: number;
  edc: number;
  energyType: keyof typeof EnergyTypeEnum;
}

export interface UpsertUnitGraphQLResponse {
  createUnit: {
    id: string;
  };
}

export const CREATE_PROPOSAL = gql`
  mutation CreateProposal($createProposalInput: CreateProposalInput!) {
    createProposal(input: $createProposalInput) {
      id
    }
  }
`;

export interface UpsertProposalGraphQLResponse {
  createProposal: { id: string };
}

interface CommissionAmount {
  period: number;
  amount: number;
}

interface CreateProposalInput {
  round: number;
  traders: string[];
  units: { id: string; startDate: string | null; endDate: string | null; totalConsumptionVolume: number }[];
  groupId: string;
  proposalType: keyof typeof ProposalTypeEnum;
  contractType: keyof typeof ContractTypeEnum;
  periods: number[];
  deadline: string;
  guaranteeType: Omit<keyof typeof GuaranteeTypeEnum, 'NO_GUARANTEE'> | null;
  guaranteeMonths: number | null;
  otherGuarantee: string | null;
  lowerFlexibility: number;
  upperFlexibility: number;
  commissionModality: keyof typeof CommissionModalityTypeEnum;
  note?: string;
}

export interface CreateProposalGraphQLInput {
  createProposalInput: CreateProposalInput;
}

export const UPDATE_PROPOSAL_STATUS = gql`
  mutation UpdateProposalStatus($proposalId: ID!, $updateProposalInput: UpdateProposalInput!) {
    updateProposal(id: $proposalId, input: $updateProposalInput) {
      id
    }
  }
`;

export interface UpdateProposalStatusGraphQLResponse {
  updateProposal: { id: string };
}

export interface UpdateProposalStatusGraphQLInput {
  proposalId: string;
  updateProposalInput: { status: number };
}

export const EDIT_PROPOSAL = gql`
  mutation EditProposal($editProposalInput: CreateProposalInput!) {
    createProposal(input: $editProposalInput) {
      id
    }
  }
`;

interface EditProposalInput extends CreateProposalInput {
  id: string;
}

export interface EditProposalGraphQLInput {
  editProposalInput: EditProposalInput;
}
