import React from 'react';
import { Dialog, Transition } from '@headlessui/react';

import useAuth from '@hooks/auth';

import ClarkeLogoWhite from '@assets/clarke-logo-white.svg';
import OpenButton from '@components/atoms/sidebar/mobile-open-button';
import CloseButton from '@components/atoms/sidebar/mobile-close-button';
import MobileNavigationLinks from '@components/atoms/sidebar/mobile-navigation-links';
import SidebarSkeleton from '@components/atoms/sidebar/skeleton';
import SidebarFooter from '@components/atoms/sidebar/footer';
import CompanyLogo from '@components/atoms/sidebar/company-logo';
import NavigationLinks from '@components/atoms/sidebar/navigation-links';

const SideBar: React.FC = () => {
  const {
    authStatus: { isAuthenticated },
  } = useAuth();

  if (!isAuthenticated) return <SidebarSkeleton />;

  return (
    <>
      <CompanyLogo />
      <NavigationLinks />
      <SidebarFooter />
    </>
  );
};

export const MobileSidebar: React.FC = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = React.useState<boolean>(false);
  const closeSidebar = () => setIsMobileSidebarOpen(false);
  const openSidebar = () => setIsMobileSidebarOpen(true);
  return (
    <div className="flex mx-auto">
      <OpenButton openSidebar={openSidebar} />

      <Transition show={isMobileSidebarOpen} as={React.Fragment}>
        <Dialog open={isMobileSidebarOpen} onClose={closeSidebar} className="relative z-50">
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 opacity-75 bg-neutral-60" aria-hidden="true" />
          </Transition.Child>
          <div className="flex fixed inset-y-0 p-l-0 max-w-[75%] w-full">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="flex relative flex-col flex-1 px-4 space-y-4 bg-primary-70">
                <CloseButton closeSideBar={closeSidebar} />
                <img className="w-6 h-6" src={ClarkeLogoWhite} alt="Clarke logo" />
                <MobileNavigationLinks />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SideBar;
