import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ptbr from 'dayjs/locale/pt-br';
import parse from 'html-react-parser';

dayjs.extend(customParseFormat);

/**
 * PT-BR configured dayjs module instance.
 * */
export const $dayjs = dayjs;

export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const parseStringToDate = (str: string): Date =>
  dayjs(str, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD'], true).toDate();

export const formatDate = (date: Date, template = 'DD MMM YYYY') => dayjs(date).locale(ptbr).format(template);

export const formatDateFromString = (dateStr: string, inputFormat: string, outputFormat = 'DD/MM/YYYY, HH:mm') =>
  dayjs(dateStr, inputFormat, 'pt-br').format(outputFormat);

export const formatNumber = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options
    ? { ...options, style: 'decimal', useGrouping: true }
    : { style: 'decimal', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCurrency = (num: number, options?: Intl.NumberFormatOptions): string => {
  return `R$ ${formatNumber(num, options)}`;
};

export const formatCNPJ = (num: string): string => {
  const formatted: string[] = [];
  [...num].forEach((char, index) => {
    formatted.push(char);
    if (index === 1 || index == 4) formatted.push('.');
    if (index === 7) formatted.push('/');
    if (index === 11) formatted.push('-');
  });
  return formatted.join('');
};

export const formatCPF = (num: string): string => {
  const formatted: string[] = [];
  [...num].forEach((char, index) => {
    formatted.push(char);
    if (index === 2 || index == 5) formatted.push('.');
    if (index === 8) formatted.push('-');
  });
  return formatted.join('');
};

export const parseStringAsHTML = (stringToParse: string) => {
  return parse(stringToParse.replace(/(?:\r\n|\r|\n)/g, '<br>'));
};
