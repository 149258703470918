export const eventLabels = {
  PROPOSALS_LIST_PAGE_VIEWED: 'PROPOSALS_LIST_PAGE_VIEWED',
  PROPOSALS_LIST_SEARCH_PROPOSAL: 'PROPOSALS_LIST_SEARCH_PROPOSAL',
  ADMIN_START_NEW_PROPOSAL_BUTTON_CLICKED: 'ADMIN_START_NEW_PROPOSAL_BUTTON_CLICKED',
  ADMIN_SEND_NEW_PROPOSAL_BUTTON_CLICKED: 'ADMIN_SEND_NEW_PROPOSAL_BUTTON_CLICKED',

  DETAILED_PROPOSAL_PAGE_INFORMATIONS_BUTTON_CLICKED: 'DETAILED_PROPOSAL_PAGE_INFORMATIONS_BUTTON_CLICKED',
  DETAILED_PROPOSAL_PAGE_BIDS_BUTTON_CLICKED: 'DETAILED_PROPOSAL_PAGE_BIDS_BUTTON_CLICKED',
  DETAILED_PROPOSAL_PAGE_COMPILATION_BUTTON_CLICKED: 'DETAILED_PROPOSAL_PAGE_COMPILATION_BUTTON_CLICKED',
  DETAILED_PROPOSAL_PAGE_CLOSE_ROUND_BUTTON_CLICKED: 'DETAILED_PROPOSAL_PAGE_CLOSE_ROUND_BUTTON_CLICKED',
  DETAILED_PROPOSAL_PAGE_EDIT_PROPOSAL_BUTTON_CLICKED: 'DETAILED_PROPOSAL_PAGE_EDIT_PROPOSAL_BUTTON_CLICKED',
  DETAILED_PROPOSAL_PAGE_ADD_BID: 'DETAILED_PROPOSAL_PAGE_ADD_BID',
};
