import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { NumericFormField } from '@components/atoms/form';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';

import { formatNumber } from '@utils/text';
import { TariffModeEnum } from '@utils/translators/proposal';

interface ChangingEqualDemandDisplayProps {
  unitIndex: number;
  unitId: string;
  unitTariffMode: keyof typeof TariffModeEnum;
}
const ChangingEqualDemandDisplay: React.FC<ChangingEqualDemandDisplayProps> = ({
  unitIndex,
  unitId,
  unitTariffMode,
}) => {
  const unitPrefix = `unit-${unitId}`;
  const { control } = useFormContext<ProposalCompilationFormData>();

  const offPeakFieldName = `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.offPeak` as const;
  const { fields: offPeakFields } = useFieldArray<ProposalCompilationFormData, typeof offPeakFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.offPeak`,
  });

  const peakFieldName = `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.peak` as const;
  const { fields: peakFields } = useFieldArray<ProposalCompilationFormData, typeof peakFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.peak`,
  });

  const offPeakHeadingText = unitTariffMode === 'BLUE' ? 'Demanda Fora Ponta ano a ano' : 'Demanda ano a ano';

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-1">
        <p className="text-heading-xsmall">{offPeakHeadingText}</p>
        <div className="grid grid-cols-4 gap-6">
          {offPeakFields.map((field, index) => {
            return (
              <NumericFormField<ProposalCompilationFormData>
                key={field.id}
                id={`${unitPrefix}-equal-off-peak-demand-${field.year}`}
                className="flex flex-col space-y-1"
                field={
                  `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.offPeak.${index}.value` as const
                }
                label={`${field.year}`}
                formatProps={{
                  placeholder: 'kW',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                options={{
                  required: { value: true, message: 'Insira o valor de demanda em kW' },
                }}
              />
            );
          })}
        </div>
      </div>

      {/* Blue tariff */}
      {unitTariffMode === 'BLUE' && (
        <div className="flex flex-col space-y-1">
          <p className="text-heading-xsmall">Demanda Ponta ano a ano</p>
          <div className="grid grid-cols-4 gap-6">
            {peakFields.map((field, index) => {
              return (
                <NumericFormField<ProposalCompilationFormData>
                  key={field.id}
                  id={`${unitPrefix}-equal-peak-demand-${field.year}`}
                  className="flex flex-col space-y-1"
                  field={
                    `demandAndConsumption.${unitIndex}.unitInfo.changingEqualDemandValues.peak.${index}.value` as const
                  }
                  label={`${field.year}`}
                  formatProps={{
                    placeholder: 'kW',
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  options={{
                    required: { value: true, message: 'Insira o valor de demanda em kW' },
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

interface NonChangingEqualDemandDisplayProps {
  unitId: string;
  unitTariffMode: keyof typeof TariffModeEnum;
  unitContractedOffPeakDemand: number;
  unitContractedPeakDemand: number;
}
const NonChangingEqualDemandDisplay: React.FC<NonChangingEqualDemandDisplayProps> = ({
  unitId,
  unitTariffMode,
  unitContractedOffPeakDemand,
  unitContractedPeakDemand,
}) => {
  const unitPrefix = `unit-${unitId}`;

  const offPeakLabel = unitTariffMode === 'GREEN' ? 'Demanda contratada' : 'Demanda contratada Fora Ponta';

  return (
    <div className="grid grid-cols-2 gap-8 grow">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col border-t border-black">
          <p className="text-paragraph-medium">{offPeakLabel}</p>
          <p className="text-paragraph-medium" data-cy={`${unitPrefix}-equal-demand-off-peak`}>{`${formatNumber(
            unitContractedOffPeakDemand,
          )} kW`}</p>
        </div>
      </div>

      {unitTariffMode === 'BLUE' && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col border-t border-black">
            <p className="text-paragraph-medium">Demanda contratada Ponta</p>
            <p className="text-paragraph-medium" data-cy={`${unitPrefix}-equal-demand-peak`}>{`${formatNumber(
              unitContractedPeakDemand,
            )} kW`}</p>
          </div>
        </div>
      )}
    </div>
  );
};

interface EqualDemandDisplayProps {
  unitIndex: number;
  unitId: string;
  unitTariffMode: keyof typeof TariffModeEnum;
  unitContractedOffPeakDemand: number;
  unitContractedPeakDemand: number;
}
const EqualDemandDisplay: React.FC<EqualDemandDisplayProps> = ({
  unitId,
  unitIndex,
  unitTariffMode,
  unitContractedPeakDemand,
  unitContractedOffPeakDemand,
}) => {
  const { watch } = useFormContext<ProposalCompilationFormData>();
  const changeDemandWatch = watch(`demandAndConsumption.${unitIndex}.demandChangesThroughYears`);

  return (
    <>
      {changeDemandWatch &&
        (changeDemandWatch === 'YES' ? (
          <ChangingEqualDemandDisplay {...{ unitId, unitIndex, unitTariffMode }} />
        ) : (
          <NonChangingEqualDemandDisplay
            {...{ unitId, unitTariffMode, unitContractedOffPeakDemand, unitContractedPeakDemand }}
          />
        ))}
    </>
  );
};
export default EqualDemandDisplay;
