import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

import { OpenFinalRoundFormData } from '@components/molecules/open-final-round/types';
import { useTraderSelectionContext } from '@components/molecules/open-final-round/trader-selection-context';

import { ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { getChosenFeedbackTemplate, getRefusedFeedbackTemplate } from './form-helpers';

const FirstStep: React.FC<{
  proposal: Proposal;
  dataForFeedback: ProposalForFeedback;
}> = ({ proposal, dataForFeedback }) => {
  const tradersOptions = proposal.traders
    .filter((trader) => trader.bid !== undefined)
    .map((trader) => ({ id: trader.id, label: trader.name, value: trader.id }))
    .concat([{ id: 'no-winner', label: 'Nenhuma comercializadora', value: 'NO_WINNER' }]);

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<OpenFinalRoundFormData>();

  const { selectedTradersFieldArray, refusedTradersFieldArray } = useTraderSelectionContext();

  return (
    <>
      <div className="flex flex-col space-y-2">
        <p className="text-paragraph-medium">
          Selecione as Comercializadoras que passarão para a próxima rodada.<span className="text-danger-60">*</span>
        </p>
        {tradersOptions.map(({ id, label, value }, index) => {
          const { onChange, ...registerRest } = register('tradersThatAreWinners', {
            required: { value: true, message: 'Ao menos uma opção deve ser escolhida' },
          });
          return (
            <label htmlFor={id} className="flex items-center space-x-2" key={index}>
              <input
                id={id}
                data-cy={`trader-${id}`}
                className="rounded border text-primary-60 border-neutral-30"
                type="checkbox"
                value={value}
                {...registerRest}
                onChange={(e) => {
                  const checkedTraderValue = e.currentTarget.value;
                  const noWinnerWasClicked = checkedTraderValue === 'NO_WINNER';

                  if (e.currentTarget.checked) {
                    if (noWinnerWasClicked) {
                      const defaultRefusedTraders = proposal.traders
                        .filter((trader) => trader.bid !== undefined)
                        .map((trader) => ({
                          trader: { id: trader.id, name: trader.name },
                          feedback: getRefusedFeedbackTemplate(trader.id, dataForFeedback),
                        }));

                      selectedTradersFieldArray.replace([]);
                      refusedTradersFieldArray.replace(defaultRefusedTraders);
                      setValue('tradersThatAreWinners', ['NO_WINNER']); // reset other traders selected
                      setValue('traders', []); // reset traders
                      setValue('noTradersChosen', true);
                      return;
                    } else {
                      const uniqueTraders = new Set([...getValues('traders'), checkedTraderValue]);
                      setValue(
                        'tradersThatAreWinners',
                        [...uniqueTraders].filter((trader) => trader !== 'NO_WINNER'),
                      ); // reset tradersThatAreWinners
                      setValue('noTradersChosen', false);
                    }
                  }

                  const indexOfSelected = selectedTradersFieldArray.fields.findIndex(
                    (field) => field.trader.id === checkedTraderValue,
                  );
                  const indexOfRefused = refusedTradersFieldArray.fields.findIndex(
                    (field) => field.trader.id === checkedTraderValue,
                  );

                  if (e.currentTarget.checked) {
                    const uniqueTraders = new Set([...getValues('traders'), checkedTraderValue]);
                    setValue('traders', [...uniqueTraders]);

                    // append/update winner feedback when checking
                    const winnerFeedback = getChosenFeedbackTemplate(dataForFeedback);
                    if (indexOfSelected > -1) {
                      // resets the winner feedback in case of toggling trader
                      selectedTradersFieldArray.update(indexOfSelected, {
                        trader: { id: checkedTraderValue, name: label },
                        feedback: winnerFeedback,
                      });
                    } else {
                      selectedTradersFieldArray.append({
                        trader: { id: checkedTraderValue, name: label },
                        feedback: winnerFeedback,
                      });
                    }

                    // remove from refused traders list
                    if (indexOfRefused > -1) refusedTradersFieldArray.remove(indexOfRefused);
                  } else {
                    const uniqueTraders = new Set([...getValues('traders')]);
                    if (uniqueTraders.has(checkedTraderValue)) uniqueTraders.delete(checkedTraderValue);
                    setValue('traders', [...uniqueTraders]);

                    // delete from selected traders when unchecking
                    if (indexOfSelected > -1) selectedTradersFieldArray.remove(indexOfSelected);

                    // add to refused when unchecking
                    refusedTradersFieldArray.append({
                      trader: { id: checkedTraderValue, name: label },
                      feedback: getRefusedFeedbackTemplate(checkedTraderValue, dataForFeedback),
                    });
                  }

                  onChange(e);
                }}
              />
              <p className="text-paragraph-medium">{label}</p>
            </label>
          );
        })}
        <ErrorMessage
          name="tradersThatAreWinners"
          errors={errors}
          render={({ message }) => <span className="text-danger-30 sm:text-paragraph-medium">{message}</span>}
        />
      </div>
    </>
  );
};

export default FirstStep;
