import React from 'react';
import { FieldPath, FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { useNotification } from '@clarke-energia/foton';

import { formatDateIntoLocalISOString } from '@utils/translators/date';
import { Unpacked } from '@utils/types';

import { BIDS_LIST_FOR_PROPOSAL_PATH, FINISH_ROUND_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import LoadingPage from '@components/layout/loading-page';
import { getRefusedFeedbackTemplate } from '@components/organisms/open-final-round/form-helpers';
import { useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import { SendSignedProposalFormData } from '@components/molecules/send-signed-proposal/types';
import TraderSelectionProvider from '@components/molecules/send-signed-proposal/trader-selection-context';
import FormStepHeader from '@components/molecules/rounds-form-step-header';
import FirstStep from '@components/organisms/send-signed-proposal/first-step';
import SecondStep from '@components/organisms/send-signed-proposal/second-step';
import ThirdStep from '@components/organisms/send-signed-proposal/third-step';
import { NavigationControls } from '@components/molecules/form-steps-navigation-control';
import { ConfirmSubmissionDialog } from '@components/molecules/confirm-form-submission-dialog';

import { useCreateBidDocument } from '@hooks/bids/create-bid';
import {
  getSelectedBidsWithFeedbacks,
  useCreateFeedbackForSignedProposal,
  useCreateRefusedFeedbackForSignedProposal,
} from '@hooks/bids/create-feedback';
import useAuth from '@hooks/auth';
import { DocumentTypeForBid } from '@hooks/bids/create-bid/mutations';
import { useUpdateBidStatus } from '@hooks/bids/update';
import { useGetBidDataForFeedback } from '@hooks/bids/get-data-for-feedback';
import { ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';
import { useSignProposal } from '@hooks/process/mutations/sign-proposal';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const renderStepElement = (currentStep: number, proposal: Proposal, dataForFeedback: ProposalForFeedback) => {
  switch (currentStep) {
    case 1:
      return <FirstStep {...{ proposal, dataForFeedback }} />;

    case 2:
      return <SecondStep {...{ proposal }} />;

    case 3:
      return <ThirdStep />;

    default:
      throw 'Invalid step number';
  }
};

const getFieldsToBeValidated = (
  step: number,
  formValuesGetterFunction: UseFormGetValues<SendSignedProposalFormData>,
): FieldPath<SendSignedProposalFormData>[] | undefined => {
  if (step === 1) return ['selectedTrader', 'files'];
  if (step === 2) return ['selectedTraderFeedback'];

  const refusedTraders = formValuesGetterFunction('feedbacksForTradersThatAreRefused');
  if (refusedTraders.length === 0) return;
  return ['feedbacksForTradersThatAreRefused'];
};

const parseCommissionData = (proposal: Proposal) => {
  const { commissionModality } = proposal;

  return {
    commissionModality,
  };
};

interface SendSignedProposalFormProps {
  proposal: Proposal;
  onSubmitCallback: (data: SendSignedProposalFormData) => void;
  dataForFeedback: ProposalForFeedback;
}
const SendSignedProposalForm: React.FC<SendSignedProposalFormProps> = ({
  proposal,
  onSubmitCallback,
  dataForFeedback,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [isConfirmSubmitDialogOpen, setIsConfirmSubmitDialogOpen] = React.useState<boolean>(false);

  const defaultRefusedTraders = proposal.traders
    .filter((trader) => trader.bid !== undefined)
    .map((trader) => ({
      trader: { id: trader.id, name: trader.name },
      feedback: getRefusedFeedbackTemplate(trader.id, dataForFeedback),
    }));

  const parsedDeadline = formatDateIntoLocalISOString(proposal.deadline).split(' ');
  const parsedCommissions = parseCommissionData(proposal);

  const methods = useForm<SendSignedProposalFormData>({
    defaultValues: {
      selectedTrader: '',
      selectedTraderFeedback: '',
      feedbacksForTradersThatAreRefused: defaultRefusedTraders,
      files: [],
      lowerFlexibility: proposal.lowerFlexibility,
      upperFlexibility: proposal.upperFlexibility,
      guaranteeType: proposal.guaranteeType,
      guaranteeMonths: proposal.guaranteeMonths as SendSignedProposalFormData['guaranteeMonths'],
      otherKindGuarantee: proposal.otherGuarantee,
      contractType: proposal.contractType,
      proposalModality: proposal.proposalType,
      proposalDeadline: { date: parsedDeadline[0], time: parsedDeadline[1].slice(0, 5) },
      ...parsedCommissions,
    },
  });

  const onCancelFormCallback = () => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${FINISH_ROUND_PATH}`);

  const onStepChangeCallback = (nextStepValue: number) => {
    if (nextStepValue > currentStep) {
      const fields = getFieldsToBeValidated(currentStep, methods.getValues);
      methods.trigger(fields).then((fieldsAreValid) => {
        if (fieldsAreValid) setCurrentStep(nextStepValue);
      });
    } else {
      setCurrentStep(nextStepValue);
    }
  };

  const onLastStepCallback = async () => {
    const fields = getFieldsToBeValidated(3, methods.getValues);
    methods.trigger(fields).then((fieldsAreValid) => {
      if (fieldsAreValid) setIsConfirmSubmitDialogOpen(true);
    });
  };

  return (
    <div className="overflow-y-auto w-full h-full max-h-[calc(100vh-14rem)]">
      <FormProvider {...methods}>
        <form>
          <TraderSelectionProvider>
            <div className="p-4 space-y-4">
              <FormStepHeader currentStep={currentStep} operationType="sendSignedProposal" />

              <div className="space-y-8">
                {renderStepElement(currentStep, proposal, dataForFeedback)}

                <NavigationControls
                  currentStep={currentStep}
                  controlParams={{
                    maxStep: 3,
                    textLabels: {
                      changeStep: 'Próximo passo',
                      endStep: 'Enviar',
                    },
                    cancelFormCallback: onCancelFormCallback,
                    changeStepCallback: onStepChangeCallback,
                    endStepCallback: onLastStepCallback,
                  }}
                />
              </div>
            </div>
          </TraderSelectionProvider>

          {currentStep === 3 && (
            <ConfirmSubmissionDialog
              displayOptions={{
                titleText: 'Proposta assinada anexada e feedbacks escritos. Tudo certo para finalizar o processo?',
                buttonSubmitText: 'Enviar',
              }}
              isOpen={isConfirmSubmitDialogOpen}
              confirmDialogCallback={async () => {
                await methods.handleSubmit(onSubmitCallback)();
                setIsConfirmSubmitDialogOpen(false);
              }}
              closeDialogCallback={() => setIsConfirmSubmitDialogOpen(false)}
            />
          )}
        </form>
      </FormProvider>
    </div>
  );
};

const getSelectedBid = (bids: Proposal['bids'], selectedTraderId: string): Unpacked<Proposal['bids']> => {
  const selectedBid = bids.find((bid) => bid.trader.id === selectedTraderId);
  if (selectedBid === undefined) throw new Error(`Oferta da comercializadora ${selectedTraderId} não encontrada.`);
  const selectedBidCopy = cloneDeep(selectedBid);
  selectedBidCopy.status = 'FINAL_WINNER';

  return selectedBidCopy;
};

const SendSignedProposalPage: React.FC = () => {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const { proposal } = useAdminProposal();

  const signProposal = useSignProposal();
  const updateBidStatus = useUpdateBidStatus();
  const createSignedFeedback = useCreateFeedbackForSignedProposal();
  const createRefusedSignedFeedback = useCreateRefusedFeedbackForSignedProposal();
  const createBidDocument = useCreateBidDocument();
  const { fetchData, proposalData, isLoading } = useGetBidDataForFeedback();

  React.useEffect(() => {
    if (proposal.id) {
      fetchData(proposal.id);
    }
  }, [proposal.id]);

  const onSubmit = async (data: SendSignedProposalFormData) => {
    const selectedBid = getSelectedBid(proposal.bids, data.selectedTrader);
    const refusedBids = getSelectedBidsWithFeedbacks(data.feedbacksForTradersThatAreRefused, proposal, 'FINAL_REFUSED');

    // Create signed final proposal round
    let createdProposalId: string, createdBidId: string;
    try {
      const signedProposalResult = await signProposal(proposal.id, selectedBid.id);
      createdProposalId = signedProposalResult.createdProposalId;
      createdBidId = signedProposalResult.createdBidId;
    } catch (error) {
      createNotification({
        kind: 'error',
        label: 'Erro!',
        message: `Não foi possível enviar a proposta assinada. Por favor, avise ao suporte técnico, informando o seguinte erro: ${error}`,
      });
      return;
    }

    await Promise.all(
      data.files.map(
        async (file) => await createBidDocument(file, createdBidId, accessToken, DocumentTypeForBid.SIGNED_PROPOSAL),
      ),
    );

    // Update bids and create feedbacks without notifying anyone
    await updateBidStatus(selectedBid.id, selectedBid.status);
    await createSignedFeedback(createdBidId, data.selectedTraderFeedback);

    await Promise.all(
      refusedBids.map(async ({ bidId, bid, feedback }) => {
        await updateBidStatus(bidId, bid.status);
        await createRefusedSignedFeedback(bidId, feedback.feedback);
      }),
    );

    navigate(`${VIEW_PROPOSAL_LIST_PATH}/${createdProposalId}/${BIDS_LIST_FOR_PROPOSAL_PATH}`);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <SendSignedProposalForm {...{ proposal, onSubmitCallback: onSubmit, dataForFeedback: proposalData }} />
  );
};

export default SendSignedProposalPage;
