import { gql } from '@apollo/client';
import { ContractTypeEnum, CoverCeeeCostEnum, GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';

export const QUERY_BID_DATA = gql`
  query GetBidData($id: ID!) {
    bid(id: $id) {
      id
      createdAt
      deadline
      status
      contractType
      periods
      economy {
        amount
        year
      }
      lowerFlexibility
      upperFlexibility
      proposal {
        id
        proposalType
        commissionModality
        supply {
          endDate
          startDate
          unit {
            id
          }
        }
      }
      coverCceeCost
      coverCceeTaxes
      coverMeterSystem
      meterSystemAmount
      guaranteeType
      guaranteeMonths
      otherGuaranteeType
      payDay
      clarkeCommissionNote
      note
      trader {
        id
      }
      retailService
    }
  }
`;

export interface QueryBidDataGraphQLResponse {
  bid: {
    id: string;
    createdAt: string;
    deadline: string;
    status: number;
    contractType: keyof typeof ContractTypeEnum;
    periods: number[];
    economy: {
      amount: number;
      year: number;
    }[];
    lowerFlexibility: number;
    upperFlexibility: number;
    proposal: {
      id: string;
      proposalType: keyof typeof ProposalTypeEnum;
      commissionModality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
      supply: {
        startDate: string | null;
        endDate: string | null;
        unit: { id: string };
        __typename?: string;
      }[];
      __typename?: string;
    };
    coverCceeCost: keyof typeof CoverCeeeCostEnum;
    coverCceeTaxes: boolean;
    coverMeterSystem: boolean;
    meterSystemAmount: number | null;
    guaranteeType: Omit<keyof typeof GuaranteeTypeEnum, 'NO_GUARANTEE'> | null;
    guaranteeMonths: number | null;
    otherGuaranteeType: string | null;
    payDay: number;
    clarkeCommissionNote: string;
    note: string;
    trader: {
      id: string;
      __typename?: string;
    };
    retailService: boolean;
    __typename?: string;
  };
}
