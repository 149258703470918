import React from 'react';

import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import CCEELogo from '@assets/ccee-logo.svg';
import { Ranking } from '@hooks/process/queries/get-process-tader-ranking/types';

interface DetailContentProps {
  info: Pick<Ranking, 'coverCceeTaxes' | 'flex' | 'guaranteeType' | 'retailService'>;
}

type Content = {
  title: string;
  content: string;
  icon: IconName | 'Ccee';
};

const DetailInfo: React.FC<DetailContentProps> = ({ info }) => {
  const content: Content[] = [
    { title: 'Felixibilidade', content: info.flex, icon: 'AdjustmentsIcon' },
    { title: 'Garantia', content: info.guaranteeType, icon: 'ShieldCheckIcon' },
    { title: 'Modalidade de Contrato', content: info.retailService, icon: 'DocumentIcon' },
    { title: 'Encargos CCEE', content: info.coverCceeTaxes, icon: 'Ccee' },
  ];

  return (
    <div className="p-5">
      <div className="justify-between p-5 rounded-2xl lg:flex bg-neutral-20">
        {content.map(({ title, content, icon }) => (
          <div className="flex gap-4 mb-2" key={title}>
            <div className="p-4 bg-gray-50 rounded-lg">
              {icon === 'Ccee' ? (
                <img src={CCEELogo} alt="logo CCEE" />
              ) : (
                <HeroIcon icon={icon} extraClassNames="h-5" />
              )}
            </div>
            <div>
              <p className="text-xs font-bold">{title}</p>
              <p className="text-xs font-normal">{content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailInfo;
