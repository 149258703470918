import cloneDeep from 'lodash.clonedeep';

import { Bid, Proposal, Subgroup, Unit } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import {
  CalculationInputs,
  ConsumptionRecords,
  ValuePerYearMapper,
  ManualValueInputs,
  RequestSavingsCalculationPayload,
  UnitCalculationInputs,
} from '@services/rest/request-savings-calculation';
import { TariffModeEnum } from '@utils/translators/proposal';
import { formatDate } from '@utils/text';
import {
  BidsForCalculation,
  DemandAndConsumptionData,
  ProposalCompilationFormData,
} from '@components/molecules/start-compilation/types';

type UnitSupply = Pick<Unit, 'supply'>;

export const getFirstSupplyDateFromProposal = (units: UnitSupply[]): Date => {
  const startDates = units.map((u) => u.supply.startDate).sort((a, b) => (a?.getTime() || NaN) - (b?.getTime() || NaN));
  const firstSupplyDate = startDates?.[0];
  if (firstSupplyDate === null || firstSupplyDate === undefined) {
    throw new Error("Couldn't find a start date for the units of this proposal");
  }
  return firstSupplyDate;
};

export const getContractDuration = (units: UnitSupply[]): number => {
  const startYear = [
    ...new Set(units.map((unit) => unit.supply.startDate?.getFullYear() ?? NaN).filter((year) => !isNaN(year))),
  ].sort((a, b) => a - b)[0];

  const endYear = [
    ...new Set(units.map((unit) => unit.supply.endDate?.getFullYear() ?? NaN).filter((year) => !isNaN(year))),
  ].sort((a, b) => b - a)[0];

  return endYear - startYear + 1;
};

export const getTariffOption = (modality: keyof typeof TariffModeEnum, subgroup: Subgroup): number => {
  switch (`${subgroup}_${modality}`) {
    case 'A4_GREEN':
      return 2;
    case 'A4_BLUE':
      return 3;
    case 'AS_BLUE':
      return 4;
    case 'AS_GREEN':
      return 5;

    default:
      throw new Error(`Invalid modality and subgroup combination: ${modality} ${subgroup}`);
  }
};

const parseBidEconomyIntoManualPrice = (bid: Pick<Bid, 'economy'>): ManualValueInputs => {
  const economy = bid.economy;
  const epy: { [year: string]: number } = {};

  const result: ManualValueInputs = {};
  economy.forEach((entry) => {
    epy[`${entry.year}`] = entry.amount;
  });

  result[`${bid.economy.length}`] = epy;

  return result;
};

const getManualValues = (bid: Pick<Bid, 'contractType' | 'economy'>) => {
  const contractType = bid.contractType;
  const result: Pick<CalculationInputs, 'manual_acl_price' | 'manual_discount_inputs'> = {};

  if (contractType === 'FIXED_PRICE') result.manual_acl_price = parseBidEconomyIntoManualPrice(bid);
  if (contractType === 'GUARANTEED_SAVING') result.manual_discount_inputs = parseBidEconomyIntoManualPrice(bid);

  return result;
};

export const MonthToNumberTranslationMapper = {
  january: '1',
  february: '2',
  march: '3',
  april: '4',
  may: '5',
  june: '6',
  july: '7',
  august: '8',
  september: '9',
  october: '10',
  november: '11',
  december: '12',
};

const getConsumptionHistories = (
  history: Unit['history'],
): { offPeakConsumptionHistory: ConsumptionRecords; peakConsumptionHistory: ConsumptionRecords } => {
  const offPeakConsumptionHistory = {
    '1': history.january.offPeak,
    '2': history.february.offPeak,
    '3': history.march.offPeak,
    '4': history.april.offPeak,
    '5': history.may.offPeak,
    '6': history.june.offPeak,
    '7': history.july.offPeak,
    '8': history.august.offPeak,
    '9': history.september.offPeak,
    '10': history.october.offPeak,
    '11': history.november.offPeak,
    '12': history.december.offPeak,
  };
  const peakConsumptionHistory = {
    '1': history.january.peak,
    '2': history.february.peak,
    '3': history.march.peak,
    '4': history.april.peak,
    '5': history.may.peak,
    '6': history.june.peak,
    '7': history.july.peak,
    '8': history.august.peak,
    '9': history.september.peak,
    '10': history.october.peak,
    '11': history.november.peak,
    '12': history.december.peak,
  };

  return { offPeakConsumptionHistory, peakConsumptionHistory };
};

const transformIntoYearValueObj = (entries: { year: number; value: number }[]): Record<string, number> =>
  entries.reduce((obj: Record<string, number>, { year, value }) => ((obj[`${year}`] = value), obj), {});

type ContractedDemands = Pick<
  UnitCalculationInputs,
  | 'acl_contracted_demand_off_peak'
  | 'acr_contracted_demand_off_peak'
  | 'acr_contracted_demand_peak'
  | 'acl_contracted_demand_peak'
  | 'manual_acl_contracted_demand_off_peak'
  | 'manual_acr_contracted_demand_off_peak'
  | 'manual_acl_contracted_demand_peak'
  | 'manual_acr_contracted_demand_peak'
>;
const getContractedDemands = (unit: Unit, demandAndConsumption: DemandAndConsumptionData): ContractedDemands => {
  const isBlueTariff = unit.tariffMode === 'BLUE';

  const equalDemand = demandAndConsumption.equalDemand;
  const demandChangesThroughYears = demandAndConsumption.demandChangesThroughYears;

  const isEqualDemandDefined = equalDemand !== '';
  const isChangingDemandDefined = demandChangesThroughYears !== '';
  if (!isEqualDemandDefined || !isChangingDemandDefined)
    throw new Error("Equal demand or changing demand option can't be blank.");

  const isEqualDemand = equalDemand === 'YES';
  const isChangingDemand = demandChangesThroughYears === 'YES';

  let result: ContractedDemands = {
    acr_contracted_demand_off_peak: NaN,
    acl_contracted_demand_off_peak: NaN,
    acr_contracted_demand_peak: null,
    acl_contracted_demand_peak: null,
  };

  const unitInfo = demandAndConsumption.unitInfo;

  if (!isChangingDemand) {
    if (isEqualDemand) {
      result.acl_contracted_demand_off_peak = unit.contractedDemandOffPeak;
      result.acr_contracted_demand_off_peak = unit.contractedDemandOffPeak;

      if (isBlueTariff) {
        result.acl_contracted_demand_peak = unit.contractedDemandPeak;
        result.acr_contracted_demand_peak = unit.contractedDemandPeak;
      }
    } else {
      result.acl_contracted_demand_off_peak = unitInfo.nonChangingNonEqualDemandValues.acl.offPeak;
      result.acr_contracted_demand_off_peak = unitInfo.nonChangingNonEqualDemandValues.acr.offPeak;

      if (isBlueTariff) {
        result.acl_contracted_demand_peak = unitInfo.nonChangingNonEqualDemandValues.acl.peak;
        result.acr_contracted_demand_peak = unitInfo.nonChangingNonEqualDemandValues.acr.peak;
      }
    }
  } else {
    const manualDemands: Pick<
      ContractedDemands,
      | 'manual_acl_contracted_demand_off_peak'
      | 'manual_acr_contracted_demand_off_peak'
      | 'manual_acl_contracted_demand_peak'
      | 'manual_acr_contracted_demand_peak'
    > = {};

    result.acl_contracted_demand_off_peak = unit.contractedDemandOffPeak;
    result.acr_contracted_demand_off_peak = unit.contractedDemandOffPeak;

    if (isEqualDemand) {
      manualDemands.manual_acl_contracted_demand_off_peak = transformIntoYearValueObj(
        unitInfo.changingEqualDemandValues.offPeak,
      );

      manualDemands.manual_acr_contracted_demand_off_peak = transformIntoYearValueObj(
        unitInfo.changingEqualDemandValues.offPeak,
      );
      if (isBlueTariff) {
        manualDemands.manual_acl_contracted_demand_peak = transformIntoYearValueObj(
          unitInfo.changingEqualDemandValues.peak,
        );

        manualDemands.manual_acr_contracted_demand_peak = transformIntoYearValueObj(
          unitInfo.changingEqualDemandValues.peak,
        );
      }
    } else {
      // Non equal demand that changes throughout the years
      manualDemands.manual_acl_contracted_demand_off_peak = transformIntoYearValueObj(
        unitInfo.changingNonEqualDemandValues.acl.offPeak,
      );
      manualDemands.manual_acr_contracted_demand_off_peak = transformIntoYearValueObj(
        unitInfo.changingNonEqualDemandValues.acr.offPeak,
      );
      if (isBlueTariff) {
        manualDemands.manual_acl_contracted_demand_peak = transformIntoYearValueObj(
          unitInfo.changingNonEqualDemandValues.acl.peak,
        );

        manualDemands.manual_acr_contracted_demand_peak = transformIntoYearValueObj(
          unitInfo.changingNonEqualDemandValues.acr.peak,
        );
      }
    }
    result = { ...result, ...manualDemands };
  }
  return result;
};

const getManualConsumptionValues = (demandAndConsumption: DemandAndConsumptionData) => {
  const consumptionChangesThroughYears = demandAndConsumption.consumptionChangesThroughYears;
  const isChangingConsumptionDefined = consumptionChangesThroughYears !== '';
  if (!isChangingConsumptionDefined) throw new Error("Changing consumption option can't be blank.");

  const result: { manual_consumption_off_peak?: ValuePerYearMapper; manual_consumption_peak?: ValuePerYearMapper } = {};
  if (consumptionChangesThroughYears === 'YES') {
    result.manual_consumption_off_peak = demandAndConsumption.unitInfo.changingConsumptionValues.offPeak.reduce(
      (obj: Record<string, number>, { year, value }) => ((obj[`${year}`] = value), obj),
      {},
    );

    result.manual_consumption_peak = demandAndConsumption.unitInfo.changingConsumptionValues.peak.reduce(
      (obj: Record<string, number>, { year, value }) => ((obj[`${year}`] = value), obj),
      {},
    );
  }

  return result;
};

export const buildUnitForPayload = (
  unit: Unit,
  demandAndConsumption: DemandAndConsumptionData,
  managementFee?: number,
  initialExpenses?: number,
) => {
  const supplyStartDate = unit.supply.startDate;
  if (supplyStartDate === null) {
    throw new Error('This unit supply start date is not defined.');
  }

  const { offPeakConsumptionHistory, peakConsumptionHistory } = getConsumptionHistories(unit.history);

  const contractedDemands = getContractedDemands(unit, demandAndConsumption);
  const manualConsumptionValues = getManualConsumptionValues(demandAndConsumption);

  // NOTE: there is a typo on calculator API with commercial icms
  const icms_type = `ICMS_${unit.unitType === 'COMMERCIAL' ? 'COMERCIAL' : unit.unitType}`;

  return {
    tariff: getTariffOption(unit.tariffMode, unit.subgroup),
    icms_type,
    nickname: unit.name,
    migration_date: formatDate(supplyStartDate, 'DD/MM/YYYY'),
    power_generator_cost: isNaN(unit.generatorCost) ? 0 : unit.generatorCost,
    energy_distribution_company: unit.edc.id,
    ...contractedDemands,
    ...manualConsumptionValues,
    consumption_off_peak: offPeakConsumptionHistory,
    consumption_peak: peakConsumptionHistory,
    management_price: managementFee,
    initial_expenses: initialExpenses,
  };
};

interface Params {
  group: Proposal['group'];
  units: Unit[];
  bidScope: { bid: Bid; isInsideScope: boolean };
  isBasePriceType: boolean;
  formData: Pick<ProposalCompilationFormData, 'demandAndConsumption' | 'taxesAndCommissions'>;
}

export const getCustomTaxes = (
  { essAndEer, modulation }: Pick<ProposalCompilationFormData['taxesAndCommissions'], 'essAndEer' | 'modulation'>,
  bid: Pick<Bid, 'retailService' | 'coverCceeTaxes'>,
) => {
  if (isNaN(essAndEer) || isNaN(modulation)) {
    return undefined;
  }

  if (bid.retailService && bid.coverCceeTaxes === 'NONE') {
    return { ess_eer: essAndEer, modulation };
  }

  return undefined;
};

export const parseFormDataIntoPayloadData = ({
  bidScope,
  group,
  units,
  isBasePriceType,
  formData,
}: Params): RequestSavingsCalculationPayload => {
  const bid = bidScope.bid;

  const contractType = bid.contractType === 'GUARANTEED_SAVING' ? 'GUARANTEED_DISCOUNT' : bid.contractType;
  const traderType = bid.retailService ? 'ACL_RETAILER' : 'ACL_WHOLESALER';

  const manualValues = getManualValues(bid);

  const custom_taxes = getCustomTaxes(formData.taxesAndCommissions, bid);

  const generalManagementFee = formData.taxesAndCommissions.managementFee;
  const generalInitialExpenses = formData.taxesAndCommissions.initialExpenses;

  const calculation_inputs: CalculationInputs = {
    acl_type: contractType,
    product_type: traderType,
    target_years: [getContractDuration(units)],
    calculation_type: 'month_to_month',
    commercial_group: group.name,
    custom_taxes,
    ...manualValues,
    units: units.map((unit) => {
      const demandEntry = formData.demandAndConsumption.find((entry) => entry.unitInfo.id === unit.id);
      if (demandEntry === undefined) throw new Error(`Unit info ${unit.id} not found.`);
      return buildUnitForPayload(unit, demandEntry, generalManagementFee, generalInitialExpenses);
    }),
    manual_acl_prices_has_charges_coverage: custom_taxes ? false : true,
  };

  const commission_per_year_snake_case = formData.taxesAndCommissions.commissionPerYear.map((value) => {
    return {
      year: value.year,
      commission_value: value.commissionValue,
      commission_modality: value.commissionModality,
    };
  });

  return {
    bid_id: bid.id,
    is_inside_scope: bidScope.isInsideScope,
    calculation_inputs,
    retail_commission: formData.taxesAndCommissions.retailCommission,
    wholesale_commission: formData.taxesAndCommissions.wholesaleCommission,
    commission_per_year: commission_per_year_snake_case,
    is_base_price_type: isBasePriceType,
  };
};

const buildEmptyYearValueArray = (numYears: number, startYear: number) => {
  return [...Array(numYears).keys()].map((value) => {
    const year = startYear + value;
    return { year, value: NaN };
  });
};

export const parseUnitIntoDemandAndConsumptionData = (unit: Unit): DemandAndConsumptionData => {
  const startYear = unit.supply.startDate?.getFullYear() ?? NaN;
  const endYear = unit.supply.endDate?.getFullYear() ?? NaN;
  const numYears = endYear - startYear + 1;

  const changingConsumptionValues = {
    offPeak: buildEmptyYearValueArray(numYears, startYear),
    peak: buildEmptyYearValueArray(numYears, startYear),
  };

  const changingEqualDemandValues = {
    offPeak: buildEmptyYearValueArray(numYears, startYear),
    peak: buildEmptyYearValueArray(numYears, startYear),
  };

  const changingNonEqualDemandValues = {
    acr: {
      offPeak: buildEmptyYearValueArray(numYears, startYear),
      peak: buildEmptyYearValueArray(numYears, startYear),
    },
    acl: {
      offPeak: buildEmptyYearValueArray(numYears, startYear),
      peak: buildEmptyYearValueArray(numYears, startYear),
    },
  };

  return {
    unitInfo: {
      id: unit.id,
      tariffMode: unit.tariffMode,
      contractedOffPeakDemand: unit.contractedDemandOffPeak,
      contractedPeakDemand: unit.contractedDemandPeak,
      history: unit.history,
      changingConsumptionValues,
      changingEqualDemandValues,
      nonChangingNonEqualDemandValues: { acr: { offPeak: NaN, peak: NaN }, acl: { offPeak: NaN, peak: NaN } },
      changingNonEqualDemandValues,
    },
    equalDemand: '',
    demandChangesThroughYears: '',
    consumptionChangesThroughYears: '',
  };
};

export const parseBidsIntoFormScopeData = (
  bids: Proposal['bids'],
): { bidsInsideScope: BidsForCalculation; bidsOutsideScope: BidsForCalculation } => {
  const newBids: BidsForCalculation = {};
  bids.forEach((bid) => {
    newBids[bid.id] = cloneDeep(bid);
  });

  return { bidsInsideScope: newBids, bidsOutsideScope: {} };
};

export const customerEngagementLevelOptions = [
  { id: '1', optionLabel: 'Muito baixo', value: 'VERY_LOW' },
  { id: '2', optionLabel: 'Baixo', value: 'LOW' },
  { id: '3', optionLabel: 'Médio', value: 'MEDIUM' },
  { id: '4', optionLabel: 'Alto', value: 'HIGH' },
  { id: '5', optionLabel: 'Muito alto', value: 'VERY_HIGH', optionSelected: true },
];

export const CUSTOMER_ENGAGEMENT_LEVEL_FACTOR_GETTER: Record<string, number> = {
  VERY_LOW: 0.2,
  LOW: 0.4,
  MEDIUM: 0.6,
  HIGH: 0.8,
  VERY_HIGH: 1,
};

export const calculateCommissionByCustomerEngagedLevel = (
  rawCommission: number,
  customerEngagementLevel: string,
): number => {
  return rawCommission * CUSTOMER_ENGAGEMENT_LEVEL_FACTOR_GETTER[customerEngagementLevel];
};
