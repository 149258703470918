import React from 'react';
import { FormField, FormSection, MultiSelectFormField, RadioFormField, SelectFormField } from '@components/atoms/form';
import { OpenNewRoundFormData } from '@components/molecules/open-new-round/types';
import { CommissionModalityTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';
import { RetailTrader } from '@hooks/proposals';
import { useFormContext } from 'react-hook-form';

const FourthStep: React.FC<{ availableTraders: RetailTrader[] }> = ({ availableTraders }) => {
  const { watch } = useFormContext<OpenNewRoundFormData>();
  const watchTradersThatAreWinner = watch('tradersThatAreWinners');
  const watchFeedbacksForTradersThatAreRefused = watch('feedbacksForTradersThatAreRefused');
  const tradersThatAreRefused = watchFeedbacksForTradersThatAreRefused.map((entry) => entry.trader.id);

  return (
    <>
      <p className="text-paragraph-medium">
        Edite as informações necessárias para a proposta da rodada seguinte. Para outras edições, altere quando a rodada
        já estiver aberta.
      </p>
      <div className="grid grid-cols-2 gap-6 py-6">
        {/* contract section */}
        <div className="col-span-full">
          <FormSection title="Sobre o contrato" subtitle="Informações sobre o perfil e o contrato de energia." />
        </div>

        <div className="col-span-full lg:col-span-1">
          <RadioFormField<OpenNewRoundFormData>
            field="proposalModality"
            label="Qual o Tipo de Proposta"
            inputOptions={(Object.keys(ProposalTypeEnum) as (keyof typeof ProposalTypeEnum)[]).map((key) => ({
              id: key.toLowerCase(),
              value: key,
              optionLabel: ProposalTypeEnum[key],
            }))}
            options={{ required: { value: true, message: 'O tipo de proposta deve ser escolhida' } }}
          />
        </div>

        <div className="col-span-full lg:col-span-1">
          <RadioFormField<OpenNewRoundFormData>
            field="commissionModality"
            label="Modalidade de contratação"
            inputOptions={[
              { id: 'retail', optionLabel: CommissionModalityTypeEnum['RETAILER'], value: 'RETAILER' },
              { id: 'wholesale', optionLabel: CommissionModalityTypeEnum['WHOLESALER'], value: 'WHOLESALER' },
              { id: 'no-preference', optionLabel: CommissionModalityTypeEnum['NO_PREFERENCE'], value: 'NO_PREFERENCE' },
            ]}
            options={{ required: { value: true, message: 'O tipo de contrato deve ser escolhido' } }}
          />
        </div>

        <div className="col-span-full lg:col-span-1">
          <MultiSelectFormField<OpenNewRoundFormData>
            field="traders"
            label="Inserir novas comercializadoras"
            id="traders"
            inputOptions={availableTraders
              .filter(({ id }) => !tradersThatAreRefused.includes(id))
              .map(({ id, name }) => ({
                value: id,
                label: name,
                isFixed: watchTradersThatAreWinner.includes(id),
              }))}
            placeholder="Selecione todas as Comercializadoras"
            options={{ required: { value: true, message: 'Ao menos uma comercializadora deve ser escolhida' } }}
          />
        </div>

        <div className="col-span-full lg:col-span-1">
          <FormField<OpenNewRoundFormData>
            field="proposalDeadline.date"
            id="proposal-deadline-date"
            label="Prazo (data) para Entrega da Proposta"
            type="date"
            options={{
              required: { value: true, message: 'Defina a data de prazo para entrega' },
            }}
          />
        </div>

        <div className="col-span-full lg:col-span-1">
          <SelectFormField<OpenNewRoundFormData>
            field="proposalDeadline.time"
            id="proposal-deadline-time"
            label="Prazo (hora) para Entrega da Proposta"
            placeholder="Selecione um prazo"
            inputOptions={[...Array(16).keys()].map((_, index) => {
              const formattedHour = `${(index + 8).toLocaleString('pt-BR', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}:00`;
              return { optionLabel: formattedHour, value: formattedHour };
            })}
            options={{
              required: { value: true, message: 'Defina a hora para prazo para entrega' },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FourthStep;
