import React from 'react';

import { BidFormData } from '@components/molecules/create-bid/types';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { BIDS_SENT_BY_TRADER_PATH, DOES_NOT_EXIST_PATH, VIEW_BID_LIST_PATH } from '@routers/constants';
import { useGetProposalDataForBidCreation } from '@hooks/proposals';
import { useCreateBid, useCreateBidDocument, parseBidFormDataIntoBidForCreation } from '@hooks/bids/create-bid';
import useAuth from '@hooks/auth';
import { DocumentTypeForBid } from '@hooks/bids/create-bid/mutations';
import { useNotification } from '@clarke-energia/foton';
import BidForm from '@components/organisms/create-bid/form';
import { getContractDates, translateProposalQueryIntoFormData } from '@components/organisms/create-bid/helpers';
import { ServerValidationError } from '@utils/errors';
import { useTrader } from '@routers/trader-route';

const CreateBidPage: React.FC = () => {
  const navigate = useNavigate();
  const { proposalId } = useParams();

  const { createNotification } = useNotification();
  const {
    authStatus: { accessToken },
  } = useAuth();
  const { traderData, isTraderLoaded } = useTrader();
  const trader = traderData.id;

  const [fetchProposalData, proposal, isProposalLoaded] = useGetProposalDataForBidCreation();

  const createBid = useCreateBid();
  const createBidDocument = useCreateBidDocument();

  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  React.useEffect(() => {
    if (trader) fetchProposalData(proposalId, trader);
  }, [trader]);

  const isPageLoading = !isProposalLoaded || !isTraderLoaded || !isProposalLoaded;

  const cancelForm = () => navigate(`${VIEW_BID_LIST_PATH}/${proposalId}/${BIDS_SENT_BY_TRADER_PATH}`);
  const initialValues = translateProposalQueryIntoFormData(proposal, trader);

  const submitForm = async (data: BidFormData) => {
    const traderForBid = proposal.trader;
    const parsedBid = parseBidFormDataIntoBidForCreation(data, traderForBid, 'ACTIVE');

    try {
      const createdBid = await createBid(parsedBid, proposalId);
      await Promise.all(
        data.commercialConditions.files.map((file) =>
          createBidDocument(file, createdBid.id, accessToken, DocumentTypeForBid.PROPOSAL),
        ),
      );

      navigate(`${VIEW_BID_LIST_PATH}/${proposalId}/${BIDS_SENT_BY_TRADER_PATH}`, { state: { createdBid: true } });
    } catch (error) {
      const message =
        error instanceof ServerValidationError
          ? error.message
          : 'Houve um problema ao criar sua proposta. Tente novamente mais tarde ou nos comunique pelos nossos canais de atendimento.';

      createNotification({
        kind: 'error',
        label: 'Erro!',
        message,
      });
    }
  };

  const contractDuration = getContractDates(proposal.units);
  const commissionModality = proposal.commissionModality;
  return isPageLoading ? (
    <h1>Loading. Please wait...</h1>
  ) : (
    <BidForm {...{ contractDuration, commissionModality, initialValues, callbacks: { submitForm, cancelForm } }} />
  );
};

export default CreateBidPage;
