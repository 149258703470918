import React from 'react';
import { UnauthenticatedGraphQLProvider } from '@services/graphql/public';

interface PublicRouteProps {
  children: React.ReactNode;
}
const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  return <UnauthenticatedGraphQLProvider>{children}</UnauthenticatedGraphQLProvider>;
};

export default PublicRoute;
