import { HeroIcon, Modal, IconProps } from '@clarke-energia/foton';
import { Proposal } from '@hooks/bids/savings/types';
import { CommissionModalityTypeEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';
import React from 'react';

interface ProcessInfoDisplayProps {
  proposal: Proposal;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CardContent {
  value: string;
  title: string;
  icon: IconProps['icon'];
}

const ContentModal: React.FC<{ title: string; value: string; iconName: IconProps['icon'] }> = ({
  title,
  value,
  iconName,
}) => {
  return (
    <div className="flex gap-4 items-center mb-5 last:mb-0">
      <div className="p-4 w-7 h-7 bg-primary-10 rounded-medium">
        <HeroIcon icon={iconName} />
      </div>
      <div className="flex flex-col gap-0">
        <div className="text-neutral-50 text-paragraph-small truncate">{title}</div>
        <div className="flex flex-col gap-4 min-[300px]:flex-row">
          <div className="truncate text-paragraph-medium">{value}</div>
        </div>
      </div>
    </div>
  );
};

const ProcessInfoDisplay: React.FC<ProcessInfoDisplayProps> = ({ proposal, showModal, setShowModal }) => {
  const flexibility = `-${proposal.lowerFlexibility}% / +${proposal.upperFlexibility}%`;
  const guarantee = GuaranteeTypeEnum[proposal.guaranteeType];
  const contract = CommissionModalityTypeEnum[proposal.commissionModality];
  const duration = proposal.periods.map((year) => `${year} anos`).join(', ');

  const contentModal: CardContent[] = [
    {
      title: 'Flexibilidade',
      value: flexibility,
      icon: 'AdjustmentsVerticalIcon',
    },
    {
      title: 'Garantia',
      value: guarantee,
      icon: 'BriefcaseIcon',
    },
    {
      title: 'Modalidade de contratação',
      value: contract,
      icon: 'NewspaperIcon',
    },
    {
      title: 'Duração',
      value: duration,
      icon: 'ClockIcon',
    },
  ];

  return (
    <>
      <Modal
        open={showModal}
        setOpen={() => setShowModal(!showModal)}
        title="Escopo Solicitado"
        subtitle={`Processo iniciado em ${proposal.startDate}`}
        className="py-7 pt-7 pb-0"
      >
        <div className="-mt-5 -mb-7">
          <p className="mb-6 text-paragraph-medium w-[80%]">
            Essas são as características solicitadas do seu contrato de energia. Elas foram definidas em conjunto e
            foram apresentadas para a coleta das propostas.
          </p>
          {contentModal.map(({ icon, title, value }) => (
            <ContentModal key={title} title={title} iconName={icon} value={value} />
          ))}
        </div>
      </Modal>
    </>
  );
};
export default ProcessInfoDisplay;
