import React from 'react';
import { Ranking } from '@hooks/process/queries/get-process-tader-ranking/types';
import { formatCurrency } from '@utils/text';

import style from './style.module.css';

const AccordionContent: React.FC<{ prices: Ranking['prices'] }> = ({ prices }) => {
  return (
    <div className="gap-8 p-5 rounded lg:flex">
      <p className={style.contentTitle}>Preço da Energia</p>
      {prices.map((price) => (
        <div key={price.value} className={style.contentContainer}>
          <p className={style.contentText}>{price.year}</p>
          <p className={style.contentTitle}>{`${formatCurrency(price.value)}/MWh`}</p>
        </div>
      ))}
    </div>
  );
};

export default AccordionContent;
