import { gql } from '@apollo/client';
import { BidStatus } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

export const UPDATE_BID_STATUS_MUTATION = gql`
  mutation UpdateBidStatus($bidId: ID!, $status: BidStatusType!) {
    updateBidStatus(id: $bidId, status: $status) {
      error
    }
  }
`;

export interface UpdateBidStatusGraphQLResponse {
  updateBidStatus: {
    error: string | null;
  };
}
export interface UpdateBidStatusGraphQLInput {
  bidId: string;
  status: keyof typeof BidStatus;
}
