import React from 'react';
import { Outlet } from 'react-router-dom';

import SideBar, { MobileSidebar } from '@components/molecules/sidebar';

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col h-screen md:flex-row">
      <div className="p-1 sm:p-3 md:hidden">
        <MobileSidebar />
      </div>
      <div className="hidden py-4 space-y-4 h-full bg-white border-r border-black md:flex md:fixed md:top-0 md:left-0 md:flex-col md:justify-between w-[16rem] md:shrink-0">
        <SideBar />
      </div>
      <div className="flex-1 h-full md:fixed md:left-[16rem] md:w-[calc(100%-16rem)] overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
