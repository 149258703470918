import React from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import configuration from '@config';
import { onError } from '@apollo/client/link/error';
import errorTracker from '@services/error-tracking';

const httpLink = createHttpLink({
  uri: configuration.GATEWAY_API_HOST,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  const { variables, operationName } = operation;
  const context = { name: operationName, values: variables };

  if (networkError) {
    context.values = {
      ...context.values,
      errorType: 'network',
      rawErrorObject: JSON.stringify(networkError),
      rawResponseObject: JSON.stringify(response),
      rawVariables: JSON.stringify(variables),
    };
    errorTracker.captureException(networkError, context);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      const err = new Error(`GraphQL error on ${operationName}`, { cause: graphQLError });
      context.values = {
        ...context.values,
        errorType: 'graphql',
        rawErrorObject: JSON.stringify(graphQLError),
      };
      errorTracker.captureException(err, context);
    });
  }
});

const cache = new InMemoryCache();

export const UnauthenticatedGraphQLProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = new ApolloClient({ cache: cache, link: from([errorLink.concat(httpLink)]) });

  return <ApolloProvider {...{ client }}>{children}</ApolloProvider>;
};
