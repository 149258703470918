import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';
import { NumericFormField } from '@components/atoms/form';
import { TariffModeEnum } from '@utils/translators/proposal';

interface NonEqualNonChangingDemandDisplayProps {
  unitId: string;
  unitIndex: number;
  unitTariffMode: keyof typeof TariffModeEnum;
}
const NonEqualNonChangingDemandDisplay: React.FC<NonEqualNonChangingDemandDisplayProps> = ({
  unitId,
  unitIndex,
  unitTariffMode,
}) => {
  const unitPrefix = `unit-${unitId}`;
  const offPeakACRLabel = unitTariffMode === 'GREEN' ? 'Demanda ACR' : 'Demanda ACR Fora Ponta';
  const offPeakACLLabel = unitTariffMode === 'GREEN' ? 'Demanda ACL' : 'Demanda ACL Fora Ponta';

  return (
    <div className="grid grid-cols-2 gap-6">
      <NumericFormField<ProposalCompilationFormData>
        id={`${unitPrefix}-acr-off-peak-demand-value`}
        className="flex flex-col space-y-1"
        field={`demandAndConsumption.${unitIndex}.unitInfo.nonChangingNonEqualDemandValues.acr.offPeak` as const}
        label={offPeakACRLabel}
        formatProps={{
          placeholder: 'kW',
          fixedDecimalScale: true,
          decimalScale: 2,
        }}
        options={{
          required: { value: true, message: 'Insira o valor de demanda em kW' },
        }}
      />

      {unitTariffMode === 'BLUE' && (
        <NumericFormField<ProposalCompilationFormData>
          id={`${unitPrefix}-acr-peak-demand-value`}
          className="flex flex-col space-y-1"
          field={`demandAndConsumption.${unitIndex}.unitInfo.nonChangingNonEqualDemandValues.acr.peak` as const}
          label="Demanda ACR Ponta"
          formatProps={{
            placeholder: 'kW',
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          options={{
            required: { value: true, message: 'Insira o valor de demanda em kW' },
          }}
        />
      )}

      <NumericFormField<ProposalCompilationFormData>
        id={`${unitPrefix}-acl-off-peak-demand-value`}
        className="flex flex-col space-y-1"
        field={`demandAndConsumption.${unitIndex}.unitInfo.nonChangingNonEqualDemandValues.acl.offPeak` as const}
        label={offPeakACLLabel}
        formatProps={{
          placeholder: 'kW',
          fixedDecimalScale: true,
          decimalScale: 2,
        }}
        options={{
          required: { value: true, message: 'Insira o valor de demanda em kW' },
        }}
      />

      {unitTariffMode === 'BLUE' && (
        <NumericFormField<ProposalCompilationFormData>
          id={`${unitPrefix}-acl-peak-demand-value`}
          className="flex flex-col space-y-1"
          field={`demandAndConsumption.${unitIndex}.unitInfo.nonChangingNonEqualDemandValues.acl.peak` as const}
          label="Demanda ACL Ponta"
          formatProps={{
            placeholder: 'kW',
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          options={{
            required: { value: true, message: 'Insira o valor de demanda em kW' },
          }}
        />
      )}
    </div>
  );
};

interface NonEqualChangingDemandDisplayProps {
  unitId: string;
  unitIndex: number;
  unitTariffMode: keyof typeof TariffModeEnum;
}
const NonEqualChangingDemandDisplay: React.FC<NonEqualChangingDemandDisplayProps> = ({
  unitId,
  unitIndex,
  unitTariffMode,
}) => {
  const unitPrefix = `unit-${unitId}`;
  const { control } = useFormContext<ProposalCompilationFormData>();

  const offPeakACRFieldName =
    `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.offPeak` as const;
  const { fields: offPeakACRFields } = useFieldArray<ProposalCompilationFormData, typeof offPeakACRFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.offPeak`,
  });

  const peakACRFieldName = `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.peak` as const;
  const { fields: peakACRFields } = useFieldArray<ProposalCompilationFormData, typeof peakACRFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.peak`,
  });

  const offPeakACLFieldName =
    `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acl.offPeak` as const;
  const { fields: offPeakACLFields } = useFieldArray<ProposalCompilationFormData, typeof offPeakACLFieldName>({
    control,
    name: `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acl.offPeak`,
  });

  const offPeakACRLabelText = unitTariffMode === 'GREEN' ? 'Demanda ACR ano a ano' : 'Demanda ACR Fora Ponta ano a ano';
  const offPeakACLLabelText = unitTariffMode === 'GREEN' ? 'Demanda ACL ano a ano' : 'Demanda ACL Fora Ponta ano a ano';

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-1">
        <p className="text-heading-xsmall">{offPeakACRLabelText}</p>
        <div className="grid grid-cols-4 gap-6">
          {offPeakACRFields.map((field, index) => {
            return (
              <NumericFormField<ProposalCompilationFormData>
                key={field.id}
                id={`${unitPrefix}-acr-off-peak-demand-${field.year}`}
                className="flex flex-col space-y-1"
                field={
                  `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.offPeak.${index}.value` as const
                }
                label={`${field.year}`}
                formatProps={{
                  placeholder: 'kW',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                options={{
                  required: { value: true, message: 'Insira o valor de demanda em kW' },
                }}
              />
            );
          })}
        </div>
      </div>

      {unitTariffMode === 'BLUE' && (
        <div className="flex flex-col space-y-1">
          <p className="text-heading-xsmall">Demanda ACR Ponta ano a ano</p>
          <div className="grid grid-cols-4 gap-6">
            {peakACRFields.map((field, index) => {
              return (
                <NumericFormField<ProposalCompilationFormData>
                  key={field.id}
                  id={`${unitPrefix}-acr-peak-demand-${field.year}`}
                  className="flex flex-col space-y-1"
                  field={
                    `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acr.peak.${index}.value` as const
                  }
                  label={`${field.year}`}
                  formatProps={{
                    placeholder: 'kW',
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  options={{
                    required: { value: true, message: 'Insira o valor de demanda em kW' },
                  }}
                />
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-col space-y-1">
        <p className="text-heading-xsmall">{offPeakACLLabelText}</p>
        <div className="grid grid-cols-4 gap-6">
          {offPeakACLFields.map((field, index) => {
            return (
              <NumericFormField<ProposalCompilationFormData>
                key={field.id}
                id={`${unitPrefix}-acl-off-peak-demand-${field.year}`}
                className="flex flex-col space-y-1"
                field={
                  `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acl.offPeak.${index}.value` as const
                }
                label={`${field.year}`}
                formatProps={{
                  placeholder: 'kW',
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                options={{
                  required: { value: true, message: 'Insira o valor de demanda em kW' },
                }}
              />
            );
          })}
        </div>
      </div>

      {unitTariffMode === 'BLUE' && (
        <div className="flex flex-col space-y-1">
          <p className="text-heading-xsmall">Demanda ACL Ponta ano a ano</p>
          <div className="grid grid-cols-4 gap-6">
            {peakACRFields.map((field, index) => {
              return (
                <NumericFormField<ProposalCompilationFormData>
                  key={field.id}
                  id={`${unitPrefix}-acl-peak-demand-${field.year}`}
                  className="flex flex-col space-y-1"
                  field={
                    `demandAndConsumption.${unitIndex}.unitInfo.changingNonEqualDemandValues.acl.peak.${index}.value` as const
                  }
                  label={`${field.year}`}
                  formatProps={{
                    placeholder: 'kW',
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  options={{
                    required: { value: true, message: 'Insira o valor de demanda em kW' },
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

interface NonEqualDemandDisplayProps {
  unitIndex: number;
  unitId: string;
  unitTariffMode: keyof typeof TariffModeEnum;
}
const NonEqualDemandDisplay: React.FC<NonEqualDemandDisplayProps> = ({ unitIndex, unitId, unitTariffMode }) => {
  const { watch } = useFormContext<ProposalCompilationFormData>();

  const changeDemandWatch = watch(`demandAndConsumption.${unitIndex}.demandChangesThroughYears`);
  return (
    <>
      {changeDemandWatch &&
        (changeDemandWatch === 'YES' ? (
          <NonEqualChangingDemandDisplay {...{ unitId, unitIndex, unitTariffMode }} />
        ) : (
          <NonEqualNonChangingDemandDisplay {...{ unitId, unitIndex, unitTariffMode }} />
        ))}
    </>
  );
};

export default NonEqualDemandDisplay;
