import React from 'react';

import { Proposal, Bid } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { formatDate } from '@utils/text';

type FinalBidState = 'signed' | 'refused' | 'expired' | 'waitingForTrader' | 'bidDeadlineAboutToExpire';

const badgeColorScheme = {
  success: { text: 'text-primary-90', background: 'bg-primary-60' },
  waiting: { text: 'text-warning-70', background: 'bg-warning-10' },
  alert: { text: 'text-danger-60', background: 'bg-danger-10' },
};

const getFinalBidBadgeState = (
  proposalStatus: Proposal['status'],
  proposalDeadline: Date,
  bid: Bid | null,
): [FinalBidState, keyof typeof badgeColorScheme] => {
  const now = Date.now();

  if (bid === null) {
    // no bid made, either expired or in progress
    if (proposalStatus === 'FINISHED' || proposalDeadline.getTime() <= now) return ['expired', 'waiting'];

    return ['waitingForTrader', 'success'];
  }

  if (proposalStatus === 'ACTIVE') return ['bidDeadlineAboutToExpire', 'alert'];

  if (bid.status === 'FINAL_REFUSED') return ['refused', 'alert'];
  if (bid.status === 'FINAL_WINNER') return ['waitingForTrader', 'waiting'];

  return ['signed', 'success'];
};

const staticLabels: Record<Exclude<FinalBidState, 'bidDeadlineAboutToExpire'>, string> = {
  expired: 'Expirada',
  waitingForTrader: 'Aguardando',
  refused: 'Recusada',
  signed: 'Assinada',
};

const getFinalBidBadgeLabel = (state: FinalBidState, bidDeadline: Date): string => {
  if (state !== 'bidDeadlineAboutToExpire') return staticLabels[state];

  return formatDate(bidDeadline).toUpperCase();
};

const FinalBidForAdminStateBadge: React.FC<{ proposal: Proposal; bid: Bid | null }> = ({ proposal, bid }) => {
  const [bidState, colorScheme] = getFinalBidBadgeState(proposal.status, proposal.deadline, bid ? bid : null);
  const label = getFinalBidBadgeLabel(bidState, bid ? bid.deadline : proposal.deadline);
  const colorClass = badgeColorScheme[colorScheme];
  return (
    <div className={`py-1 px-2 rounded-full ${colorClass.background}`}>
      <p className={`text-paragraph-medium whitespace-nowrap  ${colorClass.text}`}>{label}</p>
    </div>
  );
};

export default FinalBidForAdminStateBadge;
