import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash.clonedeep';

import { ProposalFormData } from '@components/molecules/create-proposal/types';
import { FileFormField, FormField, FormSection, SelectFormField } from '@components/atoms/form';
import UnitAccordionItem from '@components/molecules/create-proposal/unit-accordion';
import { useGroupDataContext } from './group-data-context';
import { GroupDataForProposalCreation } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { Unpacked } from '@utils/types';
import { SubmarketEnum, TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';
import { formatCNPJ, formatCPF, formatCurrency, formatDateFromString, formatNumber } from '@utils/text';
import EntityInformationContents, { EntityValueForDisplayProps } from './entity-display';

interface ExtendedUnit extends Unpacked<GroupDataForProposalCreation['units']> {
  state: string;
}
const unitDefinitions: {
  field: EntityValueForDisplayProps<ExtendedUnit>['field'];
  label: string | ((entity: ExtendedUnit) => string);
  truncate?: boolean;
}[] = [
  { field: 'name', label: 'Nome da unidade' },
  { field: 'legalName', label: 'Razão Social' },
  { field: 'unitType', label: 'Classificação', truncate: true },
  { field: 'submarket', label: 'Submercado' },
  { field: 'state', label: 'Estado' },
  { field: 'edc', label: 'Distribuidora' },
  { field: 'tariffSubgroup', label: 'Subgrupo', truncate: true },
  { field: 'tariffModality', label: 'Modalidade tarifária' },
  { field: 'contractedDemandOffPeak', label: 'Demanda fora ponta / única' },
  { field: 'contractedDemandPeak', label: 'Demanda ponta' },
  { field: 'averageInvoiceAmount', label: 'Valor estimado mensal da fatura', truncate: true },
  { field: 'migrationDate', label: 'Data de migração' },
  { field: 'powerGenerator', label: 'Gerador' },
  { field: 'generatorCost', label: 'Custo médio mensal do gerador', truncate: true },
];

const translateUnitValues = (
  field: EntityValueForDisplayProps<ExtendedUnit>['field'],
  entity: ExtendedUnit,
): string => {
  switch (field) {
    case 'name':
      return entity.name;
    case 'legalName':
      return entity.legalName;
    case 'docNumber': {
      const docType = entity.docType;
      const docNumber = entity.docNumber.replace(/\D/g, '');
      return docType === 1 ? formatCPF(docNumber) : formatCNPJ(docNumber);
    }
    case 'unitType':
      return UnitTypeEnum[entity.unitType];
    case 'state':
      return entity.edc.state;
    case 'edc':
      return entity.edc.name.split(' ')[0];
    case 'tariffSubgroup':
      return entity.tariffSubgroup;
    case 'tariffModality':
      return TariffModeEnum[entity.tariffModality];
    case 'contractedDemandOffPeak':
      return `${formatNumber(entity.contractedDemandOffPeak)} kW`;
    case 'contractedDemandPeak':
      if (entity.contractedDemandPeak === null || isNaN(entity.contractedDemandPeak)) return '-';
      return `${formatNumber(entity.contractedDemandPeak)} kW`;
    case 'averageInvoiceAmount':
      return formatCurrency(entity.averageInvoiceAmount);
    case 'migrationDate':
      return formatDateFromString(entity.migrationDate, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY');
    case 'powerGenerator':
      return entity.powerGenerator ? 'Sim' : 'Não';
    case 'generatorCost': {
      if (entity.generatorCost === null || isNaN(entity.generatorCost)) return '-';
      return formatCurrency(entity.generatorCost);
    }
    case 'submarket':
      return entity.submarket === null ? '-' : SubmarketEnum[entity.submarket];
    default:
      return '-';
  }
};

export const SecondStep: React.FC = () => {
  const { groupData, isFetchingGroupData } = useGroupDataContext();
  const { control } = useFormContext<ProposalFormData>();
  const { fields } = useFieldArray<ProposalFormData, 'units'>({ name: 'units', control });

  const isGroupLoaded = !isFetchingGroupData && groupData.id;
  return (
    <div className="flex flex-col mt-4 space-y-8">
      <div>
        <div className="col-span-1 space-y-1 xl:col-span-3">
          <p className="font-bold">Dados das Unidades</p>
          <p className="text-paragraph-medium text-neutral-50">Preencha os dados referentes às unidades do cliente.</p>
        </div>
      </div>
      {isGroupLoaded &&
        fields.map((field, index) => {
          const unit = groupData.units.find((unit) => unit.id === field.unitId);
          const docNumberLabelBuilder = (unit: ExtendedUnit): string => {
            const docType = unit.docType;
            return docType === 1 ? 'CPF' : 'CNPJ';
          };
          const completeUnitDefinitions = cloneDeep(unitDefinitions);
          completeUnitDefinitions.splice(2, 0, { field: 'docNumber', label: docNumberLabelBuilder });

          return (
            <UnitAccordionItem
              key={field.id}
              label={`Unidade ${index + 1} - ${unit?.name}`}
              deleteUnitCallback={() => {
                return;
              }}
            >
              <div className="flex flex-col px-4">
                <div className="flex flex-col space-x-8 md:flex-row">
                  <EntityInformationContents<ExtendedUnit>
                    // @ts-expect-error :: there might be a case where the 'units.find' might return nothing
                    entity={unit}
                    valuesDefinitions={completeUnitDefinitions}
                    displayTranslatorFunction={translateUnitValues}
                  />
                </div>

                <div className="flex flex-col items-center space-x-8 md:flex-row">
                  <div className="shrink">
                    <FormSection title="Fornecimento" subtitle="Informações sobre a energia" />
                  </div>
                  <div className="grid grid-cols-1 gap-6 py-6 xl:grid-cols-3">
                    <SelectFormField<ProposalFormData>
                      field={`units.${index}.energyType` as const}
                      id={`unit-${field.unitId}-energy-type`}
                      label="Tipo de Energia"
                      inputOptions={[
                        { optionLabel: 'Convencional', value: 'CONVENTIONAL' },
                        { optionLabel: 'Incentivada 0%', value: 'INCENTIVIZED_0' },
                        { optionLabel: 'Incentivada 50%', value: 'INCENTIVIZED_50' },
                        { optionLabel: 'Incentivada 80%', value: 'INCENTIVIZED_80' },
                        { optionLabel: 'Incentivada 100%', value: 'INCENTIVIZED_100' },
                      ]}
                      options={{ required: { value: true, message: 'O tipo de energia deve ser escolhido' } }}
                    />
                    <FormField<ProposalFormData>
                      field={`units.${index}.supplyStartDate` as const}
                      id={`unit-${field.unitId}-supply-start-date`}
                      label="Início de suprimento"
                      type="date"
                      options={{
                        required: { value: true, message: 'Defina a data de início de suprimento' },
                      }}
                    />
                    <FormField<ProposalFormData>
                      field={`units.${index}.supplyEndDate` as const}
                      id={`unit-${field.unitId}-supply-end-date`}
                      label="Fim de suprimento"
                      type="date"
                      options={{
                        required: { value: true, message: 'Defina a data de fim de suprimento' },
                      }}
                    />
                  </div>
                </div>
                <FileFormField<ProposalFormData>
                  name={`units.${index}.files` as const}
                  id={`unit-${field.unitId}-file`}
                  label={{ text: 'Documentos' }}
                  sublabel={{ text: 'Anexe documentos dessa unidade' }}
                  fileSizeLimitInBytes={30 * 1020 * 1024}
                  fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
                  multiple
                />
              </div>
            </UnitAccordionItem>
          );
        })}
    </div>
  );
};
