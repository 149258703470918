import { gql, useMutation } from '@apollo/client';
import {
  CommissionModalityTypeEnum,
  ContractTypeEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
} from '@utils/translators/proposal';

export interface NewRoundGraphQLInput {
  commissionModality: keyof typeof CommissionModalityTypeEnum;
  contractType: keyof typeof ContractTypeEnum;
  deadline: string;
  groupId: string;
  guaranteeMonths: number | null;
  guaranteeType: Omit<keyof typeof GuaranteeTypeEnum, 'NO_GUARANTEE'> | null;
  lowerFlexibility: number;
  note?: string;
  otherGuarantee: string | null;
  periods: number[];
  proposalType: keyof typeof ProposalTypeEnum;
  traders: string[];
  units: { id: string; startDate: string | null; endDate: string | null; totalConsumptionVolume: number }[];
  upperFlexibility: number;
}

interface OpenNewRoundGraphQLResponse {
  openNewRound: { error: string | null; proposal: { id: string } | null };
}

const OPEN_NEW_ROUND_STR = gql`
  mutation OpenNewRound($processId: ID!, $proposalInput: NewRoundInput!) {
    openNewRound(processId: $processId, proposalInput: $proposalInput) {
      error
      proposal {
        id
      }
    }
  }
`;

export function useOpenNewRound() {
  const [mutationFunction] = useMutation<
    OpenNewRoundGraphQLResponse,
    { processId: string; proposalInput: NewRoundGraphQLInput }
  >(OPEN_NEW_ROUND_STR);

  const openNewRound = async (processId: string, proposalInput: NewRoundGraphQLInput): Promise<{ id: string }> => {
    const { data, errors } = await mutationFunction({ variables: { processId, proposalInput } });

    if (errors !== undefined || !data || data.openNewRound.error !== null || data.openNewRound.proposal === null)
      throw new Error('Falha ao abrir nova rodada.');

    const result = data.openNewRound.proposal;
    return { id: result.id };
  };

  return openNewRound;
}
