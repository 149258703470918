import React from 'react';
import { Link } from 'react-router-dom';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';
import { START_COMPILATION_SUFFIX_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

interface CompilationAllowedDisplayProps {
  proposal: Proposal;
}
const CompilationAllowedDisplay: React.FC<CompilationAllowedDisplayProps> = ({ proposal }) => {
  return (
    <div data-cy="start-compilation-panel" className="flex flex-col py-8 space-y-4 max-w-xl">
      <h1 className="text-heading-2xlarge">Compilar as propostas</h1>

      <div className="flex flex-col space-y-8">
        <p className="text-paragraph-medium">
          O prazo de envio das propostas pelas comercializadoras já expirou? Temos participantes o suficiente para
          entregar boas opções ao cliente? Se sim, você já pode começar processo de compilação. Lembre-se, por enquanto
          não será possível inserir novas propostas depois!
        </p>

        <Link
          to={`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${START_COMPILATION_SUFFIX_PATH}`}
          className="py-2 px-4 mr-auto text-center rounded-full border border-black bg-primary-60"
          data-cy="start-compilation-button"
          role="button"
        >
          Compilar propostas
        </Link>
      </div>
    </div>
  );
};

export default CompilationAllowedDisplay;
