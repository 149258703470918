import React from 'react';

import { CLARKE_WHATSAPP } from '@utils/constants/common';

import useAuth from '@hooks/auth';

import { VIEW_BID_LIST_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import HeroIcon, { IconName } from '@components/atoms/hero-icon';
import { NavLink } from 'react-router-dom';

export interface NavigationLinkProps {
  label: string;
  path: string;
  iconName: IconName;
  external?: boolean;
}
const NavigationLink: React.FC<NavigationLinkProps> = ({ label, path, iconName, external = false }) => {
  const baseClass = 'flex items-center p-2 text-paragraph-medium font-medium rounded';
  const activeLinkClass = 'bg-neutral-10 text-neutral-90 hover:text-neutral-90 hover:bg-neutral-10';
  const otherLinkClass = 'text-neutral-60 hover:text-neutral-90 hover:bg-neutral-10';
  return external ? (
    <a className={`${baseClass} ${otherLinkClass}`} href={path} target="_blank" rel="noreferrer">
      <HeroIcon icon={iconName} extraClassNames="mr-3 w-6 h-6 shrink-0 text-gray" />
      {label}
    </a>
  ) : (
    <NavLink
      to={path}
      className={({ isActive }) => (isActive ? `${baseClass} ${activeLinkClass}` : `${baseClass} ${otherLinkClass}`)}
    >
      <HeroIcon icon={iconName} extraClassNames="mr-3 w-6 h-6 shrink-0 text-gray" />
      {label}
    </NavLink>
  );
};

interface NavigationLinksCollectionProps {
  links: NavigationLinkProps[];
}
const NavigationLinksCollection: React.FC<NavigationLinksCollectionProps> = ({ links }) => {
  return (
    <>
      {links.map((link, index) => (
        <NavigationLink key={index} {...link} />
      ))}
    </>
  );
};

const adminLinks: NavigationLinkProps[] = [
  { label: 'Clientes', path: VIEW_PROPOSAL_LIST_PATH, iconName: 'UserGroupIcon' },
];
const traderLinks: NavigationLinkProps[] = [
  { label: 'Propostas', path: VIEW_BID_LIST_PATH, iconName: 'DocumentDuplicateIcon' },
  { label: 'Faça uma sugestão', path: CLARKE_WHATSAPP, iconName: 'ChatAltIcon', external: true },
];

const NavigationLinks: React.FC = () => {
  const {
    user: { roles },
  } = useAuth();

  const isAdmin = roles?.includes('admin');
  const links = isAdmin ? adminLinks : traderLinks;
  return (
    <nav className="px-2 space-y-1 grow">
      <NavigationLinksCollection links={links} />
    </nav>
  );
};

export default NavigationLinks;
