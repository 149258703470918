import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormField, FormSection, NumericFormField, RadioFormField, SelectFormField } from '@components/atoms/form';
import { BidFormData } from '@components/molecules/create-bid/types';
import { CoverCeeeCostEnum, CoverCeeeTaxesEnum } from '@utils/translators/proposal';

const SecondStep: React.FC<{ commissionModality: string }> = ({ commissionModality }) => {
  const { watch } = useFormContext<BidFormData>();
  const coverMeterSystemWatch = watch('commercialConditions.coverMeterSystem');
  const traderTypeWatch = watch('commercialConditions.traderType');

  return (
    <div className="grid grid-cols-1 gap-x-5 py-6 md:grid-cols-3">
      <FormSection title="Condições Comerciais" subtitle="Informe as condições comerciais da sua proposta." />
      <div className="grid grid-cols-2 col-span-2 gap-y-4 gap-x-8 grow">
        <div className="col-span-full md:col-span-1">
          <RadioFormField<BidFormData>
            field="commercialConditions.proposalType"
            label="Tipo de Proposta"
            inputOptions={[
              { id: 'indicative', optionLabel: 'Indicativa', value: 'INDICATIVE' },
              { id: 'firm', optionLabel: 'Firme', value: 'FIRM' },
            ]}
            options={{ readOnly: true }}
          />
        </div>
        <div className="col-span-full md:col-span-1">
          <RadioFormField<BidFormData>
            field="commercialConditions.traderType"
            label="Modalidade de contratação"
            inputOptions={[
              { id: 'retail', optionLabel: 'Varejista', value: 'RETAIL' },
              { id: 'wholesale', optionLabel: 'Atacadista', value: 'WHOLESALE' },
            ]}
            options={
              commissionModality == 'NO_PREFERENCE'
                ? { required: { value: true, message: 'É preciso escolher qual o tipo de fornececimento' } }
                : { readOnly: true }
            }
          />
        </div>

        {traderTypeWatch === 'RETAIL' && (
          <div className="col-span-full md:col-span-1">
            <SelectFormField<BidFormData>
              field="commercialConditions.coverCceeTaxes"
              id="cover-ccee-taxes"
              label="Cobertura de encargos CCEE"
              inputOptions={[
                { value: 'NONE', optionLabel: CoverCeeeTaxesEnum['NONE'] },
                { value: 'FULL', optionLabel: CoverCeeeTaxesEnum['FULL'] },
              ]}
              options={{
                required: { value: true, message: 'Escolha ao menos uma opção de cobertura' },
              }}
            />
          </div>
        )}
        <div className="col-span-full md:col-span-1">
          <SelectFormField<BidFormData>
            field="commercialConditions.coverCceeCost"
            id="cover-ccee-costs"
            label="Cobertura da taxa de adesão da CCEE"
            inputOptions={[
              { value: 'NONE', optionLabel: CoverCeeeCostEnum['NONE'] },
              { value: 'FULL', optionLabel: CoverCeeeCostEnum['FULL'] },
            ]}
            options={{
              required: { value: true, message: 'Escolha ao menos uma opção de cobertura' },
            }}
          />
        </div>

        {traderTypeWatch !== 'RETAIL' && <div className="col-span-full md:col-span-1"></div>}

        <div className="col-span-full md:col-span-1">
          <NumericFormField<BidFormData>
            field="commercialConditions.lowerFlexibility"
            id="lower-flexibility"
            label="Flexibilidade inferior (%)"
            formatProps={{
              placeholder: 'Flexibilidade(%)',
              prefix: '-',
              suffix: '%',
              fixedDecimalScale: true,
              decimalScale: 0,
              allowNegative: false,
            }}
            options={{
              required: { value: true, message: 'Defina o valor de limite de flexibilidade superior' },
            }}
          />
        </div>
        <div className="col-span-full md:col-span-1">
          <NumericFormField<BidFormData>
            field="commercialConditions.upperFlexibility"
            id="upper-flexibility"
            label="Flexibilidade superior (%)"
            formatProps={{
              placeholder: 'Flexibilidade(%)',
              prefix: '+',
              suffix: '%',
              fixedDecimalScale: true,
              decimalScale: 0,
              allowNegative: false,
            }}
            options={{
              required: { value: true, message: 'Defina o valor de limite de flexibilidade superior' },
            }}
          />
        </div>
        <div className="col-span-full md:col-span-1">
          <SelectFormField<BidFormData>
            field="commercialConditions.coverMeterSystem"
            id="cover-meter-system"
            label="Cobertura da adequação do Sistema de Medição (SMF)?"
            placeholder="Selecione se haverá cobertura"
            inputOptions={[
              { value: 'YES', optionLabel: 'Sim' },
              { value: 'NO', optionLabel: 'Não' },
            ]}
            options={{
              required: { value: true, message: 'Escolha ao menos uma opção de cobertura' },
            }}
          />
        </div>
        <div className="col-span-full md:col-span-1">
          {coverMeterSystemWatch === 'YES' && (
            <NumericFormField<BidFormData>
              field="commercialConditions.meterSystemAmount"
              id="meter-system-amount"
              label="Valor da cobertura do SMF"
              formatProps={{
                placeholder: 'R$',
                prefix: 'R$ ',
              }}
              options={{
                required: { value: true, message: 'Digite o valor da cobertura' },
              }}
            />
          )}
        </div>
        <div className="col-span-full md:col-span-1">
          <FormField<BidFormData>
            field="commercialConditions.bidDeadline.date"
            id="bid-deadline-date"
            label="Data de validade da proposta"
            type="date"
            options={{
              required: { value: true, message: 'Defina a data de prazo para entrega' },
            }}
          />
        </div>
        <div className="col-span-full md:col-span-1">
          <SelectFormField<BidFormData>
            field="commercialConditions.bidDeadline.time"
            id="bid-deadline-time"
            label="Hora de validade da proposta"
            placeholder="Selecione um prazo"
            inputOptions={[...Array(16).keys()].map((_, index) => {
              const formattedHour = `${(index + 8).toLocaleString('pt-BR', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}:00`;
              return { optionLabel: formattedHour, value: formattedHour };
            })}
            options={{
              required: { value: true, message: 'Defina a hora para prazo para entrega' },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SecondStep;
