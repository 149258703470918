import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Table, ColumnOptions } from '@clarke-energia/foton';

import AccordionItem from '@components/atoms/proposal-list-panel/accordion-item';
import { ProcessSummaryData } from '@hooks/process/queries/get-process-list-for-admin';

const RowSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col space-y-1">
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </div>
  );
};

export interface ProcessesTableProps {
  isFetching: { requested: boolean; underAnalysis: boolean; finished?: boolean };
  data: { requested: ProcessSummaryData[]; underAnalysis: ProcessSummaryData[]; finished?: ProcessSummaryData[] };
  columnDefinitions: ColumnOptions<ProcessSummaryData>[];
}
export const ProcessesTable: React.FC<ProcessesTableProps> = ({ isFetching, data, columnDefinitions }) => {
  return (
    <div className="flex flex-col space-y-10">
      <AccordionItem label="Solicitado" labelSubscript={`${data.requested.length}`} testId="requested-accordion">
        <div className="overflow-x-auto">
          {isFetching.requested ? (
            <RowSkeleton />
          ) : (
            <Table data={data.requested} tableColumns={columnDefinitions} data-cy="requested-table" />
          )}
        </div>
      </AccordionItem>

      <AccordionItem
        label="Em Análise"
        labelSubscript={`${data.underAnalysis.length}`}
        testId="under-analysis-accordion"
      >
        <div className="overflow-x-auto">
          {isFetching.underAnalysis ? (
            <RowSkeleton />
          ) : (
            <Table data={data.underAnalysis} tableColumns={columnDefinitions} data-cy="under-analysis-table" />
          )}
        </div>
      </AccordionItem>

      {data.finished && (
        <AccordionItem label="Finalizado" labelSubscript={`${data.finished.length}`} testId="finished-accordion">
          <div className="overflow-x-auto">
            {isFetching.finished ? (
              <RowSkeleton />
            ) : (
              <Table data={data.finished} tableColumns={columnDefinitions} data-cy="finished-table" />
            )}
          </div>
        </AccordionItem>
      )}
    </div>
  );
};
