import React from 'react';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

export interface ProposalValueForDisplayProps<T> {
  proposal: Proposal;
  field: keyof T;
  label: string | ((proposal: Proposal) => string);
  displayTranslatorFunction: (field: ProposalValueForDisplayProps<T>['field'], proposal: Proposal) => string;
  truncate?: boolean;
}
export function ProposalValueForDisplay<T>({
  field,
  label,
  proposal,
  displayTranslatorFunction,
  truncate,
}: ProposalValueForDisplayProps<T>) {
  return (
    <>
      <div className="flex flex-col mb-2">
        <p className="text-paragraph-small text-neutral-50">{typeof label === 'string' ? label : label(proposal)}</p>
        <p className="text-paragraph-medium">{`${displayTranslatorFunction(field, proposal)}`}</p>
      </div>
      {truncate && <hr className="col-span-1 border-t border-black sm:col-span-4" />}
    </>
  );
}
