import React from 'react';

import AccordionItem from '@components/molecules/accordion';
import { formatCurrency, formatNumber } from '@utils/text';
import HeroIcon from '@components/atoms/hero-icon';
import BidWithSavingsContents from './bid-with-savings-contents';
import { BidWithSavings } from '@hooks/bids/savings/types';

interface NotRequestedSavingsDisplayItemProps {
  bid: BidWithSavings;
}
export const NotRequestedSavingsDisplayItem: React.FC<NotRequestedSavingsDisplayItemProps> = ({ bid }) => {
  return (
    <AccordionItem
      label={bid.trader.name}
      descriptionElement={
        <div className="flex items-center py-1 px-4 space-x-1 rounded-pill bg-warning-10">
          <HeroIcon icon="ExclamationIcon" extraClassNames="w-5 h-5 fill-warning-10 stroke-warning-80" />
          <p className="text-yellow-900 text-paragraph-small">Cálculo não requisitado</p>
        </div>
      }
    >
      <div className="flex flex-col p-4 space-y-6">
        <div className="flex flex-col space-y-2">
          <h2 className="font-bold text-heading-xsmall">Cálculo de proposta não requisitado</h2>
          <p className="text-paragraph-medium">Essa proposta ainda não teve seu cálculo requisitado.</p>
        </div>
      </div>
    </AccordionItem>
  );
};

interface SavingsWithErrorDisplayItemProps {
  bid: BidWithSavings;
}
export const SavingsWithErrorDisplayItem: React.FC<SavingsWithErrorDisplayItemProps> = ({ bid }) => {
  return (
    <AccordionItem
      label={bid.trader.name}
      descriptionElement={
        <div className="flex items-center py-1 px-4 space-x-1 rounded-full bg-danger-10">
          <HeroIcon icon="ExclamationIcon" extraClassNames="w-5 h-5 fill-danger-10 stroke-danger-80" />
          <p className="text-danger-80 text-paragraph-small">Erro no cálculo</p>
        </div>
      }
    >
      <div className="flex flex-col p-4 space-y-8">
        <div className="flex flex-col space-y-2">
          <h2 className="font-bold text-heading-xsmall">Erro no cálculo</h2>
          <p className="text-paragraph-medium">
            Tivemos algum problema durante o cálculo do compilado dessa proposta. Você pode tentar o recálculo dessa
            proposta novamente, acionar o suporte ou ignorar essa proposta no compilado para o cliente.
          </p>
        </div>
      </div>
    </AccordionItem>
  );
};

interface CompletedSavingsDisplayItemProps {
  bid: BidWithSavings;
}

export const CompletedSavingsDisplayItem: React.FC<CompletedSavingsDisplayItemProps> = ({ bid }) => {
  const { savings, contractType } = bid;
  const savingValues = contractType === 'FIXED_PRICE' ? savings.fixedPrice : savings.guaranteedSaving;
  const currencyValueForDisplay = formatCurrency(savingValues.groupReport.totalCurrencyAmount);
  const percentageValueForDisplay = formatNumber(savingValues.groupReport.totalPercentageAmount * 100);

  return (
    <AccordionItem
      label={bid.trader.name}
      descriptionElement={
        <div className="flex space-x-2">
          <p className="font-bold text-heading-small">{currencyValueForDisplay}</p>
          <p className="text-heading-small text-primary-60">{`(${percentageValueForDisplay}%)`}</p>
        </div>
      }
    >
      <div className="p-4">
        <BidWithSavingsContents {...{ bid }} />
      </div>
    </AccordionItem>
  );
};
