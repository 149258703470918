import React from 'react';
import useAuth from '@hooks/auth';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '@clarke-energia/foton';

import { VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import CreateProposalForm from '@components/organisms/create-proposal';
import { EMPTY_PROPOSAL, ProposalFormData } from '@components/molecules/create-proposal/types';
import { parseProposalFormDataIntoGraphQLInput } from '@components/organisms/create-proposal/form-helpers';
import LoadingPage from '@components/layout/loading-page';

import { useCreateProposalDocument } from '@hooks/proposals/single-proposal/creation/proposal';
import { useCreateUnitDocument, useEditUnit } from '@hooks/proposals/single-proposal/creation/unit';
import { useGetCommercialGroupList } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { useGetGroupDataForProposalCreation } from '@hooks/proposals/single-proposal/creation/commercial-group';
import { useAllTraders } from '@hooks/proposals';
import { useStartNewProcess } from '@hooks/process/mutations/start-new-process';

const CreateProposalPage: React.FC = () => {
  const navigate = useNavigate();

  const { createNotification } = useNotification();
  const [allTraders, isTraderLoading] = useAllTraders();
  const [allGroups, isGroupLoading] = useGetCommercialGroupList();
  const { authStatus } = useAuth();
  const [fetchGroupData, isFetchingGroupData] = useGetGroupDataForProposalCreation();

  const createUnitDocument = useCreateUnitDocument();
  const updateUnit = useEditUnit();
  const startNewProcess = useStartNewProcess();
  const createProposalDocument = useCreateProposalDocument();

  const isLoading = isTraderLoading || isGroupLoading;

  const onSubmit = async (data: ProposalFormData) => {
    const accessToken = authStatus.accessToken;
    const { client, units, contract } = data;

    const roundNumber = 1;
    const proposalPayload = parseProposalFormDataIntoGraphQLInput({ round: roundNumber, formData: data });
    const processInput = { name: contract.process.name, groupId: client.id };

    try {
      const startedProcess = await startNewProcess({ processInput, proposalInput: proposalPayload });

      const createdProposalId = startedProcess.proposalId;
      await Promise.all(
        units.map(async (unit) => {
          await updateUnit(unit, client.id);
          await Promise.all(unit.files.map((file) => createUnitDocument(file, unit.unitId, accessToken)));
        }),
      );

      await Promise.all(contract.files.map((file) => createProposalDocument(file, createdProposalId, accessToken)));
    } catch (error) {
      // eslint-disable-next-line no-console
      createNotification({
        kind: 'error',
        label: 'Erro!',
        message: `Não foi possível começar a solicitação. Por favor, avise ao suporte técnico, informando o seguinte erro: ${error}`,
      });
      return;
    }

    navigate(VIEW_PROPOSAL_LIST_PATH);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <CreateProposalForm
      isCreating={true}
      proposal={null}
      allTraders={allTraders}
      allGroups={allGroups}
      onFormSubmitCallback={onSubmit}
      initialValues={EMPTY_PROPOSAL}
      groupDataFetcher={{ fetchGroupData, isFetchingGroupData }}
    />
  );
};
export default CreateProposalPage;
