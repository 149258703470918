import React from 'react';
import { useLazyQuery } from '@apollo/client';

import { CommissionAnalysis, Trader } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { parseStringToDate } from '@utils/text';
import { ContractTypeEnum, GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';

import { QUERY_PROPOSAL_DATA_FOR_BID_CREATION } from './queries';

export interface ProposalForBid {
  id: string;
  createdAt: Date;
  deadline: Date;
  periods: number[];
  trader: Trader;
  lowerFlexibility: number;
  upperFlexibility: number;
  contractType: keyof typeof ContractTypeEnum;
  proposalType: keyof typeof ProposalTypeEnum;
  commissionModality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
  guaranteeType: keyof typeof GuaranteeTypeEnum;
  guaranteeMonths: number;
  otherKindGuarantee: string;
  units: { id: string; supply: { start: Date | null; end: Date | null } }[];
  commissionAnalysis?: CommissionAnalysis;
}

export interface ProposalQueryGraphQLResponse {
  proposal: {
    id: string;
    createdAt: string;
    deadline: string;
    periods: number[];
    traders: { id: string; name: string; __typename?: string }[];
    contractType: string;
    lowerFlexibility: number;
    upperFlexibility: number;
    proposalType: string;
    commissionModality: 'RETAILER' | 'WHOLESALER' | 'NO_PREFERENCE';
    guaranteeType: string | null;
    guaranteeMonths: number | null;
    otherGuarantee: string | null;
    supply: {
      startDate: string | null;
      endDate: string | null;
      unit: { id: string };
    }[];
    commissionAnalysis?: CommissionAnalysis;
    __typename?: string;
  };
}

interface ProposalQueryGraphQLInputs {
  proposalId: string;
}

const EMPTY_PROPOSAL_FOR_BID_CREATION: ProposalForBid = {
  id: '',
  createdAt: new Date(),
  deadline: new Date(),
  periods: [],
  trader: { id: '', name: '' },
  lowerFlexibility: NaN,
  upperFlexibility: NaN,
  contractType: 'FIXED_PRICE',
  commissionModality: 'RETAILER',
  proposalType: 'INDICATIVE',
  guaranteeType: 'NO_GUARANTEE',
  guaranteeMonths: NaN,
  otherKindGuarantee: '',
  units: [{ id: '', supply: { start: new Date(), end: new Date() } }],
  commissionAnalysis: { suggestedCommission: NaN },
};

export function useGetProposalDataForBidCreation(): [
  (proposalId: string, traderId: string) => void,
  ProposalForBid,
  boolean,
] {
  const [isProposalLoaded, setIsProposalLoaded] = React.useState<boolean>(false);
  const [proposalForBid, setProposalForBid] = React.useState<ProposalForBid>({ ...EMPTY_PROPOSAL_FOR_BID_CREATION });

  const [fetchProposalDataFunction] = useLazyQuery<ProposalQueryGraphQLResponse, ProposalQueryGraphQLInputs>(
    QUERY_PROPOSAL_DATA_FOR_BID_CREATION,
  );

  const fetchProposalData = async (proposalId: string, traderId: string) => {
    await fetchProposalDataFunction({
      variables: { proposalId },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const {
          id,
          createdAt,
          deadline,
          periods,
          traders,
          contractType,
          commissionModality,
          lowerFlexibility,
          upperFlexibility,
          proposalType,
          guaranteeType,
          guaranteeMonths,
          otherGuarantee,
          supply,
          commissionAnalysis,
        } = data.proposal;

        const traderForBid = traders.find((trader) => trader.id === traderId);
        if (traderForBid === undefined) throw new Error(`Trader id ${traderId} not found for this propopsal.`);

        const parsedProposal: ProposalForBid = {
          id,
          createdAt: parseStringToDate(createdAt),
          deadline: parseStringToDate(deadline),
          periods: [...periods],
          trader: traderForBid,
          contractType: contractType as keyof typeof ContractTypeEnum,
          lowerFlexibility,
          upperFlexibility,
          proposalType: proposalType as keyof typeof ProposalTypeEnum,
          commissionModality: commissionModality,
          guaranteeType: guaranteeType === null ? 'NO_GUARANTEE' : (guaranteeType as keyof typeof GuaranteeTypeEnum),
          guaranteeMonths: guaranteeMonths === 0 || guaranteeMonths === null ? NaN : guaranteeMonths,
          otherKindGuarantee: otherGuarantee ?? '',
          units: supply.map((entry) => ({
            id: entry.unit.id,
            supply: {
              start: entry.startDate ? parseStringToDate(entry.startDate) : null,
              end: entry.endDate ? parseStringToDate(entry.endDate) : null,
            },
          })),
          commissionAnalysis,
        };

        setProposalForBid({ ...parsedProposal });
        setIsProposalLoaded(true);
      },
      onError: () => {
        setProposalForBid({ ...EMPTY_PROPOSAL_FOR_BID_CREATION });
        setIsProposalLoaded(false);
      },
    });
  };

  return [fetchProposalData, proposalForBid, isProposalLoaded];
}
