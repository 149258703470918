import React from 'react';
import { Transition } from '@headlessui/react';

import HeroIcon from '@components/atoms/hero-icon';

interface UnitAccordionItemProps {
  children: React.ReactNode;
  label: string;
  deleteUnitCallback: () => void;
  initiallyOpen?: boolean;
  testId?: string;
}
const UnitAccordionItem: React.FC<UnitAccordionItemProps> = ({
  children,
  label,
  deleteUnitCallback,
  initiallyOpen = true,
  testId,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initiallyOpen);
  const iconClass = isOpen
    ? 'transform -rotate-180 cursor-pointer w-7 h-7'
    : 'transform -rotate-0 cursor-pointer w-7 h-7';
  return (
    <div className="flex flex-col">
      <div className="flex justify-around items-center py-2 mb-4 border-b border-black bg-neutral-10">
        <p className="flex-1 px-6 text-heading-xsmall">{label}</p>
        <div className="flex space-x-3">
          <button type="button" onClick={deleteUnitCallback}>
            <HeroIcon icon="TrashIcon" extraClassNames="h-6 w-6" />
          </button>
          <button type="button" data-cy={testId} onClick={() => setIsOpen(!isOpen)}>
            <HeroIcon icon="ChevronDownIcon" extraClassNames={iconClass} />
          </button>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition origin-top ease-out duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition origin-top ease-out duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </div>
  );
};
export default UnitAccordionItem;
