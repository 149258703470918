import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

const GET_PROCESS_BY_PROPOSAL_ID = gql`
  query GetProcessByProposalId($proposalId: ID!) {
    getProcessByProposalId(proposalId: $proposalId) {
      error
      process {
        id
        name
      }
    }
  }
`;

interface GetProcessByProposalIdGraphQLResponse {
  getProcessByProposalId: {
    error: string | null;
    process: { id: string; name: string } | null;
  };
}

interface ProcessData {
  id: string;
  name: string;
}

const EMPTY_PROCESS_DATA: ProcessData = { id: '', name: '' };

export function useGetProcessByProposalId() {
  const [isProcessDataLoading, setIsProcessDataLoading] = React.useState<boolean>(true);
  const [processData, setProcessData] = React.useState<ProcessData>({ ...EMPTY_PROCESS_DATA });
  const [queryFunction] = useLazyQuery<GetProcessByProposalIdGraphQLResponse, { proposalId: string }>(
    GET_PROCESS_BY_PROPOSAL_ID,
  );

  const fetchProcessData = async (proposalId: string): Promise<void> => {
    await queryFunction({
      variables: { proposalId },
      onCompleted: (data) => {
        if (data.getProcessByProposalId.error !== null || data.getProcessByProposalId.process === null) {
          setProcessData({ ...EMPTY_PROCESS_DATA });
          throw new Error('Server failed to respond with valid process data.');
        }

        const { name, id } = data.getProcessByProposalId.process;
        setProcessData({ name, id });
        setIsProcessDataLoading(false);
      },
      onError: () => {
        setProcessData({ ...EMPTY_PROCESS_DATA });
      },
    });
  };

  return { processData, fetchProcessData, isProcessDataLoading };
}
