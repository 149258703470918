import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FileFormField,
  FormSection,
  NumericFormField,
  RadioFormField,
  SelectFormField,
  TextAreaFormField,
} from '@components/atoms/form';
import { BidFormData } from '@components/molecules/create-bid/types';
import { ClarkeCommissionTypeEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';
import { FormTextInput } from '@components/atoms/form/text';

const ThirdStep: React.FC = () => {
  const { watch, unregister } = useFormContext<BidFormData>();
  const watchGuaranteeType = watch('commercialConditions.guaranteeType');

  const hasToSelectBillingMonths =
    watchGuaranteeType &&
    ['SURETY_BOND', 'ADVANCE_PAYMENT', 'BANK_GUARANTEE', 'GUARANTEE_LETTER'].includes(watchGuaranteeType);
  const hasToFillOtherKindOfGuarantee = watchGuaranteeType && watchGuaranteeType === 'OTHER';

  return (
    <div className="grid grid-cols-1 gap-x-5 py-6 md:grid-cols-3">
      <FormSection
        title="Pagamento e financeiro"
        subtitle="Informe a garantia, data de pagamento e comissão da Clarke"
      />
      <div className="grid grid-cols-2 col-span-2 gap-y-4 gap-x-8 grow">
        <div className="col-span-full md:col-span-1">
          <SelectFormField<BidFormData>
            field="commercialConditions.guaranteeType"
            id="guarantee-type"
            label="Qual o tipo de Garantia?"
            inputOptions={(Object.keys(GuaranteeTypeEnum) as (keyof typeof GuaranteeTypeEnum)[]).map((key) => ({
              value: key,
              optionLabel: GuaranteeTypeEnum[key],
            }))}
            options={{
              required: { value: true, message: 'O tipo de garantia financeira deve ser escolhido' },

              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                if (['OTHER', 'REGISTRATION_AGAINST_PAYMENT', 'NO_GUARANTEE'].includes(e.target.value)) {
                  unregister('commercialConditions.guaranteeMonths');

                  if (['REGISTRATION_AGAINST_PAYMENT', 'NO_GUARANTEE'].includes(e.target.value)) {
                    unregister('commercialConditions.otherKindGuarantee');
                  }
                }
              },
            }}
          />
        </div>
        <div className="col-span-full md:col-span-1">
          {hasToSelectBillingMonths && (
            <SelectFormField<BidFormData>
              field="commercialConditions.guaranteeMonths"
              id="guarantee-months"
              label="Meses de faturamento"
              placeholder="Selecione a quantidade de meses"
              inputOptions={[
                { value: '1', optionLabel: '1 mês' },
                { value: '2', optionLabel: '2 meses' },
                { value: '3', optionLabel: '3 meses' },
                { value: '4', optionLabel: '4 meses' },
                { value: '5', optionLabel: '5 meses' },
                { value: '6', optionLabel: '6 meses' },
                { value: '7', optionLabel: '7 meses' },
                { value: '8', optionLabel: '8 meses' },
                { value: '9', optionLabel: '9 meses' },
                { value: '10', optionLabel: '10 meses' },
                { value: '11', optionLabel: '11 meses' },
                { value: '12', optionLabel: '12 meses' },
              ]}
              options={{ required: { value: true, message: 'A quantidade de meses deve ser escolhida' } }}
            />
          )}
          {hasToFillOtherKindOfGuarantee && (
            <FormTextInput<BidFormData>
              id="other-kind-guarantee"
              field="commercialConditions.otherKindGuarantee"
              label="Informe outra garantia"
              placeholder="Digite o outro tipo de garantia"
              options={{ required: { value: true, message: 'Defina outra sugestão de garantia' } }}
            />
          )}
        </div>
        <div className="col-span-full md:col-span-1">
          <NumericFormField<BidFormData>
            field="commercialConditions.payDay"
            id="payday"
            label="Dia do pagamento"
            formatProps={{
              placeholder: 'Insira o dia do pagamento',
              format: '##° dia',
              fixedDecimalScale: true,
              decimalScale: 0,
              allowNegative: false,
            }}
            options={{
              min: 1,
              max: 31,
              required: { value: true, message: 'Defina o dia de pagamento' },
            }}
          />
        </div>
        <div className="col-span-full">
          <RadioFormField<BidFormData>
            field="commercialConditions.clarkeCommissionType"
            label="Comissão da Clarke"
            inputOptions={(Object.keys(ClarkeCommissionTypeEnum) as (keyof typeof ClarkeCommissionTypeEnum)[]).map(
              (key) => ({ id: key.toLowerCase(), optionLabel: ClarkeCommissionTypeEnum[key], value: key }),
            )}
            options={{ required: { value: true, message: 'Escolha uma opção' } }}
          />
        </div>
      </div>
      <div className="col-span-full">
        <FileFormField<BidFormData>
          name="commercialConditions.files"
          id="bid-files"
          label={{ text: 'Proposta' }}
          sublabel={{ text: 'Anexe sua proposta e se quiser, faça observações para aprofundar algum ponto.' }}
          fileSizeLimitInBytes={30 * 1020 * 1024}
          fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
          multiple
        />
      </div>
      <div className="col-span-full">
        <TextAreaFormField<BidFormData> field="commercialConditions.note" id="bid-note" label="Observações" />
      </div>
    </div>
  );
};

export default ThirdStep;
