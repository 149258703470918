import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, useNotification } from '@clarke-energia/foton';

import { BIDS_SENT_BY_TRADER_PATH, DOES_NOT_EXIST_PATH, VIEW_BID_LIST_PATH } from '@routers/constants';
import { useTrader } from '@routers/trader-route';

import { parseStringToDate } from '@utils/text';

import { extractBidForCreation, useCreateBid, useCreateBidDocument } from '@hooks/bids/create-bid';
import useAuth from '@hooks/auth';
import { DocumentTypeForBid } from '@hooks/bids/create-bid/mutations';

import { useTraderProposal } from '@components/layout/trader-proposal-round-layout';
import { FileFormField, FormField, SelectFormField } from '@components/atoms/form';
import { Bid, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

interface FinalBidFormData {
  bidDeadline: { date: string; time: string };
  files: File[];
}
interface CreateFinalBidFormProps {
  onFormCancelCallback: () => void;
  onFormSubmitCallback: (data: FinalBidFormData) => Promise<void>;
}
const CreateFinalBidForm: React.FC<CreateFinalBidFormProps> = ({ onFormSubmitCallback, onFormCancelCallback }) => {
  const methods = useForm<FinalBidFormData>({
    defaultValues: {
      bidDeadline: { date: '', time: '' },
      files: [],
    },
  });
  return (
    <>
      <FormProvider {...methods}>
        <form className="lg:max-w-[70%] p-4 space-y-4">
          <div className="space-y-2">
            <h2 className="uppercase text-paragraph-small text-neutral-50">FINALIZAÇÃO</h2>
            <h1 className="font-bold text-heading-2xlarge">Enviar proposta final</h1>
          </div>

          <div className="grid grid-cols-2 gap-6 py-6">
            <div className="col-span-full lg:col-span-1">
              <FormField<FinalBidFormData>
                field="bidDeadline.date"
                id="proposal-deadline-date"
                label="Prazo (data) para Entrega da Proposta"
                type="date"
                options={{
                  required: { value: true, message: 'Defina a data de prazo para entrega' },
                }}
              />
            </div>
            <div className="col-span-full lg:col-span-1">
              <SelectFormField<FinalBidFormData>
                field="bidDeadline.time"
                id="proposal-deadline-time"
                label="Prazo (hora) para Entrega da Proposta"
                placeholder="Selecione um prazo"
                inputOptions={[...Array(16).keys()].map((_, index) => {
                  const formattedHour = `${(index + 8).toLocaleString('pt-BR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}:00`;
                  return { optionLabel: formattedHour, value: formattedHour };
                })}
                options={{
                  required: { value: true, message: 'Defina a hora para prazo para entrega' },
                }}
              />
            </div>
            <div className="col-span-full">
              <FileFormField<FinalBidFormData>
                name="files"
                id="proposal-files"
                label={{ text: 'Anexe a proposta final', className: 'text-paragraph-medium' }}
                options={{ required: { value: true, message: 'É preciso selecionar ao menos um arquivo' } }}
                fileSizeLimitInBytes={30 * 1020 * 1024}
                fieldDisplayOptions={{ label: 'Documentos', sublabel: 'PNG, JPG, PDF até 30MB' }}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:max-w-sm">
            <Button kind="secondary" type="button" label="Voltar" onClick={onFormCancelCallback} />
            <Button
              kind="primary"
              type="button"
              label="Enviar proposta"
              customAttrs={{ 'data-cy': 'submit-proposal-button' }}
              onClick={async () => {
                await methods.handleSubmit(onFormSubmitCallback)();
              }}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const getMostRecentBidFromThisTrader = (proposals: Proposal[], traderId: string): Bid => {
  const bids: Bid[] = [];
  proposals.forEach((proposal) => {
    proposal.bids.forEach((bid) => {
      if (bid.trader.id === traderId) {
        bids.push(cloneDeep(bid));
      }
    });
  });

  return bids.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())[0];
};

const CreateFinalBidPage: React.FC = () => {
  const navigate = useNavigate();

  const { proposalId } = useParams();
  const { proposal, processData } = useTraderProposal();
  const { createNotification } = useNotification();

  const createBid = useCreateBid();
  const createBidDocument = useCreateBidDocument();

  const {
    authStatus: { accessToken },
  } = useAuth();

  const { traderData } = useTrader();
  const trader = traderData.id;

  if (!proposalId || proposal.id !== proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const bid = getMostRecentBidFromThisTrader(processData.proposals, trader ?? '');

  const onFormCancelCallback = () => navigate(`${VIEW_BID_LIST_PATH}/${proposalId}/${BIDS_SENT_BY_TRADER_PATH}`);

  const onSubmit = async (data: FinalBidFormData) => {
    const parsedBid = extractBidForCreation(
      bid,
      'ACTIVE',
      parseStringToDate(`${data.bidDeadline.date} ${data.bidDeadline.time}:00`),
    );

    try {
      const createdBid = await createBid(parsedBid, proposalId);
      await Promise.all(
        data.files.map((file) =>
          createBidDocument(file, createdBid.id, accessToken, DocumentTypeForBid.FINAL_PROPOSAL),
        ),
      );

      navigate(`${VIEW_BID_LIST_PATH}/${proposalId}/${BIDS_SENT_BY_TRADER_PATH}`, { state: { createdBid: true } });
    } catch (error) {
      createNotification({
        kind: 'error',
        label: 'Erro!',
        message:
          'Houve um problema ao criar sua proposta. Tente novamente mais tarde ou nos comunique pelos nossos canais de atendimento.',
      });
    }
  };
  return (
    <div className="overflow-y-auto h-full max-h-[calc(100vh-12rem)]">
      <CreateFinalBidForm {...{ onFormSubmitCallback: onSubmit, onFormCancelCallback }} />;
    </div>
  );
};

export default CreateFinalBidPage;
