import { useMutation } from '@apollo/client';

import { UnitFormData } from '@components/molecules/create-proposal/types';
import { Subgroup } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import { createCustomerDocument } from '@services/rest/create-documents';

import { EnergyTypeEnum, TariffModeEnum, UnitTypeEnum } from '@utils/translators/proposal';

import { EditUnitGraphQLInput, UpsertUnitGraphQLResponse, UPSERT_UNIT_MUTATION } from './mutation';

export function useCreateUnitDocument() {
  return (file: File, unitId: string, authToken: string) => {
    const formData = new FormData();
    formData.append('label', `${file.name}`);
    formData.append('file', file);
    formData.append('entity_id', unitId);
    formData.append('entity', 'unit');

    createCustomerDocument(formData, authToken);
  };
}
const parseUnitFormDataIntoGraphQLInput = (unit: UnitFormData, groupId: string): EditUnitGraphQLInput => {
  return {
    id: unit.unitId,
    groupId,
    name: unit.name,
    legalName: unit.companyName,
    unitType: unit.unitType as keyof typeof UnitTypeEnum,
    energyType: unit.energyType as keyof typeof EnergyTypeEnum,
    tariffModality: unit.tariffMode as keyof typeof TariffModeEnum,
    tariffSubgroup: unit.subgroup as Subgroup,
    averageInvoiceAmount: unit.monthlyInvoice,
    edc: unit.edc,
  };
};

export function useEditUnit() {
  const [mutationFunction] = useMutation<UpsertUnitGraphQLResponse, { upsertUnitInput: EditUnitGraphQLInput }>(
    UPSERT_UNIT_MUTATION,
  );

  const editSingleUnit = async (unit: UnitFormData, groupId: string) => {
    const { data, errors } = await mutationFunction({
      variables: { upsertUnitInput: parseUnitFormDataIntoGraphQLInput(unit, groupId) },
    });

    if (errors !== undefined || !data) throw new Error(`Falha ao editar unidade ${unit.unitId}.`);

    const result = data.createUnit;
    return { id: result.id };
  };

  return editSingleUnit;
}
