import { init, track } from '@amplitude/analytics-browser';
import config from '@src/config';

export const amplitudeInit = async (trackPlatform: boolean, trackOsName: boolean): Promise<boolean> => {
  if (config.IS_IN_PRODUCTION && config.AMPLITUDE_API_KEY) {
    try {
      init(config.AMPLITUDE_API_KEY, undefined, {
        trackingOptions: {
          deviceManufacturer: false,
          deviceModel: false,
          ipAddress: false,
          language: false,
          osName: trackOsName,
          osVersion: false,
          platform: trackPlatform,
        },
      });
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }

  return false;
};

export const amplitudeTrackEvent = (
  eventLabel: string,
  eventProperties?: Record<string, string | number>,
): Record<string, any> | null => {
  if (eventLabel) {
    track(eventLabel, eventProperties)
      .promise.then((response) => {
        return {
          code: response.code,
          finalEvent: response.event,
          message: response.message,
        };
      })
      .catch(() => {
        return null;
      });
  }
  return null;
};
