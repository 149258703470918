import { BidForFeedback } from './get-data-for-feedback/types';
import { BidWithSavings } from './savings/types';

export const orderedBidsForSavings = (bids: BidWithSavings[]) =>
  bids.sort((a, b) => {
    // organize by total economy value descending order
    const aPriceValue =
      a.contractType === 'FIXED_PRICE'
        ? a.savings.fixedPrice.groupReport.totalCurrencyAmount
        : a.savings.guaranteedSaving.groupReport.totalCurrencyAmount;
    const bPriceValue =
      b.contractType === 'FIXED_PRICE'
        ? b.savings.fixedPrice.groupReport.totalCurrencyAmount
        : b.savings.guaranteedSaving.groupReport.totalCurrencyAmount;
    return bPriceValue - aPriceValue;
  });

export const orderedBidsForFeedback = (bids: BidForFeedback[]) =>
  bids.sort((a, b) => {
    // organize by total economy value descending order
    const aPriceValue =
      a.contractType === 'FIXED_PRICE'
        ? a.savings.fixedPrice?.groupReport.totalCurrencyAmount
        : a.savings.guaranteedSaving?.groupReport.totalCurrencyAmount;
    const bPriceValue =
      b.contractType === 'FIXED_PRICE'
        ? b.savings.fixedPrice?.groupReport.totalCurrencyAmount
        : b.savings.guaranteedSaving?.groupReport.totalCurrencyAmount;
    return bPriceValue == null ? -1 : aPriceValue == null ? 1 : bPriceValue - aPriceValue;
  });
