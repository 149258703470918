import React from 'react';
import { FieldPath, FormProvider, useForm, UseFormGetValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '@clarke-energia/foton';

import { BIDS_LIST_FOR_PROPOSAL_PATH, FINISH_ROUND_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';
import { useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import LoadingPage from '@components/layout/loading-page';
import { OpenNewRoundFormData } from '@components/molecules/open-new-round/types';
import FormStepHeader from '@components/molecules/rounds-form-step-header';
import { NavigationControls } from '@components/molecules/form-steps-navigation-control';
import FirstStep from '@components/organisms/open-new-round/first-step';
import SecondStep from '@components/organisms/open-new-round/second-step';
import ThirdStep from '@components/organisms/open-new-round/third-step';
import FourthStep from '@components/organisms/open-new-round/fourth-step';
import TraderSelectionProvider from '@components/molecules/open-new-round/trader-selection-context';
import { ConfirmSubmissionDialog } from '@components/molecules/confirm-form-submission-dialog';
import { parseProposalFormDataIntoBaseGraphQLInput } from '@components/organisms/create-proposal/form-helpers';
import { getRefusedFeedbackTemplate } from '@components/organisms/open-new-round/form-helpers';

import { getSelectedBidsWithFeedbacks, useCreateFeedback } from '@hooks/bids/create-feedback';
import { convertUnitSupplyInfo } from '@hooks/proposals/single-proposal/creation/proposal';
import { useUpdateBidStatus } from '@hooks/bids/update';
import { useGetBidDataForFeedback } from '@hooks/bids/get-data-for-feedback';
import { ProposalForFeedback } from '@hooks/bids/get-data-for-feedback/types';
import { RetailTrader, useAllTraders } from '@hooks/proposals';
import { useOpenNewRound } from '@hooks/process/mutations/open-new-round';
import { useGetProcessByProposalId } from '@hooks/process/queries';
import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const renderStepElement = (
  currentStep: number,
  proposal: Proposal,
  dataForFeedback: ProposalForFeedback,
  availableTraders: RetailTrader[],
) => {
  switch (currentStep) {
    case 1:
      return <FirstStep {...{ proposal, dataForFeedback }} />;

    case 2:
      return <SecondStep />;

    case 3:
      return <ThirdStep />;

    case 4:
      return <FourthStep availableTraders={availableTraders} />;

    default:
      throw 'Invalid step number';
  }
};

const getFieldsToBeValidated = (
  step: number,
  formValuesGetterFunction: UseFormGetValues<OpenNewRoundFormData>,
): FieldPath<OpenNewRoundFormData>[] => {
  if (step === 1) return ['tradersThatAreWinners'];
  if (step === 2) return ['feedbacksForTradersThatAreWinners'];
  if (step === 3) {
    const refusedFeedbacks = formValuesGetterFunction('feedbacksForTradersThatAreRefused');
    if (refusedFeedbacks.length !== 0) return ['feedbacksForTradersThatAreRefused'];
  }

  const result: FieldPath<OpenNewRoundFormData>[] = [
    'proposalModality',
    'traders',
    'proposalDeadline.date',
    'proposalDeadline.time',
  ];

  // cash commission for periods
  const commissionModality = formValuesGetterFunction('commissionModality');

  return result;
};

interface OpenNewRoundFormProps {
  proposal: Proposal;
  dataForFeedback: ProposalForFeedback;
  availableTraders: RetailTrader[];
  onSubmitCallback: (data: OpenNewRoundFormData) => void;
}
const OpenNewRoundForm: React.FC<OpenNewRoundFormProps> = ({
  proposal,
  dataForFeedback,
  availableTraders,
  onSubmitCallback,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [isConfirmSubmitDialogOpen, setIsConfirmSubmitDialogOpen] = React.useState<boolean>(false);

  const defaultRefusedTraders = proposal.traders
    .filter((trader) => trader.bid !== undefined)
    .map((trader) => ({
      trader: { id: trader.id, name: trader.name },
      feedback: getRefusedFeedbackTemplate(trader.id, dataForFeedback),
    }));

  const methods = useForm<OpenNewRoundFormData>({
    defaultValues: {
      traders: [],
      tradersThatAreWinners: [],
      feedbacksForTradersThatAreWinners: [],
      feedbacksForTradersThatAreRefused: defaultRefusedTraders,
      lowerFlexibility: proposal.lowerFlexibility,
      upperFlexibility: proposal.upperFlexibility,
      guaranteeType: proposal.guaranteeType,
      guaranteeMonths: proposal.guaranteeMonths as OpenNewRoundFormData['guaranteeMonths'],
      otherKindGuarantee: proposal.otherGuarantee,
      contractType: proposal.contractType,
      commissionModality: '',
    },
  });

  const onCancelFormCallback = () => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${FINISH_ROUND_PATH}`);

  const onStepChangeCallback = (nextStepValue: number) => {
    if (nextStepValue > currentStep) {
      const fields = getFieldsToBeValidated(currentStep, methods.getValues);
      methods.trigger(fields).then((fieldsAreValid) => {
        if (fieldsAreValid) setCurrentStep(nextStepValue);
      });
    } else {
      setCurrentStep(nextStepValue);
    }
  };

  const onLastStepCallback = async () => {
    const fields = getFieldsToBeValidated(4, methods.getValues);
    methods.trigger(fields).then((fieldsAreValid) => {
      if (fieldsAreValid) setIsConfirmSubmitDialogOpen(true);
    });
  };

  return (
    <>
      <div className="overflow-y-auto w-full h-full max-h-[calc(100vh-14rem)]">
        <FormProvider {...methods}>
          <form>
            <div className="p-4 space-y-4">
              <FormStepHeader currentStep={currentStep} operationType="openNewRound" />

              <TraderSelectionProvider>
                <div className="space-y-8">
                  {renderStepElement(currentStep, proposal, dataForFeedback, availableTraders)}
                  <NavigationControls
                    currentStep={currentStep}
                    controlParams={{
                      maxStep: 4,
                      textLabels: {
                        changeStep: 'Próximo passo',
                        endStep: 'Abrir rodada',
                      },
                      cancelFormCallback: onCancelFormCallback,
                      changeStepCallback: onStepChangeCallback,
                      endStepCallback: onLastStepCallback,
                    }}
                  />
                </div>
              </TraderSelectionProvider>
              {currentStep === 4 && (
                <ConfirmSubmissionDialog
                  displayOptions={{
                    titleText: `Tudo certo para enviar os feedbacks e abrir a ${proposal.round + 1}ª rodada?`,
                    buttonSubmitText: 'Abrir nova rodada',
                  }}
                  isOpen={isConfirmSubmitDialogOpen}
                  confirmDialogCallback={async () => {
                    await methods.handleSubmit(onSubmitCallback)();
                    setIsConfirmSubmitDialogOpen(false);
                  }}
                  closeDialogCallback={() => setIsConfirmSubmitDialogOpen(false)}
                />
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

const OpenNewRoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const { proposal } = useAdminProposal();
  const [allTraders] = useAllTraders();
  const { fetchData, proposalData, isLoading } = useGetBidDataForFeedback();
  const { fetchProcessData, processData } = useGetProcessByProposalId();

  const updateBidStatus = useUpdateBidStatus();
  const createFeedback = useCreateFeedback();
  const openNewRound = useOpenNewRound();

  React.useEffect(() => {
    if (proposal.id) {
      fetchData(proposal.id);
      fetchProcessData(proposal.id);
    }
  }, [proposal.id]);

  const onSubmit = async (data: OpenNewRoundFormData) => {
    const { feedbacksForTradersThatAreWinners, feedbacksForTradersThatAreRefused } = data;
    const selectedBids = getSelectedBidsWithFeedbacks(feedbacksForTradersThatAreWinners, proposal, 'STAGE_WINNER');
    const refusedBids = getSelectedBidsWithFeedbacks(feedbacksForTradersThatAreRefused, proposal, 'STAGE_REFUSED');

    // Create new proposal round
    const contractData = { ...data, traders: data.traders, files: [] };
    const payload = parseProposalFormDataIntoBaseGraphQLInput({
      contract: contractData,
      client: {
        id: proposal.group.id,
        name: proposal.group.name,
        docType: proposal.group.docType,
        document: proposal.group.docNumber,
        companyName: proposal.group.legalName,
      },
      units: convertUnitSupplyInfo(proposal),
      selectedUnitIds: proposal.units.map((unit) => unit.id),
    });

    let createdProposalId: string;
    try {
      const createdProposal = await openNewRound(processData.id, payload);
      createdProposalId = createdProposal.id;
    } catch (error) {
      createNotification({
        kind: 'error',
        label: 'Erro!',
        message: `Não foi possível abrir a nova rodada. Por favor, avise ao suporte técnico, informando o seguinte erro: ${error}`,
      });
      return;
    }

    // Update bids and create feedbacks
    await Promise.all(
      selectedBids.map(async ({ bidId, bid, feedback }) => {
        await updateBidStatus(bidId, bid.status);
        await createFeedback({
          bidId,
          nextProposalId: createdProposalId,
          feedback: feedback.feedback,
        });
      }),
    );

    await Promise.all(
      refusedBids.map(async ({ bidId, bid, feedback }) => {
        await updateBidStatus(bidId, bid.status);
        await createFeedback({
          bidId,
          nextProposalId: createdProposalId,
          feedback: feedback.feedback,
        });
      }),
    );

    navigate(`${VIEW_PROPOSAL_LIST_PATH}/${createdProposalId}/${BIDS_LIST_FOR_PROPOSAL_PATH}`, { replace: true });
  };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <OpenNewRoundForm
      proposal={proposal}
      dataForFeedback={proposalData}
      availableTraders={allTraders}
      onSubmitCallback={onSubmit}
    />
  );
};

export default OpenNewRoundPage;
