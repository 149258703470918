import { gql } from '@apollo/client';

const PROPOSAL_FIELDS_FRAGMENT = gql`
  fragment ProposalFields on Proposal {
    id
    round
    bids {
      lowerFlexibility
      upperFlexibility
      guaranteeType
      retailService
      coverCceeTaxes

      savingsBasePrice {
        isInsideScope
        result {
          totalSavings
          rawPrices {
            value
            year
          }
        }
      }

      trader {
        id
      }
    }
  }
`;

export const GET_PROCESS_SAVINGS_BASE_PRICE_BY_PROPOSAL_ID_AND_TRADER_ID = gql`
  ${PROPOSAL_FIELDS_FRAGMENT}
  query GetProcessByProposalAndTraderId($proposalId: ID!, $traderId: ID!) {
    getProcessByProposalIdForTrader(proposalId: $proposalId, traderId: $traderId) {
      error
      process {
        proposals {
          ...ProposalFields
        }
      }
    }
  }
`;
