import { gql } from '@apollo/client';
import { ContractTypeEnum, CoverCeeeCostEnum, GuaranteeTypeEnum } from '@utils/translators/proposal';

export const UPSERT_BID_MUTATION = gql`
  mutation UpsertBid($upsertBidInput: UpsertBidInput!) {
    upsertBid(input: $upsertBidInput) {
      error
      bid {
        id
      }
    }
  }
`;

export interface UpsertBidGraphQLResponse {
  upsertBid: { error: string | null; bid: { id: string } };
}

export interface CreateBidGraphQLInput {
  upsertBidInput: {
    proposalId: string;
    status?: number;
    traderId: string;
    contractType: keyof typeof ContractTypeEnum;
    note: string;
    clarkeCommissionNote: string;
    guaranteeType?: Omit<keyof typeof GuaranteeTypeEnum, 'NO_GUARANTEE'>;
    otherGuaranteeType: string | null;
    guaranteeMonths: number | null;
    retailService: boolean;
    coverMeterSystem: boolean;
    meterSystemAmount: number | null;
    coverCceeCost: keyof typeof CoverCeeeCostEnum;
    coverCceeTaxes: boolean;
    periods: number[];
    deadline: string;
    lowerFlexibility: number;
    upperFlexibility: number;
    payDay: number;
    economy: { year: number; amount: number }[];
  };
}

export interface UpdateBidGraphQLInput {
  upsertBidInput: {
    id: string;
    status: number;
  } & CreateBidGraphQLInput['upsertBidInput'];
}

export enum DocumentTypeForBid {
  PROPOSAL = 0,
  FINAL_PROPOSAL = 1,
  SIGNED_PROPOSAL = 2,
  OTHERS = 3,
}
