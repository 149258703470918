import React from 'react';

import HeroIcon from '@components/atoms/hero-icon';
import { Transition } from '@headlessui/react';

interface AccordionItemProps {
  children: React.ReactNode;
  label: string;
  descriptionElement?: React.ReactNode;
  initiallyOpen?: boolean;
  testId?: string;
}
export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  descriptionElement,
  label,
  initiallyOpen = false,
  testId,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initiallyOpen);
  const iconBaseClass = 'cursor-pointer w-6 h-6 transition-transform ease-linear duration-300';
  const rotateClass = isOpen ? '-rotate-180' : '-rotate-0';
  const iconClass = `${iconBaseClass} ${rotateClass}`;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center py-2 pr-3 pl-6 bg-gray-50 rounded border border-black">
        <div className="flex items-center w-full">
          <p className="font-medium text-paragraph-medium grow">{label}</p>
          <div className="flex items-center space-x-4 grow-0">
            {descriptionElement}
            <button type="button" data-cy={testId} onClick={() => setIsOpen(!isOpen)}>
              <HeroIcon icon="ChevronDownIcon" extraClassNames={iconClass} />
            </button>
          </div>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition origin-top ease-out duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition origin-top ease-out duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </div>
  );
};

export default AccordionItem;
