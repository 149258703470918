import { gql, useMutation } from '@apollo/client';
import {
  CommissionModalityTypeEnum,
  ContractTypeEnum,
  GuaranteeTypeEnum,
  ProposalTypeEnum,
} from '@utils/translators/proposal';

interface EditProposalGraphQLInput {
  id: string;
  traders: string[];
  units: { id: string; startDate: string | null; endDate: string | null }[];
  groupId: string;
  proposalType: keyof typeof ProposalTypeEnum;
  contractType: keyof typeof ContractTypeEnum;
  periods: number[];
  deadline: string;
  guaranteeType: Omit<keyof typeof GuaranteeTypeEnum, 'NO_GUARANTEE'> | null;
  guaranteeMonths: number | null;
  otherGuarantee: string | null;
  lowerFlexibility: number;
  upperFlexibility: number;
  commissionModality: keyof typeof CommissionModalityTypeEnum;
  note?: string;
}

interface EditProposalGraphQLResponse {
  editProposalFromProcess: { error: string | null; proposal: { id: string } | null };
}

const EDIT_PROPOSAL_FROM_PROCESS_STR = gql`
  mutation EditProposalFromProcess($proposalInput: EditProposalInput!) {
    editProposalFromProcess(proposalInput: $proposalInput) {
      error
      proposal {
        id
      }
    }
  }
`;

export function useEditProposal() {
  const [mutationFunction] = useMutation<EditProposalGraphQLResponse, { proposalInput: EditProposalGraphQLInput }>(
    EDIT_PROPOSAL_FROM_PROCESS_STR,
  );

  const editProposal = async (proposalInput: EditProposalGraphQLInput): Promise<{ id: string }> => {
    const { data, errors } = await mutationFunction({ variables: { proposalInput } });

    if (
      errors !== undefined ||
      !data ||
      data.editProposalFromProcess.error !== null ||
      data.editProposalFromProcess.proposal === null
    )
      throw new Error('Falha ao editar proposta.');

    const result = data.editProposalFromProcess.proposal;
    return { id: result.id };
  };

  return editProposal;
}
