import React from 'react';

const EmptyNonFinalBidMessageDisplay: React.FC = () => (
  <div className="p-5 sm:p-10">
    <h1 className="pb-5 text-heading-2xlarge text-medium">Sem proposta :(</h1>
    <p className="pb-5 w-4/5 text-paragraph-medium">
      Essa fornecedora ainda não enviou proposta para o cliente. Atente-se ao prazo do envio, caso tenha excedido, siga
      para o próximo passo, ou cobre a fornecedora por e-mail.
    </p>
  </div>
);

export default EmptyNonFinalBidMessageDisplay;
