import { Table, ColumnOptions } from '@clarke-energia/foton';
import { ValuesReport } from '@hooks/bids/savings/types';
import { ParsedBidsData } from '@pages/commercial-group/savings-result';
import { formatCurrency } from '@utils/text';
import React from 'react';

interface TableProps {
  data: ParsedBidsData[];
}
interface DataTable extends TableProps {
  expandedContent: JSX.Element;
}

interface Options {
  bold: boolean;
  border?: 'border-y' | 'border-t' | 'border-b';
  bgColor?: string;
}
const getCellClass = (opts?: Options): string => {
  return `py-4 ${opts?.bold ? 'font-bold' : 'font-normal'} text-start border-neutral-20 ${
    opts ? opts.border : 'border-y'
  } text-paragraph-small ${opts?.bgColor ? opts.bgColor : ''}`;
};

const SavingsDetailTable: React.FC<{ report: ValuesReport }> = ({ report }) => {
  const totalManagementCost = report.valuesPerYear.reduce((total, value) => total + value.management, 0);
  const totalACLCost = report.valuesPerYear.reduce((total, value) => total + value.aclCost, 0);
  return (
    <div className="py-6 pl-7">
      <table className="w-full">
        <thead>
          <tr>
            <th
              className="py-4 px-5 font-extrabold text-paragraph-small text-start"
              colSpan={report.valuesPerYear.length + 2}
            >
              Detalhamento
            </th>
          </tr>
          <tr>
            <th className={getCellClass()}></th>
            {report.valuesPerYear.map(({ year }) => (
              <th key={year} className={getCellClass()}>
                {year}
              </th>
            ))}
            <th className={getCellClass()}>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {/* Energy Prices */}
          <tr>
            <td className={getCellClass()}>Preço de energia</td>
            {report.valuesPerYear.map(({ year, energyPrice }) => (
              <React.Fragment key={year}>
                <td className={getCellClass()}>{`${formatCurrency(energyPrice)}/MWh`}</td>
              </React.Fragment>
            ))}
            <td className={getCellClass()}>-</td>
          </tr>

          {/* ACR Costs */}
          <tr>
            <td className={getCellClass()}>Custo ACR</td>
            {report.valuesPerYear.map(({ year, acrCost }) => (
              <React.Fragment key={year}>
                <td className={getCellClass()}>{formatCurrency(acrCost)}</td>
              </React.Fragment>
            ))}
            <td className={getCellClass()}>
              {formatCurrency(report.valuesPerYear.reduce((total, value) => total + value.acrCost, 0))}
            </td>
          </tr>

          {/* ACL Costs */}
          <tr>
            <td className={getCellClass()}>Custo ACL</td>
            {report.valuesPerYear.map(({ year, aclCost, management }) => (
              <React.Fragment key={year}>
                <td className={getCellClass()}>{formatCurrency(aclCost - management)}</td>
              </React.Fragment>
            ))}
            <td className={getCellClass()}>{formatCurrency(totalACLCost - totalManagementCost)}</td>
          </tr>

          {/* Management */}
          <tr>
            <td className={getCellClass()}>Custo de Gestão</td>
            {report.valuesPerYear.map(({ year, management }) => (
              <React.Fragment key={year}>
                <td className={getCellClass()}>{formatCurrency(management)}</td>
              </React.Fragment>
            ))}
            <td className={getCellClass()}>{formatCurrency(totalManagementCost)}</td>
          </tr>

          {/* Initial expenses */}
          <tr>
            <td className={getCellClass()}>Investimento Inicial</td>
            {report.valuesPerYear.map(({ year }) => (
              <React.Fragment key={year}>
                <td className={getCellClass()}>-</td>
              </React.Fragment>
            ))}
            <td className={getCellClass()}>{formatCurrency(report.initialExpenses)}</td>
          </tr>

          {/* Total savings */}
          <tr>
            <td className={getCellClass({ bold: true, bgColor: 'bg-primary-60', border: 'border-y' })}>
              Economia líquida anual
            </td>
            {report.valuesPerYear.map(({ year, totalSavingsAmount }) => (
              <React.Fragment key={year}>
                <td className={getCellClass({ bold: true, bgColor: 'bg-primary-60', border: 'border-y' })}>
                  {formatCurrency(totalSavingsAmount)}
                </td>
              </React.Fragment>
            ))}
            <td className={getCellClass({ bold: true, bgColor: 'bg-primary-60', border: 'border-y' })}>
              {formatCurrency(report.totalCurrencyAmount)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TableDisplay: React.FC<TableProps> = ({ data }) => {
  const mainTableColumns: ColumnOptions<ParsedBidsData>[] = [
    { accessor: 'traderName', header: 'Fornecedora', renderAsElement: (entry) => entry.traderName },
    { accessor: 'totalEconomy', header: 'Economia', renderAsElement: (entry) => entry.totalEconomy },
    { accessor: 'npv', header: 'VLP de economia', renderAsElement: (entry) => entry.npv },
    { accessor: 'flexibility', header: 'Flexibilidade', renderAsElement: (entry) => entry.flexibility },
    { accessor: 'guaranteeType', header: 'Garantia', renderAsElement: (entry) => entry.guaranteeType },
    { accessor: 'traderType', header: 'Modalidade de contrato', renderAsElement: (entry) => entry.traderType },
    { accessor: 'deadline', header: 'Validade da proposta', renderAsElement: (entry) => entry.deadline },
    { accessor: 'cceeCoverTaxes', header: 'Encargos CCEE', renderAsElement: (entry) => entry.cceeCoverTaxes },
  ];

  const expandedDataContent = (data: ParsedBidsData[]) =>
    data.map((item) => {
      return {
        data: { ...item },
        expandedContent: <SavingsDetailTable report={item.reportForDisplay} />,
      };
    });

  return (
    <div className="overflow-x-auto overflow">
      <Table tableColumns={mainTableColumns} data={expandedDataContent(data)} expandable />
    </div>
  );
};
export default TableDisplay;
