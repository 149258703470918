import React from 'react';

import ProposalDisplay from '@components/molecules/start-compilation/proposal-display';
import BidForCalculationSelector from '@components/molecules/start-compilation/bid-for-calculation-selector';

import { Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const FirstStep: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
  return (
    <>
      <ProposalDisplay proposal={proposal} />
      <BidForCalculationSelector />
    </>
  );
};

export default FirstStep;
