// Private paths

// Commercial group
export const VIEW_SAVINGS_RESULTS_PATH = '/resultados';

// Admin paths + suffixes
export const HOME_PATH = '/';
export const CREATE_PROPOSAL_PATH = '/criar-proposta';
export const VIEW_PROPOSAL_LIST_PATH = '/solicitacoes';
export const EDIT_PROPOSAL_SUFFIX_PATH = 'editar-proposta';
export const FINISH_ROUND_PATH = 'finalizar-etapa';
export const OPEN_NEW_ROUND_PATH = 'abrir-nova-rodada';
export const SEND_SIGNED_PROPOSAL_PATH = 'enviar-proposta-assinada';
export const OPEN_FINAL_ROUND_PATH = 'abrir-rodada-final';
export const BIDS_LIST_FOR_PROPOSAL_PATH = 'ofertas';
export const PROPOSAL_INFO_PATH = 'info';
export const COMPILATION_SUFFIX_PATH = 'compilacao';
export const START_COMPILATION_SUFFIX_PATH = 'iniciar-compilacao';
export const PREVIEW_CUSTOMER_COMPILATION_SUFFIX_PATH = 'resultado-compilacao';

// Trader paths + suffixes
export const VIEW_BID_LIST_PATH = '/ofertas';
export const CREATE_BID_PATH_SUFFIX = 'criar-proposta'; // named criar-proposta instead of criar-oferta to ensure consistency with trader view
export const CREATE_FINAL_BID_PATH_SUFFIX = 'criar-proposta-final';
export const BIDS_SENT_BY_TRADER_PATH = 'propostas';
export const BIDS_RAKING = 'ranking';
export const PROPOSAL_INFO_FOR_TRADER_PATH = 'info';
export const EDIT_BID_PATH_SUFFIX_PATH = 'editar-proposta';

// 404
export const DOES_NOT_EXIST_PATH = '/does-not-exist';
