import { gql } from '@apollo/client';

export const CREATE_FEEDBACK_MUTATION = gql`
  mutation CreateBidFeedback($createFeedbackInput: CreateFeedbackInput!) {
    createFeedback(input: $createFeedbackInput) {
      id
    }
  }
`;

export interface CreateFeedbackGraphQLInput {
  createFeedbackInput: {
    bidId: string;
    nextProposalId: string;
    text: string;
  };
}

export interface CreateFeedbackGraphQLResponse {
  createFeedback: {
    id: string;
  };
}

export const CREATE_FEEDBACK_SIGNED_PROCESS_MUTATION = gql`
  mutation CreateBidFeedbackForSignedProposal($createFeedbackInput: CreateFeedbackForSignedProposalInput!) {
    createFeedbackForSignedProposal(input: $createFeedbackInput) {
      error
      feedback {
        id
      }
    }
  }
`;

export interface CreateFeedbackForSignedProposalGraphQLInput {
  createFeedbackInput: {
    bidId: string;
    text: string;
  };
}

export interface CreateFeedbackForSignedProposalGraphQLResponse {
  createFeedbackForSignedProposal: {
    error: string | null;
    feedback: {
      id: string;
    } | null;
  };
}

export const CREATE_REFUSED_FEEDBACK_SIGNED_PROCESS_MUTATION = gql`
  mutation CreateRefusedBidFeedbackForSignedProposal($createFeedbackInput: CreateFeedbackForSignedProposalInput!) {
    createRefusedFeedbackForSignedProposal(input: $createFeedbackInput) {
      error
      feedback {
        id
      }
    }
  }
`;

export interface CreateRefusedFeedbackForSignedProposalGraphQLInput {
  createFeedbackInput: {
    bidId: string;
    text: string;
  };
}

export interface CreateRefusedFeedbackForSignedProposalGraphQLResponse {
  createRefusedFeedbackForSignedProposal: {
    error: string | null;
    feedback: {
      id: string;
    } | null;
  };
}
