import React from 'react';
import { orderedBidsForFeedback } from '@hooks/bids/utils';
import { useLazyQuery } from '@apollo/client';
import { GET_BID_DATA_FOR_FEEDBACK } from './queries';
import { ProposalForFeedback } from './types';

const EMPTY_DATA: ProposalForFeedback = {
  id: '',
  contractType: 'FIXED_PRICE',
  round: NaN,
  bids: [],
};

export function useGetBidDataForFeedback() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [proposalData, setProposalData] = React.useState<ProposalForFeedback>({ ...EMPTY_DATA });
  const [queryFunction] = useLazyQuery<{ proposal: ProposalForFeedback }, { proposalId: string }>(
    GET_BID_DATA_FOR_FEEDBACK,
  );

  const fetchData = async (proposalId: string) => {
    queryFunction({
      fetchPolicy: 'network-only',
      variables: { proposalId },
      onCompleted: (data) => {
        orderedBidsForFeedback(data.proposal.bids);
        setProposalData(data.proposal);
        setIsLoading(false);
      },
      onError: () => {
        setProposalData({ ...EMPTY_DATA });
        setIsLoading(false);
      },
    });
  };

  return { proposalData, fetchData, isLoading };
}
