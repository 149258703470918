import React, { useEffect } from 'react';
import { NumericFormField } from '@components/atoms/form';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { CommissionModalityTypeEnum } from '@utils/translators/proposal';

interface CommissionPerYearFieldsProps {
  startYear: number;
  periods: number;
  commissionModality: keyof typeof CommissionModalityTypeEnum;
}

export const CommissionPerYearFields: React.FC<CommissionPerYearFieldsProps> = ({
  startYear,
  periods,
  commissionModality,
}) => {
  const { control } = useFormContext<ProposalCompilationFormData>();

  const fieldName = `taxesAndCommissions.commissionPerYear` as const;

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  useEffect(() => {
    remove();
    for (let i = 0; i < periods; i++) {
      append({ year: startYear + i, commissionValue: 0, commissionModality });
    }
  }, [startYear, periods, append, remove]);

  return (
    <div className="w-full">
      <p className="text-paragraph-medium">Valor da comissão ano a ano (R$):</p>
      <div className="grid grid-cols-1 gap-6 w-full md:grid-cols-6">
        {fields.map((field, index) => (
          <NumericFormField<ProposalCompilationFormData>
            key={field.id}
            id={`commission-per-year.${field.year}`}
            className="flex flex-col space-y-1 w-full"
            field={`${fieldName}.${index}.commissionValue` as const}
            label={`${field.year}`}
            formatProps={{
              placeholder: 'R$',
              fixedDecimalScale: true,
              decimalScale: 2,
            }}
            options={{
              required: { value: true, message: 'Insira o valor da comissão para o ano' },
            }}
          />
        ))}
      </div>
    </div>
  );
};
