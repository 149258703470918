import React from 'react';
import { ProposalProcessStatusEnum } from '@hooks/process/queries/get-process-list-for-admin';

const getRoundTagColorClasses = (status: keyof typeof ProposalProcessStatusEnum): string => {
  switch (ProposalProcessStatusEnum[status]) {
    case ProposalProcessStatusEnum.FINISHED:
      return 'bg-primary-10 text-primary-90';

    case ProposalProcessStatusEnum.ONGOING_ROUND:
      return 'bg-neutral-20';

    case ProposalProcessStatusEnum.FINAL_ROUND_EXPIRED:
      return 'bg-danger-10 text-danger-60';

    case ProposalProcessStatusEnum.FINAL_ROUND_WAITING:
      return 'bg-warning-10 text-warning-70';
    default:
      throw new Error('Invalid round state.');
  }
};

interface RoundLabelTagProps {
  status: keyof typeof ProposalProcessStatusEnum;
  round: number;
}
export const RoundLabelTag: React.FC<RoundLabelTagProps> = ({ status, round }) => {
  const text = status === 'ONGOING_ROUND' ? `${round}ª Rodada` : ProposalProcessStatusEnum[status];
  const colorClass = getRoundTagColorClasses(status);
  return <div className={`w-fit px-4 rounded-lg ${colorClass} normal-case`}>{text}</div>;
};
