import React from 'react';

interface FormSectionProps {
  title: string;
  subtitle: string;
}
export const FormSection: React.FC<FormSectionProps> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col space-y-1">
      <p className="font-bold">{title}</p>
      <p className="text-paragraph-medium text-neutral-50">{subtitle}</p>
    </div>
  );
};
