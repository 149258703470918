import { GuaranteeTypeEnum } from '@utils/translators/proposal';
import { Ranking, BidSavinsBasePrice, ProcessSavingsBasePrice } from './types';
import { TraderInfo } from '@routers/trader-route';

export const formatRanking = (bids: BidSavinsBasePrice[], targetTrader: TraderInfo): Ranking[] => {
  const bidsSortedByPrice = sortByPrices(bids);

  const ranking = bidsSortedByPrice.map((bid, idx) => {
    const {
      trader,
      coverCceeTaxes,
      guaranteeType,
      lowerFlexibility,
      upperFlexibility,
      retailService,
      savingsBasePrice: {
        isInsideScope,
        result: { rawPrices },
      },
    } = bid;

    const traderName = trader?.id === targetTrader.id ? targetTrader.name : 'º Colocado';
    return {
      isInsideScope,
      flex: `-${lowerFlexibility}%/+${upperFlexibility}%`,
      coverCceeTaxes: coverCceeTaxes ? 'Sim, há cobertura' : 'Não há cobertura',
      guaranteeType: GuaranteeTypeEnum[guaranteeType] ?? 'Sem garantia',
      retailService: retailService ? 'Varejista' : 'Atacadista',
      trader: { name: traderName, id: trader.id },
      prices: rawPrices,
      position: idx + 1,
    };
  });

  return ranking;
};

const sortByPrices = (ranking: BidSavinsBasePrice[]) =>
  ranking.sort((rankingkA, rankingB) => {
    const {
      savingsBasePrice: {
        result: { totalSavings: totalSavingsA },
      },
    } = rankingkA;
    const {
      savingsBasePrice: {
        result: { totalSavings: totalSavingsB },
      },
    } = rankingB;

    return totalSavingsB - totalSavingsA;
  });

export const findTargetProposal = (proposals: ProcessSavingsBasePrice['proposals'], proposalId: string) =>
  proposals ? proposals.find((proposal) => proposal.id === proposalId) : null;
