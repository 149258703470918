import React from 'react';

import { formatDate } from '@utils/text';
import { LayoutWithRoundActionsBar, useAdminProposal } from '@components/layout/admin-proposal-round-layout';
import AccordionItem from '@components/molecules/accordion';
import FinalBidContentsDisplay from '@components/molecules/proposal-round/final-bid-contents-display';
import NonFinalBidContentsDisplay from '@components/molecules/proposal-round/non-final-bid-contents-display';
import { BidStatusDescriptor } from '@components/organisms/admin-bid-summary';
import cloneDeep from 'lodash.clonedeep';

const RoundBidsListPage: React.FC = () => {
  const { proposal } = useAdminProposal();
  const roundLabel = proposal.round === 0 ? 'Rodada Final' : `${proposal.round}ª Rodada`;
  const roundTitle = proposal.status === 'FINISHED' ? `${roundLabel} finalizada` : `${roundLabel} aberta`;

  const tradersList = cloneDeep(proposal.traders).sort((a, b) =>
    a.name.localeCompare(b.name, 'pt-BR', { numeric: true, sensitivity: 'base' }),
  );

  const isFinalRound = proposal.round === 0;
  return (
    <LayoutWithRoundActionsBar proposal={proposal}>
      <div className="flex flex-col space-y-2">
        <p className="text-paragraph-small text-neutral-50">{formatDate(proposal.createdAt).toUpperCase()}</p>
        <p className="text-heading-xlarge">{roundTitle}</p>
      </div>
      <div className="flex flex-col space-y-4">
        {tradersList.map((trader, index) => (
          <div key={index} className="col-span-10">
            <AccordionItem
              label={trader.name}
              initiallyOpen={false}
              descriptionElement={<BidStatusDescriptor {...{ proposal, trader }} />}
            >
              {isFinalRound ? (
                <FinalBidContentsDisplay
                  bid={trader.bid ?? null}
                  gmv={proposal.gmv}
                  carbonDioxide={proposal.carbonDioxide}
                  takeRate={proposal.takeRate}
                />
              ) : (
                <NonFinalBidContentsDisplay bid={trader.bid ?? null} />
              )}
            </AccordionItem>
          </div>
        ))}
      </div>
    </LayoutWithRoundActionsBar>
  );
};

export default RoundBidsListPage;
