import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DocumentFile } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

const GET_DOCUMENT_LIST_BY_ENTITY = gql`
  query GetDocumentList($entityId: ID!) {
    documents(entityId: $entityId) {
      data {
        entity
        entityId
        label
        uri
        docType
        deletedAt
      }
    }
  }
`;
interface DocumentListGraphQLResponse {
  documents: {
    data: {
      id: string;
      entity: string;
      entityId: string;
      label: string;
      uri: string;
      docType: string;
      deletedAt: string | null;
      __typename?: string;
    }[];
  };
}
export function useFetchDocumentsByEntity(entityId: string) {
  const [documentList, setDocumentList] = React.useState<DocumentFile[]>([]);
  useQuery<DocumentListGraphQLResponse, { entityId: string }>(GET_DOCUMENT_LIST_BY_ENTITY, {
    variables: {
      entityId,
    },
    onCompleted: (data) => {
      setDocumentList(
        data.documents.data
          .filter((document) => document.deletedAt === null)
          .map(({ id, entity, entityId, label, uri, docType }) => ({
            id,
            entity,
            entityId,
            label,
            uri,
            docType: docType as DocumentFile['docType'],
          })),
      );
    },
    onError: () => {
      setDocumentList([]);
    },
  });

  return documentList;
}

const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocument($input: DeleteDocumentInput) {
    deleteDocument(input: $input) {
      deletedAt
    }
  }
`;

interface DeleteDocumentGraphQLResponse {
  deleteDocument: {
    deletedAt: string | null;
    __typename?: string;
  };
}

export function useDeleteDocument(): (id: string) => Promise<boolean> {
  const [mutationFunction] = useMutation<DeleteDocumentGraphQLResponse, { input: { id: string } }>(
    DELETE_DOCUMENT_MUTATION,
  );

  const deleteDocument = async (id: string) => {
    try {
      await mutationFunction({ variables: { input: { id } } });
    } catch (error) {
      return false;
    }
    return true;
  };
  return deleteDocument;
}
